import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  inject,
  type OnDestroy,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { SubSink } from 'subsink';

@Directive({
  selector: '[opaFocusOutside]',
  standalone: true,
})
export class FocusOutsideDirective implements OnDestroy {
  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly ngZone = inject(NgZone);
  private readonly subSink = new SubSink();

  /** Classes to ignore */
  @Input() focusOutsideIgnore: string[] = [];
  @Output() readonly focusOutside = new EventEmitter<Event>();

  constructor() {
    this.ngZone.runOutsideAngular(() => {
      this.subSink.sink = fromEvent(window, 'focusin').subscribe((event) => {
        const target = event.target as Element;
        if (!(target instanceof Node)) return;

        if (this.focusOutsideIgnore.some((selector) => target.classList.contains(selector))) return;

        if (this.elementRef.nativeElement.contains(target)) return;

        this.ngZone.run(() => this.focusOutside.emit(event));
      });
    });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
