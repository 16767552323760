<form *ngIf="form" class="form" [formGroup]="form" (ngSubmit)="submit()">
  <ng-container *ngrxLet="formData$ as data; error as dataError; suspenseTpl: spinner">
    <div *ngIf="dataError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadFormData()"
      >
        Reload Form Data
      </p-button-pure>
    </div>
    <p-select-wrapper
      *ngrxLet="form.controls.inventoryItemId as control"
      [filter]="true"
      [label]="'Inventory Item'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <select [attr.required]="true" [formControl]="control">
        <option
          *ngFor="let inventoryItem of data.inventoryItems.content; trackBy: trackInventoryItem"
          [value]="inventoryItem.id"
        >
          {{ inventoryItem.model + ' | ' + inventoryItem.serialNumber }}
        </option>
      </select>
    </p-select-wrapper>

    <p-textarea-wrapper
      *ngrxLet="form.controls.description as control"
      [label]="'Description'"
      [message]="control.errors?.['message'] ?? ''"
      [showCounter]="true"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <textarea [formControl]="control" [maxlength]="textareaMaxLength"></textarea>
    </p-textarea-wrapper>

    <p-text-field-wrapper
      *ngrxLet="form.controls.sentToServiceDate as control"
      [label]="'Sent To Service Date'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <input type="date" [attr.required]="true" [formControl]="control" />
    </p-text-field-wrapper>

    <p-text-field-wrapper
      *ngrxLet="form.controls.returnedFromServiceDate as control"
      [label]="'Returned From Service Date'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <input type="date" [attr.required]="false" [formControl]="control" />
    </p-text-field-wrapper>

    <section [ngClass]="{ hidden: (formData$ | async) === null }" class="file-input">
      <p-text>Service document </p-text>
      <input type="file" (change)="onServiceDocumentChange($event)" />
    </section>

    <div class="actions">
      <p-button
        [icon]="'none'"
        [loading]="(submitting$ | async) ?? false"
        [type]="'submit'"
        [variant]="'primary'"
      >
        {{ inventoryItemServiceEntry?.id ? 'Edit' : 'Create' }} Service
      </p-button>
    </div>
  </ng-container>
</form>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
