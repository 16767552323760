<p-heading class="title" [size]="'x-large'" [tag]="'h3'">VPN Subscription Types</p-heading>

<p-divider aria-hidden="true" />

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/add-circle.svg'" />
      <span>Create New VPN Subscription Type</span>
    </div>
  </p-heading>

  <form class="form" [formGroup]="form" (ngSubmit)="submit()">
    <p-text-field-wrapper
      *ngrxLet="form.controls.name as control"
      [label]="'Name'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
    >
      <input type="text" [attr.required]="true" [formControl]="control" />
    </p-text-field-wrapper>

    <p-text-field-wrapper
      *ngrxLet="form.controls.cost as control"
      [label]="'Cost'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
      [unit]="'EUR'"
      [unitPosition]="'suffix'"
    >
      <input type="number" [attr.required]="true" [formControl]="control" />
    </p-text-field-wrapper>

    <p-text-field-wrapper
      *ngrxLet="form.controls.allowedMonthlyOverage as control"
      [label]="'Allowed Monthly Overage'"
      [message]="control.errors?.['message'] ?? ''"
      [state]="control.touched && control.errors ? 'error' : 'none'"
      [unit]="'EUR'"
      [unitPosition]="'suffix'"
    >
      <input type="number" [attr.required]="true" [formControl]="control" />
    </p-text-field-wrapper>

    <div class="actions">
      <p-button
        [icon]="'none'"
        [loading]="(submitting$ | async) ?? false"
        [type]="'submit'"
        [variant]="'primary'"
      >
        Create VPN Subscription Type
      </p-button>
    </div>
  </form>
</section>
