import type { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://opa-dev.porsche-digital.hr',
  auth: {
    issuer: 'https://opa-dev.porsche-digital.hr/auth/realms/OPAKeycloak',
    clientId: 'opa-fe-client',
    redirectUri: `${document.baseURI}redirect`,
    postLogoutRedirectUri: document.baseURI,
  },
};
