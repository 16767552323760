import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  EMPTY,
  distinctUntilChanged,
  merge,
  of,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import { objectsEqual } from '../../shared/helpers/objects-equal.helper';
import type { VpnPhoneNumber } from '../../shared/models/api/vpn-phone-number.model';
import { VpnPhoneNumberService } from '../../shared/services/api/vpn-phone-number.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { VpnPhoneNumberEditComponent } from './vpn-phone-number-edit/vpn-phone-number-edit.component';

@Component({
  selector: 'opa-vpn-phone-number-details',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    LetDirective,
    VpnPhoneNumberEditComponent,
  ],
  templateUrl: './vpn-phone-number-details.component.html',
  styleUrl: './vpn-phone-number-details.component.scss',
})
export class VpnPhoneNumberDetailsComponent {
  private readonly mediaQueryService = inject(MediaQueryService);
  private readonly route = inject(ActivatedRoute);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly vpnPhoneNumberService = inject(VpnPhoneNumberService);

  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  vpnPhoneNumber$ = this.getVpnPhoneNumber$();
  showEditModal = false;

  reloadVpnPhoneNumber(markForCheck = false, vpnPhoneNumber?: VpnPhoneNumber): void {
    this.vpnPhoneNumber$ = this.getVpnPhoneNumber$(vpnPhoneNumber);
    if (markForCheck) this.changeDetectorRef.markForCheck();
  }

  private getVpnPhoneNumber$(vpnPhoneNumber?: VpnPhoneNumber): Observable<VpnPhoneNumber> {
    const routeParams$ = merge(
      of(this.route.snapshot.params),
      this.route.params.pipe(
        startWith(this.route.snapshot.params),
        distinctUntilChanged((a, b) => objectsEqual(a, b)),
        skip(1),
        tap(() => this.reloadVpnPhoneNumber(true)),
        switchMap(() => EMPTY),
      ),
    );

    return routeParams$.pipe(
      switchMap((routeParams) => {
        if (vpnPhoneNumber) return of(vpnPhoneNumber);

        const vpnPhoneNumberId = Number(routeParams['id']);
        return this.vpnPhoneNumberService.getVpnPhoneNumber(vpnPhoneNumberId);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
