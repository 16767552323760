/** WARN: PDS variables */
export const PdsBreakpoints = {
  base: 0,
  xs: 480,
  s: 760,
  m: 1000,
  l: 1300,
  xl: 1760,
  xxl: 1920,
} as const;
export type PdsBreakpoint = keyof typeof PdsBreakpoints;
