import { CommonModule } from '@angular/common';
import { Component, ViewChild, inject, type OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  EMPTY,
  distinctUntilChanged,
  merge,
  of,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import { objectsEqual } from '../../shared/helpers/objects-equal.helper';
import type {
  CreateOrEditInventoryItem,
  InventoryItem,
} from '../../shared/models/api/inventory-item.model';
import { InventoryItemsService } from '../../shared/services/api/inventory-items.service';
import { InventoryItemFormComponent } from '../inventory-item-form/inventory-item-form.component';

@Component({
  selector: 'opa-inventory-item-edit',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    LetDirective,
    TranslocoPipe,
    RouterLink,
    InventoryItemFormComponent,
  ],
  templateUrl: './inventory-item-edit.component.html',
  styleUrl: './inventory-item-edit.component.scss',
})
export class InventoryItemEditComponent implements OnDestroy {
  @ViewChild(InventoryItemFormComponent) form?: InventoryItemFormComponent;

  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly inventoryItemsService = inject(InventoryItemsService);

  private readonly subSink = new SubSink();
  inventoryItem$ = this.getInventoryItem();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  downloadWarranty(itemId: number): void {
    this.subSink.sink = this.inventoryItemsService
      .downloadInventoryItemWarranty(itemId)
      .subscribe();
  }

  downloadReceipt(itemId: number): void {
    this.subSink.sink = this.inventoryItemsService.downloadInventoryItemReceipt(itemId).subscribe();
  }

  onSubmit(data: CreateOrEditInventoryItem): void {
    this.subSink.sink = this.inventoryItem$
      .pipe(
        switchMap((item) =>
          this.inventoryItemsService.edit(item.id, data).pipe(
            tap({
              next: (editItem) => {
                this.toastManager.addMessage({
                  text: `Inventory item ${editItem.manufacturer.description} ${editItem.model} updated successfully.`,
                  state: 'success',
                });
                this.router.navigate(['/inventory-items', editItem.id]);
              },
              error: () => this.form?.finalizeSubmit(),
            }),
          ),
        ),
      )
      .subscribe();
  }

  private getInventoryItem(): Observable<InventoryItem> {
    const routeParams$ = merge(
      of(this.route.snapshot.params),
      this.route.params.pipe(
        startWith(this.route.snapshot.params),
        distinctUntilChanged((a, b) => objectsEqual(a, b)),
        skip(1),
        tap(() => this.reloadInventoryItem()),
        switchMap(() => EMPTY),
      ),
    );

    return routeParams$.pipe(
      switchMap((routeParams) => {
        const id = Number(routeParams['id']);
        return this.inventoryItemsService.getInventoryItem(id);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private reloadInventoryItem(): void {
    this.inventoryItem$ = this.getInventoryItem();
  }
}
