<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Budget Corrections</p-heading>

  <p-link
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/budget-corrections/create'">Create New Budget Correction</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="list">
  <p-heading
    *ngrxLet="budgetCorrections$ | async as budgetCorrections"
    [size]="'small'"
    [tag]="'h4'"
  >
    {{ budgetCorrections?.totalElements }} Budget Corrections
  </p-heading>

  <ng-container
    *ngrxLet="
      budgetCorrections$ as budgetCorrections;
      error as budgetCorrectionsError;
      suspenseTpl: spinner
    "
  >
    <div *ngIf="budgetCorrectionsError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadBudgetCorrections()"
      >
        Reload Budget Corrections
      </p-button-pure>
    </div>

    <ng-container *ngIf="!budgetCorrectionsError && budgetCorrections">
      <div class="overlay-spinner-host">
        <ng-container *ngIf="loading$ | async" [ngTemplateOutlet]="overlaySpinner" />

        <p-table caption="Budget corrections list table">
          <p-table-head>
            <p-table-head-row>
              <p-table-head-cell style="width: 20%">Date</p-table-head-cell>
              <p-table-head-cell style="width: 20%">People</p-table-head-cell>
              <p-table-head-cell style="width: 20%">Correction Amount</p-table-head-cell>
              <p-table-head-cell style="width: 40%">Description</p-table-head-cell>
            </p-table-head-row>
          </p-table-head>

          <p-table-body>
            <p-table-row
              *ngFor="
                let budgetCorrection of budgetCorrections.content
                  | fillArray: budgetCorrections.pageable.pageSize;
                trackBy: trackRow
              "
            >
              <p-table-cell>
                <ng-container *ngIf="budgetCorrection; else emptyCell">
                  <a [routerLink]="['/budget-corrections', budgetCorrection.id]">
                    {{ budgetCorrection.date | date: 'dd.MM.yyyy' }}
                  </a>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="budgetCorrection; else emptyCell">
                  {{ budgetCorrection.users.length }} people
                  <p-popover [direction]="'right'">
                    <div class="popover-content">
                      <p-text *ngFor="let user of budgetCorrection.users; trackBy: trackUser">
                        {{ user.firstName }} {{ user.lastName }}
                      </p-text>
                    </div>
                  </p-popover>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="budgetCorrection; else emptyCell">
                  {{ budgetCorrection.amount | currency: 'EUR' | leadingPlus }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="budgetCorrection; else emptyCell">
                  <div class="description-cell">
                    {{ budgetCorrection.description }}
                  </div>
                </ng-container>
              </p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>
      </div>

      <p-pagination
        [totalItemsCount]="budgetCorrections.totalElements"
        [itemsPerPage]="budgetCorrections.size"
        [activePage]="budgetCorrections.pageable.pageNumber + 1"
        (update)="updatePageNo($event.detail.page)"
      />
    </ng-container>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>

<ng-template #overlaySpinner>
  <div class="overlay-spinner"><p-spinner /></div>
</ng-template>

<ng-template #emptyCell>
  <span class="empty-cell"></span>
</ng-template>
