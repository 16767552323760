import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject, type OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, distinctUntilChanged, map, tap } from 'rxjs';
import { SubSink } from 'subsink';
import type {
  InventoryItem,
  UnassignInventoryItem,
} from '../../../shared/models/api/inventory-item.model';
import type { InventoryLocation } from '../../../shared/models/api/inventory-location.model';
import type { ControlsOf } from '../../../shared/models/controls-of.model';
import { InventoryItemsService } from '../../../shared/services/api/inventory-items.service';
import { InventoryLocationService } from '../../../shared/services/api/inventory-location.service';
import { OpaValidators } from '../../../shared/validators/opa.validators';
import type { UnassignInventoryItemFormValue } from './unassign-inventory-item.models';

@Component({
  selector: 'opa-unassign-inventory-item',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, ReactiveFormsModule, LetDirective],
  templateUrl: './unassign-inventory-item.component.html',
  styleUrl: './unassign-inventory-item.component.scss',
})
export class UnassignInventoryItemComponent implements OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly inventoryItemsService = inject(InventoryItemsService);
  private readonly toastManager = inject(ToastManager);
  readonly locations$ = inject(InventoryLocationService)
    .getInventoryLocations()
    .pipe(map((locations) => locations.filter((location) => !location.requiresAssignee)));

  @Input({ required: true }) inventoryItem?: InventoryItem;
  @Output() unassignSuccessful = new EventEmitter<undefined>();

  open = false;
  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.pipe(distinctUntilChanged());

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(success?: boolean): void {
    this.open = false;
    this.form.reset();

    if (success) this.unassignSuccessful.emit();
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const unassignRequest = this.formValueToUnassignRequest(formValue);
    this.subSink.sink = this.inventoryItemsService
      .unassign(unassignRequest)
      .pipe(
        tap({
          next: (item: InventoryItem) => {
            this.toastManager.addMessage({
              text: `Successfully unassigned item and moved it to ${item.location.description}`,
              state: 'success',
            });
            this.submitting.next(false);
            this.close(true);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<UnassignInventoryItemFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<UnassignInventoryItemFormValue>>({
      locationId: this.formBuilder.control(null, OpaValidators.required()),
    });

    return form;
  }

  private formValueToUnassignRequest(form: UnassignInventoryItemFormValue): UnassignInventoryItem {
    const request: UnassignInventoryItem = {
      locationId: form.locationId!,
      inventoryItemId: this.inventoryItem!.id,
    };

    return request;
  }

  trackInventoryLocation(index: number, location: InventoryLocation): number {
    return location.id;
  }
}
