import { Injectable } from '@angular/core';
import type { Translation, TranslocoLoader } from '@ngneat/transloco';
import { from, type Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslocoHttpLoader implements TranslocoLoader {
  getTranslation(lang: string): Observable<Translation> {
    return from(import(`../../../i18n/${lang}.json`).then((res) => res.default));
  }
}
