<p-modal [heading]="'Edit VPN Phone Number'" [open]="open" (dismiss)="close()">
  <form
    *ngrxLet="data$ as data; error as dataError; suspenseTpl: spinner"
    class="form"
    [formGroup]="form"
    (ngSubmit)="submit()"
  >
    <div *ngIf="dataError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        [type]="'button'"
        (click)="reloadData()"
      >
        Reload Edit VPN Phone Number
      </p-button-pure>
    </div>

    <ng-container *ngIf="!dataError && data">
      <div class="form-controls">
        <p-select-wrapper
          *ngrxLet="form.controls.userId as control"
          [filter]="true"
          [label]="'Person'"
          [message]="control.errors?.['message'] ?? ''"
          [state]="control.touched && control.errors ? 'error' : 'none'"
        >
          <select [attr.required]="true" [formControl]="control">
            <option *ngFor="let user of data.users; trackBy: trackUser" [value]="user.id">
              {{ user.firstName }} {{ user.lastName }}
            </option>
          </select>
        </p-select-wrapper>

        <p-text-field-wrapper
          *ngrxLet="form.controls.vpnNumber as control"
          [label]="'VPN Number'"
          [message]="control.errors?.['message'] ?? ''"
          [state]="control.touched && control.errors ? 'error' : 'none'"
        >
          <input type="text" [attr.required]="true" [formControl]="control" />
        </p-text-field-wrapper>

        <p-text-field-wrapper
          *ngrxLet="form.controls.fullNumber as control"
          [label]="'Full Number'"
          [message]="control.errors?.['message'] ?? ''"
          [state]="control.touched && control.errors ? 'error' : 'none'"
        >
          <input type="text" [attr.required]="true" [formControl]="control" />
        </p-text-field-wrapper>

        <p-select-wrapper
          *ngrxLet="form.controls.subscriptionTypeId as control"
          [filter]="true"
          [label]="'VPN Subscription Type'"
          [message]="control.errors?.['message'] ?? ''"
          [state]="control.touched && control.errors ? 'error' : 'none'"
        >
          <select [attr.required]="true" [formControl]="control">
            <option
              *ngFor="
                let vpnSubscriptionType of data.vpnSubscriptionTypes;
                trackBy: trackVpnSubscriptionType
              "
              [value]="vpnSubscriptionType.id"
            >
              {{ vpnSubscriptionType.name }}
            </option>
          </select>
        </p-select-wrapper>

        <p-switch
          *ngrxLet="form.controls.additionalOptionActivation as control"
          [disabled]="control.disabled"
          [checked]="control.value ?? false"
          (update)="control.patchValue($event.detail.checked)"
        >
          Additional Option Activation
        </p-switch>

        <ng-container *ngIf="form.controls.additionalOptionActivation.value">
          <p-text-field-wrapper
            *ngrxLet="form.controls.additionalOptionDescription as control"
            [label]="'Additional Option Description'"
            [message]="control.errors?.['message'] ?? ''"
            [state]="control.touched && control.errors ? 'error' : 'none'"
          >
            <input type="text" [attr.required]="true" [formControl]="control" />
          </p-text-field-wrapper>
        </ng-container>

        <p-switch
          *ngrxLet="form.controls.contractObligation as control"
          [disabled]="control.disabled"
          [checked]="control.value ?? false"
          (update)="control.patchValue($event.detail.checked)"
        >
          Contract Obligation
        </p-switch>

        <ng-container *ngIf="form.controls.contractObligation.value">
          <p-text-field-wrapper
            *ngrxLet="form.controls.contractStartDate as control"
            [label]="'Contract Start Date'"
            [message]="control.errors?.['message'] ?? ''"
            [state]="control.touched && control.errors ? 'error' : 'none'"
          >
            <input type="date" [attr.required]="true" [formControl]="control" />
          </p-text-field-wrapper>

          <p-text-field-wrapper
            *ngrxLet="form.controls.contractEndDate as control"
            [label]="'Contract End Date'"
            [message]="control.errors?.['message'] ?? ''"
            [state]="control.touched && control.errors ? 'error' : 'none'"
          >
            <input type="date" [attr.required]="true" [formControl]="control" />
          </p-text-field-wrapper>
        </ng-container>

        <p-switch
          *ngrxLet="form.controls.active as control"
          [disabled]="control.disabled"
          [checked]="control.value ?? false"
          (update)="control.patchValue($event.detail.checked)"
        >
          Active
        </p-switch>
      </div>

      <p-button-group class="modal-actions">
        <p-button
          [icon]="'none'"
          [loading]="data.submitting"
          [type]="'submit'"
          [variant]="'primary'"
        >
          Save
        </p-button>
        <p-button
          [iconSource]="'assets/icons/close.svg'"
          [type]="'button'"
          [variant]="'secondary'"
          (click)="close()"
        >
          Close
        </p-button>
      </p-button-group>
    </ng-container>
  </form>
</p-modal>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
