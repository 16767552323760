export enum NumberComparator {
  EQUALS = 'EQUALS',
  NOT_EQUAL = 'NOT_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
}

export const numberComparatorTranslation: Readonly<Record<NumberComparator, string>> = {
  [NumberComparator.EQUALS]: 'NUMBER_COMPARATOR.EQUALS',
  [NumberComparator.NOT_EQUAL]: 'NUMBER_COMPARATOR.NOT_EQUAL',
  [NumberComparator.LESS_THAN]: 'NUMBER_COMPARATOR.LESS_THAN',
  [NumberComparator.LESS_THAN_OR_EQUAL]: 'NUMBER_COMPARATOR.LESS_THAN_OR_EQUAL',
  [NumberComparator.GREATER_THAN]: 'NUMBER_COMPARATOR.GREATER_THAN',
  [NumberComparator.GREATER_THAN_OR_EQUAL]: 'NUMBER_COMPARATOR.GREATER_THAN_OR_EQUAL',
};
