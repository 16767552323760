<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">VPN Subscription Types</p-heading>

  <p-link
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/vpn-subscription-types/create'">Create New VPN Subscription Type</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="filters">
  <p-text-field-wrapper
    class="main-filter"
    [hideLabel]="true"
    [label]="'Search VPN Subscription Types'"
  >
    <input
      type="search"
      [formControl]="form.controls.name"
      [placeholder]="'Search VPN Subscription Types'"
    />
  </p-text-field-wrapper>
</section>

<section class="list">
  <p-heading
    *ngrxLet="vpnSubscriptionTypes$ | async as vpnSubscriptionTypes"
    [size]="'small'"
    [tag]="'h4'"
  >
    {{ vpnSubscriptionTypes?.totalElements }} VPN Subscription Types
  </p-heading>

  <ng-container
    *ngrxLet="
      vpnSubscriptionTypes$ as vpnSubscriptionTypes;
      error as vpnSubscriptionTypesError;
      suspenseTpl: spinner
    "
  >
    <div *ngIf="vpnSubscriptionTypesError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadVpnSubscriptionTypes()"
      >
        Reload VPN Subscription Types
      </p-button-pure>
    </div>

    <ng-container *ngIf="!vpnSubscriptionTypesError && vpnSubscriptionTypes">
      <div class="overlay-spinner-host">
        <ng-container *ngIf="loading$ | async" [ngTemplateOutlet]="overlaySpinner" />

        <p-table caption="VPN Subscription Types list table">
          <p-table-head>
            <p-table-head-row>
              <p-table-head-cell style="width: 1%">Edit</p-table-head-cell>
              <p-table-head-cell style="width: 24%">Name</p-table-head-cell>
              <p-table-head-cell style="width: 25%">Cost</p-table-head-cell>
              <p-table-head-cell style="width: 25%">Allowed Monthly Overage</p-table-head-cell>
              <p-table-head-cell style="width: 25%">Status</p-table-head-cell>
            </p-table-head-row>
          </p-table-head>

          <p-table-body>
            <p-table-row
              *ngFor="
                let vpnSubscriptionType of vpnSubscriptionTypes.content
                  | fillArray: vpnSubscriptionTypes.pageable.pageSize;
                trackBy: trackRow
              "
            >
              <p-table-cell>
                <ng-container *ngIf="vpnSubscriptionType; else emptyCell">
                  <p-button-pure
                    [iconSource]="'assets/icons/edit.svg'"
                    [hideLabel]="true"
                    (click)="
                      showVpnSubscriptionTypeModal = true;
                      editVpnSubscriptionType = vpnSubscriptionType
                    "
                  >
                    Edit {{ vpnSubscriptionType.name }}
                  </p-button-pure>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="vpnSubscriptionType; else emptyCell">
                  {{ vpnSubscriptionType.name }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="vpnSubscriptionType; else emptyCell">
                  {{ vpnSubscriptionType.cost | currency: 'EUR' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="vpnSubscriptionType; else emptyCell">
                  {{ vpnSubscriptionType.allowedMonthlyOverage | currency: 'EUR' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="vpnSubscriptionType; else emptyCell">
                  <div
                    class="status"
                    [ngClass]="{
                      'status--active': vpnSubscriptionType.active,
                      'status--inactive': !vpnSubscriptionType.active
                    }"
                  ></div>
                </ng-container>
              </p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>
      </div>

      <p-pagination
        [totalItemsCount]="vpnSubscriptionTypes.totalElements"
        [itemsPerPage]="vpnSubscriptionTypes.size"
        [activePage]="vpnSubscriptionTypes.pageable.pageNumber + 1"
        (update)="updatePageNo($event.detail.page)"
      />
    </ng-container>
  </ng-container>
</section>

<opa-vpn-subscription-type-edit
  [open]="showVpnSubscriptionTypeModal"
  [vpnSubscriptionType]="editVpnSubscriptionType"
  (dismiss)="showVpnSubscriptionTypeModal = false; $event && reloadVpnSubscriptionTypes()"
/>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>

<ng-template #overlaySpinner>
  <div class="overlay-spinner"><p-spinner /></div>
</ng-template>

<ng-template #emptyCell>
  <span class="empty-cell"></span>
</ng-template>
