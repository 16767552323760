<p-heading class="title" [size]="'x-large'" [tag]="'h3'">Inventory Item Service</p-heading>

<p-divider aria-hidden="true" />

<section
  *ngrxLet="inventoryItemService$ | async as inventoryItemService; suspenseTpl: spinner"
  class="section"
>
  <div class="breadcrumbs">
    <p-link-pure [hideLabel]="true" [iconSource]="'assets/icons/arrow-left.svg'">
      <a
        *ngIf="inventoryItemService"
        [routerLink]="['/inventory-item-services', inventoryItemService.id]"
      >
        Back to Inventory Item Service
      </a>
      <a *ngIf="!inventoryItemService" [routerLink]="['/inventory-item-services']">Back to List</a>
    </p-link-pure>

    <p-heading [size]="'large'" [tag]="'h4'">
      @if (!inventoryItemService) {
        &nbsp;
      } @else {
        {{ inventoryItemService.inventoryItem.model }}
        {{ inventoryItemService.inventoryItem.serialNumber }}
      }
    </p-heading>
  </div>

  <opa-inventory-item-service-form
    [inventoryItemServiceEntry]="inventoryItemService"
    (submitForm)="onSubmit($event)"
  />
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
