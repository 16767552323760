<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Inventory Item Service</p-heading>

  <p-link
    *ngIf="isInventoryAdmin"
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/inventory-item-services/create'">Create New Service</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="list">
  <p-heading
    *ngrxLet="inventoryItemServices$ | async as inventoryItemServices"
    [size]="'small'"
    [tag]="'h4'"
  >
    <div class="list-heading">
      <span>{{ inventoryItemServices?.totalElements }} Inventory Item Services</span>

      <p-button-pure
        *ngIf="inventoryItemServices"
        [iconSource]="'assets/icons/reset.svg'"
        [underline]="true"
        (click)="resetFilters()"
      >
        Reset Filters
      </p-button-pure>
    </div>
  </p-heading>

  <ng-container
    *ngrxLet="
      inventoryItemServices$ as inventoryItemServices;
      error as inventoryItemServicesError;
      suspenseTpl: spinner
    "
  >
    <div *ngIf="inventoryItemServicesError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadInventoryItemServices()"
      >
        Reload Inventory Item Services
      </p-button-pure>
    </div>

    <ng-container *ngIf="!inventoryItemServicesError && inventoryItemServices">
      <div class="overlay-spinner-host">
        <ng-container *ngIf="loading$ | async" [ngTemplateOutlet]="overlaySpinner" />

        <p-table caption="Inventory item services list table">
          <p-table-head *ngrxLet="sort$ as activeSort">
            <p-table-head-row>
              <p-table-head-cell
                *ngrxLet="{
                  label: 'Service Id',
                  sortId: 'id'
                } as cell"
                style="width: 10%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>
              <p-table-head-cell
                *ngrxLet="{
                  label: 'Inventory Item',
                  control: form.controls.inventoryItemNameOrSerialNumber,
                  sortId: 'inventoryItem.id'
                } as cell"
                style="width: 20%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-filter [active]="!!cell.control.value">
                  <p-text-field-wrapper [label]="cell.label">
                    <input type="text" [formControl]="cell.control" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: 'Description',
                  sortId: 'description'
                } as cell"
                style="width: 20%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: 'Sent To Service Date',
                  sentToService: form.controls.sentToServiceDate,
                  sortId: 'sentToServiceDate'
                } as cell"
                style="width: 20%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-filter [active]="!!cell.sentToService.value">
                  <p-text-field-wrapper [label]="cell.label">
                    <input type="date" [formControl]="cell.sentToService" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: 'Returned From Service Date',
                  returnedFromService: form.controls.returnedFromServiceDate,
                  sortId: 'returnedFromServiceDate'
                } as cell"
                style="width: 20%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-filter [active]="!!cell.returnedFromService.value">
                  <p-text-field-wrapper [label]="cell.label">
                    <input type="date" [formControl]="cell.returnedFromService" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>
            </p-table-head-row>
          </p-table-head>

          <p-table-body>
            <p-table-row
              *ngFor="
                let inventoryItemService of inventoryItemServices.content
                  | fillArray: inventoryItemServices.pageable.pageSize;
                trackBy: trackRow
              "
            >
              <p-table-cell>
                <ng-container *ngIf="inventoryItemService; else emptyCell">
                  <a [routerLink]="['/inventory-item-services', inventoryItemService.id]">{{
                    inventoryItemService.id
                  }}</a>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="inventoryItemService; else emptyCell">
                  <a [routerLink]="['/inventory-items', inventoryItemService.inventoryItem.id]">{{
                    inventoryItemService.inventoryItem.model +
                      ' | ' +
                      inventoryItemService.inventoryItem.serialNumber
                  }}</a>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="inventoryItemService; else emptyCell">
                  {{ inventoryItemService.description }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="inventoryItemService; else emptyCell">
                  {{ inventoryItemService.sentToServiceDate | date: 'dd.MM.yyyy.' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="inventoryItemService; else emptyCell">
                  {{ inventoryItemService.returnedFromServiceDate | date: 'dd.MM.yyyy.' }}
                </ng-container>
              </p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>
      </div>

      <p-pagination
        [totalItemsCount]="inventoryItemServices.totalElements"
        [itemsPerPage]="inventoryItemServices.size"
        [activePage]="inventoryItemServices.pageable.pageNumber + 1"
        (update)="updatePageNo($event.detail.page)"
      />
    </ng-container>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>

<ng-template #overlaySpinner>
  <div class="overlay-spinner"><p-spinner /></div>
</ng-template>

<ng-template #emptyCell>
  <span class="empty-cell"></span>
</ng-template>
