import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import type { TableSort } from '../../components/table-sort/table-sort.models';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type { CreateEvent, Event, EventFilter } from '../../models/api/event.model';
import type { Page } from '../../models/api/page.model';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private readonly httpClient = inject(HttpClient);
  private readonly eventApi = `${environment.apiUrl}/v1/events`;

  getEvents(pageNo: number, filter?: EventFilter, sort?: TableSort): Observable<Page<Event>> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Events');

    let params = new HttpParams().set('page', pageNo).set('size', 10);

    if (filter) {
      Object.entries(filter)
        .filter(([, value]) => value !== undefined)
        .forEach(([key, value]) => (params = params.set(key, value)));
    }
    if (sort) params = params.set('sort', `${sort.sortId},${sort.direction}`);

    return this.httpClient.get<Page<Event>>(this.eventApi, { context, params });
  }

  getMyEvents(pageNo: number, filter?: EventFilter, sort?: TableSort): Observable<Page<Event>> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get My Events');

    let params = new HttpParams().set('page', pageNo).set('size', 10);

    if (filter) {
      Object.entries(filter)
        .filter(([, value]) => value !== undefined)
        .forEach(([key, value]) => (params = params.set(key, value)));
    }
    if (sort) params = params.set('sort', `${sort.sortId},${sort.direction}`);

    return this.httpClient.get<Page<Event>>(`${this.eventApi}/my-events`, { context, params });
  }

  getEvent(eventId: number): Observable<Event> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Event');
    return this.httpClient.get<Event>(`${this.eventApi}/${eventId}`, { context });
  }

  createEvent(event: CreateEvent): Observable<Event> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Create Event');
    return this.httpClient.post<Event>(this.eventApi, event, { context });
  }
}
