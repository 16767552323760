import { BudgetType } from '../../shared/models/api/edu-expense.model';
import { EduTransactionLogType } from '../../shared/models/api/edu-transaction-log.model';

export const transactionLogTypeTranslation: Readonly<Record<EduTransactionLogType, string>> = {
  [EduTransactionLogType.EDU_EXPENSE]: 'EDU.TRANSACTION_LOG.TYPE.EDU_EXPENSE',
  [EduTransactionLogType.EQU_EXPENSE]: 'EDU.TRANSACTION_LOG.TYPE.EQU_EXPENSE',
  [EduTransactionLogType.TOTAL_BUDGET_CHANGE]: 'EDU.TRANSACTION_LOG.TYPE.TOTAL_BUDGET_CHANGE',
  [EduTransactionLogType.TOTAL_BUDGET_AVAILABLE]: 'EDU.TRANSACTION_LOG.TYPE.TOTAL_BUDGET_AVAILABLE',
  [EduTransactionLogType.TOTAL_BUDGET_PREVIOUSLY_REMAINING]:
    'EDU.TRANSACTION_LOG.TYPE.TOTAL_BUDGET_PREVIOUSLY_REMAINING',
};

export const BudgetTypeToTransactionLogTypeMap = {
  [BudgetType.EDU]: EduTransactionLogType.EDU_EXPENSE,
  [BudgetType.EQU]: EduTransactionLogType.EQU_EXPENSE,
};
