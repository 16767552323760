import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  type Observable,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VisibleColumnsService {
  readonly visibleColumnLocalStoragePrefix = 'visible-columns-' as const;
  private readonly columnChanges = new BehaviorSubject<void>(undefined);

  visibleColumns$(table: string): Observable<string[]> {
    return this.columnChanges.pipe(
      startWith(undefined),
      map(() => this.getVisibleColumns(table)),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  isColumnVisible$(table: string, column: string): Observable<boolean> {
    return this.visibleColumns$(table).pipe(
      map((visibleColumns) => visibleColumns.includes(column)),
      distinctUntilChanged(),
    );
  }

  setVisibleColumns(table: string, columns: string[]): void {
    localStorage.setItem(this.getVisibleColumnLocalStorageKey(table), JSON.stringify(columns));
    this.columnChanges.next();
  }

  toggleColumnVisibility(table: string, column: string): void {
    const visibleColumns = this.getVisibleColumns(table);
    const newVisibleColumns = visibleColumns.includes(column)
      ? visibleColumns.filter((c) => c !== column)
      : [...visibleColumns, column];

    this.setVisibleColumns(table, newVisibleColumns);
  }

  getVisibleColumns(table: string): string[] {
    const visibleColumns = localStorage.getItem(this.getVisibleColumnLocalStorageKey(table));
    return visibleColumns ? JSON.parse(visibleColumns) : [];
  }

  private getVisibleColumnLocalStorageKey(table: string): string {
    return `${this.visibleColumnLocalStoragePrefix}${table}`;
  }
}
