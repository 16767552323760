import type { RemoteUserTripPurpose } from './remote-user-trip-purpose.model';

export interface PersonalTravelTransactionLog {
  records: PersonalTravelTransactionLogForYear[];
}

export interface PersonalTravelTransactionLogForYear {
  yearTransactionLog: PersonalTravelTransactionLogForMonth[];
  year: number;
}

export interface PersonalTravelTransactionLogForMonth {
  monthTransactionLog: PersonalTravelTransactionLogRecord[];
  monthName: string;
}

export interface PersonalTravelTransactionLogRecord {
  id: number;
  type: PersonalTravelTransactionLogType;
  tripPurpose: RemoteUserTripPurpose | null;
  date: string;
  amount: number;
  amountBefore: number;
  amountAfter: number;
}

export enum PersonalTravelTransactionLogType {
  ALLOWANCE = 'ALLOWANCE',
  COST = 'COST',
  REMAINING = 'REMAINING',
  AVAILABLE = 'AVAILABLE',
}
