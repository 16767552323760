<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Edu & Equ Transactions</p-heading>

  <p-link
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/edu-transactions/create'">Create New Transaction</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/add-circle.svg'" />
      <span>Import Edu Budget</span>
    </div>
  </p-heading>

  <form class="form" [formGroup]="form" (ngSubmit)="submit()">
    <div class="file-input">
      <div class="label">
        <p-text>Spreadsheet *</p-text>

        <p-button-pure
          [iconSource]="'assets/icons/download.svg'"
          [loading]="(downloading$ | async) ?? false"
          [type]="'button'"
          [underline]="true"
          (click)="downloadTemplate()"
        >
          Download Template
        </p-button-pure>
      </div>
      <input type="file" (change)="fileChange($event, 'spreadsheet')" />
    </div>

    <div class="actions">
      <p-button
        [icon]="'none'"
        [loading]="(submitting$ | async) ?? false"
        [type]="'submit'"
        [variant]="'primary'"
      >
        Import Edu Budget
      </p-button>
    </div>
  </form>
</section>
