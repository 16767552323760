import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  type OnChanges,
  type OnDestroy,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import type { AddGroupMembers, Group } from '../../../shared/models/api/group.model';
import type { User } from '../../../shared/models/api/user.model';
import type { ControlsOf } from '../../../shared/models/controls-of.model';
import type { NgChanges } from '../../../shared/models/ng-changes.model';
import { FindUserPipe } from '../../../shared/pipes/find-user.pipe';
import { GroupService } from '../../../shared/services/api/group.service';
import { UserService } from '../../../shared/services/api/user.service';
import { OpaValidators } from '../../../shared/validators/opa.validators';
import { FilterSelectedUsersPipe } from '../../event-create/filter-selected-users.pipe';
import { JoinUsersPipe } from '../../event-create/join-users.pipe';
import { StringSplitPipe } from '../../event-create/string-split.pipe';
import { FilterGroupMembersPipe } from './filter-group-members.pipe';
import type { AddGroupMembersData, AddGroupMembersFormValue } from './group-members-add.models';

@Component({
  selector: 'opa-group-members-add',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    ReactiveFormsModule,
    LetDirective,
    FilterGroupMembersPipe,
    FilterSelectedUsersPipe,
    JoinUsersPipe,
    StringSplitPipe,
    FindUserPipe,
  ],
  templateUrl: './group-members-add.component.html',
  styleUrl: './group-members-add.component.scss',
})
export class GroupMembersAddComponent implements OnChanges, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly userService = inject(UserService);
  private readonly groupService = inject(GroupService);
  private readonly toastManager = inject(ToastManager);

  @Input({ required: true }) open = false;
  @Input({ required: true }) group: Group | undefined = undefined;
  @Output() dismiss = new EventEmitter<Group | undefined>();

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  data$: Observable<AddGroupMembersData> | undefined = undefined;

  ngOnChanges(changes: NgChanges<GroupMembersAddComponent>): void {
    if (changes.open && this.open && !this.data$) this.reloadData();
    if ((changes.open && this.open) || changes.group) this.form.reset();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(group?: Group): void {
    this.dismiss.emit(group);
  }

  reloadData(): void {
    this.data$ = this.getData$();
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  removeMember(userId: string): void {
    // TODO: move to previous or next participant or back to first focusable element before this button
    const control = this.form.controls.members;
    const selected = control.value?.split(',') ?? [];
    const value = selected.filter((id) => id !== userId).join(',') || null;
    control.patchValue(value);
    control.markAsTouched();
    control.markAsDirty();
  }

  submit(): void {
    const group = this.group;
    if (!group) return;

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const groupMembers = this.formValueToGroupMembers(formValue);
    this.subSink.sink = this.groupService
      .addGroupMembers(group.id, groupMembers)
      .pipe(
        tap({
          next: (updatedGroup) => {
            this.toastManager.addMessage({
              text: `${groupMembers.members.length} members added to group ${updatedGroup.name}`,
              state: 'success',
            });
            this.submitting.next(false);
            this.close(updatedGroup);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<AddGroupMembersFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<AddGroupMembersFormValue>>({
      members: this.formBuilder.control(null, OpaValidators.required()),
    });
    return form;
  }

  private formValueToGroupMembers(form: AddGroupMembersFormValue): AddGroupMembers {
    const group: AddGroupMembers = {
      members: form.members!.split(',').map((id) => Number(id)),
    };
    return group;
  }

  private getData$(): Observable<AddGroupMembersData> {
    const users$ = this.userService.getActiveUsers();
    const submitting$ = this.submitting.pipe(distinctUntilChanged());
    return combineLatest([users$, submitting$]).pipe(
      map(([users, submitting]) => ({ users, submitting })),
    );
  }
}
