import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  distinctUntilChanged,
  EMPTY,
  merge,
  type Observable,
  of,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { objectsEqual } from '../../shared/helpers/objects-equal.helper';
import type { Group } from '../../shared/models/api/group.model';
import type { User } from '../../shared/models/api/user.model';
import { GroupService } from '../../shared/services/api/group.service';
import { UserService } from '../../shared/services/api/user.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { groupTypeTranslation } from '../group-list/group-list.helpers';
import { UserEditComponent } from './user-edit/user-edit.component';

@Component({
  selector: 'opa-user-details',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    LetDirective,
    TranslocoPipe,
    UserEditComponent,
  ],
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.scss',
})
export class UserDetailsComponent {
  private readonly mediaQueryService = inject(MediaQueryService);
  private readonly route = inject(ActivatedRoute);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly userService = inject(UserService);
  private readonly groupService = inject(GroupService);
  readonly groupTypeTranslation = groupTypeTranslation;

  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  user$ = this.getUser$();
  groups$ = this.getGroups$();
  showEditModal = false;

  reloadUser(markForCheck = false, user?: User): void {
    this.user$ = this.getUser$(user);
    if (markForCheck) this.changeDetectorRef.markForCheck();
  }

  trackGroup(index: number, group: Group): number {
    return group.id;
  }

  private getUser$(user?: User): Observable<User> {
    const routeParams$ = merge(
      of(this.route.snapshot.params),
      this.route.params.pipe(
        startWith(this.route.snapshot.params),
        distinctUntilChanged((a, b) => objectsEqual(a, b)),
        skip(1),
        tap(() => this.reloadUser(true)),
        switchMap(() => EMPTY),
      ),
    );

    return routeParams$.pipe(
      switchMap((routeParams) => {
        if (user) return of(user);

        const userId = Number(routeParams['id']);
        return this.userService.getUser(userId);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private getGroups$(): Observable<Group[]> {
    const routeParams$ = merge(
      of(this.route.snapshot.params),
      this.route.params.pipe(
        startWith(this.route.snapshot.params),
        distinctUntilChanged((a, b) => objectsEqual(a, b)),
        skip(1),
        tap(() => this.reloadUser(true)),
        switchMap(() => EMPTY),
      ),
    );

    return routeParams$.pipe(
      switchMap((routeParams) => {
        const userId = Number(routeParams['id']);
        return this.groupService.getUserGroups(userId);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
