import { HttpClient, HttpContext, HttpHeaders, type HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, tap, type Observable } from 'rxjs';
import { ACTION_ERROR_TITLE } from '../interceptors/http-error.interceptor';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  private readonly httpClient = inject(HttpClient);
  private readonly MIME = {
    CSV: 'text/csv',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  downloadFile(
    url: string,
    errorMessage: string,
    params?: HttpParams,
    format?: 'CSV' | 'XLSX',
  ): Observable<void> {
    let headers = new HttpHeaders();
    if (format) headers = headers.set('Accept', this.MIME[format]);

    const context = new HttpContext().set(ACTION_ERROR_TITLE, errorMessage);

    return this.httpClient
      .get(url, {
        headers,
        observe: 'response',
        context,
        params,
        responseType: 'blob',
      })
      .pipe(
        tap((response) => {
          if (!response.body) throw new Error('No file found');

          const blob = response.body;
          const fileName =
            response.headers
              .get('Content-Disposition')
              ?.split(';')
              .map((item) => item.trim())
              .find((item) => item.startsWith('filename='))
              ?.replace('filename=', '')
              ?.replaceAll('"', '') ?? 'file';

          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = fileName;
          a.click();
          URL.revokeObjectURL(a.href);
        }),
        map(() => undefined),
      );
  }
}
