<p-modal [heading]="'Add Members'" [open]="open" (dismiss)="close()">
  <form
    *ngrxLet="data$ as data; error as dataError; suspenseTpl: spinner"
    class="form"
    [formGroup]="form"
    (ngSubmit)="submit()"
  >
    <div *ngIf="dataError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        [type]="'button'"
        (click)="reloadData()"
      >
        Reload Add Members
      </p-button-pure>
    </div>

    <ng-container *ngIf="!dataError && data">
      <div class="form-controls">
        <p-select-wrapper
          *ngrxLet="form.controls.members as control"
          [filter]="true"
          [label]="'Add Members'"
          [message]="control.errors?.['message'] ?? ''"
          [state]="control.touched && control.errors ? 'error' : 'none'"
        >
          <select [attr.required]="true" [formControl]="control">
            <option
              *ngFor="
                let user of data.users
                  | opaFilterGroupMembers: group?.users
                  | opaFilterSelectedUsers: control.value;
                trackBy: trackUser
              "
              [value]="user | opaJoinUsers: control.value"
            >
              {{ user.firstName }} {{ user.lastName }}
            </option>
          </select>
        </p-select-wrapper>

        <ng-container *ngrxLet="form.controls.members.value | opaStringSplit: ',' as userIds">
          <div *ngIf="userIds && userIds.length > 0">
            <p-text [color]="'contrast-medium'">{{ userIds.length }} selected:</p-text>

            <div *ngFor="let userId of userIds">
              <p-button-pure
                *ngrxLet="userId | opaFindUser: data.users as user"
                [aria]="{
                  'aria-label':
                    (user ? user.firstName + ' ' + user.lastName : userId) + ' (remove selected)'
                }"
                [iconSource]="'assets/icons/close.svg'"
                [type]="'button'"
                (click)="removeMember(userId)"
              >
                <ng-container *ngIf="user">{{ user.firstName }} {{ user.lastName }}</ng-container>
                <ng-container *ngIf="!user">{{ userId }}</ng-container>
              </p-button-pure>
            </div>
          </div>
        </ng-container>
      </div>

      <p-button-group class="modal-actions">
        <p-button
          [icon]="'none'"
          [loading]="data.submitting"
          [type]="'submit'"
          [variant]="'primary'"
        >
          Save
        </p-button>
        <p-button
          [iconSource]="'assets/icons/close.svg'"
          [type]="'button'"
          [variant]="'secondary'"
          (click)="close()"
        >
          Close
        </p-button>
      </p-button-group>
    </ng-container>
  </form>
</p-modal>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
