<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Bank Cards</p-heading>

  <p-link
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/bank-cards/create'">Create New Bank Card</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="section">
  <div *ngrxLet="bankCard$ | async as bankCard" class="breadcrumbs">
    <div class="name">
      <p-link-pure [hideLabel]="true" [iconSource]="'assets/icons/arrow-left.svg'">
        <a [routerLink]="'/bank-cards'">All Bank Cards</a>
      </p-link-pure>

      <p-heading [size]="'large'" [tag]="'h4'">
        {{ bankCard?.cardNumber ?? '&nbsp;' }}
      </p-heading>
    </div>

    <ng-container *ngIf="bankCard">
      <p-divider aria-hidden="true" class="divider" [direction]="'vertical'" />

      <p-button-pure
        [iconSource]="'assets/icons/edit.svg'"
        [size]="'x-small'"
        [underline]="true"
        (click)="showEditModal = true"
      >
        Edit Bank Card
      </p-button-pure>
    </ng-container>
  </div>
</section>

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/info.svg'" />
      <span>Bank Card Details</span>
    </div>
  </p-heading>

  <ng-container *ngrxLet="bankCard$ as bankCard; error as bankCardError; suspenseTpl: spinner">
    <div *ngIf="bankCardError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadBankCard()"
      >
        Reload Bank Card Details
      </p-button-pure>
    </div>

    <div *ngIf="!bankCardError && bankCard" class="bank-card-details">
      <div>
        <p-text [weight]="'semi-bold'">Person:</p-text>
        <p-text>{{ bankCard.user.firstName }} {{ bankCard.user.lastName }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Card Number:</p-text>
        <p-text>{{ bankCard.cardNumber }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Daily Limit:</p-text>
        <p-text>{{ bankCard.dailyLimit | currency: 'EUR' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">POS:</p-text>
        <p-text>{{ bankCard.pos ? 'Yes' : 'No' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">ATM:</p-text>
        <p-text>{{ bankCard.atm ? 'Yes' : 'No' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Banking Permissions:</p-text>
        <p-text>{{ bankCard.bankingPermissions ? 'Yes' : 'No' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Create Payment Warrants:</p-text>
        <p-text>{{ bankCard.createPaymentWarrants ? 'Yes' : 'No' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Authorize Payment Warrants:</p-text>
        <p-text>{{ bankCard.authorizePaymentWarrants ? 'Yes' : 'No' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Mobile APP:</p-text>
        <p-text>{{ bankCard.mobileApp ? 'Yes' : 'No' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Expiration Date:</p-text>
        <p-text>{{ bankCard.expirationDate | date: 'dd.MM.yyyy.' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Active:</p-text>
        <p-text>{{ bankCard.active ? 'Yes' : 'No' }}</p-text>
      </div>
    </div>
  </ng-container>
</section>

<opa-bank-card-edit
  [open]="showEditModal"
  [bankCard]="(bankCard$ | async) ?? undefined"
  (dismiss)="showEditModal = false; $event && reloadBankCard(false, $event)"
/>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
