import { CommonModule } from '@angular/common';
import { Component, inject, type OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  shareReplay,
  startWith,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import type { Page } from '../../shared/models/api/page.model';
import type { VpnPhoneNumber } from '../../shared/models/api/vpn-phone-number.model';
import type { VpnSubscriptionType } from '../../shared/models/api/vpn-subscription-type.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { FillArrayPipe } from '../../shared/pipes/fill-array.pipe';
import { ReportService } from '../../shared/services/api/report.service';
import { VpnPhoneNumberService } from '../../shared/services/api/vpn-phone-number.service';
import { VpnSubscriptionTypeService } from '../../shared/services/api/vpn-subscription-type.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import type { VpnPhoneNumberListFormValue } from './vpn-phone-number-list.models';

@Component({
  selector: 'opa-vpn-phone-number-list',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    ReactiveFormsModule,
    LetDirective,
    FillArrayPipe,
  ],
  templateUrl: './vpn-phone-number-list.component.html',
  styleUrl: './vpn-phone-number-list.component.scss',
})
export class VpnPhoneNumberListComponent implements OnDestroy {
  private readonly mediaQueryService = inject(MediaQueryService);
  private readonly vpnSubscriptionTypeService = inject(VpnSubscriptionTypeService);
  private readonly vpnPhoneNumberService = inject(VpnPhoneNumberService);
  private readonly reportService = inject(ReportService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly toastManager = inject(ToastManager);

  private readonly subSink = new SubSink();
  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  private readonly pageNo = new BehaviorSubject(1);
  readonly allValue = 'all' as const;
  readonly trueValue = 'true' as const;
  readonly falseValue = 'false' as const;
  readonly form = this.buildForm();
  private readonly exporting = new BehaviorSubject(false);
  readonly exporting$ = this.exporting.pipe(distinctUntilChanged());
  private readonly loading = new BehaviorSubject(false);
  readonly loading$ = this.loading.pipe(distinctUntilChanged());
  vpnSubscriptionTypes$ = this.vpnSubscriptionTypeService.getVpnSubscriptionTypes(null).pipe(
    map((types) => types.content),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  vpnPhoneNumbers$ = this.getVpnPhoneNumbers$();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  trackVpnSubscriptionType(index: number, vpnSubscriptionType: VpnSubscriptionType): number {
    return vpnSubscriptionType.id;
  }

  reloadVpnPhoneNumbers(): void {
    this.vpnPhoneNumbers$ = this.getVpnPhoneNumbers$();
  }

  exportVpnPhoneNumbers(): void {
    const form = this.form.getRawValue();
    const vpnNumber = form.vpnNumber ?? undefined;
    const subscriptionTypeId =
      form.subscriptionTypeId === this.allValue || form.subscriptionTypeId == null
        ? undefined
        : Number(form.subscriptionTypeId);
    const active =
      form.active === this.allValue || form.active == null
        ? undefined
        : form.active === this.trueValue;

    this.exporting.next(true);
    this.subSink.sink = this.reportService
      .getVpnNumbers(vpnNumber, subscriptionTypeId, active)
      .pipe(
        tap({
          next: () => {
            this.toastManager.addMessage({
              text: `VPN Phone Numbers Cards exported`,
              state: 'success',
            });
            this.exporting.next(false);
          },
          error: () => this.exporting.next(false),
        }),
      )
      .subscribe();
  }

  trackRow(index: number): number {
    return index;
  }

  updatePageNo(page: number): void {
    this.pageNo.next(page);
  }

  private buildForm(): FormGroup<ControlsOf<VpnPhoneNumberListFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<VpnPhoneNumberListFormValue>>({
      vpnNumber: this.formBuilder.control(null),
      subscriptionTypeId: this.formBuilder.control(this.allValue),
      active: this.formBuilder.control(this.allValue),
    });
    return form;
  }

  private getVpnPhoneNumbers$(): Observable<Page<VpnPhoneNumber>> {
    const pageNo$ = this.pageNo.pipe(distinctUntilChanged());

    const form$ = merge(
      this.form.controls.vpnNumber.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)),
      this.form.controls.subscriptionTypeId.valueChanges.pipe(distinctUntilChanged()),
      this.form.controls.active.valueChanges.pipe(distinctUntilChanged()),
    ).pipe(
      startWith(undefined),
      map(() => this.form.getRawValue()),
      tap(() => this.updatePageNo(1)),
    );

    return combineLatest([pageNo$, form$]).pipe(
      debounceTime(0),
      tap(() => this.loading.next(true)),
      switchMap(([pageNo, form]) => {
        const vpnNumber = form.vpnNumber ?? undefined;
        const subscriptionTypeId =
          form.subscriptionTypeId === this.allValue || form.subscriptionTypeId == null
            ? undefined
            : Number(form.subscriptionTypeId);
        const active =
          form.active === this.allValue || form.active == null
            ? undefined
            : form.active === this.trueValue;

        return this.vpnPhoneNumberService.getVpnPhoneNumbers(
          pageNo,
          vpnNumber,
          subscriptionTypeId,
          active,
        );
      }),
      tap({
        next: () => this.loading.next(false),
        error: () => this.loading.next(false),
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
