import { CommonModule } from '@angular/common';
import { Component, inject, type OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import type { Position } from '../../shared/models/api/position.model';
import type { CreateUser } from '../../shared/models/api/user.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { PositionService } from '../../shared/services/api/position.service';
import { UserService } from '../../shared/services/api/user.service';
import { OpaValidators } from '../../shared/validators/opa.validators';
import type { CreateUserData, CreateUserFormValue } from './user-create.models';

@Component({
  selector: 'opa-user-create',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, ReactiveFormsModule, LetDirective],
  templateUrl: './user-create.component.html',
  styleUrl: './user-create.component.scss',
})
export class UserCreateComponent implements OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly userService = inject(UserService);
  private readonly positionService = inject(PositionService);
  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  createUserData$ = this.getCreateUserData$();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  reloadCreateUserData(): void {
    this.createUserData$ = this.getCreateUserData$();
  }

  trackPosition(index: number, position: Position): number {
    return position.id;
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const user = this.formValueToUser(formValue);
    this.subSink.sink = this.userService
      .createUser(user)
      .pipe(
        tap({
          next: (createdUser) => {
            this.toastManager.addMessage({
              text: `Member ${createdUser.firstName} ${createdUser.lastName} added`,
              state: 'success',
            });
            this.router.navigate(['/users']);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<CreateUserFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<CreateUserFormValue>>({
      firstName: this.formBuilder.control(null, OpaValidators.required()),
      lastName: this.formBuilder.control(null, OpaValidators.required()),
      email: this.formBuilder.control(null, [OpaValidators.required(), OpaValidators.email()]),
      doubleMonthlyBudget: this.formBuilder.control(false, OpaValidators.required()),
      position: this.formBuilder.control(null, OpaValidators.required()),
      initialEduEquBudget: this.formBuilder.control(null, [
        OpaValidators.required(),
        OpaValidators.min(0),
        OpaValidators.maxDecimals(2),
      ]),
    });
    return form;
  }

  private formValueToUser(form: CreateUserFormValue): CreateUser {
    const user: CreateUser = {
      firstName: form.firstName!,
      lastName: form.lastName!,
      email: form.email!,
      doubleMonthlyBudget: form.doubleMonthlyBudget!,
      positionId: Number(form.position!),
      initialEduEquBudget: form.initialEduEquBudget!,
    };
    return user;
  }

  private getCreateUserData$(): Observable<CreateUserData> {
    const positions$ = this.positionService.getPositions();
    const submitting$ = this.submitting.pipe(distinctUntilChanged());
    return combineLatest([positions$, submitting$]).pipe(
      map(([positions, submitting]) => ({ positions, submitting })),
    );
  }
}
