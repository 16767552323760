<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Edu & Equ Transactions</p-heading>

  <p-link
    *ngIf="isSuperAdmin"
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/edu-transactions/import-edu-budget'">Import Edu Budget</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/add-circle.svg'" />
      <span>Create New Transaction</span>
    </div>
  </p-heading>

  <ng-container *ngrxLet="createExpenseData$ as data; error as dataError; suspenseTpl: spinner">
    <div *ngIf="dataError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadCreateExpenseData()"
      >
        Reload Create New Transaction
      </p-button-pure>
    </div>

    <form *ngIf="!dataError && data" class="form" [formGroup]="form" (ngSubmit)="submit()">
      <p-select-wrapper
        *ngrxLet="form.controls.budgetType as control"
        [filter]="true"
        [label]="'Budget Type'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <select [attr.required]="true" [formControl]="control">
          <option *ngFor="let type of budgetTypes" [value]="type">
            {{ budgetTypeTranslation[type] | transloco }}
          </option>
        </select>
      </p-select-wrapper>

      <p-select-wrapper
        *ngrxLet="form.controls.categoryId as control"
        [filter]="true"
        [label]="'Transaction Category'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <select [attr.required]="true" [formControl]="control">
          <option *ngIf="!form.controls.budgetType.value" [value]="undefined" [disabled]="true">
            Select a Budget Type first
          </option>

          <option
            *ngFor="
              let category of data.categories
                | opaEduExpenseCategoryFilter: form.controls.budgetType.value;
              trackBy: trackCategory
            "
            [value]="category.id"
          >
            {{ category.description }}
          </option>
        </select>
      </p-select-wrapper>

      <div>
        <p-button-pure
          [iconSource]="'assets/icons/add.svg'"
          [type]="'button'"
          [underline]="true"
          (click)="showCreateEduExpenseCategoryModal = true"
        >
          Add New Transaction Category
        </p-button-pure>
      </div>

      <p-select-wrapper
        *ngrxLet="form.controls.purposeId as control"
        [filter]="true"
        [label]="'Transaction Purpose'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <select [attr.required]="true" [formControl]="control">
          <option *ngIf="!form.controls.budgetType.value" [value]="undefined" [disabled]="true">
            Select a Budget Type first
          </option>

          <option
            *ngFor="
              let purpose of data.purposes
                | opaEduExpensePurposeFilter: form.controls.budgetType.value;
              trackBy: trackPurpose
            "
            [value]="purpose.id"
          >
            {{ purpose.description }}
          </option>
        </select>
      </p-select-wrapper>

      <div>
        <p-button-pure
          [iconSource]="'assets/icons/add.svg'"
          [type]="'button'"
          [underline]="true"
          (click)="showCreateEduExpensePurposeModal = true"
        >
          Add New Transaction Purpose
        </p-button-pure>
      </div>

      <div>
        <p-select-wrapper
          *ngrxLet="form.controls.userId as control"
          [filter]="true"
          [label]="'Person'"
          [message]="control.errors?.['message'] ?? ''"
          [state]="control.touched && control.errors ? 'error' : 'none'"
        >
          <select [attr.required]="true" [formControl]="control">
            <option *ngFor="let user of data.users.content; trackBy: trackUser" [value]="user.id">
              {{ user.firstName }} {{ user.lastName }}
            </option>
          </select>
        </p-select-wrapper>

        <ng-container
          *ngrxLet="form.controls.userId.value | opaFindUser: data.users.content as user"
        >
          <p-text
            *ngIf="user && form.controls.budgetType.value === BudgetType.EQU"
            [size]="'x-small'"
            [color]="'neutral-contrast-high'"
          >
            Remaining equipment budget:
            {{ user.eduEquBudgetStatus.maxEquipmentBudgetRemaining | currency: defaultCurrency }}
          </p-text>
          <p-text
            *ngIf="user && form.controls.budgetType.value === BudgetType.EDU"
            [size]="'x-small'"
            [color]="'neutral-contrast-high'"
          >
            Remaining education budget:
            {{ user.eduEquBudgetStatus.maxEducationBudgetRemaining | currency: defaultCurrency }}
          </p-text>
        </ng-container>
      </div>

      <p-text-field-wrapper
        *ngrxLet="form.controls.date as control"
        [label]="'Transaction date'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <input type="date" [attr.required]="true" [formControl]="control" [max]="maxDate" />
      </p-text-field-wrapper>

      <section>
        <div class="amount-row">
          <p-text-field-wrapper
            *ngrxLet="form.controls.amount as control"
            class="amount-input"
            [label]="'Transaction Cost'"
            [message]="control.errors?.['message'] ?? ''"
            [state]="control.touched && control.errors ? 'error' : 'none'"
          >
            <input
              type="number"
              [placeholder]="'0,00'"
              [attr.required]="true"
              [formControl]="control"
              [min]="minAmount"
            />
          </p-text-field-wrapper>

          <p-select-wrapper
            *ngrxLet="form.controls.currency as control"
            class="currency-input"
            [filter]="true"
            [label]="'Currency'"
            [message]="control.errors?.['message'] ?? ''"
            [state]="control.touched && control.errors ? 'error' : 'none'"
          >
            <select [attr.required]="true" [formControl]="control">
              <option *ngFor="let currency of data.currencies" [value]="currency">
                {{ currency }}
              </option>
            </select>
          </p-select-wrapper>
        </div>

        <ng-container *ngIf="amountInEur$ | async as amountInEur">
          <p-text
            *ngIf="amountInEur && form.controls.currency.value !== defaultCurrency"
            [size]="'x-small'"
            [color]="'neutral-contrast-high'"
          >
            Converted amount: {{ amountInEur | currency: defaultCurrency }}
          </p-text>
        </ng-container>
      </section>

      <p-text-field-wrapper
        *ngrxLet="form.controls.productiveLink as control"
        [label]="'Productive Reference Number / Link'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <input type="text" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <p-textarea-wrapper
        *ngrxLet="form.controls.description as control"
        [label]="'Description (Optional)'"
        [message]="control.errors?.['message'] ?? ''"
        [showCounter]="true"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <textarea
          [formControl]="control"
          [maxlength]="textareaMaxLength"
          [placeholder]="'Type here...'"
        ></textarea>
      </p-textarea-wrapper>

      <p-inline-notification *ngIf="form.errors" [state]="'error'">
        <p-text> {{ form.errors['message'] }} </p-text>
      </p-inline-notification>

      <div class="actions">
        <p-button
          [icon]="'none'"
          [loading]="data.submitting"
          [type]="'submit'"
          [variant]="'primary'"
        >
          Create Transaction
        </p-button>
      </div>
    </form>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>

<opa-edu-expense-category-create
  [open]="showCreateEduExpenseCategoryModal"
  (dismiss)="showCreateEduExpenseCategoryModal = false; $event && reloadCreateExpenseData()"
/>

<opa-edu-expense-purpose-create
  [open]="showCreateEduExpensePurposeModal"
  (dismiss)="showCreateEduExpensePurposeModal = false; $event && reloadCreateExpenseData()"
/>
