import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private readonly httpClient = inject(HttpClient);
  private readonly api = `${environment.apiUrl}/v1/currencies`;

  getCurrencies(): Observable<string[]> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Currencies');
    return this.httpClient.get<string[]>(this.api, { context });
  }

  getExchangeRate(fromCurrency: string, toCurrency: string = 'EUR'): Observable<number> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Exchange Rate');

    let params = new HttpParams();
    params = params.set('fromCurrency', fromCurrency).set('toCurrency', toCurrency);

    return this.httpClient.get<number>(`${this.api}/exchange-rate`, { context, params });
  }
}
