import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject, type OnDestroy } from '@angular/core';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { SubSink } from 'subsink';
import type { Vendor } from '../../../shared/models/api/vendor.model';
import { VendorService } from '../../../shared/services/api/vendor.service';

@Component({
  selector: 'opa-vendor-delete',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule],
  templateUrl: './vendor-delete.component.html',
  styleUrl: './vendor-delete.component.scss',
})
export class VendorDeleteComponent implements OnDestroy {
  private readonly vendorService = inject(VendorService);
  private readonly toastManager = inject(ToastManager);

  @Input({ required: true }) open = false;
  @Input({ required: true }) vendor: Vendor | undefined = undefined;
  @Output() dismiss = new EventEmitter<Vendor | undefined>();

  private readonly subSink = new SubSink();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.pipe(distinctUntilChanged());

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(vendor?: Vendor): void {
    this.dismiss.emit(vendor);
  }

  submit(): void {
    const vendor = this.vendor;
    if (!vendor) return;

    this.submitting.next(true);
    this.subSink.sink = this.vendorService
      .deleteVendor(vendor.id)
      .pipe(
        tap({
          next: () => {
            this.toastManager.addMessage({
              text: `Vendor ${vendor.name} deleted`,
              state: 'success',
            });
            this.submitting.next(false);
            this.dismiss.emit(vendor);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }
}
