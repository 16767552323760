<p-heading class="title" [size]="'x-large'" [tag]="'h3'">Bank Cards</p-heading>

<p-divider aria-hidden="true" />

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/add-circle.svg'" />
      <span>Create New Bank Card</span>
    </div>
  </p-heading>

  <ng-container *ngrxLet="data$ as data; error as dataError; suspenseTpl: spinner">
    <div *ngIf="dataError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadData()"
      >
        Reload Create New Bank Card
      </p-button-pure>
    </div>

    <form *ngIf="!dataError && data" class="form" [formGroup]="form" (ngSubmit)="submit()">
      <p-select-wrapper
        *ngrxLet="form.controls.userId as control"
        [filter]="true"
        [label]="'Person'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <select [attr.required]="true" [formControl]="control">
          <option *ngFor="let user of data.users; trackBy: trackUser" [value]="user.id">
            {{ user.firstName }} {{ user.lastName }}
          </option>
        </select>
      </p-select-wrapper>

      <p-text-field-wrapper
        *ngrxLet="form.controls.cardNumber as control"
        [description]="'Only last 4 digits'"
        [label]="'Card Number'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <input type="text" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <p-text-field-wrapper
        *ngrxLet="form.controls.dailyLimit as control"
        [label]="'Daily Limit'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
        [unit]="'EUR'"
        [unitPosition]="'suffix'"
      >
        <input type="number" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <p-switch
        *ngrxLet="form.controls.pos as control"
        [disabled]="control.disabled"
        [checked]="control.value ?? false"
        (update)="control.patchValue($event.detail.checked)"
      >
        POS
      </p-switch>

      <p-switch
        *ngrxLet="form.controls.atm as control"
        [disabled]="control.disabled"
        [checked]="control.value ?? false"
        (update)="control.patchValue($event.detail.checked)"
      >
        ATM
      </p-switch>

      <p-switch
        *ngrxLet="form.controls.bankingPermissions as control"
        [disabled]="control.disabled"
        [checked]="control.value ?? false"
        (update)="control.patchValue($event.detail.checked)"
      >
        Banking Permissions
      </p-switch>

      <p-switch
        *ngrxLet="form.controls.createPaymentWarrants as control"
        [disabled]="control.disabled"
        [checked]="control.value ?? false"
        (update)="control.patchValue($event.detail.checked)"
      >
        Create Payment Warrants
      </p-switch>

      <p-switch
        *ngrxLet="form.controls.authorizePaymentWarrants as control"
        [disabled]="control.disabled"
        [checked]="control.value ?? false"
        (update)="control.patchValue($event.detail.checked)"
      >
        Authorize Payment Warrants
      </p-switch>

      <p-switch
        *ngrxLet="form.controls.mobileApp as control"
        [disabled]="control.disabled"
        [checked]="control.value ?? false"
        (update)="control.patchValue($event.detail.checked)"
      >
        Mobile APP
      </p-switch>

      <p-text-field-wrapper
        *ngrxLet="form.controls.expirationDate as control"
        [label]="'Expiration date'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <input type="date" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <p-switch
        *ngrxLet="form.controls.active as control"
        [disabled]="control.disabled"
        [checked]="control.value ?? false"
        (update)="control.patchValue($event.detail.checked)"
      >
        Active
      </p-switch>

      <div class="actions">
        <p-button
          [icon]="'none'"
          [loading]="data.submitting"
          [type]="'submit'"
          [variant]="'primary'"
        >
          Create Bank Card
        </p-button>
      </div>
    </form>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
