import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type {
  CreateInventoryManufacturer,
  EditInventoryManufacturer,
  InventoryManufacturer,
} from '../../models/api/inventory-manufacturer.model';

@Injectable({
  providedIn: 'root',
})
export class InventoryManufacturerService {
  private readonly httpClient = inject(HttpClient);
  private readonly api = `${environment.apiUrl}/v1/codebooks/inventory-manufacturer`;

  getInventoryManufacturers(): Observable<InventoryManufacturer[]> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Get Inventory Manufacturer',
    );

    return this.httpClient.get<InventoryManufacturer[]>(this.api, { context });
  }

  createInventoryManufacturer(
    manufacturer: CreateInventoryManufacturer,
  ): Observable<InventoryManufacturer> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Create Inventory Manufacturer',
    );

    return this.httpClient.post<InventoryManufacturer>(this.api, manufacturer, { context });
  }

  editInventoryManufacturer(
    id: number,
    manufacturer: EditInventoryManufacturer,
  ): Observable<InventoryManufacturer> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Edit Inventory Manufacturer',
    );

    return this.httpClient.put<InventoryManufacturer>(`${this.api}/${id}`, manufacturer, {
      context,
    });
  }

  deleteInventoryManufacturer(id: number): Observable<void> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Delete Inventory Manufacturer. Please make sure there are no inventory items associated with this manufacturer',
    );

    return this.httpClient.delete<void>(`${this.api}/${id}`, { context });
  }
}
