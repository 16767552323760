import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  EMPTY,
  distinctUntilChanged,
  merge,
  of,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import { objectsEqual } from '../../shared/helpers/objects-equal.helper';
import type { Group } from '../../shared/models/api/group.model';
import type { User } from '../../shared/models/api/user.model';
import { GroupService } from '../../shared/services/api/group.service';
import { AuthService } from '../../shared/services/auth.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { groupTypeTranslation } from '../group-list/group-list.helpers';
import { GroupDeleteComponent } from './group-delete/group-delete.component';
import { GroupEditComponent } from './group-edit/group-edit.component';
import { GroupMembersAddComponent } from './group-members-add/group-members-add.component';
import { GroupMembersRemoveComponent } from './group-members-remove/group-members-remove.component';

@Component({
  selector: 'opa-group-details',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    LetDirective,
    TranslocoPipe,
    GroupEditComponent,
    GroupMembersAddComponent,
    GroupMembersRemoveComponent,
    GroupDeleteComponent,
  ],
  templateUrl: './group-details.component.html',
  styleUrl: './group-details.component.scss',
})
export class GroupDetailsComponent {
  private readonly authService = inject(AuthService);
  private readonly mediaQueryService = inject(MediaQueryService);
  private readonly route = inject(ActivatedRoute);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly groupService = inject(GroupService);
  readonly groupTypeTranslation = groupTypeTranslation;

  readonly now = new Date();
  readonly isPeopleAdmin = this.authService.isPeopleAdmin();
  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  group$ = this.getGroup$();
  showEditModal = false;
  showMembersAddModal = false;
  showRemoveMemberModal = false;
  showGroupDeleteModal = false;
  removeMember: User | undefined = undefined;

  reloadGroup(markForCheck = false, group?: Group): void {
    this.group$ = this.getGroup$(group);
    if (markForCheck) this.changeDetectorRef.markForCheck();
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  private getGroup$(group?: Group): Observable<Group> {
    const routeParams$ = merge(
      of(this.route.snapshot.params),
      this.route.params.pipe(
        startWith(this.route.snapshot.params),
        distinctUntilChanged((a, b) => objectsEqual(a, b)),
        skip(1),
        tap(() => this.reloadGroup(true)),
        switchMap(() => EMPTY),
      ),
    );

    return routeParams$.pipe(
      switchMap((routeParams) => {
        if (group) return of(group);

        const groupId = Number(routeParams['id']);
        return this.groupService.getGroup(groupId);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
