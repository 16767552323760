<p-heading class="title" [size]="'x-large'" [tag]="'h3'">Events</p-heading>

<p-divider aria-hidden="true" />

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/calendar-add.svg'" />
      <span>Create New Event</span>
    </div>
  </p-heading>

  <ng-container *ngrxLet="createEventData$ as data; error as dataError; suspenseTpl: spinner">
    <div *ngIf="dataError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadCreateEventData()"
      >
        Reload Create New Event
      </p-button-pure>
    </div>

    <form *ngIf="!dataError && data" class="form" [formGroup]="form" (ngSubmit)="submit()">
      <p-text-field-wrapper
        *ngrxLet="form.controls.name as control"
        [label]="'Event Name'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <input type="text" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <p-select-wrapper
        *ngrxLet="form.controls.vendorId as control"
        [filter]="true"
        [label]="'Vendor'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <select [attr.required]="true" [formControl]="control">
          <option *ngFor="let vendor of data.vendors; trackBy: trackVendor" [value]="vendor.id">
            {{ vendor.name }}
          </option>
        </select>
      </p-select-wrapper>

      <div>
        <p-button-pure
          [iconSource]="'assets/icons/add.svg'"
          [underline]="true"
          [type]="'button'"
          (click)="showVendorModal = true"
        >
          Add New Vendor
        </p-button-pure>
      </div>

      <p-select-wrapper
        *ngrxLet="form.controls.eventType as control"
        [filter]="true"
        [label]="'Event Type'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <select [attr.required]="true" [formControl]="control">
          <option *ngFor="let type of eventTypes" [value]="type">
            {{ eventTypeTranslation[type] | transloco }}
          </option>
        </select>
      </p-select-wrapper>

      <p-text-field-wrapper
        *ngrxLet="form.controls.date as control"
        [label]="'Event Date'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <input type="date" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <p-select-wrapper
        *ngrxLet="form.controls.participants as control"
        [filter]="true"
        [label]="'Participants'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <select [attr.required]="true" [formControl]="control">
          <optgroup [label]="'GROUPS'">
            <option
              *ngFor="
                let group of data.groups.content | opaFilterEmptyGroups: control.value;
                trackBy: trackGroup
              "
              [value]="group.users | opaJoinUsers: control.value"
            >
              {{ group.name }}
            </option>
          </optgroup>

          <optgroup [label]="'PEOPLE'">
            <option
              *ngFor="
                let user of data.users.content | opaFilterSelectedUsers: control.value;
                trackBy: trackUser
              "
              [value]="user | opaJoinUsers: control.value"
            >
              {{ user.firstName }} {{ user.lastName }}
            </option>
          </optgroup>
        </select>
      </p-select-wrapper>

      <ng-container *ngrxLet="form.controls.participants.value | opaStringSplit: ',' as userIds">
        <div *ngIf="userIds && userIds.length > 0">
          <p-text [color]="'contrast-medium'">{{ userIds.length }} selected:</p-text>

          <div *ngFor="let userId of userIds">
            <p-button-pure
              *ngrxLet="userId | opaFindUser: data.users.content as user"
              [aria]="{
                'aria-label':
                  (user ? user.firstName + ' ' + user.lastName : userId) + ' (remove selected)'
              }"
              [iconSource]="'assets/icons/close.svg'"
              [type]="'button'"
              (click)="removeParticipant(userId)"
            >
              <ng-container *ngIf="user">{{ user.firstName }} {{ user.lastName }}</ng-container>
              <ng-container *ngIf="!user">{{ userId }}</ng-container>
            </p-button-pure>
          </div>
        </div>
      </ng-container>

      <p-select-wrapper
        *ngrxLet="form.controls.payerId as control"
        [filter]="true"
        [label]="'Refund To'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <select [attr.required]="true" [formControl]="control">
          <ng-container
            *ngFor="let userId of form.controls.participants.value | opaStringSplit: ','"
          >
            <option *ngrxLet="userId | opaFindUser: data.users.content as user" [value]="userId">
              <ng-container *ngIf="user">{{ user.firstName }} {{ user.lastName }}</ng-container>
              <ng-container *ngIf="!user">{{ userId }}</ng-container>
            </option>
          </ng-container>
        </select>
      </p-select-wrapper>

      <p-text-field-wrapper
        *ngrxLet="form.controls.amount as control"
        [label]="'Total Event Cost'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
        [unit]="'EUR'"
        [unitPosition]="'suffix'"
      >
        <input type="number" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <p-text-field-wrapper
        *ngrxLet="form.controls.referenceLink as control"
        [label]="'Productive Reference Number / Link'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <input type="text" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <p-textarea-wrapper
        *ngrxLet="form.controls.optionalMsg as control"
        [label]="'Description (Optional)'"
        [message]="control.errors?.['message'] ?? ''"
        [showCounter]="true"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <textarea [formControl]="control" [maxlength]="textareaMaxLength"></textarea>
      </p-textarea-wrapper>

      <div class="actions">
        <p-button
          [icon]="'none'"
          [loading]="data.submitting"
          [type]="'submit'"
          [variant]="'primary'"
        >
          Create Event
        </p-button>
      </div>
    </form>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>

<opa-vendor-create
  [open]="showVendorModal"
  (dismiss)="showVendorModal = false; $event && reloadVendors()"
/>
