<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">VPN Phone Numbers</p-heading>

  <p-link
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/vpn-phone-numbers/create'">Create New VPN Phone Number</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="section">
  <div *ngrxLet="vpnPhoneNumber$ | async as vpnPhoneNumber" class="breadcrumbs">
    <div class="name">
      <p-link-pure [hideLabel]="true" [iconSource]="'assets/icons/arrow-left.svg'">
        <a [routerLink]="'/vpn-phone-numbers'">All VPN Phone Numbers</a>
      </p-link-pure>

      <p-heading [size]="'large'" [tag]="'h4'">
        {{ vpnPhoneNumber?.vpnNumber ?? '&nbsp;' }}
      </p-heading>
    </div>

    <ng-container *ngIf="vpnPhoneNumber">
      <p-divider aria-hidden="true" class="divider" [direction]="'vertical'" />

      <p-button-pure
        [iconSource]="'assets/icons/edit.svg'"
        [size]="'x-small'"
        [underline]="true"
        (click)="showEditModal = true"
      >
        Edit VPN Phone Number
      </p-button-pure>
    </ng-container>
  </div>
</section>

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/info.svg'" />
      <span>VPN Phone Number Details</span>
    </div>
  </p-heading>

  <ng-container
    *ngrxLet="vpnPhoneNumber$ as vpnPhoneNumber; error as vpnPhoneNumberError; suspenseTpl: spinner"
  >
    <div *ngIf="vpnPhoneNumberError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadVpnPhoneNumber()"
      >
        Reload VPN Phone Number Details
      </p-button-pure>
    </div>

    <div *ngIf="!vpnPhoneNumberError && vpnPhoneNumber" class="vpn-phone-number-details">
      <div>
        <p-text [weight]="'semi-bold'">Person:</p-text>
        <p-text>{{ vpnPhoneNumber.user.firstName }} {{ vpnPhoneNumber.user.lastName }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">VPN Number:</p-text>
        <p-text>{{ vpnPhoneNumber.vpnNumber }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Full Number:</p-text>
        <p-text>{{ vpnPhoneNumber.fullNumber }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">VPN Subscription Type:</p-text>
        <p-text>{{ vpnPhoneNumber.subscriptionType.name }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Additional Option Activation:</p-text>
        <p-text>{{ vpnPhoneNumber.additionalOptionActivation ? 'Yes' : 'No' }}</p-text>
      </div>

      <div *ngIf="vpnPhoneNumber.additionalOptionActivation">
        <p-text [weight]="'semi-bold'">Additional Option Description:</p-text>
        <p-text>{{ vpnPhoneNumber.additionalOptionDescription }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Contract Obligation:</p-text>
        <p-text>{{ vpnPhoneNumber.contractObligation ? 'Yes' : 'No' }}</p-text>
      </div>

      <ng-container *ngIf="vpnPhoneNumber.contractObligation">
        <div>
          <p-text [weight]="'semi-bold'">Contract Start Date:</p-text>
          <p-text>{{ vpnPhoneNumber.contractStartDate | date: 'dd.MM.yyyy.' }}</p-text>
        </div>

        <div>
          <p-text [weight]="'semi-bold'">Contract End Date:</p-text>
          <p-text>{{ vpnPhoneNumber.contractEndDate | date: 'dd.MM.yyyy.' }}</p-text>
        </div>
      </ng-container>

      <div>
        <p-text [weight]="'semi-bold'">Active:</p-text>
        <p-text>{{ vpnPhoneNumber.active ? 'Yes' : 'No' }}</p-text>
      </div>
    </div>
  </ng-container>
</section>

<opa-vpn-phone-number-edit
  [open]="showEditModal"
  [vpnPhoneNumber]="(vpnPhoneNumber$ | async) ?? undefined"
  (dismiss)="showEditModal = false; $event && reloadVpnPhoneNumber(false, $event)"
/>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
