import { Injectable } from '@angular/core';
import { BehaviorSubject, type Observable } from 'rxjs';
import type { ErrorBannerItem } from './error-banner.models';

@Injectable({
  providedIn: 'root',
})
export class ErrorBannerService {
  private readonly errors = new BehaviorSubject<ErrorBannerItem[]>([]);

  getAll(): Observable<ErrorBannerItem[]> {
    return this.errors.asObservable();
  }

  add(error: ErrorBannerItem): void {
    this.errors.next([...this.errors.value, error]);
  }

  clear(): void {
    this.errors.next([]);
  }
}
