<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Groups</p-heading>

  <p-link
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/groups/create'">Create New Group</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="filters">
  <p-text-field-wrapper class="main-filter" [hideLabel]="true" [label]="'Search Groups'">
    <input type="search" [formControl]="form.controls.groupName" [placeholder]="'Search Groups'" />
  </p-text-field-wrapper>
</section>

<section class="list">
  <p-heading *ngrxLet="groups$ | async as groups" [size]="'small'" [tag]="'h4'">
    {{ groups?.totalElements }} Groups
  </p-heading>

  <ng-container *ngrxLet="groups$ as groups; error as groupsError; suspenseTpl: spinner">
    <div *ngIf="groupsError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadGroups()"
      >
        Reload Groups
      </p-button-pure>
    </div>

    <ng-container *ngIf="!groupsError && groups">
      <div class="overlay-spinner-host">
        <ng-container *ngIf="loading$ | async" [ngTemplateOutlet]="overlaySpinner" />

        <p-table caption="Group list table">
          <p-table-head>
            <p-table-head-row>
              <p-table-head-cell style="width: 25%">Group Name</p-table-head-cell>
              <p-table-head-cell style="width: 25%">Group Type</p-table-head-cell>
              <p-table-head-cell style="width: 25%">Members</p-table-head-cell>
              <p-table-head-cell style="width: 25%">Available Budget</p-table-head-cell>
            </p-table-head-row>
          </p-table-head>

          <p-table-body>
            <p-table-row
              *ngFor="
                let group of groups.content | fillArray: groups.pageable.pageSize;
                trackBy: trackRow
              "
            >
              <p-table-cell>
                <ng-container *ngIf="group; else emptyCell">
                  <a [routerLink]="['/groups', group.id]">{{ group.name }}</a>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="group; else emptyCell">
                  {{ groupTypeTranslation[group.type] | transloco }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="group; else emptyCell">
                  {{ group.users.length }} people
                  <p-popover [direction]="'right'">
                    <div class="popover-content">
                      <p-text *ngFor="let user of group.users; trackBy: trackUser">
                        {{ user.firstName }} {{ user.lastName }}
                      </p-text>
                    </div>
                  </p-popover>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="group; else emptyCell">
                  {{ group.availableBudget | currency: 'EUR' }}
                </ng-container>
              </p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>
      </div>

      <p-pagination
        [totalItemsCount]="groups.totalElements"
        [itemsPerPage]="groups.size"
        [activePage]="groups.pageable.pageNumber + 1"
        (update)="updatePageNo($event.detail.page)"
      />
    </ng-container>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>

<ng-template #overlaySpinner>
  <div class="overlay-spinner"><p-spinner /></div>
</ng-template>

<ng-template #emptyCell>
  <span class="empty-cell"></span>
</ng-template>
