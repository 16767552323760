import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import type { ErrorBannerItem } from './error-banner.models';
import { ErrorBannerService } from './error-banner.service';

@Component({
  selector: 'opa-error-banner',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, LetDirective],
  templateUrl: './error-banner.component.html',
  styleUrl: './error-banner.component.scss',
})
export class ErrorBannerComponent {
  private readonly errorBannerService = inject(ErrorBannerService);

  readonly errors$ = this.errorBannerService.getAll();

  trackError(index: number, error: ErrorBannerItem): ErrorBannerItem {
    return error;
  }

  dismiss(): void {
    this.errorBannerService.clear();
  }
}
