import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  EMPTY,
  distinctUntilChanged,
  merge,
  of,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import { objectsEqual } from '../../shared/helpers/objects-equal.helper';
import type { BudgetCorrection } from '../../shared/models/api/budget-correction.model';
import type { User } from '../../shared/models/api/user.model';
import { LeadingPlusPipe } from '../../shared/pipes/leading-plus.pipe';
import { BudgetCorrectionService } from '../../shared/services/api/budget-correction.service';
import { MediaQueryService } from '../../shared/services/media-query.service';

@Component({
  selector: 'opa-budget-correction-details',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, RouterLink, LetDirective, LeadingPlusPipe],
  templateUrl: './budget-correction-details.component.html',
  styleUrl: './budget-correction-details.component.scss',
})
export class BudgetCorrectionDetailsComponent {
  private readonly mediaQueryService = inject(MediaQueryService);
  private readonly route = inject(ActivatedRoute);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly budgetCorrectionService = inject(BudgetCorrectionService);

  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  budgetCorrection$ = this.getBudgetCorrection$();

  reloadBudgetCorrection(markForCheck = false): void {
    this.budgetCorrection$ = this.getBudgetCorrection$();
    if (markForCheck) this.changeDetectorRef.markForCheck();
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  private getBudgetCorrection$(): Observable<BudgetCorrection> {
    const routeParams$ = merge(
      of(this.route.snapshot.params),
      this.route.params.pipe(
        startWith(this.route.snapshot.params),
        distinctUntilChanged((a, b) => objectsEqual(a, b)),
        skip(1),
        tap(() => this.reloadBudgetCorrection(true)),
        switchMap(() => EMPTY),
      ),
    );

    return routeParams$.pipe(
      switchMap((routeParams) => {
        const budgetCorrectionId = Number(routeParams['id']);
        return this.budgetCorrectionService.getBudgetCorrection(budgetCorrectionId);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
