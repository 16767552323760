import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  type OnChanges,
  type OnDestroy,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { SubSink } from 'subsink';
import type { CreateInventoryLocation } from '../../../shared/models/api/inventory-location.model';
import type { ControlsOf } from '../../../shared/models/controls-of.model';
import type { NgChanges } from '../../../shared/models/ng-changes.model';
import { InventoryLocationService } from '../../../shared/services/api/inventory-location.service';
import { OpaValidators } from '../../../shared/validators/opa.validators';
import type { CreateInventoryLocationFormValue } from './inventory-location-create.models';

@Component({
  selector: 'opa-inventory-location-create',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    ReactiveFormsModule,
    LetDirective,
    TranslocoPipe,
  ],
  templateUrl: './inventory-location-create.component.html',
  styleUrl: './inventory-location-create.component.scss',
})
export class InventoryLocationCreateComponent implements OnChanges, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly inventoryLocationService = inject(InventoryLocationService);
  private readonly toastManager = inject(ToastManager);

  @Input({ required: true }) open = false;
  @Output() dismiss = new EventEmitter<boolean | undefined>();

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.pipe(distinctUntilChanged());

  ngOnChanges(changes: NgChanges<InventoryLocationCreateComponent>): void {
    if (changes.open && this.open) this.form.reset();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(success?: boolean): void {
    this.dismiss.emit(success);
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const location = this.formValueToLocation(formValue);
    this.subSink.sink = this.inventoryLocationService
      .createInventoryLocation(location)
      .pipe(
        tap({
          next: (createLocation) => {
            this.toastManager.addMessage({
              text: `Location '${createLocation.description}' created`,
              state: 'success',
            });
            this.submitting.next(false);
            this.close(true);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<CreateInventoryLocationFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<CreateInventoryLocationFormValue>>({
      name: this.formBuilder.control(null, [
        OpaValidators.required(),
        OpaValidators.isNormalized(),
      ]),
      description: this.formBuilder.control(null, OpaValidators.required()),
      requiresAssignee: this.formBuilder.control(false),
    });

    return form;
  }

  private formValueToLocation(form: CreateInventoryLocationFormValue): CreateInventoryLocation {
    const newLocation: CreateInventoryLocation = {
      description: form.description!,
      name: form.name!,
      requiresAssignee: !!form.requiresAssignee,
    };
    return newLocation;
  }
}
