import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type {
  CreateEduExpensePurpose,
  EditEduExpensePurpose,
  EduExpensePurpose,
} from '../../models/api/edu-expense-purpose.model';

@Injectable({
  providedIn: 'root',
})
export class EduExpensePurposeService {
  private readonly httpClient = inject(HttpClient);
  private readonly purposeApi = `${environment.apiUrl}/v1/codebooks/edu-equ-expense-purposes`;

  getEduExpensePurposes(): Observable<EduExpensePurpose[]> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Get Edu & Equ Transaction Purposes',
    );

    return this.httpClient.get<EduExpensePurpose[]>(this.purposeApi, { context });
  }

  getEduExpensePurpose(purposeId: number): Observable<EduExpensePurpose> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Get Edu & Equ Transaction Purpose',
    );

    return this.httpClient.get<EduExpensePurpose>(`${this.purposeApi}/${purposeId}`, {
      context,
    });
  }

  createEduExpensePurpose(purpose: CreateEduExpensePurpose): Observable<EduExpensePurpose> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Create Edu & Equ Transaction Purpose',
    );

    return this.httpClient.post<EduExpensePurpose>(this.purposeApi, purpose, { context });
  }

  editEduExpensePurpose(
    purposeId: number,
    purpose: EditEduExpensePurpose,
  ): Observable<EduExpensePurpose> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Edit Edu & Equ Transaction Purpose',
    );

    return this.httpClient.put<EduExpensePurpose>(`${this.purposeApi}/${purposeId}`, purpose, {
      context,
    });
  }

  deleteEduExpensePurpose(purposeId: number): Observable<void> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Delete Edu & Equ Transaction Purpose',
    );

    return this.httpClient.delete<void>(`${this.purposeApi}/${purposeId}`, { context });
  }
}
