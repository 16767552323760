<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Inventory</p-heading>
</div>

<p-divider aria-hidden="true" />

<section *ngrxLet="data$ as data; error as dataError; suspenseTpl: spinner">
  <div *ngIf="dataError">
    <p-button-pure
      [hideLabel]="true"
      [iconSource]="'assets/icons/refresh.svg'"
      [size]="'large'"
      (click)="reloadData()"
    >
      Reload Data
    </p-button-pure>
  </div>

  <div *ngIf="!dataError && data.inventoryItems">
    <p-text *ngIf="!data.inventoryItems.length" class="no-inventory-items">
      No inventory items.
    </p-text>

    <p-heading
      *ngIf="data.inventoryItems.length"
      class="inventory-items-count"
      [size]="'medium'"
      [tag]="'h4'"
    >
      {{ data.inventoryItems.length }} items assigned
    </p-heading>

    <div class="inventory-items">
      <article *ngFor="let item of data.inventoryItems" class="inventory-item">
        <div class="item-main">
          <div class="item-main-icon-wrapper">
            <p-icon [size]="'x-large'" [source]="item.type.name | opaInventoryItemIcon" />
          </div>

          <div class="item-main-data">
            <p-heading class="contrast-medium" [color]="'inherit'" [size]="'small'" [tag]="'h5'">
              {{ item.type.description }}
            </p-heading>

            <p-heading [size]="'small'" [tag]="'h5'">
              {{ item.manufacturer.description }} {{ item.model }}
            </p-heading>
          </div>
        </div>

        <p-divider aria-hidden="true" [direction]="data.mediaMaxS ? 'horizontal' : 'vertical'" />

        <div class="item-properties">
          <div *ngIf="!item.type.imeiRequired" class="property">
            <p-text [size]="'small'" [color]="'contrast-medium'">S/N:</p-text>
            <p-text [size]="'small'">{{ item.serialNumber }}</p-text>
          </div>
          <div *ngIf="item.type.imeiRequired" class="property">
            <p-text [size]="'small'" [color]="'contrast-medium'">IMEI:</p-text>
            <p-text [size]="'small'">{{ item.imei }}</p-text>
          </div>
          <div class="property">
            <p-text [size]="'small'" [color]="'contrast-medium'">Warranty expiration:</p-text>
            <p-text [size]="'small'">{{ item.warrantyExpiration | date: 'dd.MM.yyyy.' }}</p-text>
          </div>
        </div>

        <div class="item-link">
          <div>
            <p-link-pure
              [hideLabel]="!data.mediaMaxS"
              [iconSource]="'assets/icons/arrow-right.svg'"
              [underline]="true"
            >
              <a [routerLink]="['/inventory-items', item.id]">View Details</a>
            </p-link-pure>
          </div>
        </div>
      </article>
    </div>
  </div>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
