import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  type OnChanges,
  type OnDestroy,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import type { BankCard, EditBankCard } from '../../../shared/models/api/bank-card.model';
import type { User } from '../../../shared/models/api/user.model';
import type { ControlsOf } from '../../../shared/models/controls-of.model';
import type { NgChanges } from '../../../shared/models/ng-changes.model';
import { BankCardService } from '../../../shared/services/api/bank-card.service';
import { UserService } from '../../../shared/services/api/user.service';
import { OpaValidators } from '../../../shared/validators/opa.validators';
import type { EditBankCardData, EditBankCardFormValue } from './bank-card-edit.models';

@Component({
  selector: 'opa-bank-card-edit',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, ReactiveFormsModule, LetDirective],
  templateUrl: './bank-card-edit.component.html',
  styleUrl: './bank-card-edit.component.scss',
})
export class BankCardEditComponent implements OnChanges, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly userService = inject(UserService);
  private readonly bankCardService = inject(BankCardService);
  private readonly toastManager = inject(ToastManager);

  @Input({ required: true }) open = false;
  @Input({ required: true }) bankCard: BankCard | undefined = undefined;
  @Output() dismiss = new EventEmitter<BankCard | undefined>();

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  data$: Observable<EditBankCardData> | undefined = undefined;

  ngOnChanges(changes: NgChanges<BankCardEditComponent>): void {
    if (changes.open && this.open && !this.data$) this.reloadData();
    if ((changes.open && this.open) || changes.bankCard) this.resetForm(this.bankCard);
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(bankCard?: BankCard): void {
    this.dismiss.emit(bankCard);
  }

  reloadData(): void {
    this.data$ = this.getData$();
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  submit(): void {
    const bankCard = this.bankCard;
    if (!bankCard) return;

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const editBankCard = this.formValueToBankCard(formValue);
    this.subSink.sink = this.bankCardService
      .editBankCard(bankCard.id, editBankCard)
      .pipe(
        tap({
          next: (updatedBankCard) => {
            this.toastManager.addMessage({
              text: `Bank Card ${updatedBankCard.cardNumber} updated`,
              state: 'success',
            });
            this.submitting.next(false);
            this.close(updatedBankCard);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<EditBankCardFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<EditBankCardFormValue>>({
      userId: this.formBuilder.control(null, OpaValidators.required()),
      cardNumber: this.formBuilder.control(null, [
        OpaValidators.required(),
        OpaValidators.minLength(4),
        OpaValidators.maxLength(4),
      ]),
      dailyLimit: this.formBuilder.control(null, [
        OpaValidators.required(),
        OpaValidators.min(0),
        OpaValidators.maxDecimals(2),
      ]),
      pos: this.formBuilder.control(false, OpaValidators.required()),
      atm: this.formBuilder.control(false, OpaValidators.required()),
      bankingPermissions: this.formBuilder.control(false, OpaValidators.required()),
      createPaymentWarrants: this.formBuilder.control(false, OpaValidators.required()),
      authorizePaymentWarrants: this.formBuilder.control(false, OpaValidators.required()),
      mobileApp: this.formBuilder.control(false, OpaValidators.required()),
      expirationDate: this.formBuilder.control(null, OpaValidators.required()),
      active: this.formBuilder.control(false, OpaValidators.required()),
    });
    return form;
  }

  private formValueToBankCard(form: EditBankCardFormValue): EditBankCard {
    const bankCard: EditBankCard = {
      userId: Number(form.userId!),
      cardNumber: form.cardNumber!,
      dailyLimit: Number(form.dailyLimit!),
      pos: form.pos!,
      atm: form.atm!,
      bankingPermissions: form.bankingPermissions!,
      createPaymentWarrants: form.createPaymentWarrants!,
      authorizePaymentWarrants: form.authorizePaymentWarrants!,
      mobileApp: form.mobileApp!,
      expirationDate: form.expirationDate!,
      active: form.active!,
    };
    return bankCard;
  }

  private resetForm(bankCard?: BankCard): void {
    if (!bankCard) {
      this.form.reset();
      return;
    }

    this.form.reset({
      userId: String(bankCard.user.id),
      cardNumber: bankCard.cardNumber!,
      dailyLimit: Number(bankCard.dailyLimit!),
      pos: bankCard.pos!,
      atm: bankCard.atm!,
      bankingPermissions: bankCard.bankingPermissions!,
      createPaymentWarrants: bankCard.createPaymentWarrants!,
      authorizePaymentWarrants: bankCard.authorizePaymentWarrants!,
      mobileApp: bankCard.mobileApp!,
      expirationDate: bankCard.expirationDate!,
      active: bankCard.active!,
    });
  }

  private getData$(): Observable<EditBankCardData> {
    const users$ = this.userService.getActiveUsers();
    const submitting$ = this.submitting.pipe(distinctUntilChanged());
    return combineLatest([users$, submitting$]).pipe(
      map(([users, submitting]) => ({ users, submitting })),
    );
  }
}
