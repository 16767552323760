import { Pipe, type PipeTransform } from '@angular/core';
import type { EduExpensePurpose } from '../../shared/models/api/edu-expense-purpose.model';
import type { BudgetType } from '../../shared/models/api/edu-expense.model';

@Pipe({
  name: 'opaEduExpensePurposeFilter',
  standalone: true,
})
export class EduExpensePurposeFilterPipe implements PipeTransform {
  transform(purposes: EduExpensePurpose[], budgetType: BudgetType | null): EduExpensePurpose[] {
    return purposes?.filter((purpose) => purpose.budgetType === budgetType);
  }
}
