import { Pipe, type PipeTransform } from '@angular/core';
import type { Group } from '../../shared/models/api/group.model';

@Pipe({
  name: 'opaFilterEmptyGroups',
  standalone: true,
})
export class FilterEmptyGroupsPipe implements PipeTransform {
  transform(groups: Group[], selectedUserIds: string | null): Group[] {
    const selected = selectedUserIds?.split(',') ?? [];
    return groups.filter((group) => {
      return group.users.filter((user) => !selected.includes(`${user.id}`)).length > 0;
    });
  }
}
