import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject, type OnDestroy } from '@angular/core';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { SubSink } from 'subsink';
import type { InventoryType } from '../../../shared/models/api/inventory-type.model';
import { InventoryTypeService } from '../../../shared/services/api/inventory-type.service';

@Component({
  selector: 'opa-inventory-type-delete',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule],
  templateUrl: './inventory-type-delete.component.html',
  styleUrl: './inventory-type-delete.component.scss',
})
export class InventoryTypeDeleteComponent implements OnDestroy {
  private readonly inventoryTypeService = inject(InventoryTypeService);
  private readonly toastManager = inject(ToastManager);

  @Input({ required: true }) open = false;
  @Input({ required: true }) inventoryType: InventoryType | undefined = undefined;
  @Output() dismiss = new EventEmitter<InventoryType | undefined>();

  private readonly subSink = new SubSink();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.pipe(distinctUntilChanged());

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(inventoryType?: InventoryType): void {
    this.dismiss.emit(inventoryType);
  }

  submit(): void {
    const inventoryType = this.inventoryType;
    if (!inventoryType) return;

    this.submitting.next(true);
    this.subSink.sink = this.inventoryTypeService
      .deleteInventoryType(inventoryType.id)
      .pipe(
        tap({
          next: () => {
            this.toastManager.addMessage({
              text: `Type ${inventoryType.name} deleted`,
              state: 'success',
            });
            this.submitting.next(false);
            this.dismiss.emit(inventoryType);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }
}
