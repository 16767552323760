<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">VPN Phone Numbers</p-heading>

  <p-link
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/vpn-phone-numbers/create'">Create New VPN Phone Number</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="filters">
  <p-text-field-wrapper class="main-filter" [hideLabel]="true" [label]="'Search VPN Phone Numbers'">
    <input
      type="search"
      [formControl]="form.controls.vpnNumber"
      [placeholder]="'Search VPN Phone Numbers'"
    />
  </p-text-field-wrapper>

  <div class="more-filters">
    <p-select-wrapper class="filter" [label]="'VPN Subscription Type'">
      <select [formControl]="form.controls.subscriptionTypeId">
        <option [value]="allValue">All</option>
        <ng-container
          *ngrxLet="vpnSubscriptionTypes$ as types; error as error; suspenseTpl: loadingOption"
        >
          <ng-container *ngIf="!error && types">
            <option *ngFor="let type of types; trackBy: trackVpnSubscriptionType" [value]="type.id">
              {{ type.name }}
            </option>
          </ng-container>
        </ng-container>
        <ng-template #loadingOption>
          <option disabled>…</option>
        </ng-template>
      </select>
    </p-select-wrapper>

    <p-select-wrapper class="filter" [label]="'Active'">
      <select [formControl]="form.controls.active">
        <option [value]="allValue">All</option>
        <option [value]="trueValue">Active</option>
        <option [value]="falseValue">Inactive</option>
      </select>
    </p-select-wrapper>
  </div>
</section>

<section class="list">
  <div class="list-heading">
    <p-heading *ngrxLet="vpnPhoneNumbers$ | async as vpnPhoneNumbers" [size]="'small'" [tag]="'h4'">
      {{ vpnPhoneNumbers?.totalElements }} VPN Phone Numbers
    </p-heading>

    <p-divider aria-hidden="true" [direction]="'vertical'" />

    <p-button-pure
      [iconSource]="'assets/icons/download.svg'"
      [loading]="(exporting$ | async) ?? false"
      [size]="'x-small'"
      [underline]="true"
      (click)="exportVpnPhoneNumbers()"
    >
      Export VPN Phone Numbers
    </p-button-pure>
  </div>

  <ng-container
    *ngrxLet="
      vpnPhoneNumbers$ as vpnPhoneNumbers;
      error as vpnPhoneNumbersError;
      suspenseTpl: spinner
    "
  >
    <div *ngIf="vpnPhoneNumbersError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadVpnPhoneNumbers()"
      >
        Reload VPN Phone Numbers
      </p-button-pure>
    </div>

    <ng-container *ngIf="!vpnPhoneNumbersError && vpnPhoneNumbers">
      <div class="overlay-spinner-host">
        <ng-container *ngIf="loading$ | async" [ngTemplateOutlet]="overlaySpinner" />

        <p-table caption="VPN Phone Numbers list table">
          <p-table-head>
            <p-table-head-row>
              <p-table-head-cell style="width: 16.667%">Person</p-table-head-cell>
              <p-table-head-cell style="width: 16.667%">VPN Number</p-table-head-cell>
              <p-table-head-cell style="width: 16.667%">Full Number</p-table-head-cell>
              <p-table-head-cell style="width: 16.667%">VPN Subscription Type</p-table-head-cell>
              <p-table-head-cell style="width: 16.667%">
                Additional Option Activation
              </p-table-head-cell>
              <p-table-head-cell style="width: 16.667%">Status</p-table-head-cell>
            </p-table-head-row>
          </p-table-head>

          <p-table-body>
            <p-table-row
              *ngFor="
                let vpnPhoneNumber of vpnPhoneNumbers.content
                  | fillArray: vpnPhoneNumbers.pageable.pageSize;
                trackBy: trackRow
              "
            >
              <p-table-cell>
                <ng-container *ngIf="vpnPhoneNumber; else emptyCell">
                  {{ vpnPhoneNumber.user.firstName }} {{ vpnPhoneNumber.user.lastName }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="vpnPhoneNumber; else emptyCell">
                  <a [routerLink]="['/vpn-phone-numbers', vpnPhoneNumber.id]">
                    {{ vpnPhoneNumber.vpnNumber }}
                  </a>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="vpnPhoneNumber; else emptyCell">
                  {{ vpnPhoneNumber.fullNumber }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="vpnPhoneNumber; else emptyCell">
                  {{ vpnPhoneNumber.subscriptionType.name }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="vpnPhoneNumber; else emptyCell">
                  {{ vpnPhoneNumber.additionalOptionActivation ? 'Yes' : 'No' }}
                  <p-popover *ngIf="vpnPhoneNumber.additionalOptionActivation">
                    <div class="popover-content">
                      <p-text class="popover-text">
                        {{ vpnPhoneNumber.additionalOptionDescription }}
                      </p-text>
                    </div>
                  </p-popover>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="vpnPhoneNumber; else emptyCell">
                  <div
                    class="status"
                    [ngClass]="{
                      'status--active': vpnPhoneNumber.active,
                      'status--inactive': !vpnPhoneNumber.active
                    }"
                  ></div>
                </ng-container>
              </p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>
      </div>

      <p-pagination
        [totalItemsCount]="vpnPhoneNumbers.totalElements"
        [itemsPerPage]="vpnPhoneNumbers.size"
        [activePage]="vpnPhoneNumbers.pageable.pageNumber + 1"
        (update)="updatePageNo($event.detail.page)"
      />
    </ng-container>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>

<ng-template #overlaySpinner>
  <div class="overlay-spinner"><p-spinner /></div>
</ng-template>

<ng-template #emptyCell>
  <span class="empty-cell"></span>
</ng-template>
