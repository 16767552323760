<div
  class="icon"
  tabindex="0"
  [attr.aria-label]="name"
  [style.backgroundColor]="name | opaUserIconColor: isCounter"
  [tippy]="name"
>
  <p-text aria-hidden="true" [size]="'xx-small'" [color]="isCounter ? 'primary' : 'inherit'">
    {{ name | opaUserIconInitials: isCounter }}
  </p-text>
</div>
