import type { AbstractControl, AsyncValidatorFn, FormGroup } from '@angular/forms';
import { combineLatest, map, type Observable } from 'rxjs';
import { BudgetType } from '../../shared/models/api/edu-expense.model';
import type { User } from '../../shared/models/api/user.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import type { ExtendedValidationErrors } from '../../shared/models/extended-validation-errors.model';
import type { CreateEduExpenseFormValue } from './edu-expense-create.models';

export function isAmountAboveUsersBudget(
  users$: Observable<User[]>,
  amountInEur$: Observable<number>,
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ExtendedValidationErrors | null> => {
    const form = control as FormGroup<ControlsOf<CreateEduExpenseFormValue>>;

    return combineLatest([users$, amountInEur$]).pipe(
      map(([users, amountInEur]) => {
        if (!form.controls.amount.value) return null;

        if (!form.controls.userId.value)
          return { userNotSelected: true, message: 'User must be selected first' };

        const user = users.find((u) => u.id === Number(form.controls.userId.value));
        if (!user) return { userNotFound: true, message: 'Selected user was not found' };

        if (!form.controls.budgetType.value)
          return { budgetTypeNotSelected: true, message: 'Budget type must be selected first' };

        if (!amountInEur)
          return {
            invalidAmount: true,
            message: 'Amount and currency must be selected first',
          };

        const maxAmount =
          form.controls.budgetType.value === BudgetType.EDU
            ? user.eduEquBudgetStatus.maxEducationBudgetRemaining
            : user.eduEquBudgetStatus.maxEquipmentBudgetRemaining;

        if (amountInEur <= maxAmount) return null;

        return {
          aboveUsersBudget: true,
          message: `Transaction cost is above users budget of €${maxAmount}`,
        };
      }),
    );
  };
}
