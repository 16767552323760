import { Directive, ElementRef, Input, inject, type OnDestroy } from '@angular/core';
import { Subject, combineLatest, switchMap } from 'rxjs';
import { SubSink } from 'subsink';
import { VisibleColumnsService } from '../components/paged-table/visible-columns.service';

@Directive({
  selector: '[opaVisibleColumn]',
  standalone: true,
})
export class VisibleColumnDirective implements OnDestroy {
  private readonly visibleColumnsService = inject(VisibleColumnsService);
  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly subSink = new SubSink();
  private readonly hiddenClass = 'hidden';

  private readonly _tableName = new Subject<string>();
  private readonly _columnName = new Subject<string>();

  @Input({ required: true }) set tableName(tableName: string) {
    this._tableName.next(tableName);
  }

  @Input({ required: true }) set columnName(columnName: string) {
    this._columnName.next(columnName);
  }

  constructor() {
    this.subSink.sink = combineLatest([
      this._tableName.asObservable(),
      this._columnName.asObservable(),
    ])
      .pipe(
        switchMap(([tableName, columnName]) =>
          this.visibleColumnsService.isColumnVisible$(tableName, columnName),
        ),
      )
      .subscribe((isVisible) => {
        if (isVisible) {
          this.elementRef.nativeElement.classList.remove(this.hiddenClass);
        } else {
          this.elementRef.nativeElement.classList.add(this.hiddenClass);
        }
      });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
