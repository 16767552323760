import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  EMPTY,
  distinctUntilChanged,
  merge,
  of,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import { objectsEqual } from '../../shared/helpers/objects-equal.helper';
import type { BankCard } from '../../shared/models/api/bank-card.model';
import { BankCardService } from '../../shared/services/api/bank-card.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { BankCardEditComponent } from './bank-card-edit/bank-card-edit.component';

@Component({
  selector: 'opa-bank-card-details',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    LetDirective,
    BankCardEditComponent,
  ],
  templateUrl: './bank-card-details.component.html',
  styleUrl: './bank-card-details.component.scss',
})
export class BankCardDetailsComponent {
  private readonly mediaQueryService = inject(MediaQueryService);
  private readonly route = inject(ActivatedRoute);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly bankCardService = inject(BankCardService);

  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  bankCard$ = this.getBankCard$();
  showEditModal = false;

  reloadBankCard(markForCheck = false, bankCard?: BankCard): void {
    this.bankCard$ = this.getBankCard$(bankCard);
    if (markForCheck) this.changeDetectorRef.markForCheck();
  }

  private getBankCard$(bankCard?: BankCard): Observable<BankCard> {
    const routeParams$ = merge(
      of(this.route.snapshot.params),
      this.route.params.pipe(
        startWith(this.route.snapshot.params),
        distinctUntilChanged((a, b) => objectsEqual(a, b)),
        skip(1),
        tap(() => this.reloadBankCard(true)),
        switchMap(() => EMPTY),
      ),
    );

    return routeParams$.pipe(
      switchMap((routeParams) => {
        if (bankCard) return of(bankCard);

        const bankCardId = Number(routeParams['id']);
        return this.bankCardService.getBankCard(bankCardId);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
