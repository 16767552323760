import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type { Page } from '../../models/api/page.model';
import type {
  CreateVpnPhoneNumber,
  EditVpnPhoneNumber,
  VpnPhoneNumber,
} from '../../models/api/vpn-phone-number.model';

@Injectable({
  providedIn: 'root',
})
export class VpnPhoneNumberService {
  private readonly httpClient = inject(HttpClient);
  private readonly vpnPhoneNumberApi = `${environment.apiUrl}/v1/vpn`;

  getVpnPhoneNumbers(
    pageNo: number | null,
    vpnNumber?: string,
    subscriptionTypeId?: number,
    active?: boolean,
  ): Observable<Page<VpnPhoneNumber>> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get VPN Phone Numbers');

    let params = new HttpParams();

    if (pageNo === null) params = params.set('unpaged', true);
    else params = params.set('page', pageNo).set('size', 10);

    if (vpnNumber != null) params = params.set('vpnNumber', vpnNumber);
    if (subscriptionTypeId != null) params = params.set('subscriptionTypeId', subscriptionTypeId);
    if (active != null) params = params.set('active', active);

    return this.httpClient.get<Page<VpnPhoneNumber>>(this.vpnPhoneNumberApi, { context, params });
  }

  getVpnPhoneNumber(vpnPhoneNumberId: number): Observable<VpnPhoneNumber> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get VPN Phone Number');
    return this.httpClient.get<VpnPhoneNumber>(`${this.vpnPhoneNumberApi}/${vpnPhoneNumberId}`, {
      context,
    });
  }

  createVpnPhoneNumber(vpnPhoneNumber: CreateVpnPhoneNumber): Observable<VpnPhoneNumber> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Create VPN Phone Number');
    return this.httpClient.post<VpnPhoneNumber>(this.vpnPhoneNumberApi, vpnPhoneNumber, {
      context,
    });
  }

  editVpnPhoneNumber(
    vpnPhoneNumberId: number,
    vpnPhoneNumber: EditVpnPhoneNumber,
  ): Observable<VpnPhoneNumber> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Edit VPN Phone Number');
    return this.httpClient.put<VpnPhoneNumber>(
      `${this.vpnPhoneNumberApi}/${vpnPhoneNumberId}`,
      vpnPhoneNumber,
      { context },
    );
  }
}
