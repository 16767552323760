import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  combineLatest,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  tap,
  type Observable,
} from 'rxjs';
import {
  PersonalTravelTransactionLogType,
  type PersonalTravelTransactionLogForMonth,
  type PersonalTravelTransactionLogForYear,
  type PersonalTravelTransactionLogRecord,
} from '../../shared/models/api/personal-travel-transaction-log.model';
import type { User } from '../../shared/models/api/user.model';
import { LeadingPlusPipe } from '../../shared/pipes/leading-plus.pipe';
import { UserService } from '../../shared/services/api/user.service';
import { transactionLogTypeTranslation } from './personal-travel-budget.helpers';
import type { TransactionLogData } from './personal-travel-budget.models';

@Component({
  selector: 'opa-personal-travel-budget',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    LetDirective,
    ReactiveFormsModule,
    TranslocoModule,
    LeadingPlusPipe,
  ],
  templateUrl: './personal-travel-budget.component.html',
  styleUrl: './personal-travel-budget.component.scss',
})
export class PersonalTravelBudgetComponent {
  private readonly formBuilder = inject(FormBuilder).nonNullable;
  private readonly userService = inject(UserService);
  readonly TransactionLogType = PersonalTravelTransactionLogType;
  readonly transactionLogTypeTranslation = transactionLogTypeTranslation;

  readonly now = new Date();
  me$ = this.getMe$();
  readonly yearControl = this.formBuilder.control<string | undefined>(undefined);
  transactionLogData$ = this.getTransactionLogData$();

  reloadMe(): void {
    this.me$ = this.getMe$();
  }

  reloadTransactionLogData(): void {
    this.transactionLogData$ = this.getTransactionLogData$();
  }

  trackYearLog(index: number, log: PersonalTravelTransactionLogForYear): number {
    return log.year;
  }

  trackMonthLog(index: number, log: PersonalTravelTransactionLogForMonth): string {
    return log.monthName;
  }

  trackLog(index: number, log: PersonalTravelTransactionLogRecord): number {
    return log.id;
  }

  private getMe$(): Observable<User> {
    return this.userService.getMe().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  private getTransactionLogData$(): Observable<TransactionLogData> {
    const activeYears$ = this.userService.getMyActiveYears().pipe(
      tap((activeYears) => {
        if (!this.yearControl.value) this.yearControl.patchValue(`${activeYears[0]}`);
      }),
    );
    const transactionLogs$ = combineLatest([
      this.yearControl.valueChanges.pipe(startWith(this.yearControl.value), distinctUntilChanged()),
      this.userService.getMyPersonalTravelTransactionLog(),
    ]).pipe(map(([year, log]) => log.records.filter((record) => `${record.year}` === year)));
    return combineLatest([activeYears$, transactionLogs$]).pipe(
      map(([activeYears, transactionLogs]) => ({ activeYears, transactionLogs })),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
