/**
 * WARN: PDS variables.
 * Heading and Text share size, but Heading is bolder.
 */
export const PdsFonts = {
  family: `'Porsche Next','Arial Narrow',Arial,'Heiti SC',SimHei,sans-serif`,
  lineHeight: 'calc(6px + 2.125ex)',
  size: {
    xxSmall: '0.75rem',
    xxSmallPx: 12,
    xSmall: 'clamp(0.81rem, 0.77rem + 0.23vw, 0.88rem)',
    xSmallPx: 14,
    small: '1rem',
    smallPx: 16,
    medium: 'clamp(1.13rem, 1.08rem + 0.21vw, 1.33rem)',
    mediumPx: 20,
    large: 'clamp(1.27rem, 0.51vw + 1.16rem, 1.78rem)',
    largePx: 28,
    xLarge: 'clamp(1.42rem, 0.94vw + 1.23rem, 2.37rem)',
    xLargePx: 38,
    xxLarge: 'clamp(1.6rem, 1.56vw + 1.29rem, 3.16rem)',
    xxLargePx: 50,
  },
  weigth: {
    regular: 400,
    semiBold: 600,
    bold: 700,
  },
} as const;
