import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { combineLatest, map, type Observable } from 'rxjs';
import { InventoryItemsService } from '../../shared/services/api/inventory-items.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { InventoryItemIconPipe } from './inventory-item-icon.pipe';
import type { MyInventoryItemsData } from './my-inventory-items.models';

@Component({
  selector: 'opa-my-inventory-items',
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    CommonModule,
    LetDirective,
    RouterLink,
    InventoryItemIconPipe,
  ],
  templateUrl: './my-inventory-items.component.html',
  styleUrl: './my-inventory-items.component.scss',
})
export class MyInventoryItemsComponent {
  private readonly inventoryItemsService = inject(InventoryItemsService);
  private readonly mediaQueryService = inject(MediaQueryService);

  data$ = this.getData();

  reloadData(): void {
    this.data$ = this.getData();
  }

  getData(): Observable<MyInventoryItemsData> {
    return combineLatest([
      this.inventoryItemsService.getMyItems(),
      this.mediaQueryService.max('s'),
    ]).pipe(map(([inventoryItems, mediaMaxS]) => ({ inventoryItems, mediaMaxS })));
  }
}
