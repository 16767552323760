import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import { TableFilterComponent } from '../../shared/components/table-filter/table-filter.component';
import { TableSortComponent } from '../../shared/components/table-sort/table-sort.component';
import type {
  TableSort,
  TableSortDirection,
} from '../../shared/components/table-sort/table-sort.models';
import { objectsEqual } from '../../shared/helpers/objects-equal.helper';
import { EventType, type Event, type EventFilter } from '../../shared/models/api/event.model';
import type { Page } from '../../shared/models/api/page.model';
import type { User } from '../../shared/models/api/user.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import {
  NumberComparator,
  numberComparatorTranslation,
} from '../../shared/models/number-comparator.model';
import { FillArrayPipe } from '../../shared/pipes/fill-array.pipe';
import { EventService } from '../../shared/services/api/event.service';
import { AuthService } from '../../shared/services/auth.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { eventTypeTranslation } from '../event-create/event-create.helpers';
import type { EventListFormValue, EventListRouteData } from './event-list.models';

@Component({
  selector: 'opa-events',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    ReactiveFormsModule,
    TranslocoPipe,
    LetDirective,
    FillArrayPipe,
    TableFilterComponent,
    TableSortComponent,
  ],
  templateUrl: './event-list.component.html',
  styleUrl: './event-list.component.scss',
})
export class EventListComponent {
  private readonly authService = inject(AuthService);
  private readonly mediaQueryService = inject(MediaQueryService);
  private readonly route = inject(ActivatedRoute);
  private readonly formBuilder = inject(FormBuilder);
  private readonly eventService = inject(EventService);
  readonly eventTypes = Object.values(EventType);
  readonly eventTypeTranslation = eventTypeTranslation;
  readonly numberComparators = Object.values(NumberComparator);
  readonly numberComparatorTranslation = numberComparatorTranslation;

  readonly isFunGamesAdmin = this.authService.isFunGamesAdmin();
  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  private readonly pageNo = new BehaviorSubject(1);
  readonly allValue = 'all' as const;
  readonly form = this.buildForm();
  private readonly loading = new BehaviorSubject(false);
  readonly loading$ = this.loading.pipe(distinctUntilChanged());
  private readonly sort = new BehaviorSubject<TableSort | undefined>(undefined);
  readonly sort$ = this.sort.pipe(distinctUntilChanged((a, b) => objectsEqual(a, b)));
  events$ = this.getEvents$();

  reloadEvents(): void {
    this.events$ = this.getEvents$();
  }

  resetFilters(): void {
    this.sort.next(undefined);
    this.form.reset();
  }

  updateSort(sortId: string, direction: TableSortDirection | undefined): void {
    if (!direction) this.sort.next(undefined);
    else this.sort.next({ sortId, direction });
  }

  trackRow(index: number): number {
    return index;
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  updatePageNo(page: number): void {
    this.pageNo.next(page);
  }

  private buildForm(): FormGroup<ControlsOf<EventListFormValue>> {
    // TODO: make all form builders nonNullable in the project
    const form = this.formBuilder.nonNullable.group<ControlsOf<EventListFormValue>>({
      eventName: this.formBuilder.nonNullable.control(null),
      vendorName: this.formBuilder.nonNullable.control(null),
      eventType: this.formBuilder.nonNullable.control(this.allValue),
      participantCountComparator: this.formBuilder.nonNullable.control(this.numberComparators[0]),
      participantCount: this.formBuilder.nonNullable.control(null),
      dateFrom: this.formBuilder.nonNullable.control(null),
      dateTo: this.formBuilder.nonNullable.control(null),
    });
    return form;
  }

  private getEvents$(): Observable<Page<Event>> {
    const routeData$ = (this.route.data as Observable<EventListRouteData>).pipe(
      startWith(this.route.snapshot.data as EventListRouteData),
      distinctUntilChanged((a, b) => objectsEqual(a, b)),
      tap(() => this.updatePageNo(1)),
    );

    const pageNo$ = this.pageNo.pipe(distinctUntilChanged());

    const form$ = merge(
      ...Object.entries(this.form.controls).map(([key, control]) => {
        const controlName = key as keyof EventListFormValue;
        let value$ = control.valueChanges as Observable<unknown>;
        value$ = value$.pipe(
          startWith(control.value),
          distinctUntilChanged(),
          skip(1),
          map(() => undefined),
        );
        if (
          controlName === 'eventName' ||
          controlName === 'vendorName' ||
          controlName === 'participantCount'
        )
          value$ = value$.pipe(debounceTime(500));
        return value$;
      }),
    ).pipe(
      startWith(undefined),
      map(() => this.form.getRawValue()),
      tap(() => this.updatePageNo(1)),
    );

    const sort$ = this.sort$.pipe(tap(() => this.updatePageNo(1)));

    return combineLatest([routeData$, pageNo$, form$, sort$]).pipe(
      debounceTime(0),
      tap(() => this.loading.next(true)),
      switchMap(([routeData, pageNo, form, sort]) => {
        const filter: EventFilter = {
          name: form.eventName ?? undefined,
          vendorName: form.vendorName ?? undefined,
          eventType: form.eventType === this.allValue ? undefined : form.eventType ?? undefined,
          participantCountComparator:
            form.participantCount == null
              ? undefined
              : form.participantCountComparator ?? undefined,
          participantCount: form.participantCount ?? undefined,
          dateFrom: form.dateFrom ?? undefined,
          dateTo: form.dateTo ?? undefined,
        };

        return routeData.filter === 'all'
          ? this.eventService.getEvents(pageNo, filter, sort)
          : this.eventService.getMyEvents(pageNo, filter, sort);
      }),
      tap({
        next: () => this.loading.next(false),
        error: () => this.loading.next(false),
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
