<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Edu & Equ Transactions</p-heading>

  <p-link
    *ngIf="isEduEquAdmin"
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/edu-transactions/create'">Create New Transaction</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="list">
  <p-heading *ngrxLet="results$ | async as eduExpenses" [size]="'small'" [tag]="'h4'">
    <div class="list-heading">
      <div class="total-items">
        {{ eduExpenses?.totalElements }} Transactions
        <p-divider aria-hidden="true" [direction]="'vertical'" />

        <p-button-pure
          [iconSource]="'assets/icons/download.svg'"
          [size]="'x-small'"
          [underline]="true"
          [loading]="(exportInProgress$ | async) ?? false"
          (click)="exportEduExpenseList()"
        >
          Export Edu Transactions
        </p-button-pure>
      </div>
    </div>
  </p-heading>

  <ng-container *ngIf="activeFilters$ | async as filters">
    <div *ngIf="filters.length" style="width: 100%">
      <p-scroller style="white-space: nowrap">
        <p-tag-dismissible
          class="filter-item"
          *ngFor="let filter of filters"
          [label]="filterTranslations[filter.key] | transloco"
          (click)="clearFilter(filter.key)"
        >
          {{ filter.value }}
        </p-tag-dismissible>

        <div class="reset-filter">
          <p-button-pure
            [iconSource]="'assets/icons/reset.svg'"
            [underline]="true"
            (click)="resetFilters()"
          >
            Reset Filters
          </p-button-pure>
        </div>
      </p-scroller>
    </div>
  </ng-container>

  <ng-container *ngrxLet="results$ as eduExpenses; error as eduExpensesError; suspenseTpl: spinner">
    <div *ngIf="eduExpensesError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reload()"
      >
        Reload Transactions
      </p-button-pure>
    </div>

    <ng-container *ngIf="!eduExpensesError && eduExpenses">
      <div class="overlay-spinner-host">
        <ng-container *ngIf="loading$ | async" [ngTemplateOutlet]="overlaySpinner" />

        <p-table
          *ngrxLet="data$ as data; error as dataError; suspenseTpl: spinner"
          caption="Edu Transactions list table"
        >
          <div *ngIf="dataError">
            <p-button-pure
              [hideLabel]="true"
              [iconSource]="'assets/icons/refresh.svg'"
              [size]="'large'"
              (click)="reloadData()"
            >
              Reload Data
            </p-button-pure>
          </div>

          <p-table-head *ngrxLet="sort$ as activeSort">
            <p-table-head-row>
              <p-table-head-cell
                *ngrxLet="{
                  label: 'Id',
                  sortId: 'id'
                } as cell"
                class="align-end"
                style="width: 1%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: 'Person',
                  control: form.controls.name,
                  sortId: 'user.firstName'
                } as cell"
                style="width: 16.5%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-filter [active]="!!cell.control.value">
                  <p-text-field-wrapper [label]="cell.label">
                    <input type="text" [formControl]="cell.control" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: 'Budget Type',
                  control: form.controls.budgetType,
                  sortId: 'budgetType'
                } as cell"
                style="width: 16.5%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-filter
                  [active]="!!cell.control.value && cell.control.value !== allValue"
                >
                  <p-select-wrapper [native]="true" [label]="'Budget Type'">
                    <select [formControl]="form.controls.budgetType">
                      <option [value]="allValue">All</option>
                      <option *ngFor="let type of budgetTypes" [value]="type">
                        {{ budgetTypeTranslation[type] | transloco }}
                      </option>
                    </select>
                  </p-select-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: 'Category',
                  control: form.controls.expenseCategoryId,
                  sortId: 'expenseCategoryId'
                } as cell"
                style="width: 16.5%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-filter
                  [active]="!!cell.control.value && cell.control.value !== allValue"
                >
                  <p-select-wrapper [native]="true" [label]="cell.label">
                    <select [formControl]="cell.control">
                      <option [value]="allValue">All</option>

                      <option
                        *ngFor="let category of data.categories; trackBy: trackCategory"
                        [value]="category.id"
                      >
                        {{ category.description }} ({{ category.budgetType }})
                      </option>
                    </select>
                  </p-select-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: 'Purpose',
                  control: form.controls.expensePurposeId,
                  sortId: 'expensePurposeId'
                } as cell"
                style="width: 16.5%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-filter
                  [active]="!!cell.control.value && cell.control.value !== allValue"
                >
                  <p-select-wrapper [native]="true" [label]="cell.label">
                    <select [formControl]="cell.control">
                      <option [value]="allValue">All</option>

                      <option
                        *ngFor="let purpose of data.purposes; trackBy: trackPurpose"
                        [value]="purpose.id"
                      >
                        {{ purpose.description }} ({{ purpose.budgetType }})
                      </option>
                    </select>
                  </p-select-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: 'Date',
                  controlFrom: form.controls.dateFrom,
                  controlTo: form.controls.dateTo,
                  sortId: 'dateUsed'
                } as cell"
                style="width: 16.5%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-filter [active]="!!cell.controlFrom.value || !!cell.controlTo.value">
                  <p-text-field-wrapper [label]="cell.label + ' From'">
                    <input type="date" [formControl]="cell.controlFrom" />
                  </p-text-field-wrapper>

                  <p-text-field-wrapper [label]="cell.label + ' To'">
                    <input type="date" [formControl]="cell.controlTo" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: 'Amount',
                  sortId: 'amount'
                } as cell"
                style="width: 16.5%"
              >
                <span>{{ cell.label }}</span>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>
            </p-table-head-row>
          </p-table-head>

          <p-table-body>
            <p-table-row
              *ngFor="
                let eduExpense of eduExpenses.content | fillArray: eduExpenses.pageable.pageSize;
                trackBy: trackRow
              "
            >
              <p-table-cell class="align-end">
                <ng-container *ngIf="eduExpense; else emptyCell">
                  <a [routerLink]="['/edu-transactions', eduExpense.id]">{{ eduExpense.id }}</a>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="eduExpense; else emptyCell">
                  {{ eduExpense.user.firstName }} {{ eduExpense.user.lastName }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="eduExpense; else emptyCell">
                  {{
                    eduExpense.budget ? (budgetTypeTranslation[eduExpense.budget] | transloco) : '-'
                  }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="eduExpense; else emptyCell">
                  {{ eduExpense.category || '-' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="eduExpense; else emptyCell">
                  {{ eduExpense.purpose || '-' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="eduExpense; else emptyCell">
                  {{ eduExpense.dateUsed | date: 'dd.MM.yyyy.' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="eduExpense; else emptyCell">
                  {{ eduExpense.amount | currency: 'EUR' | leadingPlus }}
                </ng-container>
              </p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>
      </div>

      <p-pagination
        [totalItemsCount]="eduExpenses.totalElements"
        [itemsPerPage]="eduExpenses.size"
        [activePage]="eduExpenses.pageable.pageNumber + 1"
        (update)="updatePageNo($event.detail.page)"
      />
    </ng-container>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>

<ng-template #overlaySpinner>
  <div class="overlay-spinner"><p-spinner /></div>
</ng-template>

<ng-template #emptyCell>
  <span class="empty-cell"></span>
</ng-template>
