<p-heading class="title" [size]="'x-large'" [tag]="'h3'">Inventory</p-heading>

<p-divider aria-hidden="true" />

<section *ngrxLet="inventoryItem$ | async as inventoryItem; suspenseTpl: spinner" class="section">
  <div class="breadcrumbs">
    <p-link-pure [hideLabel]="true" [iconSource]="'assets/icons/arrow-left.svg'">
      <a *ngIf="inventoryItem" [routerLink]="['/inventory-items', inventoryItem.id]">
        Back to Item
      </a>
      <a *ngIf="!inventoryItem" [routerLink]="['/inventory-items']">Back to List</a>
    </p-link-pure>

    <p-heading [size]="'large'" [tag]="'h4'">
      @if (!inventoryItem) {
        &nbsp;
      } @else {
        {{ inventoryItem.manufacturer.description }} {{ inventoryItem.model }}
      }
    </p-heading>
  </div>

  <opa-inventory-item-form [inventoryItem]="inventoryItem" (submitForm)="onSubmit($event)" />
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
