import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  type OnChanges,
  type OnDestroy,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { SubSink } from 'subsink';
import type { CreateInventoryStatus } from '../../../shared/models/api/inventory-status.model';
import type { ControlsOf } from '../../../shared/models/controls-of.model';
import type { NgChanges } from '../../../shared/models/ng-changes.model';
import { InventoryStatusService } from '../../../shared/services/api/inventory-status.service';
import { OpaValidators } from '../../../shared/validators/opa.validators';
import type { CreateInventoryStatusFormValue } from './inventory-status-create.models';

@Component({
  selector: 'opa-inventory-status-create',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    ReactiveFormsModule,
    LetDirective,
    TranslocoPipe,
  ],
  templateUrl: './inventory-status-create.component.html',
  styleUrl: './inventory-status-create.component.scss',
})
export class InventoryStatusCreateComponent implements OnChanges, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly inventoryStatusService = inject(InventoryStatusService);
  private readonly toastManager = inject(ToastManager);

  @Input({ required: true }) open = false;
  @Output() dismiss = new EventEmitter<boolean | undefined>();

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.pipe(distinctUntilChanged());

  ngOnChanges(changes: NgChanges<InventoryStatusCreateComponent>): void {
    if (changes.open && this.open) this.form.reset();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(success?: boolean): void {
    this.dismiss.emit(success);
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const status = this.formValueToStatus(formValue);
    this.subSink.sink = this.inventoryStatusService
      .createInventoryStatus(status)
      .pipe(
        tap({
          next: (createStatus) => {
            this.toastManager.addMessage({
              text: `Status '${createStatus.description}' created`,
              state: 'success',
            });
            this.submitting.next(false);
            this.close(true);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<CreateInventoryStatusFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<CreateInventoryStatusFormValue>>({
      name: this.formBuilder.control(null, [
        OpaValidators.required(),
        OpaValidators.isNormalized(),
      ]),
      description: this.formBuilder.control(null, OpaValidators.required()),
    });

    return form;
  }

  private formValueToStatus(form: CreateInventoryStatusFormValue): CreateInventoryStatus {
    const status: CreateInventoryStatus = {
      description: form.description!,
      name: form.name!,
    };
    return status;
  }
}
