<p-modal
  [heading]="'Are you sure you want to delete ' + group?.name + ' group?'"
  [open]="open"
  (dismiss)="close()"
>
  <p-text class="warning-text">
    This action cannot be undone. Please confirm that you want to proceed.
  </p-text>

  <p-button-group class="modal-actions">
    <p-button [type]="'button'" [variant]="'primary'" (click)="close()"> Cancel </p-button>

    <p-button
      [icon]="'none'"
      [loading]="(submitting$ | async) ?? false"
      [variant]="'secondary'"
      (click)="submit()"
    >
      Delete Group
    </p-button>
  </p-button-group>
</p-modal>
