<!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus -->
<div class="navbar-backdrop" [class.open]="navbarOpen$ | async" (click)="closeNavbar()"></div>

<aside
  aria-label="Navigation sidebar"
  class="navbar"
  opaFocusOutside
  [class.open]="navbarOpen$ | async"
  [focusOutsideIgnore]="[navbarOpenerClass]"
  (focusOutside)="closeNavbar()"
>
  <div>
    <div class="controls">
      <p-button-pure
        class="close-btn"
        [hideLabel]="true"
        [iconSource]="'assets/icons/close.svg'"
        (click)="closeNavbar()"
      >
        Close Navigation Sidebar
      </p-button-pure>
    </div>

    <p-heading class="title" [size]="'medium'" [tag]="'h1'">{{ appTitle }}</p-heading>

    <p-accordion
      class="user-accordion"
      [compact]="true"
      [open]="userOpen"
      (update)="userOpen = $event.detail.open"
    >
      <div slot="heading">
        <div *ngrxLet="me$ as me; error as meError; suspenseTpl: spinner" class="user-name">
          <p-icon *ngIf="meError" [name]="'exclamation'" [size]="'large'" />

          <ng-container *ngIf="!meError && me">
            <opa-user-icon [name]="me.firstName + ' ' + me.lastName" />
            <div>
              <p-text [size]="'x-small'">Hi, {{ me.firstName }} {{ me.lastName }}</p-text>
              <p-text *ngIf="isSuperAdmin" [color]="'contrast-medium'" [size]="'xx-small'">
                Administrator Account
              </p-text>
            </div>
          </ng-container>
        </div>
      </div>

      <menu>
        <li>
          <p-button-pure class="user-action" [icon]="'none'" (click)="logout()">
            Logout
          </p-button-pure>
        </li>
      </menu>
    </p-accordion>

    <ng-container *ngrxLet="navGroups$ as navGroups">
      <ng-container *ngrxLet="me$ as me; error as meError; suspenseTpl: spinner">
        <div *ngIf="meError">
          <p-button-pure
            [hideLabel]="true"
            [iconSource]="'assets/icons/refresh.svg'"
            [size]="'large'"
            (click)="reloadMe()"
          >
            Reload Navbar
          </p-button-pure>
        </div>

        <ng-container *ngIf="!meError && me">
          <ng-container *ngFor="let navGroup of navGroups; index as index; trackBy: trackNavGroup">
            <p-accordion
              *ngIf="navGroup | opaFilterNavLink: me"
              class="nav-group"
              [open]="navGroup.open"
              (update)="navGroup.open = $event.detail.open"
            >
              <div slot="heading">
                <p-heading [attr.id]="'nav-header-' + index" [size]="'small'" [tag]="'h2'">
                  <p-icon class="nav-group--header" [source]="navGroup.icon" />
                  {{ navGroup.label }}
                </p-heading>
              </div>

              <nav [attr.aria-labelledby]="'nav-header-' + index">
                <ng-container *ngFor="let navLink of navGroup.links; trackBy: trackNavLink">
                  <ng-container *ngIf="navLink | opaFilterNavLink: me">
                    <div
                      class="nav-row"
                      *ngrxLet="{ hover: false, mainActive: false, plusActive: false } as row"
                      (pointerenter)="row.hover = true"
                      (pointerleave)="row.hover = false"
                    >
                      <p-link-pure
                        class="nav-link"
                        [active]="row.hover || row.mainActive || row.plusActive"
                        [class.nav-link--big]="navLink.plusLink"
                        [icon]="'none'"
                        (click)="closeNavbar()"
                      >
                        <a
                          routerLinkActive
                          (isActiveChange)="row.mainActive = $event"
                          [routerLink]="navLink.url"
                        >
                          {{ navLink.label }}
                        </a>
                      </p-link-pure>

                      <p-link-pure
                        *ngIf="navLink.plusLink && (navLink.plusLink | opaFilterNavLink: me)"
                        class="nav-link nav-link--small"
                        [active]="row.hover || row.mainActive || row.plusActive"
                        [hideLabel]="true"
                        [iconSource]="'/assets/icons/add-circle.svg'"
                        (click)="closeNavbar()"
                      >
                        <a
                          routerLinkActive
                          (isActiveChange)="row.plusActive = $event"
                          [routerLink]="navLink.plusLink.url"
                        >
                          {{ navLink.plusLink.label }}
                        </a>
                      </p-link-pure>
                    </div>
                  </ng-container>
                </ng-container>
              </nav>

              <p-button
                *ngIf="isSuperAdmin && index === 1"
                class="nav-group--action-button"
                [iconSource]="'assets/icons/add.svg'"
                [variant]="'secondary'"
                (click)="showAddMonthlyIncomeModal = true"
              >
                Add Monthly Income
              </p-button>
            </p-accordion>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div class="logo-container">
    <img src="assets/images/pdhr-logo.svg" alt="PDHR Logo" />
  </div>
</aside>

<opa-add-monthly-income
  [open]="showAddMonthlyIncomeModal"
  (dismiss)="showAddMonthlyIncomeModal = false"
/>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner [size]="'small'" /></div>
</ng-template>
