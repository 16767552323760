import type { EduExpenseCategory } from './edu-expense-category.model';
import type { EduExpensePurpose } from './edu-expense-purpose.model';

export interface EduTransactionLog {
  records: EduTransactionLogForYear[];
}

export interface EduTransactionLogForYear {
  yearTransactionLog: EduTransactionLogForMonth[];
  year: number;
}

export interface EduTransactionLogForMonth {
  monthTransactionLog: EduTransactionLogRecord[];
  monthName: string;
}

export interface EduTransactionLogRecord {
  id: number;
  type: EduTransactionLogType;
  date: string;
  category: EduExpenseCategory | null;
  purpose: EduExpensePurpose | null;
  amount: number;
  budgetChange: number;
  description?: string;
  totalBudgetBefore: number;
  totalBudgetAfter: number;
  totalBudgetOverCap: number;
}

export enum EduTransactionLogType {
  EDU_EXPENSE = 'EDU_EXPENSE',
  EQU_EXPENSE = 'EQU_EXPENSE',
  TOTAL_BUDGET_CHANGE = 'TOTAL_BUDGET_CHANGE',
  TOTAL_BUDGET_AVAILABLE = 'TOTAL_BUDGET_AVAILABLE',
  TOTAL_BUDGET_PREVIOUSLY_REMAINING = 'TOTAL_BUDGET_PREVIOUSLY_REMAINING',
}
