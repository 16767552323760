import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  EMPTY,
  distinctUntilChanged,
  merge,
  of,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import { objectsEqual } from '../../shared/helpers/objects-equal.helper';
import type { EduExpense } from '../../shared/models/api/edu-expense.model';
import { LeadingPlusPipe } from '../../shared/pipes/leading-plus.pipe';
import { EduExpenseService } from '../../shared/services/api/edu-expense.service';
import { AuthService } from '../../shared/services/auth.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { budgetTypeTranslation } from '../edu-expense-create/edu-expense-create.helpers';

@Component({
  selector: 'opa-edu-expense-details',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    LetDirective,
    TranslocoPipe,
    LeadingPlusPipe,
  ],
  templateUrl: './edu-expense-details.component.html',
  styleUrl: './edu-expense-details.component.scss',
})
export class EduExpenseDetailsComponent {
  private readonly authService = inject(AuthService);
  private readonly mediaQueryService = inject(MediaQueryService);
  private readonly route = inject(ActivatedRoute);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly eduExpenseService = inject(EduExpenseService);
  readonly budgetTypeTranslation = budgetTypeTranslation;

  readonly isEduEquAdmin = this.authService.isEduEquAdmin();
  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  eduExpense$ = this.getEduExpense();

  reloadEduExpense(markForCheck = false): void {
    this.eduExpense$ = this.getEduExpense();
    if (markForCheck) this.changeDetectorRef.markForCheck();
  }

  private getEduExpense(): Observable<EduExpense> {
    const routeParams$ = merge(
      of(this.route.snapshot.params),
      this.route.params.pipe(
        startWith(this.route.snapshot.params),
        distinctUntilChanged((a, b) => objectsEqual(a, b)),
        skip(1),
        tap(() => this.reloadEduExpense(true)),
        switchMap(() => EMPTY),
      ),
    );

    return routeParams$.pipe(
      switchMap((routeParams) => {
        const eduExpenseId = Number(routeParams['id']);
        return this.eduExpenseService.getEduExpense(eduExpenseId);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
