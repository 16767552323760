import type { Event } from './event.model';

export interface FunTransactionLog {
  records: FunTransactionLogForYear[];
}

export interface FunTransactionLogForYear {
  yearTransactionLog: FunTransactionLogForMonth[];
  year: number;
}

export interface FunTransactionLogForMonth {
  monthTransactionLog: FunTransactionLogRecord[];
  monthName: string;
}

export interface FunTransactionLogRecord {
  id: number;
  type: FunTransactionLogType;
  event: Event | null;
  dateCreated: string;
  amount: number;
  budgetBefore: number;
  budgetChange: number;
  budgetAfter: number;
}

export enum FunTransactionLogType {
  ALLOWANCE = 'ALLOWANCE',
  COST = 'COST',
  REMAINING = 'REMAINING',
  AVAILABLE = 'AVAILABLE',
  CORRECTION = 'CORRECTION',
}
