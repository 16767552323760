import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  type OnChanges,
  type OnDestroy,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { SubSink } from 'subsink';
import type {
  EditVpnSubscriptionType,
  VpnSubscriptionType,
} from '../../../shared/models/api/vpn-subscription-type.model';
import type { ControlsOf } from '../../../shared/models/controls-of.model';
import type { NgChanges } from '../../../shared/models/ng-changes.model';
import { VpnSubscriptionTypeService } from '../../../shared/services/api/vpn-subscription-type.service';
import { OpaValidators } from '../../../shared/validators/opa.validators';
import type { EditVpnSubscriptionTypeFormValue } from './vpn-subscription-type-edit.models';

@Component({
  selector: 'opa-vpn-subscription-type-edit',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, ReactiveFormsModule, LetDirective],
  templateUrl: './vpn-subscription-type-edit.component.html',
  styleUrl: './vpn-subscription-type-edit.component.scss',
})
export class VpnSubscriptionTypeEditComponent implements OnChanges, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly vpnSubscriptionTypeService = inject(VpnSubscriptionTypeService);
  private readonly toastManager = inject(ToastManager);

  @Input({ required: true }) open = false;
  @Input({ required: true }) vpnSubscriptionType: VpnSubscriptionType | undefined = undefined;
  @Output() dismiss = new EventEmitter<boolean | undefined>();

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.pipe(distinctUntilChanged());

  ngOnChanges(changes: NgChanges<VpnSubscriptionTypeEditComponent>): void {
    if ((changes.open && this.open) || changes.vpnSubscriptionType)
      this.resetForm(this.vpnSubscriptionType);
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(success?: boolean): void {
    this.dismiss.emit(success);
  }

  submit(): void {
    const vpnSubscriptionType = this.vpnSubscriptionType;
    if (!vpnSubscriptionType) return;

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const editVpnSubscriptionType = this.formValueToVpnSubscriptionType(formValue);
    this.subSink.sink = this.vpnSubscriptionTypeService
      .editVpnSubscriptionType(vpnSubscriptionType.id, editVpnSubscriptionType)
      .pipe(
        tap({
          next: (updatedVpnSubscriptionType) => {
            this.toastManager.addMessage({
              text: `VPN Subscription Type ${updatedVpnSubscriptionType.name} updated`,
              state: 'success',
            });
            this.submitting.next(false);
            this.close(true);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<EditVpnSubscriptionTypeFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<EditVpnSubscriptionTypeFormValue>>({
      name: this.formBuilder.control(null, OpaValidators.required()),
      cost: this.formBuilder.control(null, [
        OpaValidators.required(),
        OpaValidators.min(0),
        OpaValidators.maxDecimals(2),
      ]),
      allowedMonthlyOverage: this.formBuilder.control(null, [
        OpaValidators.required(),
        OpaValidators.min(0),
        OpaValidators.maxDecimals(2),
      ]),
      active: this.formBuilder.control(null, OpaValidators.required()),
    });
    return form;
  }

  private formValueToVpnSubscriptionType(
    form: EditVpnSubscriptionTypeFormValue,
  ): EditVpnSubscriptionType {
    const vpnSubscriptionType: EditVpnSubscriptionType = {
      name: form.name!,
      cost: form.cost!,
      allowedMonthlyOverage: form.allowedMonthlyOverage!,
      active: form.active!,
    };
    return vpnSubscriptionType;
  }

  private resetForm(vpnSubscriptionType?: VpnSubscriptionType): void {
    if (!vpnSubscriptionType) {
      this.form.reset();
      return;
    }

    this.form.reset({
      name: vpnSubscriptionType.name,
      cost: vpnSubscriptionType.cost,
      allowedMonthlyOverage: vpnSubscriptionType.allowedMonthlyOverage,
      active: vpnSubscriptionType.active,
    });
  }
}
