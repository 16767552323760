<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Budget Corrections</p-heading>

  <p-link
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/budget-corrections/create'">Create New Budget Correction</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="section">
  <div class="breadcrumbs">
    <p-link-pure [hideLabel]="true" [iconSource]="'assets/icons/arrow-left.svg'">
      <a [routerLink]="'/budget-corrections'">All Budget Corrections</a>
    </p-link-pure>

    <p-heading
      *ngrxLet="budgetCorrection$ | async as budgetCorrection"
      [size]="'large'"
      [tag]="'h4'"
    >
      {{ budgetCorrection ? (budgetCorrection.date | date: 'dd.MM.yyyy.') : '&nbsp;' }}
    </p-heading>
  </div>
</section>

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/info.svg'" />
      <span>Budget Correction Details</span>
    </div>
  </p-heading>

  <ng-container
    *ngrxLet="
      budgetCorrection$ as budgetCorrection;
      error as budgetCorrectionError;
      suspenseTpl: spinner
    "
  >
    <div *ngIf="budgetCorrectionError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadBudgetCorrection()"
      >
        Reload Budget Correction Details
      </p-button-pure>
    </div>

    <div *ngIf="!budgetCorrectionError && budgetCorrection" class="budget-correction-details">
      <div>
        <p-text [weight]="'semi-bold'">People:</p-text>
        <p-table caption="People list table">
          <p-table-head>
            <p-table-head-row>
              <p-table-head-cell style="width: 1%" [hideLabel]="true">Counter</p-table-head-cell>
              <p-table-head-cell style="width: 49%">Name</p-table-head-cell>
              <p-table-head-cell style="width: 50%">Correction Amount</p-table-head-cell>
            </p-table-head-row>
          </p-table-head>

          <p-table-body>
            <p-table-row
              *ngFor="let user of budgetCorrection.users; trackBy: trackUser; index as index"
            >
              <p-table-cell class="align-end">{{ index + 1 }}.</p-table-cell>
              <p-table-cell>{{ user.firstName }} {{ user.lastName }}</p-table-cell>
              <p-table-cell>
                {{ budgetCorrection.amount | currency: 'EUR' | leadingPlus }}
              </p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>
      </div>

      <div *ngIf="budgetCorrection.description">
        <p-text [weight]="'semi-bold'">Description:</p-text>
        <p-text>{{ budgetCorrection.description }}</p-text>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
