import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type {
  CreateInventoryOwner,
  EditInventoryOwner,
  InventoryOwner,
} from '../../models/api/inventory-owner.model';

@Injectable({
  providedIn: 'root',
})
export class InventoryOwnerService {
  private readonly httpClient = inject(HttpClient);
  private readonly api = `${environment.apiUrl}/v1/codebooks/inventory-owner`;

  getInventoryOwners(): Observable<InventoryOwner[]> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Inventory Owner');

    return this.httpClient.get<InventoryOwner[]>(this.api, { context });
  }

  createInventoryOwner(owner: CreateInventoryOwner): Observable<InventoryOwner> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Create Inventory Owner');

    return this.httpClient.post<InventoryOwner>(this.api, owner, { context });
  }

  deleteInventoryOwner(id: number): Observable<void> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Delete Inventory Owner. Please make sure there are no inventory items associated with this owner',
    );

    return this.httpClient.delete<void>(`${this.api}/${id}`, { context });
  }

  editInventoryOwner(id: number, owner: EditInventoryOwner): Observable<InventoryOwner> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Edit Inventory Owner');

    return this.httpClient.put<InventoryOwner>(`${this.api}/${id}`, owner, { context });
  }
}
