<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Events</p-heading>

  <p-link
    *ngIf="isFunGamesAdmin"
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/events/create'">Create New Event</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="section">
  <div class="breadcrumbs">
    <p-link-pure [hideLabel]="true" [iconSource]="'assets/icons/arrow-left.svg'">
      <a [routerLink]="isFunGamesAdmin ? '/events' : '/my-events'">
        {{ isFunGamesAdmin ? 'All Events' : 'My Events' }}
      </a>
    </p-link-pure>

    <p-heading *ngrxLet="event$ | async as event" [size]="'large'" [tag]="'h4'">
      {{ event?.name ?? '&nbsp;' }}
    </p-heading>
  </div>
</section>

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/info.svg'" />
      <span>Event Details</span>
    </div>
  </p-heading>

  <ng-container *ngrxLet="event$ as event; error as eventError; suspenseTpl: spinner">
    <div *ngIf="eventError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadEvent()"
      >
        Reload Event Details
      </p-button-pure>
    </div>

    <div *ngIf="!eventError && event" class="event-details">
      <div>
        <p-text [weight]="'semi-bold'">Vendor:</p-text>
        <p-text>{{ event.vendorName }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Event Type:</p-text>
        <p-text>{{ eventTypeTranslation[event.type] | transloco }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Event Date:</p-text>
        <p-text>{{ event.date | date: 'dd.MM.yyyy' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">People:</p-text>
        <p-table caption="People list table">
          <p-table-head>
            <p-table-head-row>
              <p-table-head-cell style="width: 1%" [hideLabel]="true">Counter</p-table-head-cell>
              <p-table-head-cell style="width: 33%">Name</p-table-head-cell>
              <p-table-head-cell style="width: 33%">
                Available Budget for the Event
              </p-table-head-cell>
              <p-table-head-cell style="width: 33%">Spent</p-table-head-cell>
            </p-table-head-row>
          </p-table-head>

          <p-table-body>
            <p-table-row
              *ngFor="
                let transaction of event.transactions;
                trackBy: trackTransaction;
                index as index
              "
            >
              <p-table-cell class="align-end">{{ index + 1 }}.</p-table-cell>
              <p-table-cell>
                {{ transaction.user.firstName }} {{ transaction.user.lastName }}
              </p-table-cell>
              <p-table-cell>{{ transaction.amountBefore | currency: 'EUR' }}</p-table-cell>
              <p-table-cell>{{ transaction.amount | currency: 'EUR' }}</p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Productive Reference Number / Link:</p-text>
        <p-text>{{ event.productiveLink }}</p-text>
      </div>

      <div *ngIf="event.description">
        <p-text [weight]="'semi-bold'">Description:</p-text>
        <p-text>{{ event.description }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Total Event Cost:</p-text>
        <p-text>{{ event.totalEventCost | currency: 'EUR' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Covered by Budget:</p-text>
        <p-text>{{ event.coveredByBudget | currency: 'EUR' }}</p-text>
      </div>

      <div *ngIf="event.extraSpent">
        <p-text [weight]="'semi-bold'">Extra Spent:</p-text>
        <p-text>{{ event.extraSpent | currency: 'EUR' }}</p-text>
      </div>

      <p-divider aria-hidden="true" />

      <p-text class="refund-to" [size]="'medium'" [weight]="'semi-bold'">
        Refund {{ event.coveredByBudget | currency: 'EUR' }} to {{ event.payer.firstName }}
        {{ event.payer.lastName }}
      </p-text>
    </div>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
