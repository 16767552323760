<p-modal
  [heading]="'Edit ' + (vpnSubscriptionType ? vpnSubscriptionType.name : 'VPN Subscription Type')"
  [open]="open"
  (dismiss)="close()"
>
  <form class="form" [formGroup]="form" (ngSubmit)="submit()">
    <div class="form-controls">
      <p-text-field-wrapper
        *ngrxLet="form.controls.name as control"
        [label]="'Name'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <input type="text" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <p-text-field-wrapper
        *ngrxLet="form.controls.cost as control"
        [label]="'Cost'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
        [unit]="'EUR'"
        [unitPosition]="'suffix'"
      >
        <input type="number" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <p-text-field-wrapper
        *ngrxLet="form.controls.allowedMonthlyOverage as control"
        [label]="'Allowed Monthly Overage'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
        [unit]="'EUR'"
        [unitPosition]="'suffix'"
      >
        <input type="number" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <div class="switch-control">
        <p-text>VPN Subscription Type Status</p-text>
        <p-switch
          *ngrxLet="form.controls.active as control"
          [checked]="control.value ?? undefined"
          [disabled]="control.disabled"
          (update)="control.patchValue($event.detail.checked)"
        >
          Active
        </p-switch>
      </div>
    </div>

    <p-button-group class="modal-actions">
      <p-button
        [icon]="'none'"
        [loading]="(submitting$ | async) ?? false"
        [type]="'submit'"
        [variant]="'primary'"
      >
        Save
      </p-button>
      <p-button
        [iconSource]="'assets/icons/close.svg'"
        [type]="'button'"
        [variant]="'secondary'"
        (click)="close()"
      >
        Close
      </p-button>
    </p-button-group>
  </form>
</p-modal>
