import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject, type OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  auditTime,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import type { EduExpenseCategory } from '../../shared/models/api/edu-expense-category.model';
import type { EduExpensePurpose } from '../../shared/models/api/edu-expense-purpose.model';
import { BudgetType, type CreateEduExpense } from '../../shared/models/api/edu-expense.model';
import type { Page } from '../../shared/models/api/page.model';
import type { User } from '../../shared/models/api/user.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { FindUserPipe } from '../../shared/pipes/find-user.pipe';
import { CurrencyService } from '../../shared/services/api/currency.service';
import { EduExpenseCategoryService } from '../../shared/services/api/edu-expense-category.service';
import { EduExpensePurposeService } from '../../shared/services/api/edu-expense-purpose.service';
import { EduExpenseService } from '../../shared/services/api/edu-expense.service';
import { UserService } from '../../shared/services/api/user.service';
import { AuthService } from '../../shared/services/auth.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { OpaValidators } from '../../shared/validators/opa.validators';
import { EduExpenseCategoryCreateComponent } from '../edu-expense-category-list/edu-expense-category-create/edu-expense-category-create.component';
import { EduExpensePurposeCreateComponent } from '../edu-expense-purpose-list/edu-expense-purpose-create/edu-expense-purpose-create.component';
import { EduExpenseCategoryFilterPipe } from './edu-expense-category-filter.pipe';
import { budgetTypeTranslation } from './edu-expense-create.helpers';
import type { CreateEduExpenseData, CreateEduExpenseFormValue } from './edu-expense-create.models';
import { EduExpensePurposeFilterPipe } from './edu-expense-purpose-filter.pipe';
import { isAmountAboveUsersBudget } from './is-edu-expense-over-budget.validator';

@Component({
  selector: 'opa-edu-expense-create',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    ReactiveFormsModule,
    LetDirective,
    TranslocoPipe,
    EduExpensePurposeFilterPipe,
    EduExpenseCategoryFilterPipe,
    FindUserPipe,
    EduExpenseCategoryCreateComponent,
    EduExpensePurposeCreateComponent,
  ],
  templateUrl: './edu-expense-create.component.html',
  styleUrl: './edu-expense-create.component.scss',
})
export class EduExpenseCreateComponent implements OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly eduExpenseCategoryService = inject(EduExpenseCategoryService);
  private readonly eduExpensePurposeService = inject(EduExpensePurposeService);
  private readonly eduExpenseService = inject(EduExpenseService);
  private readonly currencyService = inject(CurrencyService);
  private readonly userService = inject(UserService);
  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);

  readonly isSuperAdmin = inject(AuthService).isSuperAdmin();
  readonly mediaMaxXs$ = inject(MediaQueryService).max('xs');
  readonly BudgetType = BudgetType;
  readonly budgetTypes = Object.values(BudgetType);
  readonly budgetTypeTranslation = budgetTypeTranslation;
  readonly textareaMaxLength = 255;
  readonly minAmount = 0;
  readonly maxDate = this.getEndOfToday();
  readonly defaultCurrency = 'EUR';

  showCreateEduExpenseCategoryModal = false;
  showCreateEduExpensePurposeModal = false;

  private readonly subSink = new SubSink();
  private readonly submitting = new BehaviorSubject(false);
  amountInEur$?: Observable<number>;

  readonly users$ = this.getUsers$();
  readonly form = this.buildForm();
  createExpenseData$ = this.getCreateExpenseData$();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  reloadCreateExpenseData(): void {
    this.createExpenseData$ = this.getCreateExpenseData$();
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  trackCategory(index: number, category: EduExpenseCategory): number {
    return category.id;
  }

  trackPurpose(index: number, purpose: EduExpensePurpose): number {
    return purpose.id;
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const expense = this.formValueToGroup(formValue);
    this.subSink.sink = this.eduExpenseService
      .createEduExpense(expense)
      .pipe(
        tap({
          next: (createdExpense) => {
            this.toastManager.addMessage({
              text: `${createdExpense.category} transaction created`,
              state: 'success',
            });
            this.router.navigate(['/edu-transactions']);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  formValueToGroup(formValue: CreateEduExpenseFormValue): CreateEduExpense {
    const expense: CreateEduExpense = {
      budgetType: formValue.budgetType!,
      categoryId: Number(formValue.categoryId!),
      purposeId: Number(formValue.purposeId!),
      userId: Number(formValue.userId!),
      date: formValue.date!,
      amount: formValue.amount!,
      description: formValue.description || undefined,
      productiveLink: formValue.productiveLink!,
      currency:
        formValue.currency && formValue.currency !== this.defaultCurrency
          ? formValue.currency
          : undefined,
    };

    return expense;
  }

  private buildForm(): FormGroup<ControlsOf<CreateEduExpenseFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<CreateEduExpenseFormValue>>({
      budgetType: this.formBuilder.control(null, OpaValidators.required()),
      categoryId: this.formBuilder.control(null, OpaValidators.required()),
      purposeId: this.formBuilder.control(null, OpaValidators.required()),
      userId: this.formBuilder.control(null, OpaValidators.required()),
      date: this.formBuilder.control(null, [
        OpaValidators.required(),
        OpaValidators.maxDate(this.maxDate),
      ]),
      amount: this.formBuilder.control(null, [
        OpaValidators.required(),
        OpaValidators.min(this.minAmount),
        OpaValidators.maxDecimals(2),
      ]),
      currency: this.formBuilder.control(this.defaultCurrency, OpaValidators.required()),
      description: this.formBuilder.control(null, OpaValidators.maxLength(this.textareaMaxLength)),
      productiveLink: this.formBuilder.control(null, OpaValidators.required()),
    });

    this.amountInEur$ = combineLatest([
      form.controls.amount.valueChanges,
      form.controls.currency.valueChanges.pipe(
        startWith(form.controls.currency.value),
        switchMap((currency) =>
          currency && currency !== this.defaultCurrency
            ? this.currencyService.getExchangeRate(currency)
            : of(1),
        ),
      ),
    ]).pipe(
      map(([amount, exchangeRate]) => (amount ? amount * exchangeRate : 0)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.subSink.add(
      form.controls.budgetType.valueChanges.subscribe(() => {
        form.patchValue({ categoryId: null, purposeId: null });
      }),
    );

    form.addAsyncValidators([
      isAmountAboveUsersBudget(
        this.users$.pipe(map((page) => page.content)),
        this.amountInEur$.pipe(
          auditTime(500), // Wait for observables to settle, only emmit last value
          take(1), // Take only one, otherwise form will get stuck in PENDING state
          tap(() => this.changeDetectorRef.markForCheck()),
        ),
      ),
    ]);
    form.updateValueAndValidity();

    return form;
  }

  private getCreateExpenseData$(): Observable<CreateEduExpenseData> {
    const categories$ = this.eduExpenseCategoryService.getEduExpenseCategories();
    const purposes$ = this.eduExpensePurposeService.getEduExpensePurposes();
    const submitting$ = this.submitting.pipe(distinctUntilChanged());
    const currencies$ = this.currencyService.getCurrencies();

    return combineLatest([purposes$, categories$, this.users$, currencies$, submitting$]).pipe(
      map(([purposes, categories, users, currencies, submitting]) => ({
        purposes,
        categories,
        users,
        currencies,
        submitting,
      })),
    );
  }

  private getUsers$(): Observable<Page<User>> {
    return this.userService.getUsers(null).pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  // TODO: Replace with date library
  private getEndOfToday(): Date {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    return date;
  }
}
