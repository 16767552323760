import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import type { TableSort } from '../../components/table-sort/table-sort.models';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type {
  CreateEduExpense,
  EduExpense,
  EduExpenseListFilter,
  ImportEduBudget,
} from '../../models/api/edu-expense.model';
import type { Page } from '../../models/api/page.model';
import { DownloadService } from '../download.service';

@Injectable({
  providedIn: 'root',
})
export class EduExpenseService {
  private readonly httpClient = inject(HttpClient);
  private readonly downloadService = inject(DownloadService);
  private readonly expenseApi = `${environment.apiUrl}/v1/edu-mgmt`;
  private readonly expenseTransactionApi = `${this.expenseApi}/transactions`;

  getEduExpenses(
    pageNo: number | null,
    filter?: EduExpenseListFilter,
    sort?: TableSort,
  ): Observable<Page<EduExpense>> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Get Edu & Equ Transactions',
    );

    let params = new HttpParams();

    if (pageNo === null) params = params.set('unpaged', true);
    else params = params.set('page', pageNo).set('size', 10);

    if (filter) {
      Object.entries(filter)
        .filter(([, value]) => value !== undefined)
        .forEach(([key, value]) => (params = params.set(key, value)));
    }
    if (sort) params = params.set('sort', `${sort.sortId},${sort.direction}`);

    return this.httpClient.get<Page<EduExpense>>(this.expenseTransactionApi, { context, params });
  }

  getEduExpense(expenseId: number): Observable<EduExpense> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Get Edu & Equ Transaction',
    );

    return this.httpClient.get<EduExpense>(`${this.expenseTransactionApi}/${expenseId}`, {
      context,
    });
  }

  createEduExpense(expense: CreateEduExpense): Observable<EduExpense> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Create Edu & Equ Transaction',
    );

    return this.httpClient.post<EduExpense>(`${this.expenseApi}/add-new-expense`, expense, {
      context,
    });
  }

  downloadEduBudgetImportTemplate(): Observable<void> {
    return this.downloadService.downloadFile(
      `${this.expenseApi}/bulk-import-template`,
      'Failed to Get Edu Budget Import Template',
    );
  }

  importEduBudget(items: ImportEduBudget): Observable<EduExpense[]> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Import Edu Budget');
    const formData = new FormData();

    formData.append('spreadsheet', items.spreadsheet);

    return this.httpClient.post<EduExpense[]>(
      `${this.expenseApi}/bulk-import-edu-budgets`,
      formData,
      { context },
    );
  }
}
