<p-heading class="title" [size]="'x-large'" [tag]="'h3'">Edu & Equ Budget</p-heading>

<p-divider aria-hidden="true" />

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon class="icon" [source]="'assets/icons/wallet.svg'" />
      <span>Your Budget</span>
    </div>
  </p-heading>

  <ng-container *ngrxLet="me$ as me; error as meError; suspenseTpl: spinner">
    <div *ngIf="meError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadMe()"
      >
        Reload Your Budget
      </p-button-pure>
    </div>

    <div *ngIf="!meError && me" class="budget-card">
      <div class="date">
        <p-text [align]="'center'" [size]="'x-small'">
          {{ now | date: 'MMMM' }}
          <br aria-hidden="true" />
          {{ now | date: 'dd' }}
        </p-text>
      </div>

      <div class="amount-wrapper">
        <div class="amount">
          <p-text [size]="'x-small'">Total Available</p-text>
          <p-text [color]="'notification-info'" [size]="'large'" [weight]="'semi-bold'">
            {{ me.eduEquBudgetStatus.totalEduEquBudgetRemaining | currency: 'EUR' }}
          </p-text>
        </div>

        <p-divider aria-hidden="true" />

        <div class="amount">
          <p-text [size]="'x-small'">Maximum available for Equipment</p-text>
          <p-text [color]="'contrast-high'" [weight]="'bold'">
            {{ me.eduEquBudgetStatus.maxEquipmentBudgetRemaining | currency: 'EUR' }}
          </p-text>
        </div>
      </div>
    </div>
  </ng-container>
</section>

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon class="icon" [source]="'assets/icons/credit-card.svg'" />
      <span>Transaction Log</span>
    </div>
  </p-heading>

  <ng-container *ngrxLet="transactionLogData$ as data; error as dataError; suspenseTpl: spinner">
    <div *ngIf="dataError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadTransactionLogData()"
      >
        Reload Transaction Log
      </p-button-pure>
    </div>

    <ng-container *ngIf="!dataError && data">
      <section class="filters">
        <p-select-wrapper class="filter" [label]="'Year'">
          <select [formControl]="form.controls.activeYear">
            <option *ngFor="let year of data.activeYears" [value]="year">
              {{ year }}
            </option>
          </select>
        </p-select-wrapper>

        <p-select-wrapper
          *ngrxLet="form.controls.budgetType as control"
          class="filter"
          [label]="'Budget Type'"
        >
          <select [formControl]="control">
            <option [value]="null">All</option>

            <option *ngFor="let type of budgetTypes" [value]="type">
              {{ budgetTypeTranslation[type] | transloco }}
            </option>
          </select>
        </p-select-wrapper>
      </section>

      <p-text *ngIf="data.transactionLogs.length === 0" class="transaction-log-empty">
        No transactions.
      </p-text>

      <ng-container *ngFor="let yearLogs of data.transactionLogs; trackBy: trackYearLog">
        <div
          *ngFor="let monthLogs of yearLogs.yearTransactionLog; trackBy: trackMonthLog"
          class="transaction-log"
        >
          <p-text class="month">{{ monthLogs.monthName | titlecase }}</p-text>

          <ng-container
            *ngFor="
              let log of monthLogs.monthTransactionLog
                | eduBudgetTransactionFilter: form.controls.budgetType
                | async;
              trackBy: trackLog;
              first as first
            "
          >
            <div
              class="transaction"
              [ngClass]="{
                first: first,
                info: log.budgetChange === 0,
                inflow: log.budgetChange > 0,
                outflow: log.budgetChange < 0
              }"
            >
              <div class="date">
                <p-text [size]="'x-small'">{{ log.date | date: 'dd' }}</p-text>
              </div>

              <div class="message">
                <p-text [size]="'x-small'">
                  {{ logTypeTranslation[log.type] | transloco | titlecase }}
                  <span *ngIf="log.description">- {{ log.description }}</span>
                </p-text>

                <ng-container
                  *ngIf="
                    log.type !== TransactionLogType.TOTAL_BUDGET_CHANGE && log.budgetChange < 0
                  "
                >
                  <div class="message-line">
                    <p-icon [source]="'assets/icons/tag.svg'" />
                    <p-text [size]="'x-small'">
                      <span *ngIf="log.category && log.purpose">
                        {{ log.category.description }} - {{ log.purpose.description }}
                      </span>
                    </p-text>
                  </div>

                  <div class="message-line">
                    <p-icon [source]="'assets/icons/invoice.svg'" />
                    <p-text [size]="'xx-small'">
                      Total cost:
                      {{ log.amount | currency: 'EUR' }}
                    </p-text>
                  </div>
                </ng-container>

                <ng-container *ngIf="log.type === TransactionLogType.TOTAL_BUDGET_CHANGE">
                  <div class="message-line">
                    <p-text [size]="'xx-small'">
                      {{ log.budgetChange >= 0 ? 'Top up' : 'Budget adjustment' }}:
                      {{ log.amount | currency: 'EUR' }}
                    </p-text>
                  </div>

                  <div class="message-line">
                    <p-text [size]="'xx-small'">
                      Previously available budget: {{ log.totalBudgetBefore | currency: 'EUR' }}
                    </p-text>
                  </div>

                  <div class="message-line">
                    <p-text [size]="'xx-small'">
                      Total available budget: {{ log.totalBudgetAfter | currency: 'EUR' }}
                    </p-text>
                  </div>

                  <div class="message-line">
                    <p-text [size]="'xx-small'">
                      Expired budget: {{ log.totalBudgetOverCap | currency: 'EUR' }}
                    </p-text>
                  </div>
                </ng-container>

                <div *ngIf="isEduEquAdmin && log.id > 0" class="message-line">
                  <p-link-pure [icon]="'none'" [size]="'x-small'" [underline]="true">
                    <a [routerLink]="['/edu-transactions', log.id]">See Details</a>
                  </p-link-pure>
                </div>
              </div>

              <div class="change">
                <p-text [color]="'inherit'" [weight]="'semi-bold'">
                  <ng-container *ngIf="log.budgetChange !== 0">
                    {{ log.budgetChange | currency: 'EUR' | leadingPlus }}
                  </ng-container>

                  <ng-container *ngIf="log.budgetChange === 0">
                    {{ log.totalBudgetAfter | currency: 'EUR' }}
                  </ng-container>
                </p-text>
              </div>
            </div>
            <p-divider aria-hidden="true" />
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
