import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject, type OnDestroy } from '@angular/core';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { SubSink } from 'subsink';
import type { InventoryStatus } from '../../../shared/models/api/inventory-status.model';
import { InventoryStatusService } from '../../../shared/services/api/inventory-status.service';

@Component({
  selector: 'opa-inventory-status-delete',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule],
  templateUrl: './inventory-status-delete.component.html',
  styleUrl: './inventory-status-delete.component.scss',
})
export class InventoryStatusDeleteComponent implements OnDestroy {
  private readonly inventoryStatusService = inject(InventoryStatusService);
  private readonly toastManager = inject(ToastManager);

  @Input({ required: true }) open = false;
  @Input({ required: true }) inventoryStatus: InventoryStatus | undefined = undefined;
  @Output() dismiss = new EventEmitter<InventoryStatus | undefined>();

  private readonly subSink = new SubSink();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.pipe(distinctUntilChanged());

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(inventoryStatus?: InventoryStatus): void {
    this.dismiss.emit(inventoryStatus);
  }

  submit(): void {
    const inventoryStatus = this.inventoryStatus;
    if (!inventoryStatus) return;

    this.submitting.next(true);
    this.subSink.sink = this.inventoryStatusService
      .deleteInventoryStatus(inventoryStatus.id)
      .pipe(
        tap({
          next: () => {
            this.toastManager.addMessage({
              text: `Status ${inventoryStatus.name} deleted`,
              state: 'success',
            });
            this.submitting.next(false);
            this.dismiss.emit(inventoryStatus);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }
}
