import { Pipe, type PipeTransform } from '@angular/core';
import type { User } from '../../../shared/models/api/user.model';

@Pipe({
  name: 'opaFilterGroupMembers',
  standalone: true,
})
export class FilterGroupMembersPipe implements PipeTransform {
  transform(users: User[], groupMembers: User[] | undefined): User[] {
    if (!groupMembers) return users;

    return users.filter((user) => !groupMembers.some((member) => member.id === user.id));
  }
}
