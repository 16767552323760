import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  merge,
  shareReplay,
  skip,
  startWith,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import { PagedTableComponent } from '../../shared/components/paged-table/paged-table.component';
import { TableFilterComponent } from '../../shared/components/table-filter/table-filter.component';
import { TableSortComponent } from '../../shared/components/table-sort/table-sort.component';
import { VisibleColumnsPickerComponent } from '../../shared/components/visible-columns-picker/visible-columns-picker.component';
import { VisibleColumnDirective } from '../../shared/directives/visible-column.directive';
import {
  InventoryFilters,
  type InventoryFilter,
  type InventoryItem,
  type InventoryItemFilter,
} from '../../shared/models/api/inventory-item.model';
import type { InventoryLocation } from '../../shared/models/api/inventory-location.model';
import type { InventoryManufacturer } from '../../shared/models/api/inventory-manufacturer.model';
import type { InventoryOwner } from '../../shared/models/api/inventory-owner.model';
import type { InventoryStatus } from '../../shared/models/api/inventory-status.model';
import type { InventoryType } from '../../shared/models/api/inventory-type.model';
import type { User } from '../../shared/models/api/user.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import {
  NumberComparator,
  numberComparatorTranslation,
} from '../../shared/models/number-comparator.model';
import { FillArrayPipe } from '../../shared/pipes/fill-array.pipe';
import { InventoryItemsService } from '../../shared/services/api/inventory-items.service';
import { InventoryLocationService } from '../../shared/services/api/inventory-location.service';
import { InventoryManufacturerService } from '../../shared/services/api/inventory-manufacturer.service';
import { InventoryOwnerService } from '../../shared/services/api/inventory-owner.service';
import { InventoryStatusService } from '../../shared/services/api/inventory-status.service';
import { InventoryTypeService } from '../../shared/services/api/inventory-type.service';
import { ReportService } from '../../shared/services/api/report.service';
import { UserService } from '../../shared/services/api/user.service';
import { AuthService } from '../../shared/services/auth.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { AssignInventoryItemComponent } from './assign-inventory-item/assign-inventory-item.component';
import {
  inventoryColumnTranslation,
  inventoryFilterTranslation,
} from './inventory-item-list.helpers';
import {
  InventoryColumns,
  type InventoryItemListFormValue,
  type InventoryItemsData,
} from './inventory-item-list.models';
import { UnassignInventoryItemComponent } from './unassign-inventory-item/unassign-inventory-item.component';

@Component({
  selector: 'opa-inventory-item-list',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    FillArrayPipe,
    LetDirective,
    TranslocoPipe,
    ReactiveFormsModule,
    TableFilterComponent,
    TableSortComponent,
    VisibleColumnsPickerComponent,
    VisibleColumnDirective,
    AssignInventoryItemComponent,
    UnassignInventoryItemComponent,
  ],
  templateUrl: './inventory-item-list.component.html',
  styleUrl: './inventory-item-list.component.scss',
})
export class InventoryItemListComponent extends PagedTableComponent<
  InventoryItem,
  InventoryItemFilter,
  InventoryItemListFormValue
> {
  private readonly inventoryItemsService = inject(InventoryItemsService);
  private readonly toastManager = inject(ToastManager);
  readonly mediaMaxXs$ = inject(MediaQueryService).max('xs');
  readonly isInventoryAdmin = inject(AuthService).isInventoryAdmin();
  readonly manufacturerService = inject(InventoryManufacturerService);
  readonly locationService = inject(InventoryLocationService);
  readonly ownerService = inject(InventoryOwnerService);
  readonly typeService = inject(InventoryTypeService);
  readonly statusService = inject(InventoryStatusService);
  readonly userService = inject(UserService);
  private readonly reportService = inject(ReportService);

  readonly numberComparators = Object.values(NumberComparator);
  readonly numberComparatorTranslation = numberComparatorTranslation;
  readonly columns = InventoryColumns;
  readonly alwaysVisibleColumns = [this.columns.id];
  readonly columnTranslations = inventoryColumnTranslation;
  readonly filters = InventoryFilters;
  readonly filterTranslations = inventoryFilterTranslation;
  readonly tableName = 'inventory-item-list';

  private readonly subSink = new SubSink();
  data$ = this.getData$();
  readonly activeFilters$ = this.getActiveFilters$();
  private readonly exportInProgress = new BehaviorSubject(false);
  readonly exportInProgress$ = this.exportInProgress.asObservable();

  constructor() {
    super();
    this.setDefaultVisibleColumns();
  }

  protected override query$ = this.inventoryItemsService.getInventoryItems.bind(
    this.inventoryItemsService,
  );

  protected override buildForm(): FormGroup<ControlsOf<InventoryItemListFormValue>> {
    const params = this.activatedRoute.snapshot.queryParams;

    const form = this.formBuilder.nonNullable.group<ControlsOf<InventoryItemListFormValue>>({
      manufacturerId: this.formBuilder.control(
        params[InventoryFilters.manufacturerId] ?? this.allValue,
      ),
      model: this.formBuilder.control(params[InventoryFilters.model] ?? null),
      specification: this.formBuilder.control(params[InventoryFilters.specification] ?? null),
      typeId: this.formBuilder.control(params[InventoryFilters.typeId] ?? this.allValue),
      serialNumber: this.formBuilder.control(params[InventoryFilters.serialNumber] ?? null),
      imei: this.formBuilder.control(params[InventoryFilters.imei] ?? null),
      ownerId: this.formBuilder.control(params[InventoryFilters.ownerId] ?? this.allValue),
      locationId: this.formBuilder.control(params[InventoryFilters.locationId] ?? this.allValue),
      warrantyExpirationFrom: this.formBuilder.control(
        params[InventoryFilters.warrantyExpirationFrom] ?? null,
      ),
      warrantyExpirationTo: this.formBuilder.control(
        params[InventoryFilters.warrantyExpirationTo] ?? null,
      ),
      contractNumber: this.formBuilder.control(params[InventoryFilters.contractNumber] ?? null),
      price: this.formBuilder.control(params[InventoryFilters.price] ?? null),
      priceComparisonType: this.formBuilder.control(
        params[InventoryFilters.priceComparisonType] ?? NumberComparator.EQUALS,
      ),
      assigneeId: this.formBuilder.control(params[InventoryFilters.assigneeId] ?? this.allValue),
      statusId: this.formBuilder.control(params[InventoryFilters.statusId] ?? this.allValue),
      note: this.formBuilder.control(params[InventoryFilters.note] ?? null),
    });

    return form;
  }

  protected override getFilter$(): Observable<InventoryItemFilter> {
    const typedInputs: (keyof InventoryItemListFormValue)[] = [
      'model',
      'specification',
      'imei',
      'serialNumber',
      'warrantyExpirationFrom',
      'warrantyExpirationTo',
      'contractNumber',
      'price',
      'note',
    ];

    return merge(
      ...Object.entries(this.form.controls).map(([key, control]) => {
        const controlName = key as keyof InventoryItemListFormValue;
        let value$ = control.valueChanges as Observable<unknown>;
        value$ = value$.pipe(
          startWith(control.value),
          distinctUntilChanged(),
          skip(1),
          map(() => undefined),
        );
        if (typedInputs.includes(controlName)) value$ = value$.pipe(debounceTime(500));
        return value$;
      }),
    ).pipe(
      startWith(undefined),
      map(() => {
        const form = this.form.getRawValue();

        if (
          form.warrantyExpirationFrom &&
          form.warrantyExpirationTo &&
          form.warrantyExpirationFrom > form.warrantyExpirationTo
        ) {
          form.warrantyExpirationTo = form.warrantyExpirationFrom;
          this.form.patchValue({ warrantyExpirationTo: form.warrantyExpirationFrom });
          this.toastManager.addMessage({
            text: 'Warranty expiration from date cannot be greater than warranty expiration to date. Setting warranty expiration to date to warranty expiration from date.',
            state: 'info',
          });
        }

        const filters: InventoryItemFilter = {
          manufacturerId:
            form.manufacturerId === this.allValue ? undefined : form.manufacturerId ?? undefined,
          model: form.model ?? undefined,
          specification: form.specification ?? undefined,
          typeId: form.typeId === this.allValue ? undefined : form.typeId ?? undefined,
          serialNumber: form.serialNumber ?? undefined,
          imei: form.imei ?? undefined,
          ownerId: form.ownerId === this.allValue ? undefined : form.ownerId ?? undefined,
          locationId: form.locationId === this.allValue ? undefined : form.locationId ?? undefined,
          warrantyExpirationFrom: form.warrantyExpirationFrom ?? undefined,
          warrantyExpirationTo: form.warrantyExpirationTo ?? undefined,
          contractNumber: form.contractNumber ?? undefined,
          price: form.price && form.priceComparisonType ? form.price : undefined,
          priceComparisonType:
            form.priceComparisonType && form.price ? form.priceComparisonType : undefined,
          assigneeId: form.assigneeId === this.allValue ? undefined : form.assigneeId ?? undefined,
          statusId: form.statusId === this.allValue ? undefined : form.statusId ?? undefined,
          note: form.note ?? undefined,
        };

        return filters;
      }),
    );
  }

  reloadData(): void {
    this.data$ = this.getData$();
  }

  exportInventoryItems(): void {
    if (this.exportInProgress.value) {
      this.toastManager.addMessage({
        text: 'Previous export is still in progress',
        state: 'info',
      });
    }

    const form = this.form.getRawValue();

    const filters: InventoryItemFilter = {
      manufacturerId:
        form.manufacturerId === this.allValue ? undefined : form.manufacturerId ?? undefined,
      model: form.model ?? undefined,
      specification: form.specification ?? undefined,
      typeId: form.typeId === this.allValue ? undefined : form.typeId ?? undefined,
      serialNumber: form.serialNumber ?? undefined,
      imei: form.imei ?? undefined,
      ownerId: form.ownerId === this.allValue ? undefined : form.ownerId ?? undefined,
      locationId: form.locationId === this.allValue ? undefined : form.locationId ?? undefined,
      warrantyExpirationFrom: form.warrantyExpirationFrom ?? undefined,
      warrantyExpirationTo: form.warrantyExpirationTo ?? undefined,
      contractNumber: form.contractNumber ?? undefined,
      price: form.price && form.priceComparisonType ? form.price : undefined,
      priceComparisonType:
        form.priceComparisonType && form.price ? form.priceComparisonType : undefined,
      assigneeId: form.assigneeId === this.allValue ? undefined : form.assigneeId ?? undefined,
      statusId: form.statusId === this.allValue ? undefined : form.statusId ?? undefined,
      note: form.note ?? undefined,
    };

    this.exportInProgress.next(true);
    this.toastManager.addMessage({
      text: 'Exporting Inventory Items in progress',
      state: 'info',
    });

    this.subSink.sink = this.reportService
      .getInventoryItems(filters)
      .pipe(
        tap(() =>
          this.toastManager.addMessage({
            text: 'Exporting Inventory Items finished',
            state: 'success',
          }),
        ),
        finalize(() => this.exportInProgress.next(false)),
      )
      .subscribe();
  }

  trackRow(index: number): number {
    return index;
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  trackInventoryManufacturer(index: number, manufacturer: InventoryManufacturer): number {
    return manufacturer.id;
  }

  trackInventoryLocation(index: number, location: InventoryLocation): number {
    return location.id;
  }

  trackInventoryOwner(index: number, owner: InventoryOwner): number {
    return owner.id;
  }

  trackInventoryType(index: number, type: InventoryType): number {
    return type.id;
  }

  trackInventoryStatus(index: number, status: InventoryStatus): number {
    return status.id;
  }

  private setDefaultVisibleColumns(): void {
    if (!this.visibleColumnsService.getVisibleColumns(this.tableName).length) {
      this.visibleColumnsService.setVisibleColumns(this.tableName, Object.keys(this.columns));
    }
  }

  private getData$(): Observable<InventoryItemsData> {
    return combineLatest([
      this.manufacturerService.getInventoryManufacturers(),
      this.typeService.getInventoryTypes(),
      this.ownerService.getInventoryOwners(),
      this.locationService.getInventoryLocations(),
      this.userService.getUsers(null).pipe(map((users) => users.content)),
      this.statusService.getInventoryStatuses(),
      this.setQueryParams$,
    ]).pipe(
      map(([manufacturers, types, owners, locations, assignees, statuses]) => {
        return { manufacturers, types, owners, locations, assignees, statuses };
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private getActiveFilters$(): Observable<{ key: InventoryFilter; value: unknown }[]> {
    const activeFilters$ = combineLatest([this.filter$, this.data$]).pipe(
      map(([filters, data]) => {
        const filtersWithValues = Object.entries(filters).filter(([, value]) => value != null);

        return filtersWithValues.map(([key, value]) => {
          if (key === this.filters.typeId) {
            return {
              key: key as InventoryFilter,
              value: data.types.find((type) => type.id === Number(value))?.description ?? 'Uknown',
            };
          }
          if (key === this.filters.manufacturerId) {
            return {
              key: key as InventoryFilter,
              value:
                data.manufacturers.find((manufacturer) => manufacturer.id === Number(value))
                  ?.description ?? 'Uknown',
            };
          }

          if (key === this.filters.ownerId) {
            return {
              key: key as InventoryFilter,
              value:
                data.owners.find((owner) => owner.id === Number(value))?.description ?? 'Uknown',
            };
          }

          if (key === this.filters.locationId) {
            return {
              key: key as InventoryFilter,
              value:
                data.locations.find((location) => location.id === Number(value))?.description ??
                'Uknown',
            };
          }

          if (key === this.filters.statusId) {
            return {
              key: key as InventoryFilter,
              value:
                data.statuses.find((status) => status.id === Number(value))?.description ??
                'Uknown',
            };
          }

          return { key: key as InventoryFilter, value };
        });
      }),
    );

    return activeFilters$;
  }
}
