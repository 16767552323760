import { CommonModule } from '@angular/common';
import { Component, inject, type OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import type { CreateBudgetCorrection } from '../../shared/models/api/budget-correction.model';
import type { User } from '../../shared/models/api/user.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { FindUserPipe } from '../../shared/pipes/find-user.pipe';
import { BudgetCorrectionService } from '../../shared/services/api/budget-correction.service';
import { UserService } from '../../shared/services/api/user.service';
import { OpaValidators } from '../../shared/validators/opa.validators';
import { FilterSelectedUsersPipe } from '../event-create/filter-selected-users.pipe';
import { JoinUsersPipe } from '../event-create/join-users.pipe';
import { StringSplitPipe } from '../event-create/string-split.pipe';
import type {
  CreateBudgetCorrectionData,
  CreateBudgetCorrectionFormValue,
} from './budget-correction-create.models';

@Component({
  selector: 'opa-budget-correction-create',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    ReactiveFormsModule,
    LetDirective,
    FilterSelectedUsersPipe,
    JoinUsersPipe,
    StringSplitPipe,
    FindUserPipe,
  ],
  templateUrl: './budget-correction-create.component.html',
  styleUrl: './budget-correction-create.component.scss',
})
export class BudgetCorrectionCreateComponent implements OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly userService = inject(UserService);
  private readonly budgetCorrectionService = inject(BudgetCorrectionService);
  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);

  private readonly subSink = new SubSink();
  private readonly submitting = new BehaviorSubject(false);
  readonly form = this.buildForm();
  readonly textareaMaxLength = 255;
  createBudgetCorrectionData$ = this.getCreateBudgetCorrectionData$();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  reloadCreateBudgetCorrectionData(): void {
    this.createBudgetCorrectionData$ = this.getCreateBudgetCorrectionData$();
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  removeParticipant(userId: string): void {
    // TODO: move to previous or next participant or back to first focusable element before this button
    const control = this.form.controls.participants;
    const selected = control.value?.split(',') ?? [];
    const value = selected.filter((id) => id !== userId).join(',') || null;
    control.patchValue(value);
    control.markAsTouched();
    control.markAsDirty();
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const budgetCorrection = this.formValueToBudgetCorrection(formValue);
    this.subSink.sink = this.budgetCorrectionService
      .createBudgetCorrection(budgetCorrection)
      .pipe(
        tap({
          next: () => {
            this.toastManager.addMessage({ text: `Budget correction created`, state: 'success' });
            this.router.navigate(['/budget-corrections']);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<CreateBudgetCorrectionFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<CreateBudgetCorrectionFormValue>>({
      participants: this.formBuilder.control(null, OpaValidators.required()),
      amount: this.formBuilder.control(null, [
        OpaValidators.required(),
        OpaValidators.maxDecimals(2),
      ]),
      description: this.formBuilder.control(null, OpaValidators.maxLength(this.textareaMaxLength)),
    });
    return form;
  }

  private formValueToBudgetCorrection(
    form: CreateBudgetCorrectionFormValue,
  ): CreateBudgetCorrection {
    const budgetCorrection: CreateBudgetCorrection = {
      participants: form.participants!.split(',').map((id) => Number(id)),
      amount: form.amount!,
      description: form.description ?? '',
    };
    return budgetCorrection;
  }

  private getCreateBudgetCorrectionData$(): Observable<CreateBudgetCorrectionData> {
    const users$ = this.userService.getActiveUsers();
    const submitting$ = this.submitting.pipe(distinctUntilChanged());
    return combineLatest([users$, submitting$]).pipe(
      map(([users, submitting]) => ({ users, submitting })),
    );
  }
}
