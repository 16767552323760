import { Pipe, type PipeTransform } from '@angular/core';
import type { User } from '../models/api/user.model';

@Pipe({
  name: 'opaFindUser',
  standalone: true,
})
export class FindUserPipe implements PipeTransform {
  transform(id: string | null, users: User[]): User | undefined {
    if (!id) return undefined;

    return users.find((user) => `${user.id}` === id);
  }
}
