import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, type Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavbarService {
  private readonly showNavbarUser$ = new BehaviorSubject(false);

  toggle(force?: boolean): void {
    const show = force != null ? force : !this.showNavbarUser$.value;
    this.showNavbarUser$.next(show);
  }

  isOpen$(): Observable<boolean> {
    return this.showNavbarUser$.pipe(distinctUntilChanged());
  }
}
