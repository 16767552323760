import { CommonModule } from '@angular/common';
import { Component, inject, type OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  shareReplay,
  startWith,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import type { BankCard } from '../../shared/models/api/bank-card.model';
import type { Page } from '../../shared/models/api/page.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { FillArrayPipe } from '../../shared/pipes/fill-array.pipe';
import { BankCardService } from '../../shared/services/api/bank-card.service';
import { ReportService } from '../../shared/services/api/report.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { OpaValidators } from '../../shared/validators/opa.validators';
import type { BankCardListFormValue } from './bank-card-list.models';

@Component({
  selector: 'opa-bank-card-list',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    ReactiveFormsModule,
    LetDirective,
    FillArrayPipe,
  ],
  templateUrl: './bank-card-list.component.html',
  styleUrl: './bank-card-list.component.scss',
})
export class BankCardListComponent implements OnDestroy {
  private readonly mediaQueryService = inject(MediaQueryService);
  private readonly bankCardService = inject(BankCardService);
  private readonly reportService = inject(ReportService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly toastManager = inject(ToastManager);

  private readonly subSink = new SubSink();
  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  private readonly pageNo = new BehaviorSubject(1);
  readonly allValue = 'all' as const;
  readonly trueValue = 'true' as const;
  readonly falseValue = 'false' as const;
  readonly form = this.buildForm();
  private readonly exporting = new BehaviorSubject(false);
  readonly exporting$ = this.exporting.pipe(distinctUntilChanged());
  private readonly loading = new BehaviorSubject(false);
  readonly loading$ = this.loading.pipe(distinctUntilChanged());
  bankCards$ = this.getBankCards$();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  reloadBankCards(): void {
    this.bankCards$ = this.getBankCards$();
  }

  exportBankCards(): void {
    const form = this.form.getRawValue();
    const cardNumber = form.cardNumber ?? undefined;
    const dailyLimit = form.dailyLimit ?? undefined;
    const active =
      form.active === this.allValue || form.active == null
        ? undefined
        : form.active === this.trueValue;

    this.exporting.next(true);
    this.subSink.sink = this.reportService
      .getBankCards(cardNumber, dailyLimit, active)
      .pipe(
        tap({
          next: () => {
            this.toastManager.addMessage({
              text: `Bank Cards exported`,
              state: 'success',
            });
            this.exporting.next(false);
          },
          error: () => this.exporting.next(false),
        }),
      )
      .subscribe();
  }

  trackRow(index: number): number {
    return index;
  }

  updatePageNo(page: number): void {
    this.pageNo.next(page);
  }

  private buildForm(): FormGroup<ControlsOf<BankCardListFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<BankCardListFormValue>>({
      cardNumber: this.formBuilder.control(null),
      dailyLimit: this.formBuilder.control(null, [
        OpaValidators.min(0),
        OpaValidators.maxDecimals(2),
      ]),
      active: this.formBuilder.control(this.allValue),
    });
    return form;
  }

  private getBankCards$(): Observable<Page<BankCard>> {
    const pageNo$ = this.pageNo.pipe(distinctUntilChanged());

    const form$ = merge(
      this.form.controls.cardNumber.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)),
      this.form.controls.dailyLimit.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)),
      this.form.controls.active.valueChanges.pipe(distinctUntilChanged()),
    ).pipe(
      startWith(undefined),
      filter(() => this.form.valid),
      map(() => this.form.getRawValue()),
      tap(() => this.updatePageNo(1)),
    );

    return combineLatest([pageNo$, form$]).pipe(
      debounceTime(0),
      tap(() => this.loading.next(true)),
      switchMap(([pageNo, form]) => {
        const cardNumber = form.cardNumber ?? undefined;
        const dailyLimit = form.dailyLimit ?? undefined;
        const active =
          form.active === this.allValue || form.active == null
            ? undefined
            : form.active === this.trueValue;

        return this.bankCardService.getBankCards(pageNo, cardNumber, dailyLimit, active);
      }),
      tap({
        next: () => this.loading.next(false),
        error: () => this.loading.next(false),
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
