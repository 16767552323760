<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Groups</p-heading>

  <p-link
    *ngIf="isPeopleAdmin"
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/groups/create'">Create New Group</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="section">
  <div *ngrxLet="group$ | async as group" class="breadcrumbs">
    <div class="name">
      <p-link-pure [hideLabel]="true" [iconSource]="'assets/icons/arrow-left.svg'">
        <a [routerLink]="isPeopleAdmin ? '/groups' : '/fun-budget'">
          {{ isPeopleAdmin ? 'All Groups' : 'Fun Budget' }}
        </a>
      </p-link-pure>

      <p-heading [size]="'large'" [tag]="'h4'">{{ group?.name ?? '&nbsp;' }}</p-heading>
    </div>

    <ng-container *ngIf="group">
      <p-divider aria-hidden="true" class="divider" [direction]="'vertical'" />

      <p-text [size]="'x-small'">{{ groupTypeTranslation[group.type] | transloco }}</p-text>

      <ng-container *ngIf="isPeopleAdmin">
        <p-divider aria-hidden="true" class="divider" [direction]="'vertical'" />

        <p-button-pure
          [iconSource]="'assets/icons/edit.svg'"
          [size]="'x-small'"
          [underline]="true"
          (click)="showEditModal = true"
        >
          Edit Group Info
        </p-button-pure>

        <p-divider aria-hidden="true" class="divider" [direction]="'vertical'" />

        <p-button-pure
          [iconSource]="'assets/icons/delete.svg'"
          [size]="'x-small'"
          [underline]="true"
          (click)="showGroupDeleteModal = true"
        >
          Delete group
        </p-button-pure>
      </ng-container>
    </ng-container>
  </div>
</section>

<section class="section" *ngIf="group$ | async as group">
  <div class="budget-card">
    <div class="date">
      <p-text [align]="'center'" [size]="'x-small'">
        {{ now | date: 'MMMM' }}
        <br aria-hidden="true" />
        {{ now | date: 'dd' }}
      </p-text>
    </div>

    <div class="amount">
      <p-text [size]="'x-small'">Available Budget</p-text>
      <p-text [color]="'notification-info'" [size]="'large'" [weight]="'semi-bold'">
        {{ group.availableBudget | currency: 'EUR' }}
      </p-text>
    </div>
  </div>
</section>

<section class="section">
  <p-heading
    *ngrxLet="group$ | async as group"
    class="section-heading"
    [size]="'medium'"
    [tag]="'h4'"
  >
    <div class="content">
      <p-icon [source]="'assets/icons/group.svg'" />
      <span>Team Members {{ group ? '(' + group.users.length + ')' : '' }}</span>
    </div>
  </p-heading>

  <ng-container *ngrxLet="group$ as group; error as groupError; suspenseTpl: spinner">
    <div *ngIf="groupError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadGroup()"
      >
        Reload Group Details
      </p-button-pure>
    </div>

    <div *ngIf="!groupError && group" class="group-details">
      <div *ngIf="isPeopleAdmin">
        <p-button-pure
          [iconSource]="'assets/icons/add.svg'"
          [underline]="true"
          (click)="showMembersAddModal = true"
        >
          Add Members
        </p-button-pure>
      </div>

      <p-table caption="People list table">
        <p-table-head>
          <p-table-head-row>
            <p-table-head-cell style="width: 40%">Name</p-table-head-cell>
            <p-table-head-cell style="width: 40%">Available Budget</p-table-head-cell>
            <p-table-head-cell *ngIf="isPeopleAdmin" class="align-end" style="width: 20%">
              Remove Member
            </p-table-head-cell>
          </p-table-head-row>
        </p-table-head>

        <p-table-body>
          <p-table-row *ngFor="let user of group.users; trackBy: trackUser">
            <p-table-cell>{{ user.firstName }} {{ user.lastName }}</p-table-cell>
            <p-table-cell>{{ user.teamBudget | currency: 'EUR' }}</p-table-cell>
            <p-table-cell *ngIf="isPeopleAdmin" class="align-end">
              <p-button-pure
                [disabled]="group.users.length === 1"
                [hideLabel]="true"
                [iconSource]="'assets/icons/close.svg'"
                (click)="showRemoveMemberModal = true; removeMember = user"
              >
                Remove Member
              </p-button-pure>
            </p-table-cell>
          </p-table-row>
        </p-table-body>
      </p-table>
    </div>
  </ng-container>
</section>

<opa-group-edit
  [open]="showEditModal"
  [group]="(group$ | async) ?? undefined"
  (dismiss)="showEditModal = false; $event && reloadGroup(false, $event)"
/>

<opa-group-members-add
  [open]="showMembersAddModal"
  [group]="(group$ | async) ?? undefined"
  (dismiss)="showMembersAddModal = false; $event && reloadGroup(false, $event)"
/>

<opa-group-members-remove
  [open]="showRemoveMemberModal"
  [group]="(group$ | async) ?? undefined"
  [user]="removeMember"
  (dismiss)="showRemoveMemberModal = false; $event && reloadGroup(false, $event)"
/>

<opa-group-delete
  [open]="showGroupDeleteModal"
  [group]="(group$ | async) ?? undefined"
  (dismiss)="showGroupDeleteModal = false"
/>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
