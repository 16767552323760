import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject, type OnDestroy } from '@angular/core';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { SubSink } from 'subsink';
import type { InventoryManufacturer } from '../../../shared/models/api/inventory-manufacturer.model';
import { InventoryManufacturerService } from '../../../shared/services/api/inventory-manufacturer.service';

@Component({
  selector: 'opa-inventory-manufacturer-delete',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule],
  templateUrl: './inventory-manufacturer-delete.component.html',
  styleUrl: './inventory-manufacturer-delete.component.scss',
})
export class InventoryManufacturerDeleteComponent implements OnDestroy {
  private readonly inventoryManufacturerService = inject(InventoryManufacturerService);
  private readonly toastManager = inject(ToastManager);

  @Input({ required: true }) open = false;
  @Input({ required: true }) inventoryManufacturer: InventoryManufacturer | undefined = undefined;
  @Output() dismiss = new EventEmitter<InventoryManufacturer | undefined>();

  private readonly subSink = new SubSink();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.pipe(distinctUntilChanged());

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(inventoryManufacturer?: InventoryManufacturer): void {
    this.dismiss.emit(inventoryManufacturer);
  }

  submit(): void {
    const inventoryManufacturer = this.inventoryManufacturer;
    if (!inventoryManufacturer) return;

    this.submitting.next(true);
    this.subSink.sink = this.inventoryManufacturerService
      .deleteInventoryManufacturer(inventoryManufacturer.id)
      .pipe(
        tap({
          next: () => {
            this.toastManager.addMessage({
              text: `Manufacturer ${inventoryManufacturer.name} deleted`,
              state: 'success',
            });
            this.submitting.next(false);
            this.dismiss.emit(inventoryManufacturer);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }
}
