import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { combineLatest, map, share, shareReplay, type Observable } from 'rxjs';
import { PdhrColors } from '../../shared/constants/colors.constants';
import { Linkouts } from '../../shared/constants/linkouts.constants';
import { DeferLoadingDirective } from '../../shared/directives/defer-loading.directive';
import type { EduTransactionLogRecord } from '../../shared/models/api/edu-transaction-log.model';
import type { Event } from '../../shared/models/api/event.model';
import type { FunTransactionLogRecord } from '../../shared/models/api/fun-transaction-log.model';
import { LeadingPlusPipe } from '../../shared/pipes/leading-plus.pipe';
import { UserService } from '../../shared/services/api/user.service';
import { AuthService } from '../../shared/services/auth.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { transactionLogTypeTranslation } from '../edu-budget/edu-budget.helpers';
import { getEduChart, getFunChart } from './overview.helpers';
import type { EduData, FunData } from './overview.models';

@Component({
  selector: 'opa-overview',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterModule,
    LetDirective,
    BaseChartDirective,
    TranslocoPipe,
    LeadingPlusPipe,
    DeferLoadingDirective,
  ],
  providers: [
    provideCharts(withDefaultRegisterables()),
    // TODO:
    // provideCharts({
    //   registerables: [
    //     DoughnutController,
    //     ArcElement,
    //     CategoryScale,
    //     LinearScale,
    //     LineController,
    //     PointElement,
    //     LineElement,
    //     BarController,
    //     BarElement,
    //     Tooltip,
    //   ],
    // }),
    DatePipe,
    CurrencyPipe,
  ],
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss',
})
export class OverviewComponent {
  private readonly mediaQueryService = inject(MediaQueryService);
  private readonly userService = inject(UserService);
  private readonly datePipe = inject(DatePipe);
  private readonly currencyPipe = inject(CurrencyPipe);
  readonly linkouts = Linkouts;
  readonly logTypeTranslation = transactionLogTypeTranslation;
  readonly pdhrColors = PdhrColors;

  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  readonly isEduEquAdmin = inject(AuthService).isEduEquAdmin();
  funData$ = this.getFunData$();
  eduData$ = this.getEduData$();

  reloadFunData(): void {
    this.funData$ = this.getFunData$();
  }

  reloadEduData(): void {
    this.eduData$ = this.getEduData$();
  }

  trackEvent(index: number, event: Event): number {
    return event.id;
  }

  trackExpenses(index: number, expense: EduTransactionLogRecord): number {
    return expense.id;
  }

  private getFunData$(): Observable<FunData> {
    const me$ = this.userService.getMe();

    const transactionRecords$ = this.userService.getMyFunTransactionLog().pipe(
      map((log) => {
        const records: FunTransactionLogRecord[] = [];
        log.records.forEach((yearLog) => {
          yearLog.yearTransactionLog.forEach((monthLog) => {
            monthLog.monthTransactionLog
              .filter((record) => record.id >= 0)
              .forEach((record) => records.push(record));
          });
        });
        return records;
      }),
      share(),
    );

    const events$ = transactionRecords$.pipe(
      map((records) =>
        records
          .filter((record) => record.event != null)
          .slice(0, 3)
          .map((record) => record.event!),
      ),
    );

    const chart$ = transactionRecords$.pipe(
      map((records) => getFunChart(records, this.datePipe, this.currencyPipe)),
    );

    return combineLatest([me$, events$, chart$]).pipe(
      map(([me, events, chart]) => ({ me, events, chart })),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private getEduData$(): Observable<EduData> {
    const me$ = this.userService.getMe();

    const expenses$ = this.userService.getMyEduTransactionLog().pipe(
      map((log) => {
        const expenses: EduTransactionLogRecord[] = [];
        log.records.forEach((yearLog) => {
          yearLog.yearTransactionLog.forEach((monthLog) => {
            monthLog.monthTransactionLog
              .filter((record) => record.id >= 0 && record.amount <= 0)
              .forEach((record) => expenses.push(record));
          });
        });
        return expenses;
      }),
      share(),
    );

    const chart$ = expenses$.pipe(map((expenses) => getEduChart(expenses)));

    return combineLatest([me$, expenses$, chart$]).pipe(
      map(([me, expenses, chart]) => ({ me, expenses: expenses.slice(0, 3), chart })),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
