import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  ReplaySubject,
  distinctUntilChanged,
  shareReplay,
  tap,
  type Observable,
} from 'rxjs';
import type { InventoryStatus } from '../../shared/models/api/inventory-status.model';
import { InventoryStatusService } from '../../shared/services/api/inventory-status.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { InventoryStatusCreateComponent } from './inventory-status-create/inventory-status-create.component';
import { InventoryStatusDeleteComponent } from './inventory-status-delete/inventory-status-delete.component';
import { InventoryStatusEditComponent } from './inventory-status-edit/inventory-status-edit.component';
@Component({
  selector: 'opa-inventory-item-status-list',
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    CommonModule,
    RouterLink,
    LetDirective,
    InventoryStatusCreateComponent,
    InventoryStatusEditComponent,
    InventoryStatusDeleteComponent,
  ],
  templateUrl: './inventory-item-status-list.component.html',
  styleUrl: './inventory-item-status-list.component.scss',
})
export class InventoryItemStatusListComponent {
  readonly mediaQueryService = inject(MediaQueryService);
  private readonly inventoryStatusService = inject(InventoryStatusService);

  private readonly loading = new BehaviorSubject(false);
  private readonly inventoryStatus = new ReplaySubject<InventoryStatus | null>(1);
  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  readonly loading$ = this.loading.pipe(distinctUntilChanged());
  readonly inventoryStatus$ = this.inventoryStatus.asObservable();

  data$ = this.getData$();
  showCreateModal = false;
  showDeleteModal = false;
  showEditModal = false;

  trackInventoryStatus(index: number, status: InventoryStatus): number {
    return status.id;
  }

  reloadData(): void {
    this.data$ = this.getData$();
  }

  private getData$(): Observable<InventoryStatus[]> {
    this.loading.next(true);

    return this.inventoryStatusService.getInventoryStatuses().pipe(
      tap({
        next: () => this.loading.next(false),
        error: () => this.loading.next(false),
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  openDeleteModal(inventoryStatus: InventoryStatus): void {
    this.inventoryStatus.next(inventoryStatus);
    this.showDeleteModal = true;
  }

  closeDeleteModal(inventoryStatus?: InventoryStatus): void {
    this.showDeleteModal = false;
    if (inventoryStatus) this.reloadData();
    this.inventoryStatus.next(null);
  }

  openEditModal(inventoryStatus: InventoryStatus): void {
    this.inventoryStatus.next(inventoryStatus);
    this.showEditModal = true;
  }

  closeEditModal(success?: boolean): void {
    this.showEditModal = false;
    if (success) this.reloadData();
    this.inventoryStatus.next(null);
  }
}
