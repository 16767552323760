import type { AbstractControl, AsyncValidatorFn, FormGroup } from '@angular/forms';
import { map, type Observable } from 'rxjs';
import type { InventoryLocation } from '../../shared/models/api/inventory-location.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import type { ExtendedValidationErrors } from '../../shared/models/extended-validation-errors.model';
import type { InventoryItemFormValue } from './inventory-item-form.models';

export function inventoryItemLocationAssigneeValidator(
  locations$: Observable<InventoryLocation[]>,
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ExtendedValidationErrors | null> => {
    const form = control as FormGroup<ControlsOf<InventoryItemFormValue>>;

    return locations$.pipe(
      map((locations) => {
        if (!form.controls.locationId)
          return { locationNotSelected: true, message: 'Location must be selected' };

        const location = locations.find((u) => u.id === Number(form.controls.locationId.value));
        if (!location)
          return { locationNotFound: true, message: 'Selected location was not found' };

        const assigneeId = form.controls.assigneeId.value;

        if (location.requiresAssignee && (!assigneeId || assigneeId === 'null'))
          return {
            assigneeNotSelected: true,
            message: `Assignee must be selected for location ${location.description}`,
          };

        if (!location.requiresAssignee && assigneeId && assigneeId !== 'null')
          return {
            assigneeNotRequired: true,
            message: `Assignee must not be selected for location ${location.description}`,
          };

        return null;
      }),
    );
  };
}
