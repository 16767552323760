<p-modal [heading]="'Add Monthly Income'" [open]="open" (dismiss)="close()">
  <p-text>Are you sure you want to add monthly income?</p-text>

  <p-button-group class="modal-actions">
    <p-button
      [icon]="'none'"
      [loading]="(submitting$ | async) ?? false"
      [variant]="'primary'"
      (click)="submit()"
    >
      Add
    </p-button>
    <p-button [iconSource]="'assets/icons/close.svg'" [variant]="'secondary'" (click)="close()">
      Close
    </p-button>
  </p-button-group>
</p-modal>
