import { PersonalTravelTransactionLogType } from '../../shared/models/api/personal-travel-transaction-log.model';

export const transactionLogTypeTranslation: Readonly<
  Record<PersonalTravelTransactionLogType, string>
> = {
  [PersonalTravelTransactionLogType.ALLOWANCE]:
    'PERSONAL_TRAVEL_BUDGET.TRANSACTION_LOG.TYPE.ALLOWANCE',
  [PersonalTravelTransactionLogType.COST]: 'PERSONAL_TRAVEL_BUDGET.TRANSACTION_LOG.TYPE.COST',
  [PersonalTravelTransactionLogType.REMAINING]:
    'PERSONAL_TRAVEL_BUDGET.TRANSACTION_LOG.TYPE.REMAINING',
  [PersonalTravelTransactionLogType.AVAILABLE]:
    'PERSONAL_TRAVEL_BUDGET.TRANSACTION_LOG.TYPE.AVAILABLE',
};
