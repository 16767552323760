import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'opaUserIconInitials',
  standalone: true,
})
export class UserIconInitialsPipe implements PipeTransform {
  transform(name: string, isCounter: boolean): string {
    if (isCounter || !name) return name;

    return name
      .split(' ')
      .map((part) => part.trim().charAt(0))
      .filter((char) => !!char)
      .join('')
      .toLocaleUpperCase();
  }
}
