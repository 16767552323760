import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'leadingPlus',
  standalone: true,
})
export class LeadingPlusPipe implements PipeTransform {
  transform(value: string | null | undefined): string | null | undefined {
    if (!value) return value;

    const number = Number(value.replace(/[^-.0-9]/g, ''));

    return number >= 0 ? `+${value}` : value;
  }
}
