<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Inventory Item Service</p-heading>

  <p-link
    *ngIf="isInventoryAdmin"
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/inventory-item-services/create'">Create New Service</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="section">
  <div *ngrxLet="inventoryItemService$ | async as inventoryItemService" class="breadcrumbs">
    <div class="name">
      <p-link-pure [hideLabel]="true" [iconSource]="'assets/icons/arrow-left.svg'">
        <a [routerLink]="'/inventory-item-services'">
          {{ 'Inventory Item Services' }}
        </a>
      </p-link-pure>

      <p-heading [size]="'large'" [tag]="'h4'">
        @if (!inventoryItemService) {
          &nbsp;
        } @else {
          {{ inventoryItemService.inventoryItem.model }}
          {{ inventoryItemService.inventoryItem.serialNumber }}
        }
      </p-heading>
    </div>

    <ng-container *ngIf="isInventoryAdmin && inventoryItemService">
      <p-divider aria-hidden="true" class="divider" [direction]="'vertical'" />

      <p-link-pure [iconSource]="'assets/icons/edit.svg'" [size]="'x-small'" [underline]="true">
        <a [routerLink]="['/inventory-item-services', inventoryItemService.id, 'edit']"
          >Edit Inventory Item Service</a
        >
      </p-link-pure>
    </ng-container>
  </div>
</section>

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/info.svg'" />
      <span>Inventory Item Service Details</span>
    </div>
  </p-heading>

  <ng-container
    *ngrxLet="
      inventoryItemService$ as inventoryItemService;
      error as inventoryItemServiceError;
      suspenseTpl: spinner
    "
  >
    <div *ngIf="inventoryItemServiceError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadInventoryItemService()"
      >
        Reload Inventory Item Service Details
      </p-button-pure>
    </div>

    <div
      *ngIf="!inventoryItemServiceError && inventoryItemService"
      class="inventory-item-service-details"
    >
      <div>
        <p-text [weight]="'semi-bold'">Inventory Item Model:</p-text>
        <p-text>{{ inventoryItemService.inventoryItem.model }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Inventory Item Serial Number:</p-text>
        <p-text>{{ inventoryItemService.inventoryItem.serialNumber }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Description:</p-text>
        <p-text>{{ inventoryItemService.description }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Sent To Service Date:</p-text>
        <p-text>{{ inventoryItemService.sentToServiceDate | date: 'dd.MM.yyyy' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Returned From Service Date:</p-text>
        <p-text>{{ inventoryItemService.returnedFromServiceDate | date: 'dd.MM.yyyy' }}</p-text>
      </div>

      <div *ngIf="isInventoryAdmin">
        <p-text [weight]="'semi-bold'">Service Document:</p-text>

        <p-button-pure
          *ngIf="inventoryItemService.s3FileKey"
          [iconSource]="'assets/icons/download.svg'"
          [underline]="true"
          (click)="downloadServiceDocument(inventoryItemService.id)"
        >
          Download Service Document
        </p-button-pure>

        <p-text *ngIf="!inventoryItemService.s3FileKey">{{ '-' }}</p-text>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
