import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  type OnChanges,
  type OnDestroy,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { SubSink } from 'subsink';
import type { CreateVendor } from '../../../shared/models/api/vendor.model';
import type { ControlsOf } from '../../../shared/models/controls-of.model';
import type { NgChanges } from '../../../shared/models/ng-changes.model';
import { VendorService } from '../../../shared/services/api/vendor.service';
import { OpaValidators } from '../../../shared/validators/opa.validators';
import type { CreateVendorFormValue } from './vendor-create.models';

@Component({
  selector: 'opa-vendor-create',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, ReactiveFormsModule, LetDirective],
  templateUrl: './vendor-create.component.html',
  styleUrl: './vendor-create.component.scss',
})
export class VendorCreateComponent implements OnChanges, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly vendorService = inject(VendorService);
  private readonly toastManager = inject(ToastManager);

  @Input({ required: true }) open = false;
  @Output() dismiss = new EventEmitter<boolean | undefined>();

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.pipe(distinctUntilChanged());

  ngOnChanges(changes: NgChanges<VendorCreateComponent>): void {
    if (changes.open && this.open) this.form.reset();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(success?: boolean): void {
    this.dismiss.emit(success);
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const vendor = this.formValueToVendor(formValue);
    this.subSink.sink = this.vendorService
      .createVendor(vendor)
      .pipe(
        tap({
          next: (createdVendor) => {
            this.toastManager.addMessage({
              text: `Vendor ${createdVendor.name} created`,
              state: 'success',
            });
            this.submitting.next(false);
            this.close(true);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<CreateVendorFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<CreateVendorFormValue>>({
      name: this.formBuilder.control(null, OpaValidators.required()),
      legalName: this.formBuilder.control(null, OpaValidators.required()),
    });
    return form;
  }

  private formValueToVendor(form: CreateVendorFormValue): CreateVendor {
    const vendor: CreateVendor = {
      vendorName: form.name!,
      legalName: form.legalName!,
    };
    return vendor;
  }
}
