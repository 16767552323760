import { Pipe, type PipeTransform } from '@angular/core';
import type { FormControl } from '@angular/forms';
import { map, startWith, type Observable } from 'rxjs';
import { BudgetType } from '../../shared/models/api/edu-expense.model';
import {
  EduTransactionLogType,
  type EduTransactionLogRecord,
} from '../../shared/models/api/edu-transaction-log.model';

@Pipe({
  name: 'eduBudgetTransactionFilter',
  standalone: true,
})
export class EduBudgetTransactionFilterPipe implements PipeTransform {
  private readonly budgetTypeToTransactionLogTypeMap = {
    [BudgetType.EDU]: EduTransactionLogType.EDU_EXPENSE,
    [BudgetType.EQU]: EduTransactionLogType.EQU_EXPENSE,
  };

  transform(
    transactions: EduTransactionLogRecord[],
    type: FormControl<BudgetType | null>,
  ): Observable<EduTransactionLogRecord[]> {
    return type.valueChanges.pipe(
      startWith(type.value),
      map((budgetType) => {
        if (!budgetType) return transactions;

        const transactionLogType = this.budgetTypeToTransactionLogTypeMap[budgetType];
        if (!transactionLogType) return transactions;

        return transactions.filter((transaction) => transaction.type === transactionLogType);
      }),
    );
  }
}
