import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  distinctUntilChanged,
  shareReplay,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import type { BudgetCorrection } from '../../shared/models/api/budget-correction.model';
import type { Page } from '../../shared/models/api/page.model';
import type { User } from '../../shared/models/api/user.model';
import { FillArrayPipe } from '../../shared/pipes/fill-array.pipe';
import { LeadingPlusPipe } from '../../shared/pipes/leading-plus.pipe';
import { BudgetCorrectionService } from '../../shared/services/api/budget-correction.service';
import { MediaQueryService } from '../../shared/services/media-query.service';

@Component({
  selector: 'opa-budget-corrections',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    LetDirective,
    FillArrayPipe,
    LeadingPlusPipe,
  ],
  templateUrl: './budget-correction-list.component.html',
  styleUrl: './budget-correction-list.component.scss',
})
export class BudgetCorrectionListComponent {
  private readonly budgetCorrectionService = inject(BudgetCorrectionService);
  private readonly mediaQueryService = inject(MediaQueryService);

  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  private readonly pageNo = new BehaviorSubject(1);
  private readonly loading = new BehaviorSubject(false);
  readonly loading$ = this.loading.pipe(distinctUntilChanged());
  budgetCorrections$ = this.getBudgetCorrections$();

  reloadBudgetCorrections(): void {
    this.budgetCorrections$ = this.getBudgetCorrections$();
  }

  trackRow(index: number): number {
    return index;
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  updatePageNo(page: number): void {
    this.pageNo.next(page);
  }

  private getBudgetCorrections$(): Observable<Page<BudgetCorrection>> {
    return this.pageNo.pipe(
      distinctUntilChanged(),
      tap(() => this.loading.next(true)),
      switchMap((pageNo) => this.budgetCorrectionService.getBudgetCorrections(pageNo)),
      tap({
        next: () => this.loading.next(false),
        error: () => this.loading.next(false),
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
