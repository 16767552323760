import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'fillArray',
  standalone: true,
})
export class FillArrayPipe implements PipeTransform {
  transform<T>(
    value: T[] | undefined | null,
    length: number,
  ): (T | undefined)[] | undefined | null {
    if (!Array.isArray(value)) return value;

    return Array.from({ length }, (_, index) => value[index]);
  }
}
