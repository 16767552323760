<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Inventory</p-heading>

  <p-link
    *ngIf="isInventoryAdmin"
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/inventory-items/create'">Create New Item</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="list">
  <p-heading *ngrxLet="results$ | async as inventoryItems" [size]="'small'" [tag]="'h4'">
    <div class="list-heading">
      <div class="total-items">
        {{ inventoryItems?.totalElements }} Items
        <p-divider aria-hidden="true" [direction]="'vertical'" />

        <p-button-pure
          [iconSource]="'assets/icons/download.svg'"
          [size]="'x-small'"
          [underline]="true"
          [loading]="(exportInProgress$ | async) ?? false"
          (click)="exportInventoryItems()"
        >
          Export Inventory Items
        </p-button-pure>
      </div>

      <opa-visible-columns-picker
        [alwaysVisibleColumns]="alwaysVisibleColumns"
        [columnsWithTranslations]="columnTranslations"
        [tableName]="tableName"
      />
    </div>
  </p-heading>

  <ng-container *ngIf="activeFilters$ | async as filters">
    <div *ngIf="filters.length" style="width: 100%">
      <p-scroller style="white-space: nowrap">
        <p-tag-dismissible
          class="filter-item"
          *ngFor="let filter of filters"
          [label]="filterTranslations[filter.key] | transloco"
          (click)="clearFilter(filter.key)"
        >
          {{ filter.value }}
        </p-tag-dismissible>

        <div class="reset-filter">
          <p-button-pure
            [iconSource]="'assets/icons/reset.svg'"
            [underline]="true"
            (click)="resetFilters()"
          >
            Reset Filters
          </p-button-pure>
        </div>
      </p-scroller>
    </div>
  </ng-container>

  <ng-container
    *ngrxLet="results$ as inventoryItems; error as inventoryItemsError; suspenseTpl: spinner"
  >
    <div *ngIf="inventoryItemsError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reload()"
      >
        Reload Inventory
      </p-button-pure>
    </div>

    <ng-container *ngIf="!inventoryItemsError && inventoryItems">
      <div
        *ngrxLet="data$ as data; error as dataError; suspenseTpl: spinner"
        class="overlay-spinner-host"
      >
        <ng-container *ngIf="loading$ | async" [ngTemplateOutlet]="overlaySpinner" />

        <p-table caption="Inventory Items list table">
          <div *ngIf="dataError">
            <p-button-pure
              [hideLabel]="true"
              [iconSource]="'assets/icons/refresh.svg'"
              [size]="'large'"
              (click)="reloadData()"
            >
              Reload Data
            </p-button-pure>
          </div>

          <p-table-head *ngrxLet="sort$ as activeSort">
            <p-table-head-row>
              <p-table-head-cell
                *ngrxLet="{
                  label: columns.id,
                  sortId: 'id'
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.id"
                class="align-end"
                style="width: 10%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.manufacturerId,
                  control: form.controls.manufacturerId,
                  sortId: 'manufacturer.description'
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.manufacturerId"
                style="width: 20%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-filter
                  [active]="!!cell.control.value && cell.control.value !== allValue"
                >
                  <p-select-wrapper
                    [native]="true"
                    [label]="filterTranslations[cell.label] | transloco"
                  >
                    <select [formControl]="cell.control">
                      <option [value]="allValue">All</option>

                      <option
                        *ngFor="
                          let manufacturer of data.manufacturers;
                          trackBy: trackInventoryManufacturer
                        "
                        [value]="manufacturer.id"
                      >
                        {{ manufacturer.description }}
                      </option>
                    </select>
                  </p-select-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.model,
                  control: form.controls.model,
                  sortId: 'model'
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.model"
                style="width: 20%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-filter [active]="!!cell.control.value">
                  <p-text-field-wrapper [label]="filterTranslations[cell.label] | transloco">
                    <input type="text" [formControl]="cell.control" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.specification,
                  control: form.controls.specification,
                  sortId: 'specification'
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.specification"
                style="width: 20%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-filter [active]="!!cell.control.value">
                  <p-text-field-wrapper [label]="filterTranslations[cell.label] | transloco">
                    <input type="text" [formControl]="cell.control" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.typeId,
                  control: form.controls.typeId,
                  sortId: 'type'
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.typeId"
                style="width: 20%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-filter
                  [active]="!!cell.control.value && cell.control.value !== allValue"
                >
                  <p-select-wrapper
                    [native]="true"
                    [label]="filterTranslations[cell.label] | transloco"
                  >
                    <select [formControl]="cell.control">
                      <option [value]="allValue">All</option>

                      <option
                        *ngFor="let type of data.types; trackBy: trackInventoryType"
                        [value]="type.id"
                      >
                        {{ type.description }}
                      </option>
                    </select>
                  </p-select-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.serialNumber,
                  control: form.controls.serialNumber,
                  sortId: 'serialNumber'
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.serialNumber"
                style="width: 20%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-filter [active]="!!cell.control.value">
                  <p-text-field-wrapper [label]="filterTranslations[cell.label] | transloco">
                    <input type="text" [formControl]="cell.control" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.imei,
                  control: form.controls.imei,
                  sortId: 'imei'
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.imei"
                style="width: 20%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-filter [active]="!!cell.control.value">
                  <p-text-field-wrapper [label]="filterTranslations[cell.label] | transloco">
                    <input type="text" [formControl]="cell.control" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.ownerId,
                  control: form.controls.ownerId,
                  sortId: 'owner.description'
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.ownerId"
                style="width: 20%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-filter
                  [active]="!!cell.control.value && cell.control.value !== allValue"
                >
                  <p-select-wrapper
                    [native]="true"
                    [label]="filterTranslations[cell.label] | transloco"
                  >
                    <select [formControl]="cell.control">
                      <option [value]="allValue">All</option>

                      <option
                        *ngFor="let owner of data.owners; trackBy: trackInventoryOwner"
                        [value]="owner.id"
                      >
                        {{ owner.description }}
                      </option>
                    </select>
                  </p-select-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.locationId,
                  control: form.controls.locationId,
                  sortId: 'location.description'
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.locationId"
                style="width: 20%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-filter
                  [active]="!!cell.control.value && cell.control.value !== allValue"
                >
                  <p-select-wrapper
                    [native]="true"
                    [label]="filterTranslations[cell.label] | transloco"
                  >
                    <select [formControl]="cell.control">
                      <option [value]="allValue">All</option>

                      <option
                        *ngFor="let location of data.locations; trackBy: trackInventoryLocation"
                        [value]="location.id"
                      >
                        {{ location.description }}
                      </option>
                    </select>
                  </p-select-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.warrantyExpiration,
                  controlFrom: form.controls.warrantyExpirationFrom,
                  controlTo: form.controls.warrantyExpirationTo,
                  labelFrom: filters.warrantyExpirationFrom,
                  labelTo: filters.warrantyExpirationTo,
                  sortId: 'warrantyExpiration'
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.warrantyExpiration"
                style="width: 20%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-filter [active]="!!cell.controlFrom.value || !!cell.controlTo.value">
                  <p-text-field-wrapper [label]="filterTranslations[cell.labelFrom] | transloco">
                    <input
                      type="date"
                      [max]="cell.controlTo.value"
                      [formControl]="cell.controlFrom"
                    />
                  </p-text-field-wrapper>

                  <p-text-field-wrapper [label]="filterTranslations[cell.labelTo] | transloco">
                    <input
                      type="date"
                      [min]="cell.controlFrom.value"
                      [formControl]="cell.controlTo"
                    />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.contractNumber,
                  control: form.controls.contractNumber,
                  sortId: 'contractNumber'
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.contractNumber"
                style="width: 20%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-filter [active]="!!cell.control.value">
                  <p-text-field-wrapper [label]="filterTranslations[cell.label] | transloco">
                    <input type="text" [formControl]="cell.control" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.price,
                  priceComparisonType: form.controls.priceComparisonType,
                  price: form.controls.price,
                  sortId: 'price'
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.price"
                style="width: 20%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-filter [active]="cell.price.value != null">
                  <p-select-wrapper [native]="true" [label]="'Comparator'">
                    <select [formControl]="cell.priceComparisonType">
                      <option *ngFor="let comparator of numberComparators" [value]="comparator">
                        {{ numberComparatorTranslation[comparator] | transloco }}
                      </option>
                    </select>
                  </p-select-wrapper>

                  <p-text-field-wrapper [label]="filterTranslations[cell.label] | transloco">
                    <input type="number" [formControl]="cell.price" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.assigneeId,
                  control: form.controls.assigneeId,
                  sortId: 'assignee.id'
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.assigneeId"
                style="width: 20%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-filter
                  [active]="!!cell.control.value && cell.control.value !== allValue"
                >
                  <p-select-wrapper
                    [label]="filterTranslations[cell.label] | transloco"
                    [native]="true"
                  >
                    <select [formControl]="cell.control">
                      <option [value]="allValue">All</option>

                      <option
                        *ngFor="let user of data.assignees; trackBy: trackUser"
                        [value]="user.id"
                      >
                        {{ user.firstName }} {{ user.lastName }}
                      </option>
                    </select>
                  </p-select-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.note,
                  sortId: 'note',
                  control: form.controls.note
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.note"
                style="width: 20%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-filter [active]="!!cell.control.value">
                  <p-text-field-wrapper [label]="filterTranslations[cell.label] | transloco">
                    <input type="text" [formControl]="cell.control" />
                  </p-text-field-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.statusId,
                  control: form.controls.statusId,
                  sortId: 'status.description'
                } as cell"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.statusId"
                style="width: 20%"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>

                <opa-table-filter
                  [active]="!!cell.control.value && cell.control.value !== allValue"
                >
                  <p-select-wrapper
                    [native]="true"
                    [label]="filterTranslations[cell.label] | transloco"
                  >
                    <select [formControl]="cell.control">
                      <option [value]="allValue">All</option>

                      <option
                        *ngFor="let status of data.statuses; trackBy: trackInventoryStatus"
                        [value]="status.id"
                      >
                        {{ status.description }}
                      </option>
                    </select>
                  </p-select-wrapper>
                </opa-table-filter>

                <opa-table-sort
                  [direction]="
                    !activeSort || activeSort.sortId !== cell.sortId
                      ? undefined
                      : activeSort.direction
                  "
                  (updateSort)="updateSort(cell.sortId, $event)"
                />
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.serviceNo
                } as cell"
                style="width: 20%"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.serviceNo"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>
              </p-table-head-cell>

              <p-table-head-cell
                *ngrxLet="{
                  label: columns.action
                } as cell"
                style="width: 20%"
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.action"
              >
                <span>{{ columnTranslations[cell.label] | transloco }}</span>
              </p-table-head-cell>
            </p-table-head-row>
          </p-table-head>

          <p-table-body>
            <p-table-row
              *ngFor="
                let inventoryItem of inventoryItems.content
                  | fillArray: inventoryItems.pageable.pageSize;
                trackBy: trackRow
              "
            >
              <p-table-cell
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.id"
                class="align-end"
              >
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  <a [routerLink]="['/inventory-items', inventoryItem.id]">
                    {{ inventoryItem.id }}
                  </a>
                </ng-container>
              </p-table-cell>
              <p-table-cell
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.manufacturerId"
              >
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  {{ inventoryItem.manufacturer.description }}
                </ng-container>
              </p-table-cell>
              <p-table-cell opaVisibleColumn [tableName]="tableName" [columnName]="columns.model">
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  {{ inventoryItem.model }}
                </ng-container>
              </p-table-cell>
              <p-table-cell
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.specification"
              >
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  {{ inventoryItem.specification }}
                </ng-container>
              </p-table-cell>
              <p-table-cell opaVisibleColumn [tableName]="tableName" [columnName]="columns.typeId">
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  {{ inventoryItem.type.description }}
                </ng-container>
              </p-table-cell>
              <p-table-cell
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.serialNumber"
              >
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  {{ inventoryItem.serialNumber }}
                </ng-container>
              </p-table-cell>
              <p-table-cell opaVisibleColumn [tableName]="tableName" [columnName]="columns.imei">
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  {{ inventoryItem.imei }}
                </ng-container>
              </p-table-cell>
              <p-table-cell opaVisibleColumn [tableName]="tableName" [columnName]="columns.ownerId">
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  {{ inventoryItem.owner.description }}
                </ng-container>
              </p-table-cell>
              <p-table-cell
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.locationId"
              >
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  {{ inventoryItem.location.description }}
                </ng-container>
              </p-table-cell>
              <p-table-cell
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.warrantyExpiration"
              >
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  {{ inventoryItem.warrantyExpiration | date: 'dd.MM.yyyy' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.contractNumber"
              >
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  {{ inventoryItem.contractNumber }}
                </ng-container>
              </p-table-cell>
              <p-table-cell opaVisibleColumn [tableName]="tableName" [columnName]="columns.price">
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  {{ inventoryItem.price | currency: 'EUR' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.assigneeId"
              >
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  <span *ngIf="inventoryItem.assignee">
                    {{ inventoryItem.assignee.firstName }} {{ inventoryItem.assignee.lastName }}
                  </span>
                  <span *ngIf="!inventoryItem.assignee">-</span>
                </ng-container>
              </p-table-cell>
              <p-table-cell opaVisibleColumn [tableName]="tableName" [columnName]="columns.note">
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  {{ inventoryItem.note }}
                </ng-container>
              </p-table-cell>
              <p-table-cell
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.statusId"
              >
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  {{ inventoryItem.status.description }}
                </ng-container>
              </p-table-cell>
              <p-table-cell
                opaVisibleColumn
                [tableName]="tableName"
                [columnName]="columns.serviceNo"
              >
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  {{ inventoryItem.serviceCount }}
                </ng-container>
              </p-table-cell>
              <p-table-cell opaVisibleColumn [tableName]="tableName" [columnName]="columns.action">
                <ng-container *ngIf="inventoryItem; else emptyCell">
                  <opa-assign-inventory-item
                    [inventoryItem]="inventoryItem"
                    (assignSuccessful)="refresh()"
                  />
                  <opa-unassign-inventory-item
                    [inventoryItem]="inventoryItem"
                    (unassignSuccessful)="refresh()"
                  />
                </ng-container>
              </p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>

        <p-pagination
          [totalItemsCount]="inventoryItems.totalElements"
          [itemsPerPage]="inventoryItems.size"
          [activePage]="inventoryItems.pageable.pageNumber + 1"
          (update)="updatePageNo($event.detail.page)"
        />
      </div>
    </ng-container>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>

<ng-template #overlaySpinner>
  <div class="overlay-spinner"><p-spinner /></div>
</ng-template>

<ng-template #emptyCell>
  <span class="empty-cell"></span>
</ng-template>
