import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  type OnChanges,
  type OnDestroy,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  map,
  shareReplay,
  startWith,
  take,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import type {
  CreateOrEditInventoryItem,
  InventoryItem,
} from '../../shared/models/api/inventory-item.model';
import type { InventoryLocation } from '../../shared/models/api/inventory-location.model';
import type { InventoryManufacturer } from '../../shared/models/api/inventory-manufacturer.model';
import type { InventoryOwner } from '../../shared/models/api/inventory-owner.model';
import type { InventoryStatus } from '../../shared/models/api/inventory-status.model';
import type { InventoryType } from '../../shared/models/api/inventory-type.model';
import type { User } from '../../shared/models/api/user.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import type { NgChanges } from '../../shared/models/ng-changes.model';
import { InventoryLocationService } from '../../shared/services/api/inventory-location.service';
import { InventoryManufacturerService } from '../../shared/services/api/inventory-manufacturer.service';
import { InventoryOwnerService } from '../../shared/services/api/inventory-owner.service';
import { InventoryStatusService } from '../../shared/services/api/inventory-status.service';
import { InventoryTypeService } from '../../shared/services/api/inventory-type.service';
import { UserService } from '../../shared/services/api/user.service';
import { OpaValidators } from '../../shared/validators/opa.validators';
import { InventoryLocationCreateComponent } from '../inventory-item-location-list/inventory-location-create/inventory-location-create.component';
import { InventoryManufacturerCreateComponent } from '../inventory-item-manufacturer-list/inventory-manufacturer-create/inventory-manufacturer-create.component';
import { InventoryOwnerCreateComponent } from '../inventory-item-owner-list/inventory-owner-create/inventory-owner-create.component';
import { InventoryStatusCreateComponent } from '../inventory-item-status-list/inventory-status-create/inventory-status-create.component';
import { InventoryTypeCreateComponent } from '../inventory-item-type-list/inventory-type-create/inventory-type-create.component';
import type { InventoryItemFormData, InventoryItemFormValue } from './inventory-item-form.models';
import { inventoryItemImeiValidator } from './inventory-item-imei.validator';
import { inventoryItemLocationAssigneeValidator } from './inventory-item-location-assignee.validator';

@Component({
  selector: 'opa-inventory-item-form',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    ReactiveFormsModule,
    LetDirective,
    TranslocoPipe,
    RouterLink,
    InventoryOwnerCreateComponent,
    InventoryLocationCreateComponent,
    InventoryTypeCreateComponent,
    InventoryManufacturerCreateComponent,
    InventoryStatusCreateComponent,
  ],
  templateUrl: './inventory-item-form.component.html',
  styleUrl: './inventory-item-form.component.scss',
})
export class InventoryItemFormComponent implements OnChanges, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly userService = inject(UserService);

  readonly inventoryManufacturerService = inject(InventoryManufacturerService);
  readonly inventoryLocationService = inject(InventoryLocationService);
  readonly inventoryOwnerService = inject(InventoryOwnerService);
  readonly inventoryTypeService = inject(InventoryTypeService);
  readonly inventoryStatusService = inject(InventoryStatusService);

  @Input() inventoryItem: InventoryItem | null = null;
  @Output() readonly submitForm = new EventEmitter<CreateOrEditInventoryItem>();

  readonly textareaMaxLength = 1000;
  readonly minAmount = 0;

  private readonly subSink = new SubSink();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.asObservable();
  formData$ = this.getFormData$();
  form = this.buildForm();

  showCreateOwnerModal = false;
  showCreateLocationModal = false;
  showCreateTypeModal = false;
  showCreateStatusModal = false;
  showCreateManufacturerModal = false;
  isImeiRequired$?: Observable<boolean>;

  ngOnChanges(changes: NgChanges<InventoryItemFormComponent>): void {
    if (changes.inventoryItem) {
      this.form = this.buildForm();
    }
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  reloadFormData(): void {
    this.formData$ = this.getFormData$();
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  trackManufacturer(index: number, manufacturer: InventoryManufacturer): number {
    return manufacturer.id;
  }

  trackLocation(index: number, location: InventoryLocation): number {
    return location.id;
  }

  trackOwner(index: number, owner: InventoryOwner): number {
    return owner.id;
  }

  trackType(index: number, type: InventoryType): number {
    return type.id;
  }

  trackStatus(index: number, status: InventoryStatus): number {
    return status.id;
  }

  submit(): void {
    if (!this.form) return;

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const item = this.formValueToItem(formValue);

    this.submitForm.emit(item);
  }

  finalizeSubmit(): void {
    this.submitting.next(false);
  }

  onWarrantyChange(event: Event): void {
    if (!this.form) return;
    if (!(event.target as HTMLInputElement).files?.length) return;

    this.form.patchValue({
      warranty: (event.target as HTMLInputElement).files![0],
    });
  }

  onReceiptChange(event: Event): void {
    if (!this.form) return;
    if (!(event.target as HTMLInputElement).files?.length) return;

    this.form.patchValue({
      receipt: (event.target as HTMLInputElement).files![0],
    });
  }

  formValueToItem(formValue: InventoryItemFormValue): CreateOrEditInventoryItem {
    const item: CreateOrEditInventoryItem = {
      warranty: formValue.warranty!,
      receipt: formValue.receipt!,
      command: {
        manufacturerId: formValue.manufacturerId!,
        model: formValue.model!,
        specification: formValue.specification!,
        typeId: formValue.typeId!,
        serialNumber: formValue.serialNumber!,
        imei: formValue.imei,
        ownerId: formValue.ownerId!,
        locationId: formValue.locationId!,
        warrantyExpiration: formValue.warrantyExpiration!,
        contractNumber: formValue.contractNumber,
        price: formValue.price!,
        assigneeId:
          formValue.assigneeId && formValue.assigneeId !== 'null' ? formValue.assigneeId : null,
        note: formValue.note,
        statusId: formValue.statusId!,
      },
    };

    return item;
  }

  private buildForm(): FormGroup<ControlsOf<InventoryItemFormValue>> {
    const item = this.inventoryItem;
    const typeIdControl = this.formBuilder.control(
      item?.type?.id ?? null,
      OpaValidators.required(),
    );

    this.isImeiRequired$ = combineLatest([
      typeIdControl.valueChanges.pipe(startWith(typeIdControl.value)),
      this.formData$,
    ]).pipe(
      map(([typeId, formData]) => {
        const typeIdNumber = Number(typeId);
        const type = formData.types.find((t) => t.id === typeIdNumber);
        return type?.imeiRequired ?? false;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    const form = this.formBuilder.nonNullable.group<ControlsOf<InventoryItemFormValue>>(
      {
        warranty: this.formBuilder.control(null),
        receipt: this.formBuilder.control(null),
        manufacturerId: this.formBuilder.control(
          item?.manufacturer?.id || null,
          OpaValidators.required(),
        ),
        model: this.formBuilder.control(item?.model ?? null, OpaValidators.required()),
        serialNumber: this.formBuilder.control(
          item?.serialNumber ?? null,
          OpaValidators.required(),
        ),
        imei: this.formBuilder.control(
          item?.imei ?? null,
          [],
          [inventoryItemImeiValidator(this.isImeiRequired$.pipe(take(1)))],
        ),
        typeId: typeIdControl,
        specification: this.formBuilder.control(item?.specification ?? null, [
          OpaValidators.required(),
        ]),
        ownerId: this.formBuilder.control(item?.owner?.id ?? null, OpaValidators.required()),
        locationId: this.formBuilder.control(item?.location?.id ?? null, OpaValidators.required()),
        warrantyExpiration: this.formBuilder.control(
          item?.warrantyExpiration ?? null,
          OpaValidators.required(),
        ),
        contractNumber: this.formBuilder.control(
          item?.contractNumber ?? null,
          OpaValidators.pattern('^[0-9-/]+$', `Only numbers, '-' and '/' are allowed`),
        ),
        price: this.formBuilder.control(item?.price ?? null, [
          OpaValidators.required(),
          OpaValidators.min(this.minAmount),
        ]),
        assigneeId: this.formBuilder.control(item?.assignee?.id ?? null),
        note: this.formBuilder.control(item?.note ?? null, [
          OpaValidators.maxLength(this.textareaMaxLength),
        ]),
        statusId: this.formBuilder.control(item?.status?.id ?? null, OpaValidators.required()),
      },
      {
        asyncValidators: [inventoryItemLocationAssigneeValidator(this.getLocations$())],
      },
    );

    this.subSink.add(
      this.isImeiRequired$.subscribe(() => {
        form.controls.imei.updateValueAndValidity();
      }),
    );

    return form;
  }

  private getFormData$(): Observable<InventoryItemFormData> {
    const manufacturers$ = this.inventoryManufacturerService.getInventoryManufacturers();
    const owners$ = this.inventoryOwnerService.getInventoryOwners();
    const types$ = this.inventoryTypeService.getInventoryTypes();
    const statuses$ = this.inventoryStatusService.getInventoryStatuses();
    const users$ = this.userService.getActiveUsers();

    return combineLatest([
      manufacturers$,
      this.getLocations$(),
      owners$,
      types$,
      statuses$,
      users$,
    ]).pipe(
      map(
        ([manufacturers, locations, owners, types, statuses, users]): InventoryItemFormData => ({
          manufacturers,
          locations,
          owners,
          types,
          statuses,
          users,
        }),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private getLocations$(): Observable<InventoryLocation[]> {
    return this.inventoryLocationService
      .getInventoryLocations()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }
}
