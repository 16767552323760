<p-heading class="title" [size]="'x-large'" [tag]="'h3'">Groups</p-heading>

<p-divider aria-hidden="true" />

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/group-add.svg'" />
      <span>Create New Group</span>
    </div>
  </p-heading>

  <ng-container *ngrxLet="createGroupData$ as data; error as dataError; suspenseTpl: spinner">
    <div *ngIf="dataError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadCreateGroupData()"
      >
        Reload Create New Group
      </p-button-pure>
    </div>

    <form *ngIf="!dataError && data" class="form" [formGroup]="form" (ngSubmit)="submit()">
      <p-text-field-wrapper
        *ngrxLet="form.controls.name as control"
        [label]="'Group Name'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <input type="text" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <p-select-wrapper
        *ngrxLet="form.controls.type as control"
        [filter]="true"
        [label]="'Group Type'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <select [attr.required]="true" [formControl]="control">
          <option *ngFor="let type of groupTypes" [value]="type">
            {{ groupTypeTranslation[type] | transloco }}
          </option>
        </select>
      </p-select-wrapper>

      <p-select-wrapper
        *ngrxLet="form.controls.members as control"
        [filter]="true"
        [label]="'Add Members'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <select [attr.required]="true" [formControl]="control">
          <option
            *ngFor="
              let user of data.users | opaFilterSelectedUsers: control.value;
              trackBy: trackUser
            "
            [value]="user | opaJoinUsers: control.value"
          >
            {{ user.firstName }} {{ user.lastName }}
          </option>
        </select>
      </p-select-wrapper>

      <ng-container *ngrxLet="form.controls.members.value | opaStringSplit: ',' as userIds">
        <div *ngIf="userIds && userIds.length > 0">
          <p-text [color]="'contrast-medium'">{{ userIds.length }} selected:</p-text>

          <div *ngFor="let userId of userIds">
            <p-button-pure
              *ngrxLet="userId | opaFindUser: data.users as user"
              [aria]="{
                'aria-label':
                  (user ? user.firstName + ' ' + user.lastName : userId) + ' (remove selected)'
              }"
              [iconSource]="'assets/icons/close.svg'"
              [type]="'button'"
              (click)="removeMember(userId)"
            >
              <ng-container *ngIf="user">{{ user.firstName }} {{ user.lastName }}</ng-container>
              <ng-container *ngIf="!user">{{ userId }}</ng-container>
            </p-button-pure>
          </div>
        </div>
      </ng-container>

      <div class="actions">
        <p-button
          [icon]="'none'"
          [loading]="data.submitting"
          [type]="'submit'"
          [variant]="'primary'"
        >
          Create Group
        </p-button>
      </div>
    </form>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
