import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type { Position } from '../../models/api/position.model';

@Injectable({
  providedIn: 'root',
})
export class PositionService {
  private readonly httpClient = inject(HttpClient);
  private readonly positionApi = `${environment.apiUrl}/v1/positions`;

  getPositions(): Observable<Position[]> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Positions');
    return this.httpClient.get<Position[]>(`${this.positionApi}`, { context });
  }
}
