import { Pipe, type PipeTransform } from '@angular/core';
import type { User } from '../../shared/models/api/user.model';

@Pipe({
  name: 'opaJoinUsers',
  standalone: true,
})
export class JoinUsersPipe implements PipeTransform {
  transform(users: User | User[], selectedUserIds: string | null): string | undefined {
    const selected = selectedUserIds ? selectedUserIds.split(',') : [];
    users = Array.isArray(users) ? users : [users];
    const all = [
      ...selected,
      ...users.filter((user) => !selected.includes(`${user.id}`)).map((user) => `${user.id}`),
    ].join(',');
    return all || undefined;
  }
}
