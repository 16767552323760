import { CommonModule } from '@angular/common';
import { Component, inject, type OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, type FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  type Observable,
  tap,
} from 'rxjs';
import { SubSink } from 'subsink';
import {
  CreateOrEditInventoryItemService,
  CreateInventoryItemServiceCommand,
} from '../../shared/models/api/inventory-item-service.model';
import {
  type CreateOrEditInventoryItem,
  InventoryItem,
} from '../../shared/models/api/inventory-item.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { InventoryItemServiceService } from '../../shared/services/api/inventory-item-service.service';
import { InventoryItemsService } from '../../shared/services/api/inventory-items.service';
import { AuthService } from '../../shared/services/auth.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { OpaValidators } from '../../shared/validators/opa.validators';
import { InventoryItemFormComponent } from '../inventory-item-form/inventory-item-form.component';
import { InventoryItemServiceFormComponent } from '../inventory-item-service-form/inventory-item-service-form.component';
import {
  CreateInventoryItemServiceData,
  InventoryItemServiceFormValue,
} from '../inventory-item-service-form/inventory-item-service-create.models';

@Component({
  selector: 'opa-inventory-item-service-create',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    ReactiveFormsModule,
    LetDirective,
    InventoryItemServiceFormComponent,
  ],
  templateUrl: './inventory-item-service-create.component.html',
  styleUrl: './inventory-item-service-create.component.scss',
})
export class InventoryItemServiceCreateComponent implements OnDestroy {
  @ViewChild(InventoryItemServiceFormComponent) form?: InventoryItemServiceFormComponent;

  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);
  private readonly inventoryItemServiceService = inject(InventoryItemServiceService);

  readonly isInventoryAdmin = inject(AuthService).isInventoryAdmin();
  readonly mediaMaxXs$ = inject(MediaQueryService).max('xs');
  private readonly subSink = new SubSink();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  onSubmit(item: CreateOrEditInventoryItemService): void {
    this.subSink.sink = this.inventoryItemServiceService
      .createInventoryItemService(item)
      .pipe(
        tap({
          next: (createItemService) => {
            this.toastManager.addMessage({
              text: `Inventory item service ${createItemService.id} created`,
              state: 'success',
            });
            this.router.navigate(['/inventory-item-services']);
          },
          error: () => this.form?.finalizeSubmit(),
        }),
      )
      .subscribe();
  }
}
