<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Inventory Item Service</p-heading>
</div>

<p-divider aria-hidden="true" />

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/add-circle.svg'" />
      <span>Create New Inventory Item Service</span>
    </div>
  </p-heading>

  <opa-inventory-item-service-form (submitForm)="onSubmit($event)" />
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
