import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private readonly httpClient = inject(HttpClient);
  private readonly adminApi = `${environment.apiUrl}/v1/admin`;

  addMonthlyIncome(): Observable<void> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Add Monthly Income');
    return this.httpClient.post<void>(`${this.adminApi}/add-monthly-income`, null, { context });
  }
}
