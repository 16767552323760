import { Pipe, type PipeTransform } from '@angular/core';

export const inventoryIcons = {
  camera: 'assets/icons/inventory/camera.svg',
  default: 'assets/icons/inventory/default.svg',
  eScooter: 'assets/icons/inventory/e-scooter.svg',
  game: 'assets/icons/inventory/game.svg',
  headset: 'assets/icons/inventory/headset.svg',
  keyboard: 'assets/icons/inventory/keyboard.svg',
  laptop: 'assets/icons/inventory/laptop.svg',
  monitor: 'assets/icons/inventory/monitor.svg',
  mouse: 'assets/icons/inventory/mouse.svg',
  phone: 'assets/icons/inventory/phone.svg',
  stereo: 'assets/icons/inventory/stereo.svg',
  tablet: 'assets/icons/inventory/tablet.svg',
  tv: 'assets/icons/inventory/tv.svg',
  watch: 'assets/icons/inventory/watch.svg',
} as const;

@Pipe({
  name: 'opaInventoryItemIcon',
  standalone: true,
})
export class InventoryItemIconPipe implements PipeTransform {
  transform(inventoryItemTypeName: string): string {
    switch (inventoryItemTypeName) {
      case 'CAMERA':
        return inventoryIcons.camera;
      case 'ELECTRIC_SCOOTER':
        return inventoryIcons.eScooter;
      case 'CONSOLE':
      case 'CONTROLLER':
      case 'GAME':
        return inventoryIcons.game;
      case 'HEADSET':
        return inventoryIcons.headset;
      case 'KEYBOARD':
        return inventoryIcons.keyboard;
      case 'LAPTOP':
        return inventoryIcons.laptop;
      case 'MONITOR':
        return inventoryIcons.monitor;
      case 'MOUSE':
        return inventoryIcons.mouse;
      case 'PHONE':
      case 'TESTING_PHONE':
        return inventoryIcons.phone;
      case 'STEREO':
        return inventoryIcons.stereo;
      case 'TABLET':
        return inventoryIcons.tablet;
      case 'TV':
        return inventoryIcons.tv;
      case 'WATCH':
      case 'TESTING_WATCH':
        return inventoryIcons.watch;

      default:
        return inventoryIcons.default;
    }
  }
}
