<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Members</p-heading>

  <p-link
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/users/create'">Add New Member</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="filters">
  <p-text-field-wrapper class="main-filter" [hideLabel]="true" [label]="'Search Members'">
    <input type="search" [formControl]="form.controls.userName" [placeholder]="'Search Members'" />
  </p-text-field-wrapper>
</section>

<section class="list">
  <p-heading *ngrxLet="data$ | async as data" [size]="'small'" [tag]="'h4'">
    <div class="users-status-count">
      <span>{{ data?.usersStatusCount?.active }} Active</span>
      <p-divider aria-hidden="true" [direction]="'vertical'" />
      <span>{{ data?.usersStatusCount?.inactive }} Archived</span>

      <p-divider aria-hidden="true" [direction]="'vertical'" />

      <p-button-pure
        [iconSource]="'assets/icons/download.svg'"
        [size]="'x-small'"
        [underline]="true"
        [loading]="(exporting$ | async) ?? false"
        (click)="exportUsers()"
      >
        Export Members
      </p-button-pure>
    </div>
  </p-heading>

  <ng-container *ngrxLet="data$ as data; error as dataError; suspenseTpl: spinner">
    <div *ngIf="dataError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadData()"
      >
        Reload Members
      </p-button-pure>
    </div>

    <ng-container *ngIf="!dataError && data">
      <div class="overlay-spinner-host">
        <ng-container *ngIf="loading$ | async" [ngTemplateOutlet]="overlaySpinner" />

        <p-table caption="Members list table" (update)="updateSort($event.detail)">
          <p-table-head>
            <p-table-head-row>
              <p-table-head-cell style="width: 12%">Member</p-table-head-cell>
              <p-table-head-cell style="width: 12%">Position</p-table-head-cell>
              <p-table-head-cell style="width: 12%">Dedicated Budget</p-table-head-cell>
              <p-table-head-cell style="width: 12%">Fun & Games Budget</p-table-head-cell>
              <p-table-head-cell style="width: 12%">Remote Location</p-table-head-cell>
              <p-table-head-cell style="width: 12%">Personal Travel Budget</p-table-head-cell>
              <p-table-head-cell style="width: 12%">Max Education Budget</p-table-head-cell>
              <p-table-head-cell style="width: 12%">Max Equipment Budget</p-table-head-cell>
              <p-table-head-cell style="width: 1%" [sort]="(statusSort$ | async) ?? undefined">
                Status
              </p-table-head-cell>
            </p-table-head-row>
          </p-table-head>

          <p-table-body>
            <p-table-row
              *ngFor="
                let user of data.users.content | fillArray: data.users.pageable.pageSize;
                trackBy: trackRow
              "
            >
              <p-table-cell>
                <ng-container *ngIf="user; else emptyCell">
                  <a [routerLink]="['/users', user.id]">
                    {{ user.firstName }} {{ user.lastName }}
                  </a>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="user; else emptyCell">
                  {{ user.position.name }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="user; else emptyCell">
                  <ng-container *ngIf="user.active">
                    {{ user.monthlyTeamBudget | currency: 'EUR' }}
                  </ng-container>
                  <ng-container *ngIf="!user.active">-</ng-container>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="user; else emptyCell">
                  <ng-container *ngIf="user.active">
                    {{ user.teamBudget | currency: 'EUR' }}
                  </ng-container>
                  <ng-container *ngIf="!user.active">-</ng-container>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="user; else emptyCell">
                  <ng-container *ngIf="user.remoteEmployee">
                    {{ user.remoteLocation?.name }}
                  </ng-container>
                  <ng-container *ngIf="!user.remoteEmployee">-</ng-container>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="user; else emptyCell">
                  <ng-container *ngIf="user.remoteEmployee">
                    {{ user.personalTravelBudget | currency: 'EUR' }}
                  </ng-container>
                  <ng-container *ngIf="!user.remoteEmployee">-</ng-container>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="user; else emptyCell">
                  <ng-container *ngIf="user.active">
                    {{ user.eduEquBudgetStatus.maxEducationBudgetRemaining | currency: 'EUR' }}
                  </ng-container>
                  <ng-container *ngIf="!user.active">-</ng-container>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="user; else emptyCell">
                  <ng-container *ngIf="user.active">
                    {{ user.eduEquBudgetStatus.maxEquipmentBudgetRemaining | currency: 'EUR' }}
                  </ng-container>
                  <ng-container *ngIf="!user.active">-</ng-container>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="user; else emptyCell">
                  <div
                    class="status"
                    [ngClass]="{
                      'status--active': user.active,
                      'status--inactive': !user.active
                    }"
                  ></div>
                </ng-container>
              </p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>
      </div>

      <p-pagination
        [totalItemsCount]="data.users.totalElements"
        [itemsPerPage]="data.users.size"
        [activePage]="data.users.pageable.pageNumber + 1"
        (update)="updatePageNo($event.detail.page)"
      />
    </ng-container>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>

<ng-template #overlaySpinner>
  <div class="overlay-spinner"><p-spinner /></div>
</ng-template>

<ng-template #emptyCell>
  <span class="empty-cell"></span>
</ng-template>
