import type { NavGroup } from './navbar.models';

export const navbarOpenerClass = 'navbar-opener';

export const navGroups: NavGroup[] = [
  {
    label: 'Overview',
    icon: 'assets/icons/overview.svg',
    open: true,
    links: [
      {
        label: 'Overview',
        url: '/overview',
      },
    ],
  },
  {
    label: 'Fun & Games',
    icon: 'assets/icons/fun-and-games.svg',
    open: true,
    links: [
      {
        label: 'My Budget and Expenses',
        url: '/fun-budget',
      },
      {
        label: 'My Events',
        url: '/my-events',
      },
      {
        label: 'All Events',
        url: '/events',
        funGamesAdmin: true,
        plusLink: {
          label: 'Create Event',
          url: '/events/create',
          funGamesAdmin: true,
        },
      },
      {
        label: 'Budget Corrections',
        url: '/budget-corrections',
        funGamesAdmin: true,
        plusLink: {
          label: 'Create Budget Correction',
          url: '/budget-corrections/create',
          funGamesAdmin: true,
        },
      },
      {
        label: 'Vendors',
        url: '/vendors',
        funGamesAdmin: true,
      },
    ],
  },
  {
    label: 'Personal Travel',
    icon: 'assets/icons/location.svg',
    open: true,
    remoteEmployee: true,
    links: [
      {
        label: 'My Budget',
        url: '/personal-travel-budget',
        remoteEmployee: true,
      },
    ],
  },
  {
    label: 'Edu & Equ',
    open: true,
    icon: 'assets/icons/education.svg',
    links: [
      {
        label: 'My Budget',
        url: '/edu-budget',
      },
      {
        label: 'All Transactions',
        url: '/edu-transactions',
        eduEquAdmin: true,
        plusLink: {
          label: 'Create Transaction',
          url: '/edu-transactions/create',
          eduEquAdmin: true,
        },
      },
      {
        label: 'Edu & Equ Categories',
        url: '/edu-categories',
        eduEquAdmin: true,
      },
      {
        label: 'Edu & Equ Purposes',
        url: '/edu-purposes',
        eduEquAdmin: true,
      },
    ],
  },
  {
    label: 'Inventory',
    open: true,
    icon: 'assets/icons/my-inventory.svg',
    links: [
      {
        label: 'My Inventory',
        url: '/my-inventory-items',
      },
      {
        label: 'All Inventory',
        url: '/inventory-items',
        inventoryAdmin: true,
        plusLink: {
          label: 'Create Inventory Item',
          url: '/inventory-items/create',
          inventoryAdmin: true,
        },
      },
      {
        label: 'All Inventory Services',
        url: '/inventory-item-services',
        inventoryAdmin: true,
        plusLink: {
          label: 'Create Inventory Item Service',
          url: '/inventory-item-services/create',
          inventoryAdmin: true,
        },
      },
      {
        label: 'Inventory Locations',
        url: '/inventory-locations',
        inventoryAdmin: true,
      },
      {
        label: 'Inventory Manufacturers',
        url: '/inventory-manufacturers',
        inventoryAdmin: true,
      },
      {
        label: 'Inventory Owners',
        url: '/inventory-owners',
        inventoryAdmin: true,
      },
      {
        label: 'Inventory Types',
        url: '/inventory-types',
        inventoryAdmin: true,
      },
      {
        label: 'Inventory Statuses',
        url: '/inventory-statuses',
        inventoryAdmin: true,
      },
    ],
  },
  {
    label: 'People',
    peopleAdmin: true,
    icon: 'assets/icons/groups2.svg',
    links: [
      {
        label: 'All Groups',
        url: '/groups',
        peopleAdmin: true,
        plusLink: {
          label: 'Create Group',
          url: '/groups/create',
          peopleAdmin: true,
        },
      },
      {
        label: 'Members',
        url: '/users',
        peopleAdmin: true,
        plusLink: {
          label: 'Create Member',
          url: '/users/create',
          peopleAdmin: true,
        },
      },
    ],
  },
  {
    label: 'Phone Numbers',
    phoneNumbersAdmin: true,
    icon: 'assets/icons/sim-card.svg',
    links: [
      {
        label: 'VPN Phone Numbers',
        url: '/vpn-phone-numbers',
        phoneNumbersAdmin: true,
        plusLink: {
          label: 'Create VPN Phone Number',
          url: '/vpn-phone-numbers/create',
          phoneNumbersAdmin: true,
        },
      },
      {
        label: 'VPN Subscription Types',
        url: '/vpn-subscription-types',
        phoneNumbersAdmin: true,
        plusLink: {
          label: 'Create VPN Subscription Type',
          url: '/vpn-subscription-types/create',
          phoneNumbersAdmin: true,
        },
      },
    ],
  },
  {
    label: 'Bank Cards',
    bankCardsAdmin: true,
    icon: 'assets/icons/credit-card.svg',
    links: [
      {
        label: 'All Bank Cards',
        url: '/bank-cards',
        bankCardsAdmin: true,
        plusLink: {
          label: 'Create Bank Card',
          url: '/bank-cards/create',
          bankCardsAdmin: true,
        },
      },
    ],
  },
  {
    label: 'Extras',
    icon: 'assets/icons/smile-circle.svg',
    open: true,
    devOnly: true,
    links: [
      {
        label: 'Salary Calculator',
        url: '/salary-calculator',
      },
    ],
  },
];
