<p-heading class="title" [size]="'x-large'" [tag]="'h3'">Members</p-heading>

<p-divider aria-hidden="true" />

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/user-add.svg'" />
      <span>Add New Member</span>
    </div>
  </p-heading>

  <ng-container *ngrxLet="createUserData$ as data; error as dataError; suspenseTpl: spinner">
    <div *ngIf="dataError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadCreateUserData()"
      >
        Reload Add New Member
      </p-button-pure>
    </div>

    <form *ngIf="!dataError && data" class="form" [formGroup]="form" (ngSubmit)="submit()">
      <p-text-field-wrapper
        *ngrxLet="form.controls.firstName as control"
        [label]="'First Name'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <input type="text" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <p-text-field-wrapper
        *ngrxLet="form.controls.lastName as control"
        [label]="'Last Name'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <input type="text" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <p-select-wrapper
        *ngrxLet="form.controls.position as control"
        [filter]="true"
        [label]="'Position'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <select [attr.required]="true" [formControl]="control">
          <option
            *ngFor="let position of data.positions; trackBy: trackPosition"
            [value]="position.id"
          >
            {{ position.name }}
          </option>
        </select>
      </p-select-wrapper>

      <p-text-field-wrapper
        *ngrxLet="form.controls.email as control"
        [label]="'Email'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <input type="email" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <div class="checkbox-control">
        <p-text>Fun & Games Budget</p-text>

        <p-checkbox-wrapper
          *ngrxLet="form.controls.doubleMonthlyBudget as control"
          [label]="'Add additional €20 per month'"
          [message]="control.errors?.['message'] ?? ''"
          [state]="control.touched && control.errors ? 'error' : 'none'"
        >
          <input type="checkbox" [formControl]="control" />
        </p-checkbox-wrapper>
      </div>

      <p-text-field-wrapper
        *ngrxLet="form.controls.initialEduEquBudget as control"
        [label]="'Initial Education & Equipment Budget'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
        [unit]="'EUR'"
        [unitPosition]="'suffix'"
      >
        <input type="number" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>

      <div class="actions">
        <p-button
          [icon]="'none'"
          [loading]="data.submitting"
          [type]="'submit'"
          [variant]="'primary'"
        >
          Add Member
        </p-button>
      </div>
    </form>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
