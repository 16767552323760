import type { User } from './user.model';

export interface EduExpense {
  id: number;
  user: EduExpenseUser;
  budget: BudgetType;
  category?: string;
  purpose?: string;
  amount: number;
  totalBudgetBefore: number;
  totalBudgetAfter: number;
  totalBudgetOverCap: number;
  dateUsed: string;
  description?: string;
  productiveLink?: string;
}

export type EduExpenseUser = Pick<User, 'id' | 'firstName' | 'lastName' | 'position' | 'active'>;

export enum BudgetType {
  EDU = 'EDU',
  EQU = 'EQU',
}

export interface ImportEduBudget {
  spreadsheet: File;
}

export interface CreateEduExpense {
  userId: number;
  budgetType: BudgetType;
  categoryId: number;
  purposeId: number;
  date: string;
  amount: number;
  description?: string;
  productiveLink?: string;
  currency?: string;
}

export interface EditEduExpense {
  userId: number;
  budgetType: BudgetType;
  categoryId: number;
  purposeId: number;
  date: string;
  amount: number;
  description?: string;
}

export interface EduExpenseListFilter {
  [EduExpenseFilters.budgetType]?: BudgetType;
  [EduExpenseFilters.expenseCategoryId]?: number;
  [EduExpenseFilters.expensePurposeId]?: number;
  [EduExpenseFilters.dateFrom]?: string;
  [EduExpenseFilters.dateTo]?: string;
  [EduExpenseFilters.name]?: string;
}

export const EduExpenseFilters = {
  budgetType: 'budgetType',
  expenseCategoryId: 'expenseCategoryId',
  expensePurposeId: 'expensePurposeId',
  dateFrom: 'dateFrom',
  dateTo: 'dateTo',
  name: 'name',
} as const;

export type EduExpenseFilter = keyof typeof EduExpenseFilters;
