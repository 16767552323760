import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type { AddGroupMembers, CreateGroup, EditGroup, Group } from '../../models/api/group.model';
import type { Page } from '../../models/api/page.model';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  private readonly httpClient = inject(HttpClient);
  private readonly groupApi = `${environment.apiUrl}/v1/groups`;

  getGroups(pageNo: number | null, name?: string): Observable<Page<Group>> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Groups');

    let params = new HttpParams();

    if (pageNo === null) params = params.set('unpaged', true);
    else params = params.set('page', pageNo).set('size', 10);

    if (name != null) params = params.set('name', name);

    return this.httpClient.get<Page<Group>>(this.groupApi, { context, params });
  }

  getMyGroups(): Observable<Group[]> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get My Groups');
    return this.httpClient.get<Group[]>(`${this.groupApi}/my-groups`, { context });
  }

  getUserGroups(userId: number): Observable<Group[]> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get User Groups');
    const params = new HttpParams().set('userId', userId);
    return this.httpClient.get<Group[]>(`${this.groupApi}/user-groups`, { context, params });
  }

  createGroup(group: CreateGroup): Observable<Group> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Create Group');
    return this.httpClient.post<Group>(this.groupApi, group, { context });
  }

  getGroup(groupId: number): Observable<Group> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Group');
    return this.httpClient.get<Group>(`${this.groupApi}/${groupId}`, { context });
  }

  editGroup(groupId: number, group: EditGroup): Observable<Group> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Edit Group');
    return this.httpClient.put<Group>(`${this.groupApi}/${groupId}`, group, { context });
  }

  addGroupMembers(groupId: number, members: AddGroupMembers): Observable<Group> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Add Group Members');
    return this.httpClient.put<Group>(`${this.groupApi}/${groupId}/members`, members, { context });
  }

  removeGroupMember(groupId: number, userId: number): Observable<void> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Remove Group Members');
    return this.httpClient.delete<void>(`${this.groupApi}/${groupId}/members/${userId}`, {
      context,
    });
  }

  deleteGroup(groupId: number): Observable<void> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Delete Group');
    return this.httpClient.delete<void>(`${this.groupApi}/${groupId}`, { context });
  }
}
