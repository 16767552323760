/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject } from '@angular/core';
import { FormBuilder, type FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  shareReplay,
  startWith,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import { objectsEqual } from '../../helpers/objects-equal.helper';
import type { Page } from '../../models/api/page.model';
import type { ControlsOf } from '../../models/controls-of.model';
import type { TableSort, TableSortDirection } from '../table-sort/table-sort.models';
import { VisibleColumnsService } from './visible-columns.service';

export abstract class PagedTableComponent<
  TResult,
  TFilter extends Record<string, any>,
  TFormValue extends Record<string, any>,
> {
  protected readonly formBuilder = inject(FormBuilder);
  protected readonly visibleColumnsService = inject(VisibleColumnsService);
  readonly router = inject(Router);
  readonly activatedRoute = inject(ActivatedRoute);

  readonly allValue = 'all' as const;

  private readonly pageNo = new BehaviorSubject(1);
  private readonly loading = new BehaviorSubject(false);
  private readonly refreshPage = new Subject<void>();
  private readonly sort = new BehaviorSubject<TableSort | undefined>(this.getDefaultSort());
  private readonly successfulFilter = new Subject<TFilter>();
  readonly setQueryParams$ = this.getSetQueryParams$();

  protected abstract buildForm(): FormGroup<ControlsOf<TFormValue>>;
  protected abstract getFilter$(): Observable<TFilter>;
  protected abstract query$(
    pageNo: number | null,
    filter?: TFilter,
    sort?: TableSort,
  ): Observable<Page<TResult>>;

  readonly loading$ = this.loading.pipe(distinctUntilChanged());
  readonly pageNo$ = this.pageNo.pipe(distinctUntilChanged());
  readonly sort$ = this.sort.pipe(distinctUntilChanged((a, b) => objectsEqual(a, b)));
  readonly form = this.buildForm();

  readonly filter$ = this.getFilter$().pipe(
    distinctUntilChanged((a, b) => objectsEqual(a, b)),
    tap(() => this.updatePageNo(1)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  results$ = this.getResult$();

  protected getDefaultSort(): TableSort | undefined {
    return undefined;
  }

  reload(): void {
    this.results$ = this.getResult$();
  }

  refresh(): void {
    this.refreshPage.next();
  }

  resetFilters(): void {
    this.sort.next(undefined);
    this.form.reset();
  }

  clearFilter(filterKey: string): void {
    this.form.get(filterKey)?.reset();
  }

  resetSort(): void {
    this.sort.next(undefined);
  }

  updateSort(sortId: string, direction: TableSortDirection | undefined): void {
    if (!direction) this.sort.next(undefined);
    else this.sort.next({ sortId, direction });
  }

  updatePageNo(page: number): void {
    this.pageNo.next(page);
  }

  updateLoading(loading: boolean): void {
    this.loading.next(loading);
  }

  getResult$(): Observable<Page<TResult>> {
    const obs = combineLatest([
      this.pageNo$,
      this.filter$,
      this.sort$,
      this.refreshPage.pipe(startWith(undefined)),
    ]).pipe(
      debounceTime(0),
      tap(() => this.updateLoading(true)),
      switchMap(([pageNo, filter, sort]) =>
        this.query$(pageNo, filter, sort).pipe(tap(() => this.successfulFilter.next(filter))),
      ),
      tap({
        next: () => this.updateLoading(false),
        error: () => this.updateLoading(false),
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    return obs;
  }

  isColumnVisible$(tableName: string, columnName: string): Observable<boolean> {
    return this.visibleColumnsService.isColumnVisible$(tableName, columnName);
  }

  private getSetQueryParams$(): Observable<TFilter> {
    return this.successfulFilter.pipe(
      distinctUntilChanged((a, b) => objectsEqual(a, b)),
      tap((filter) => {
        const filterWithValues = Object.entries(filter)
          .filter(([, value]) => value)
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: filterWithValues,
        });
      }),
    );
  }
}
