import { CommonModule } from '@angular/common';
import { Component, inject, type OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, tap } from 'rxjs';
import { SubSink } from 'subsink';
import type { CreateVpnSubscriptionType } from '../../shared/models/api/vpn-subscription-type.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { VpnSubscriptionTypeService } from '../../shared/services/api/vpn-subscription-type.service';
import { OpaValidators } from '../../shared/validators/opa.validators';
import type { CreateVpnSubscriptionTypeFormValue } from './vpn-subscription-type-create.models';

@Component({
  selector: 'opa-vpn-subscription-type-create',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, ReactiveFormsModule, LetDirective],
  templateUrl: './vpn-subscription-type-create.component.html',
  styleUrl: './vpn-subscription-type-create.component.scss',
})
export class VpnSubscriptionTypeCreateComponent implements OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly vpnSubscriptionTypeService = inject(VpnSubscriptionTypeService);
  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.asObservable();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const vpnSubscriptionType = this.formValueToVpnSubscriptionType(formValue);
    this.subSink.sink = this.vpnSubscriptionTypeService
      .createVpnSubscriptionType(vpnSubscriptionType)
      .pipe(
        tap({
          next: (createdVpnSubscriptionType) => {
            this.toastManager.addMessage({
              text: `VPN Subscription Type ${createdVpnSubscriptionType.name} created`,
              state: 'success',
            });
            this.router.navigate(['/vpn-subscription-types']);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<CreateVpnSubscriptionTypeFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<CreateVpnSubscriptionTypeFormValue>>(
      {
        name: this.formBuilder.control(null, OpaValidators.required()),
        cost: this.formBuilder.control(null, [
          OpaValidators.required(),
          OpaValidators.min(0),
          OpaValidators.maxDecimals(2),
        ]),
        allowedMonthlyOverage: this.formBuilder.control(null, [
          OpaValidators.required(),
          OpaValidators.min(0),
          OpaValidators.maxDecimals(2),
        ]),
      },
    );
    return form;
  }

  private formValueToVpnSubscriptionType(
    form: CreateVpnSubscriptionTypeFormValue,
  ): CreateVpnSubscriptionType {
    const vpnSubscriptionType: CreateVpnSubscriptionType = {
      name: form.name!,
      cost: form.cost!,
      allowedMonthlyOverage: form.allowedMonthlyOverage!,
    };
    return vpnSubscriptionType;
  }
}
