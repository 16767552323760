import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { distinctUntilChanged, filter, map, shareReplay, type Observable } from 'rxjs';
import { appTitle } from '../../constants/app-title.constant';
import { FocusOutsideDirective } from '../../directives/focus-outside.directive';
import type { User } from '../../models/api/user.model';
import { UserService } from '../../services/api/user.service';
import { AuthService } from '../../services/auth.service';
import { UserIconComponent } from '../user-icon/user-icon.component';
import { AddMonthlyIncomeComponent } from './add-monthly-income/add-monthly-income.component';
import { FilterNavLinkPipe } from './filter-nav-link.pipe';
import { navGroups, navbarOpenerClass } from './navbar.constants';
import type { NavGroup, NavLink } from './navbar.models';
import { NavbarService } from './navbar.service';

@Component({
  selector: 'opa-navbar',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    FocusOutsideDirective,
    UserIconComponent,
    FilterNavLinkPipe,
    RouterLink,
    RouterLinkActive,
    LetDirective,
    AddMonthlyIncomeComponent,
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  private readonly navbarService = inject(NavbarService);
  private readonly userService = inject(UserService);
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);

  readonly appTitle = appTitle;
  readonly navbarOpenerClass = navbarOpenerClass;
  readonly navbarOpen$ = this.navbarService.isOpen$();
  me$ = this.getMe$();
  readonly isSuperAdmin = this.authService.isSuperAdmin();
  private readonly isNotAdmin = this.authService.isNotAdmin();
  readonly navGroups$ = this.getNavGroups$();
  userOpen = false;
  showAddMonthlyIncomeModal = false;

  closeNavbar(): void {
    this.navbarService.toggle(false);
  }

  logout(): void {
    this.authService.logout();
  }

  reloadMe(): void {
    this.me$ = this.getMe$();
  }

  trackNavGroup(index: number, group: NavGroup): string {
    return group.label;
  }

  trackNavLink(index: number, link: NavLink): string {
    return link.label;
  }

  getNavGroups$(): Observable<NavGroup[]> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => (event as NavigationEnd).url),
      distinctUntilChanged(),
      map(() => this.getOpenNavGroups()),
    );
  }

  private getMe$(): Observable<User> {
    return this.userService.getMe().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  private getOpenNavGroups(): NavGroup[] {
    navGroups.forEach((group) => {
      group.open = this.isNotAdmin
        ? group.open || this.isGroupLinkOpen(group)
        : this.isGroupLinkOpen(group);
    });

    return navGroups;
  }

  private isGroupLinkOpen(group: NavGroup): boolean {
    return group.links.some((link) =>
      this.router.isActive(link.url, {
        paths: 'subset',
        queryParams: 'ignored',
        fragment: 'ignored',
        matrixParams: 'ignored',
      }),
    );
  }
}
