import { Pipe, type PipeTransform } from '@angular/core';
import type { User } from '../../shared/models/api/user.model';

@Pipe({
  name: 'opaFilterSelectedUsers',
  standalone: true,
})
export class FilterSelectedUsersPipe implements PipeTransform {
  transform(users: User[], selectedUserIds: string | null): User[] {
    const selected = selectedUserIds?.split(',') ?? [];
    return users.filter((user) => !selected.includes(`${user.id}`));
  }
}
