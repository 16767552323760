import type { InventoryFilters } from '../../shared/models/api/inventory-item.model';
import type { InventoryLocation } from '../../shared/models/api/inventory-location.model';
import type { InventoryManufacturer } from '../../shared/models/api/inventory-manufacturer.model';
import type { InventoryOwner } from '../../shared/models/api/inventory-owner.model';
import type { InventoryStatus } from '../../shared/models/api/inventory-status.model';
import type { InventoryType } from '../../shared/models/api/inventory-type.model';
import type { User } from '../../shared/models/api/user.model';
import type { NumberComparator } from '../../shared/models/number-comparator.model';

export interface InventoryItemsData {
  manufacturers: InventoryManufacturer[];
  types: InventoryType[];
  owners: InventoryOwner[];
  locations: InventoryLocation[];
  assignees: User[];
  statuses: InventoryStatus[];
}

export interface InventoryItemListFormValue {
  [InventoryFilters.manufacturerId]: number | 'all' | null;
  [InventoryFilters.model]: string | null;
  [InventoryFilters.specification]: string | null;
  [InventoryFilters.typeId]: number | 'all' | null;
  [InventoryFilters.serialNumber]: string | null;
  [InventoryFilters.imei]: string | null;
  [InventoryFilters.ownerId]: number | 'all' | null;
  [InventoryFilters.locationId]: number | 'all' | null;
  [InventoryFilters.warrantyExpirationFrom]: string | null;
  [InventoryFilters.warrantyExpirationTo]: string | null;
  [InventoryFilters.contractNumber]: string | null;
  [InventoryFilters.price]: number | null;
  [InventoryFilters.assigneeId]: number | 'all' | null;
  [InventoryFilters.statusId]: number | 'all' | null;
  [InventoryFilters.note]: string | null;
  [InventoryFilters.priceComparisonType]: NumberComparator | null;
}

export const InventoryColumns = {
  id: 'id',
  manufacturerId: 'manufacturerId',
  model: 'model',
  specification: 'specification',
  typeId: 'typeId',
  serialNumber: 'serialNumber',
  imei: 'imei',
  ownerId: 'ownerId',
  locationId: 'locationId',
  warrantyExpiration: 'warrantyExpiration',
  contractNumber: 'contractNumber',
  price: 'price',
  assigneeId: 'assigneeId',
  statusId: 'statusId',
  note: 'note',
  serviceNo: 'serviceNo',
  action: 'action',
} as const;

export type InventoryColumn = keyof typeof InventoryColumns;
