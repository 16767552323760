import { Injectable, NgZone, inject, type OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  auditTime,
  distinctUntilChanged,
  fromEvent,
  map,
  merge,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import { PdsBreakpoints, type PdsBreakpoint } from '../constants/breakpoints.constants';

@Injectable({
  providedIn: 'root',
})
export class MediaQueryService implements OnDestroy {
  private readonly ngZone = inject(NgZone);
  private readonly subSink = new SubSink();
  private readonly screenWidth$ = new BehaviorSubject(window.innerWidth);

  constructor() {
    this.ngZone.runOutsideAngular(() => {
      this.subSink.sink = merge(
        fromEvent(window, 'resize').pipe(auditTime(25)),
        fromEvent(window, 'orientationchange'),
      )
        .pipe(tap(() => this.ngZone.run(() => this.screenWidth$.next(window.innerWidth))))
        .subscribe();
    });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  min(min: PdsBreakpoint): Observable<boolean> {
    return this.screenWidth$.pipe(
      map((width) => width >= PdsBreakpoints[min]),
      distinctUntilChanged(),
    );
  }

  max(max: PdsBreakpoint): Observable<boolean> {
    return this.screenWidth$.pipe(
      map((width) => width < PdsBreakpoints[max]),
      distinctUntilChanged(),
    );
  }

  minMax(min: PdsBreakpoint, max: PdsBreakpoint): Observable<boolean> {
    return this.screenWidth$.pipe(
      map((width) => width >= PdsBreakpoints[min] && width < PdsBreakpoints[max]),
      distinctUntilChanged(),
    );
  }
}
