<p-banner
  *ngrxLet="errors$ as errors"
  [open]="errors.length > 0"
  [state]="'error'"
  (dismiss)="dismiss()"
>
  <div class="description" slot="description">
    <div *ngFor="let error of errors; trackBy: trackError">
      <p-heading [size]="'small'" [tag]="'h5'">{{ error.title }}</p-heading>
      <p-text>{{ error.message }}</p-text>
    </div>
  </div>
</p-banner>
