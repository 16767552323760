import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type { Page } from '../../models/api/page.model';
import type {
  CreateVpnSubscriptionType,
  EditVpnSubscriptionType,
  VpnSubscriptionType,
} from '../../models/api/vpn-subscription-type.model';

@Injectable({
  providedIn: 'root',
})
export class VpnSubscriptionTypeService {
  private readonly httpClient = inject(HttpClient);
  private readonly vpnSubscriptionTypeApi = `${environment.apiUrl}/v1/vpn-subscription-types`;

  getVpnSubscriptionTypes(
    pageNo: number | null,
    name?: string,
  ): Observable<Page<VpnSubscriptionType>> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Get VPN Subscription Types',
    );

    let params = new HttpParams();

    if (pageNo === null) params = params.set('unpaged', true);
    else params = params.set('page', pageNo).set('size', 10);

    if (name != null) params = params.set('name', name);

    return this.httpClient.get<Page<VpnSubscriptionType>>(this.vpnSubscriptionTypeApi, {
      context,
      params,
    });
  }

  createVpnSubscriptionType(
    vpnSubscriptionType: CreateVpnSubscriptionType,
  ): Observable<VpnSubscriptionType> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Create VPN Subscription Type',
    );
    return this.httpClient.post<VpnSubscriptionType>(
      this.vpnSubscriptionTypeApi,
      vpnSubscriptionType,
      { context },
    );
  }

  editVpnSubscriptionType(
    vpnSubscriptionTypeId: number,
    vpnSubscriptionType: EditVpnSubscriptionType,
  ): Observable<VpnSubscriptionType> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Edit VPN Subscription Type',
    );
    return this.httpClient.put<VpnSubscriptionType>(
      `${this.vpnSubscriptionTypeApi}/${vpnSubscriptionTypeId}`,
      vpnSubscriptionType,
      { context },
    );
  }
}
