import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  type OnChanges,
  type OnDestroy,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { SubSink } from 'subsink';
import type {
  EditInventoryType,
  InventoryType,
} from '../../../shared/models/api/inventory-type.model';
import type { ControlsOf } from '../../../shared/models/controls-of.model';
import type { NgChanges } from '../../../shared/models/ng-changes.model';
import { InventoryTypeService } from '../../../shared/services/api/inventory-type.service';
import { OpaValidators } from '../../../shared/validators/opa.validators';
import type { EditInventoryTypeFormValue } from './inventory-type-edit.models';

@Component({
  selector: 'opa-inventory-type-edit',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    ReactiveFormsModule,
    LetDirective,
    TranslocoPipe,
  ],
  templateUrl: './inventory-type-edit.component.html',
  styleUrl: './inventory-type-edit.component.scss',
})
export class InventoryTypeEditComponent implements OnChanges, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly inventoryTypeService = inject(InventoryTypeService);
  private readonly toastManager = inject(ToastManager);

  @Input({ required: true }) open = false;
  @Input({ required: true }) inventoryType: InventoryType | undefined = undefined;
  @Output() dismiss = new EventEmitter<boolean | undefined>();

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.pipe(distinctUntilChanged());

  ngOnChanges(changes: NgChanges<InventoryTypeEditComponent>): void {
    if (changes.open && this.open) this.resetForm(this.inventoryType);
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(success?: boolean): void {
    this.dismiss.emit(success);
  }

  submit(): void {
    if (!this.form.valid || !this.inventoryType) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const type = this.formValueToType(formValue);
    this.subSink.sink = this.inventoryTypeService
      .editInventoryType(this.inventoryType.id, type)
      .pipe(
        tap({
          next: (editedType) => {
            this.toastManager.addMessage({
              text: `Type '${editedType.description}' edited`,
              state: 'success',
            });
            this.submitting.next(false);
            this.close(true);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<EditInventoryTypeFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<EditInventoryTypeFormValue>>({
      description: this.formBuilder.control(null, [OpaValidators.required()]),
      imeiRequired: this.formBuilder.control(null),
    });

    return form;
  }

  private formValueToType(form: EditInventoryTypeFormValue): EditInventoryType {
    const type: EditInventoryType = {
      description: form.description!,
      imeiRequired: !!form.imeiRequired,
      name: this.inventoryType!.name,
    };
    return type;
  }

  private resetForm(inventoryType?: InventoryType): void {
    this.form.reset();
    if (!inventoryType) return;

    this.form.patchValue({
      description: inventoryType.description,
      imeiRequired: inventoryType.imeiRequired,
    });
  }
}
