import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import type { TableSortDirection } from './table-sort.models';

@Component({
  selector: 'opa-table-sort',
  standalone: true,
  imports: [PorscheDesignSystemModule],
  templateUrl: './table-sort.component.html',
  styleUrl: './table-sort.component.scss',
})
export class TableSortComponent {
  @Input({ required: true }) direction: TableSortDirection | undefined = undefined;
  @Output() readonly updateSort = new EventEmitter<TableSortDirection | undefined>();

  changeSort(): void {
    if (!this.direction) this.direction = 'asc';
    else if (this.direction === 'asc') this.direction = 'desc';
    else this.direction = undefined;
    this.updateSort.emit(this.direction);
  }
}
