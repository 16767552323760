import {
  HttpContextToken,
  type HttpErrorResponse,
  type HttpInterceptorFn,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import type { ErrorBannerItem } from '../components/error-banner/error-banner.models';
import { ErrorBannerService } from '../components/error-banner/error-banner.service';
import type { HttpErrorContent } from '../models/http-error.model';

export const ACTION_ERROR_TITLE = new HttpContextToken<string>(() => 'HTTP error');

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const url = req.url;
  if (url.startsWith(environment.auth.issuer)) return next(req);

  if (!url.startsWith(environment.apiUrl)) return next(req);

  const errorBannerService = inject(ErrorBannerService);
  return next(req).pipe(
    tap({
      error: (e: unknown) => {
        const response = e as HttpErrorResponse | undefined;
        const error = response?.error as HttpErrorContent | undefined;
        const message = error?.message ?? '';
        const formatted: ErrorBannerItem = {
          message,
          title: req.context.get(ACTION_ERROR_TITLE),
        };
        errorBannerService.add(formatted);
      },
    }),
  );
};
