import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, type OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import { GroupType, type CreateGroup } from '../../shared/models/api/group.model';
import type { User } from '../../shared/models/api/user.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { FindUserPipe } from '../../shared/pipes/find-user.pipe';
import { GroupService } from '../../shared/services/api/group.service';
import { UserService } from '../../shared/services/api/user.service';
import { OpaValidators } from '../../shared/validators/opa.validators';
import { FilterSelectedUsersPipe } from '../event-create/filter-selected-users.pipe';
import { JoinUsersPipe } from '../event-create/join-users.pipe';
import { StringSplitPipe } from '../event-create/string-split.pipe';
import { groupTypeTranslation } from '../group-list/group-list.helpers';
import type { CreateGroupData, CreateGroupFormValue } from './group-create.models';

@Component({
  selector: 'opa-group-create',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    ReactiveFormsModule,
    LetDirective,
    TranslocoPipe,
    FilterSelectedUsersPipe,
    JoinUsersPipe,
    StringSplitPipe,
    FindUserPipe,
  ],
  templateUrl: './group-create.component.html',
  styleUrl: './group-create.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupCreateComponent implements OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly groupService = inject(GroupService);
  private readonly userService = inject(UserService);
  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);
  readonly groupTypes = Object.values(GroupType);
  readonly groupTypeTranslation = groupTypeTranslation;

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  createGroupData$ = this.getCreateGroupData$();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  reloadCreateGroupData(): void {
    this.createGroupData$ = this.getCreateGroupData$();
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  removeMember(userId: string): void {
    // TODO: move to previous or next participant or back to first focusable element before this button
    const control = this.form.controls.members;
    const selected = control.value?.split(',') ?? [];
    const value = selected.filter((id) => id !== userId).join(',') || null;
    control.patchValue(value);
    control.markAsTouched();
    control.markAsDirty();
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const group = this.formValueToGroup(formValue);
    this.subSink.sink = this.groupService
      .createGroup(group)
      .pipe(
        tap({
          next: (createdGroup) => {
            this.toastManager.addMessage({
              text: `Group ${createdGroup.name} created`,
              state: 'success',
            });
            this.router.navigate(['/groups']);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<CreateGroupFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<CreateGroupFormValue>>({
      name: this.formBuilder.control(null, OpaValidators.required()),
      type: this.formBuilder.control(null, OpaValidators.required()),
      members: this.formBuilder.control(null, OpaValidators.required()),
    });
    return form;
  }

  private formValueToGroup(form: CreateGroupFormValue): CreateGroup {
    const group: CreateGroup = {
      name: form.name!,
      type: form.type!,
      members: form.members!.split(',').map((id) => Number(id)),
    };
    return group;
  }

  private getCreateGroupData$(): Observable<CreateGroupData> {
    const users$ = this.userService.getActiveUsers();
    const submitting$ = this.submitting.pipe(distinctUntilChanged());
    return combineLatest([users$, submitting$]).pipe(
      map(([users, submitting]) => ({ users, submitting })),
    );
  }
}
