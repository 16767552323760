import {
  EduExpenseFilters,
  type EduExpenseFilter,
} from '../../shared/models/api/edu-expense.model';

export const eduExpenseFilterTranslation: Readonly<Record<EduExpenseFilter, string>> = {
  [EduExpenseFilters.budgetType]: 'EDU.FILTERS.BUDGET_TYPE',
  [EduExpenseFilters.expenseCategoryId]: 'EDU.FILTERS.EXPENSE_CATEGORY',
  [EduExpenseFilters.expensePurposeId]: 'EDU.FILTERS.EXPENSE_PURPOSE',
  [EduExpenseFilters.dateFrom]: 'EDU.FILTERS.DATE_FROM',
  [EduExpenseFilters.dateTo]: 'EDU.FILTERS.DATE_TO',
  [EduExpenseFilters.name]: 'EDU.FILTERS.NAME',
};
