import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  distinctUntilChanged,
  EMPTY,
  merge,
  Observable,
  of,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { SubSink } from 'subsink';
import { objectsEqual } from '../../shared/helpers/objects-equal.helper';
import { InventoryItemServiceEntry } from '../../shared/models/api/inventory-item-service.model';
import { InventoryItemServiceService } from '../../shared/services/api/inventory-item-service.service';
import { AuthService } from '../../shared/services/auth.service';
import { MediaQueryService } from '../../shared/services/media-query.service';

@Component({
  selector: 'opa-inventory-item-service-details',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, RouterLink, LetDirective],
  templateUrl: './inventory-item-service-details.component.html',
  styleUrl: './inventory-item-service-details.component.scss',
})
export class InventoryItemServiceDetailsComponent implements OnDestroy {
  readonly mediaMaxXs$ = inject(MediaQueryService).max('xs');
  readonly isInventoryAdmin = inject(AuthService).isInventoryAdmin();
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly route = inject(ActivatedRoute);
  private readonly inventoryItemServiceService = inject(InventoryItemServiceService);
  private readonly subSink = new SubSink();

  inventoryItemService$ = this.getInventoryItemService$();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  reloadInventoryItemService(markForCheck = false): void {
    this.inventoryItemService$ = this.getInventoryItemService$();
    if (markForCheck) this.changeDetectorRef.markForCheck();
  }

  downloadServiceDocument(serviceId: number): void {
    this.subSink.sink = this.inventoryItemServiceService
      .downloadServiceDocument(serviceId)
      .subscribe();
  }

  private getInventoryItemService$(): Observable<InventoryItemServiceEntry> {
    const routeParams$ = merge(
      of(this.route.snapshot.params),
      this.route.params.pipe(
        startWith(this.route.snapshot.params),
        distinctUntilChanged((a, b) => objectsEqual(a, b)),
        skip(1),
        tap(() => this.reloadInventoryItemService(true)),
        switchMap(() => EMPTY),
      ),
    );

    return routeParams$.pipe(
      switchMap((routeParams) => {
        const inventoryItemId = Number(routeParams['id']);
        return this.inventoryItemServiceService.getInventoryItemService(inventoryItemId);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
