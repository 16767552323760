<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Members</p-heading>

  <p-link
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/users/create'">Add New Member</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="section">
  <div *ngrxLet="user$ | async as user" class="breadcrumbs">
    <div class="name">
      <p-link-pure [hideLabel]="true" [iconSource]="'assets/icons/arrow-left.svg'">
        <a [routerLink]="'/users'">All Members</a>
      </p-link-pure>

      <p-heading [size]="'large'" [tag]="'h4'">
        @if (user) {
          {{ user.firstName }} {{ user.lastName }}
        } @else {
          &nbsp;
        }
      </p-heading>
    </div>

    <ng-container *ngIf="user">
      <p-divider aria-hidden="true" class="divider" [direction]="'vertical'" />

      <p-text [size]="'x-small'">{{ user.position.name }}</p-text>

      <p-divider aria-hidden="true" class="divider" [direction]="'vertical'" />

      <div class="status-box">
        <div
          class="status"
          [ngClass]="{
            'status--active': user.active,
            'status--inactive': !user.active
          }"
        ></div>
        <p-text [size]="'x-small'">{{ user.active ? 'Active' : 'Inactive' }}</p-text>
      </div>

      <p-divider aria-hidden="true" class="divider" [direction]="'vertical'" />

      <p-button-pure
        [iconSource]="'assets/icons/edit.svg'"
        [size]="'x-small'"
        [underline]="true"
        (click)="showEditModal = true"
      >
        Edit Member
      </p-button-pure>
    </ng-container>
  </div>
</section>

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/info.svg'" />
      <span>Member Details</span>
    </div>
  </p-heading>

  <ng-container *ngrxLet="user$ as user; error as userError; suspenseTpl: spinner">
    <div *ngIf="userError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadUser()"
      >
        Reload Member Details
      </p-button-pure>
    </div>

    <div *ngIf="!userError && user" class="member-details">
      <div>
        <p-text [weight]="'semi-bold'">Dedicated Budget:</p-text>
        <p-text>{{ user.monthlyTeamBudget | currency: 'EUR' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Fun & Games Budget:</p-text>
        <p-text>{{ user.teamBudget | currency: 'EUR' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Remote Location:</p-text>
        <p-text *ngIf="user.remoteEmployee">
          {{ user.remoteLocation?.name }}
        </p-text>
        <p-text *ngIf="!user.remoteEmployee">-</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Personal Travel Budget:</p-text>
        <p-text *ngIf="user.remoteEmployee">
          {{ user.personalTravelBudget | currency: 'EUR' }}
        </p-text>
        <p-text *ngIf="!user.remoteEmployee">-</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Max Education Budget:</p-text>
        <p-text>{{ user.eduEquBudgetStatus.maxEducationBudgetRemaining | currency: 'EUR' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Max Equipment Budget:</p-text>
        <p-text>{{ user.eduEquBudgetStatus.maxEquipmentBudgetRemaining | currency: 'EUR' }}</p-text>
      </div>

      <div *ngIf="groups$ | async as groups">
        <p-text [weight]="'semi-bold'">Groups:</p-text>
        <p-text *ngIf="!groups.length">None</p-text>
        <p-table *ngIf="groups.length" caption="Group list table">
          <p-table-head>
            <p-table-head-row>
              <p-table-head-cell style="width: 50%">Name</p-table-head-cell>
              <p-table-head-cell style="width: 50%">Type</p-table-head-cell>
            </p-table-head-row>
          </p-table-head>

          <p-table-body>
            <p-table-row *ngFor="let group of groups; trackBy: trackGroup">
              <p-table-cell>
                <a [routerLink]="['/groups', group.id]">{{ group.name }}</a>
              </p-table-cell>
              <p-table-cell>
                {{ groupTypeTranslation[group.type] | transloco }}
              </p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>
      </div>
    </div>
  </ng-container>
</section>

<opa-user-edit
  [open]="showEditModal"
  [user]="(user$ | async) ?? undefined"
  (dismiss)="showEditModal = false; $event && reloadUser(false, $event)"
/>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
