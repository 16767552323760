import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type {
  CreateInventoryType,
  EditInventoryType,
  InventoryType,
} from '../../models/api/inventory-type.model';

@Injectable({
  providedIn: 'root',
})
export class InventoryTypeService {
  private readonly httpClient = inject(HttpClient);
  private readonly api = `${environment.apiUrl}/v1/codebooks/inventory-type`;

  getInventoryTypes(): Observable<InventoryType[]> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Inventory Types');

    return this.httpClient.get<InventoryType[]>(this.api, { context });
  }

  createInventoryType(type: CreateInventoryType): Observable<InventoryType> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Create Inventory Type');

    return this.httpClient.post<InventoryType>(this.api, type, { context });
  }

  deleteInventoryType(id: number): Observable<void> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Delete Inventory Type. Please make sure there are no inventory items associated with this type.',
    );

    return this.httpClient.delete<void>(`${this.api}/${id}`, { context });
  }

  editInventoryType(id: number, type: EditInventoryType): Observable<InventoryType> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Edit Inventory Type');

    return this.httpClient.put<InventoryType>(`${this.api}/${id}`, type, { context });
  }
}
