import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type {
  BudgetCorrection,
  CreateBudgetCorrection,
} from '../../models/api/budget-correction.model';
import type { Page } from '../../models/api/page.model';

@Injectable({
  providedIn: 'root',
})
export class BudgetCorrectionService {
  private readonly httpClient = inject(HttpClient);
  private readonly budgetCorrectionApi = `${environment.apiUrl}/v1/budget-corrections`;

  getBudgetCorrections(pageNo: number): Observable<Page<BudgetCorrection>> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Budget Corrections');
    const params = new HttpParams().set('page', pageNo).set('size', 10);
    return this.httpClient.get<Page<BudgetCorrection>>(this.budgetCorrectionApi, {
      context,
      params,
    });
  }

  createBudgetCorrection(budgetCorrection: CreateBudgetCorrection): Observable<BudgetCorrection> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Create Budget Correction');
    return this.httpClient.post<BudgetCorrection>(this.budgetCorrectionApi, budgetCorrection, {
      context,
    });
  }

  getBudgetCorrection(budgetCorrectionId: number): Observable<BudgetCorrection> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Budget Correction');
    return this.httpClient.get<BudgetCorrection>(
      `${this.budgetCorrectionApi}/${budgetCorrectionId}`,
      { context },
    );
  }
}
