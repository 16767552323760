import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  shareReplay,
  startWith,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import type { Page } from '../../shared/models/api/page.model';
import type { VpnSubscriptionType } from '../../shared/models/api/vpn-subscription-type.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { FillArrayPipe } from '../../shared/pipes/fill-array.pipe';
import { VpnSubscriptionTypeService } from '../../shared/services/api/vpn-subscription-type.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { VpnSubscriptionTypeEditComponent } from './vpn-subscription-type-edit/vpn-subscription-type-edit.component';
import type { VpnSubscriptionTypeListFormValue } from './vpn-subscription-type-list.models';

@Component({
  selector: 'opa-vpn-subscription-type-list',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    ReactiveFormsModule,
    LetDirective,
    FillArrayPipe,
    VpnSubscriptionTypeEditComponent,
  ],
  templateUrl: './vpn-subscription-type-list.component.html',
  styleUrl: './vpn-subscription-type-list.component.scss',
})
export class VpnSubscriptionTypeListComponent {
  private readonly mediaQueryService = inject(MediaQueryService);
  private readonly vpnSubscriptionTypeService = inject(VpnSubscriptionTypeService);
  private readonly formBuilder = inject(FormBuilder);

  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  private readonly pageNo = new BehaviorSubject(1);
  readonly form = this.buildForm();
  private readonly loading = new BehaviorSubject(false);
  readonly loading$ = this.loading.pipe(distinctUntilChanged());
  vpnSubscriptionTypes$ = this.getVpnSubscriptionTypes$();
  showVpnSubscriptionTypeModal = false;
  editVpnSubscriptionType: VpnSubscriptionType | undefined = undefined;

  reloadVpnSubscriptionTypes(): void {
    this.vpnSubscriptionTypes$ = this.getVpnSubscriptionTypes$();
  }

  trackRow(index: number): number {
    return index;
  }

  updatePageNo(page: number): void {
    this.pageNo.next(page);
  }

  private buildForm(): FormGroup<ControlsOf<VpnSubscriptionTypeListFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<VpnSubscriptionTypeListFormValue>>({
      name: this.formBuilder.control(null),
    });
    return form;
  }

  private getVpnSubscriptionTypes$(): Observable<Page<VpnSubscriptionType>> {
    const pageNo$ = this.pageNo.pipe(distinctUntilChanged());

    const form$ = merge(
      this.form.controls.name.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)),
    ).pipe(
      startWith(undefined),
      map(() => this.form.getRawValue()),
      tap(() => this.updatePageNo(1)),
    );

    return combineLatest([pageNo$, form$]).pipe(
      debounceTime(0),
      tap(() => this.loading.next(true)),
      switchMap(([pageNo, form]) => {
        const name = form.name ?? undefined;
        return this.vpnSubscriptionTypeService.getVpnSubscriptionTypes(pageNo, name);
      }),
      tap({
        next: () => this.loading.next(false),
        error: () => this.loading.next(false),
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
