import { CommonModule } from '@angular/common';
import { Component, inject, type OnDestroy } from '@angular/core';
import { FormBuilder, type FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, tap } from 'rxjs';
import { SubSink } from 'subsink';
import type { ImportEduBudget } from '../../shared/models/api/edu-expense.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { EduExpenseService } from '../../shared/services/api/edu-expense.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import type { ImportEduBudgetFormValue } from './edu-budget-import.models';

@Component({
  selector: 'opa-edu-budget-import',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, RouterLink, ReactiveFormsModule],
  templateUrl: './edu-budget-import.component.html',
  styleUrl: './edu-budget-import.component.scss',
})
export class EduBudgetImportComponent implements OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly eduExpenseService = inject(EduExpenseService);
  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);

  readonly mediaMaxXs$ = inject(MediaQueryService).max('xs');
  private readonly subSink = new SubSink();
  private readonly downloading = new BehaviorSubject(false);
  readonly downloading$ = this.downloading.asObservable();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.asObservable();
  readonly form = this.buildForm();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  downloadTemplate(): void {
    this.downloading.next(true);
    this.subSink.sink = this.eduExpenseService
      .downloadEduBudgetImportTemplate()
      .pipe(
        tap({
          next: () => {
            this.toastManager.addMessage({
              text: 'Edu Budget Import Template downloaded',
              state: 'success',
            });
            this.downloading.next(false);
          },
          error: () => this.downloading.next(false),
        }),
      )
      .subscribe();
  }

  fileChange(event: Event, controlName: keyof ImportEduBudgetFormValue): void {
    if (!this.form) return;
    const target = event.target as HTMLInputElement;
    if (!target.files?.length) return;

    this.form.patchValue({ [controlName]: target.files[0] });
  }

  submit(): void {
    if (!this.form) return;

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const items = this.formValueToItems(formValue);
    this.subSink.sink = this.eduExpenseService
      .importEduBudget(items)
      .pipe(
        tap({
          next: () => {
            this.toastManager.addMessage({
              text: 'Edu Budget imported',
              state: 'success',
            });
            this.router.navigate(['/edu-transactions']);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<ImportEduBudgetFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<ImportEduBudgetFormValue>>({
      spreadsheet: this.formBuilder.nonNullable.control(null, Validators.required),
    });
    return form;
  }

  private formValueToItems(formValue: ImportEduBudgetFormValue): ImportEduBudget {
    const items: ImportEduBudget = {
      spreadsheet: formValue.spreadsheet!,
    };
    return items;
  }
}
