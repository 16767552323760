import { CommonModule } from '@angular/common';
import { Component, inject, type OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import { EventType, type CreateEvent } from '../../shared/models/api/event.model';
import type { Group } from '../../shared/models/api/group.model';
import type { User } from '../../shared/models/api/user.model';
import type { Vendor } from '../../shared/models/api/vendor.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { FindUserPipe } from '../../shared/pipes/find-user.pipe';
import { EventService } from '../../shared/services/api/event.service';
import { GroupService } from '../../shared/services/api/group.service';
import { UserService } from '../../shared/services/api/user.service';
import { VendorService } from '../../shared/services/api/vendor.service';
import { OpaValidators } from '../../shared/validators/opa.validators';
import { VendorCreateComponent } from '../vendor-list/vendor-create/vendor-create.component';
import { eventTypeTranslation } from './event-create.helpers';
import type { CreateEventData, CreateEventFormValue } from './event-create.models';
import { FilterEmptyGroupsPipe } from './filter-empty-groups.pipe';
import { FilterSelectedUsersPipe } from './filter-selected-users.pipe';
import { JoinUsersPipe } from './join-users.pipe';
import { StringSplitPipe } from './string-split.pipe';

@Component({
  selector: 'opa-event-create',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    ReactiveFormsModule,
    LetDirective,
    TranslocoPipe,
    FilterEmptyGroupsPipe,
    FilterSelectedUsersPipe,
    JoinUsersPipe,
    StringSplitPipe,
    FindUserPipe,
    VendorCreateComponent,
  ],
  templateUrl: './event-create.component.html',
  styleUrl: './event-create.component.scss',
})
export class EventCreateComponent implements OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly vendorService = inject(VendorService);
  private readonly groupService = inject(GroupService);
  private readonly userService = inject(UserService);
  private readonly eventService = inject(EventService);
  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);
  readonly eventTypes = Object.values(EventType);
  readonly eventTypeTranslation = eventTypeTranslation;

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly refreshVendors = new BehaviorSubject<void>(undefined);
  readonly textareaMaxLength = 255;
  private readonly submitting = new BehaviorSubject(false);
  createEventData$ = this.getCreateEventData$();
  showVendorModal = false;

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  reloadCreateEventData(): void {
    this.createEventData$ = this.getCreateEventData$();
  }

  trackVendor(index: number, vendor: Vendor): number {
    return vendor.id;
  }

  reloadVendors(): void {
    this.refreshVendors.next();
  }

  trackGroup(index: number, group: Group): number {
    return group.id;
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  removeParticipant(userId: string): void {
    // TODO: move to previous or next participant or back to first focusable element before this button
    const control = this.form.controls.participants;
    const selected = control.value?.split(',') ?? [];
    const value = selected.filter((id) => id !== userId).join(',') || null;
    control.patchValue(value);
    control.markAsTouched();
    control.markAsDirty();
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const event = this.formValueToEvent(formValue);
    this.subSink.sink = this.eventService
      .createEvent(event)
      .pipe(
        tap({
          next: (createdEvent) => {
            this.toastManager.addMessage({
              text: `Event ${createdEvent.name} created`,
              state: 'success',
            });
            this.router.navigate(['/events']);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<CreateEventFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<CreateEventFormValue>>({
      name: this.formBuilder.control(null, OpaValidators.required()),
      vendorId: this.formBuilder.control(null, OpaValidators.required()),
      eventType: this.formBuilder.control(null, OpaValidators.required()),
      date: this.formBuilder.control(null, OpaValidators.required()),
      participants: this.formBuilder.control(null, OpaValidators.required()),
      payerId: this.formBuilder.control(null, OpaValidators.required()),
      amount: this.formBuilder.control(null, [
        OpaValidators.required(),
        OpaValidators.min(0),
        OpaValidators.maxDecimals(2),
      ]),
      referenceLink: this.formBuilder.control(null, OpaValidators.required()),
      optionalMsg: this.formBuilder.control(null, OpaValidators.maxLength(this.textareaMaxLength)),
    });
    return form;
  }

  private formValueToEvent(form: CreateEventFormValue): CreateEvent {
    const event: CreateEvent = {
      name: form.name!,
      vendorId: Number(form.vendorId!),
      eventType: form.eventType!,
      date: form.date!,
      participants: form.participants!.split(',').map((id) => Number(id)),
      payerId: Number(form.payerId!),
      amount: form.amount!,
      referenceLink: form.referenceLink!,
      optionalMsg: form.optionalMsg ?? '',
    };
    return event;
  }

  private getCreateEventData$(): Observable<CreateEventData> {
    // TODO: add probe to track vendors refreshing and add loader in template
    const vendors$ = this.refreshVendors.pipe(switchMap(() => this.vendorService.getVendors()));
    const groups$ = this.groupService.getGroups(null);
    const users$ = this.userService.getUsers(null);
    const submitting$ = this.submitting.pipe(distinctUntilChanged());
    return combineLatest([vendors$, groups$, users$, submitting$]).pipe(
      map(([vendors, groups, users, submitting]) => ({ vendors, groups, users, submitting })),
    );
  }
}
