import type { AbstractControl, AsyncValidatorFn, FormControl } from '@angular/forms';
import { map, type Observable } from 'rxjs';
import type { ExtendedValidationErrors } from '../../shared/models/extended-validation-errors.model';

export function inventoryItemImeiValidator(isImeiRequired$: Observable<boolean>): AsyncValidatorFn {
  return (c: AbstractControl): Observable<ExtendedValidationErrors | null> => {
    const control = c as FormControl<null | boolean>;

    return isImeiRequired$.pipe(
      map((isImeiRequired) => {
        if (!isImeiRequired) return null;
        if (control?.value) return null;

        return { imeiNotProvided: true, message: 'IMEI must be provided' };
      }),
    );
  };
}
