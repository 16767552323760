import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
  standalone: true,
})
export class OrderByPipe implements PipeTransform {
  transform<T>(value: T[], propertyName: keyof T): T[] {
    if (!value || !propertyName) return value;

    return value.sort((a, b) => {
      const valueA = a[propertyName];
      const valueB = b[propertyName];

      if (typeof valueA !== 'string' || typeof valueB !== 'string')
        throw new Error('orderBy pipe only works with properties of type string');

      return valueA.localeCompare(valueB);
    });
  }
}
