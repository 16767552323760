import type { Transaction } from './transaction.model';
import type { User } from './user.model';

export interface Event {
  id: number;
  name: string;
  vendorName: string;
  vendorLegalName: string;
  type: EventType;
  date: string;
  participants: User[];
  payer: User;
  productiveLink: string;
  description: string;
  totalEventCost: number;
  coveredByBudget: number;
  extraSpent: number;
  transactions: Transaction[];
}

export interface EventFilter {
  name?: string;
  eventType?: EventType;
  dateFrom?: string;
  dateTo?: string;
  vendorName?: string;
  participantCountComparator?: string;
  participantCount?: number;
}

export interface CreateEvent {
  name: string;
  vendorId: number;
  eventType: EventType;
  date: string;
  participants: number[];
  payerId: number;
  amount: number;
  referenceLink: string;
  optionalMsg: string;
}

export enum EventType {
  LUNCH_AND_DINNER = 'LUNCH_AND_DINNER',
  FUN_AND_GAMES = 'FUN_AND_GAMES',
}
