import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import type { BudgetType } from '../../models/api/edu-expense.model';
import type { InventoryItemFilter } from '../../models/api/inventory-item.model';
import { DownloadService } from '../download.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private readonly downloadService = inject(DownloadService);
  private readonly reportApi = `${environment.apiUrl}/v1/reports`;

  getVpnNumbers(
    vpnNumber?: string,
    subscriptionTypeId?: number,
    active?: boolean,
    format: 'CSV' | 'XLSX' = 'XLSX',
  ): Observable<void> {
    let params = new HttpParams().set('reportFormat', format);
    if (vpnNumber != null) params = params.set('vpnNumber', vpnNumber);
    if (subscriptionTypeId != null) params = params.set('subscriptionTypeId', subscriptionTypeId);
    if (active != null) params = params.set('active', active);

    return this.downloadService.downloadFile(
      `${this.reportApi}/vpn-numbers`,
      'Failed to Get VPN Phone Numbers Report',
      params,
      format,
    );
  }

  getBankCards(
    cardNumber?: string,
    dailyLimit?: number,
    active?: boolean,
    format: 'CSV' | 'XLSX' = 'XLSX',
  ): Observable<void> {
    let params = new HttpParams().set('reportFormat', format);
    if (cardNumber != null) params = params.set('cardNumber', cardNumber);
    if (dailyLimit != null) params = params.set('dailyLimit', dailyLimit);
    if (active != null) params = params.set('active', active);

    return this.downloadService.downloadFile(
      `${this.reportApi}/bank-cards`,
      'Failed to Get Bank Cards Report',
      params,
      format,
    );
  }

  getEduExpenses(
    name?: string,
    budgetType?: BudgetType,
    expenseCategoryId?: number,
    expensePurposeId?: number,
    dateFrom?: string,
    dateTo?: string,
    format: 'CSV' | 'XLSX' = 'XLSX',
  ): Observable<void> {
    let params = new HttpParams().set('reportFormat', format);
    if (name != null) params = params.set('name', name);
    if (budgetType != null) params = params.set('budgetType', budgetType);
    if (expenseCategoryId != null) params = params.set('expenseCategoryId', expenseCategoryId);
    if (expensePurposeId != null) params = params.set('expensePurposeId', expensePurposeId);
    if (dateFrom != null) params = params.set('dateFrom', dateFrom);
    if (dateTo != null) params = params.set('dateTo', dateTo);

    return this.downloadService.downloadFile(
      `${this.reportApi}/edu-equ-transactions`,
      'Failed to Get Education Expenses Report',
      params,
      format,
    );
  }

  getUsers(name?: string, format: 'CSV' | 'XLSX' = 'XLSX'): Observable<void> {
    let params = new HttpParams().set('reportFormat', format);
    if (name != null) params = params.set('name', name);

    return this.downloadService.downloadFile(
      `${this.reportApi}/users`,
      'Failed to Get Users Report',
      params,
      format,
    );
  }

  getInventoryItems(
    filter: InventoryItemFilter,
    format: 'CSV' | 'XLSX' = 'XLSX',
  ): Observable<void> {
    let params = new HttpParams().set('reportFormat', format);

    Object.entries(filter)
      .filter(([, value]) => value !== undefined)
      .forEach(([key, value]) => (params = params.set(key, value)));

    return this.downloadService.downloadFile(
      `${this.reportApi}/inventory-items`,
      'Failed to Get Inventory Items Report',
      params,
      format,
    );
  }
}
