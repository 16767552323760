<section class="card">
  <div class="card-header">
    <p-heading class="card-title" [size]="'large'" [tag]="'h4'">
      <div class="content">
        <p-icon [source]="'assets/icons/fun-and-games.svg'" />
        <span>Fun & Games</span>
      </div>
    </p-heading>

    <p-link-pure
      [hideLabel]="(mediaMaxXs$ | async) ?? false"
      [underline]="true"
      [iconSource]="'assets/icons/arrow-right.svg'"
    >
      <a [routerLink]="'/my-events'">Go to My Events</a>
    </p-link-pure>
  </div>

  <p-divider aria-hidden="true" />

  <ng-container *ngrxLet="funData$ as data; error as error; suspenseTpl: spinner">
    <div *ngIf="error">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadFunData()"
      >
        Reload Fun & Games Data
      </p-button-pure>
    </div>

    <div *ngIf="!error && data" class="card-content">
      <div class="data-wrapper">
        <div class="budget-box">
          <p-text [size]="'small'" [weight]="'semi-bold'">Remaining budget:</p-text>
          <p-text [size]="'small'" [weight]="'semi-bold'">
            {{ data.me.teamBudget | currency: 'EUR' }}
          </p-text>
        </div>

        <p-text [size]="'small'" [weight]="'semi-bold'">Your latest events</p-text>

        <p-text
          *ngIf="!data.events.length"
          class="no-data-text"
          [color]="'contrast-medium'"
          [size]="'x-small'"
        >
          You haven't attended any events yet.
          <br />
          Check out the
          <a [href]="linkouts.Handbook.FunAndGames">Fun & Games</a>
          budget section in the PDC Handbook to learn more on how to use your budget.
        </p-text>

        <div *ngIf="data.events.length" class="event-list">
          <div *ngFor="let event of data.events; trackBy: trackEvent">
            <p-link-pure
              [alignLabel]="'start'"
              [iconSource]="'assets/icons/arrow-right.svg'"
              [stretch]="true"
            >
              <a [routerLink]="['/events', event.id]">
                <div class="event-item">
                  <p-text>{{ event.name }}</p-text>

                  <div class="event-item-info">
                    <p-text [size]="'xx-small'">{{ event.date }}</p-text>
                    <p-divider aria-hidden="true" class="divider" [direction]="'vertical'" />
                    <p-text [size]="'xx-small'">{{ event.vendorName }}</p-text>
                  </div>
                </div>
              </a>
            </p-link-pure>
            <p-divider aria-hidden="true" />
          </div>
        </div>
      </div>

      <div class="chart-wrapper">
        <p-text [size]="'small'" [weight]="'semi-bold'">Your Events in the past 12 months</p-text>

        <div>
          <div class="legend">
            <div class="value">
              <div class="color" [style.backgroundColor]="pdhrColors.Lime"></div>
              <p-text [size]="'xx-small'">Total monthly top-up</p-text>
            </div>
            <div class="value">
              <div class="color" [style.backgroundColor]="pdhrColors.Water"></div>
              <p-text [size]="'xx-small'">Budget deduction</p-text>
            </div>
            <div class="value">
              <div class="color" [style.backgroundColor]="pdhrColors.Orange"></div>
              <p-text [size]="'xx-small'">Extra spent</p-text>
            </div>
          </div>

          <div class="chart">
            <canvas
              *deferLoading
              [class.no-data]="!data.events.length"
              baseChart
              [data]="data.chart.data"
              [options]="data.chart.options"
              [type]="'bar'"
            ></canvas>

            <div *ngIf="!data.events.length" class="no-data-tooltip">
              <p-text [color]="'contrast-medium'">No events to show.</p-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</section>

<section class="card">
  <div class="card-header">
    <p-heading class="card-title" [size]="'large'" [tag]="'h4'">
      <div class="content">
        <p-icon [source]="'assets/icons/education.svg'" />
        <span>Edu & Equ</span>
      </div>
    </p-heading>

    <p-link-pure
      [hideLabel]="(mediaMaxXs$ | async) ?? false"
      [underline]="true"
      [iconSource]="'assets/icons/arrow-right.svg'"
    >
      <a [routerLink]="'/edu-budget'">Go to My Budget</a>
    </p-link-pure>
  </div>

  <p-divider aria-hidden="true" />

  <ng-container *ngrxLet="eduData$ as data; error as error; suspenseTpl: spinner">
    <div *ngIf="error">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadFunData()"
      >
        Reload Edu & Equ Data
      </p-button-pure>
    </div>

    <div *ngIf="!error && data" class="card-content card-content--reverse">
      <div class="chart-wrapper chart-wrapper--reverse">
        <div class="chart">
          <canvas
            *deferLoading
            [class.no-data]="!data.expenses.length"
            baseChart
            [data]="data.chart.data"
            [options]="data.chart.options"
            [type]="'doughnut'"
          ></canvas>

          <div class="stats">
            <p-text [size]="'small'" [weight]="'semi-bold'">Total spent</p-text>
            <div>
              <div *ngIf="!data.expenses.length" class="value">
                <p-text [color]="'contrast-medium'">No expenses to show.</p-text>
              </div>

              <ng-container *ngIf="data.expenses.length">
                <div class="value">
                  <div class="color" [style.backgroundColor]="pdhrColors.Plum"></div>
                  <p-text [size]="'xx-small'">
                    Education {{ data.chart.data?.datasets?.[0]?.data?.[0] }}%
                  </p-text>
                </div>
                <div class="value">
                  <div class="color" [style.backgroundColor]="pdhrColors.Mint"></div>
                  <p-text [size]="'xx-small'">
                    Equipment {{ data.chart.data?.datasets?.[0]?.data?.[1] }}%
                  </p-text>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="data-wrapper data-wrapper--reverse">
        <div class="budget-box">
          <p-text [size]="'small'" [weight]="'semi-bold'">Remaining budget:</p-text>
          <p-text [size]="'small'" [weight]="'semi-bold'">
            {{ data.me.eduEquBudgetStatus.totalEduEquBudgetRemaining | currency: 'EUR' }}
          </p-text>
        </div>

        <p-text [size]="'small'" [weight]="'semi-bold'">Your latest expenses</p-text>

        <p-text
          *ngIf="!data.expenses.length"
          class="no-data-text"
          [color]="'contrast-medium'"
          [size]="'x-small'"
        >
          You haven't spent any of your Edu & Equ budget yet.
          <br />
          Check out the
          <a [href]="linkouts.Handbook.EduAndEqu">Edu & Equ</a>
          section in the PDC Handbook to learn more on how to use your budget.
        </p-text>

        <p-table *ngIf="data.expenses.length" caption="Edu & Equ expenses list table">
          <p-table-head>
            <p-table-head-row>
              <p-table-head-cell *ngIf="isEduEquAdmin" style="width: 1%">
                Expense ID
              </p-table-head-cell>
              <p-table-head-cell style="width: 20%">Type</p-table-head-cell>
              <p-table-head-cell style="width: 20%">Category</p-table-head-cell>
              <p-table-head-cell style="width: 20%">Purpose</p-table-head-cell>
              <p-table-head-cell style="width: 20%">Date</p-table-head-cell>
              <p-table-head-cell style="width: 20%">Amount</p-table-head-cell>
            </p-table-head-row>
          </p-table-head>
          <p-table-body>
            <p-table-row *ngFor="let expense of data.expenses; trackBy: trackExpenses">
              <p-table-cell *ngIf="isEduEquAdmin">
                <a [routerLink]="['/edu-transactions', expense.id]">{{ expense.id }}</a>
              </p-table-cell>
              <p-table-cell>
                {{ logTypeTranslation[expense.type] | transloco | titlecase }}
              </p-table-cell>
              <p-table-cell>{{ expense.category?.description }}</p-table-cell>
              <p-table-cell>{{ expense.purpose?.description }}</p-table-cell>
              <p-table-cell>{{ expense.date | date: 'dd.MM.yyyy.' }}</p-table-cell>
              <p-table-cell>{{ expense.amount | currency: 'EUR' | leadingPlus }}</p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
