import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  ReplaySubject,
  distinctUntilChanged,
  shareReplay,
  tap,
  type Observable,
} from 'rxjs';
import type { EduExpensePurpose } from '../../shared/models/api/edu-expense-purpose.model';
import { BudgetType } from '../../shared/models/api/edu-expense.model';
import { EduExpensePurposeService } from '../../shared/services/api/edu-expense-purpose.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { budgetTypeTranslation } from '../edu-expense-create/edu-expense-create.helpers';
import { EduExpensePurposeCreateComponent } from './edu-expense-purpose-create/edu-expense-purpose-create.component';
import { EduExpensePurposeDeleteComponent } from './edu-expense-purpose-delete/edu-expense-purpose-delete.component';
import { EduExpensePurposeEditComponent } from './edu-expense-purpose-edit/edu-expense-purpose-edit.component';
@Component({
  selector: 'opa-edu-expense-purpose-list',
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    CommonModule,
    RouterLink,
    LetDirective,
    TranslocoPipe,
    EduExpensePurposeCreateComponent,
    EduExpensePurposeEditComponent,
    EduExpensePurposeDeleteComponent,
  ],
  templateUrl: './edu-expense-purpose-list.component.html',
  styleUrl: './edu-expense-purpose-list.component.scss',
})
export class EduExpensePurposeListComponent {
  readonly mediaQueryService = inject(MediaQueryService);
  private readonly eduExpenseService = inject(EduExpensePurposeService);

  private readonly loading = new BehaviorSubject(false);
  private readonly eduExpensePurpose = new ReplaySubject<EduExpensePurpose | null>(1);
  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  readonly loading$ = this.loading.pipe(distinctUntilChanged());
  readonly eduExpensePurpose$ = this.eduExpensePurpose.asObservable();
  readonly budgetTypes = Object.values(BudgetType);
  readonly budgetTypeTranslation = budgetTypeTranslation;

  data$ = this.getData$();
  showCreateModal = false;
  showDeleteModal = false;
  showEditModal = false;

  trackEduExpensePurpose(index: number, purpose: EduExpensePurpose): number {
    return purpose.id;
  }

  reloadData(): void {
    this.data$ = this.getData$();
  }

  private getData$(): Observable<EduExpensePurpose[]> {
    this.loading.next(true);

    return this.eduExpenseService.getEduExpensePurposes().pipe(
      tap({
        next: () => this.loading.next(false),
        error: () => this.loading.next(false),
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  openDeleteModal(eduExpensePurpose: EduExpensePurpose): void {
    this.eduExpensePurpose.next(eduExpensePurpose);
    this.showDeleteModal = true;
  }

  closeDeleteModal(eduExpensePurpose?: EduExpensePurpose): void {
    this.showDeleteModal = false;
    if (eduExpensePurpose) this.reloadData();
    this.eduExpensePurpose.next(null);
  }

  openEditModal(eduExpensePurpose: EduExpensePurpose): void {
    this.eduExpensePurpose.next(eduExpensePurpose);
    this.showEditModal = true;
  }

  closeEditModal(success?: boolean): void {
    this.showEditModal = false;
    if (success) this.reloadData();
    this.eduExpensePurpose.next(null);
  }
}
