<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Bank Cards</p-heading>

  <p-link
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/bank-cards/create'">Create New Bank Card</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="filters">
  <p-text-field-wrapper class="main-filter" [hideLabel]="true" [label]="'Search Bank Cards'">
    <input
      type="search"
      [formControl]="form.controls.cardNumber"
      [placeholder]="'Search Bank Cards'"
    />
  </p-text-field-wrapper>

  <div class="more-filters">
    <p-text-field-wrapper
      *ngrxLet="form.controls.dailyLimit as control"
      class="filter"
      [label]="'Daily Limit'"
      [state]="control.errors ? 'error' : 'none'"
      [unit]="'EUR'"
      [unitPosition]="'suffix'"
    >
      <input type="number" [formControl]="control" />
    </p-text-field-wrapper>

    <p-select-wrapper class="filter" [label]="'Active'">
      <select [formControl]="form.controls.active">
        <option [value]="allValue">All</option>
        <option [value]="trueValue">Active</option>
        <option [value]="falseValue">Inactive</option>
      </select>
    </p-select-wrapper>
  </div>
</section>

<section class="list">
  <div class="list-heading">
    <p-heading *ngrxLet="bankCards$ | async as bankCards" [size]="'small'" [tag]="'h4'">
      {{ bankCards?.totalElements }} Bank Cards
    </p-heading>

    <p-divider aria-hidden="true" [direction]="'vertical'" />

    <p-button-pure
      [iconSource]="'assets/icons/download.svg'"
      [loading]="(exporting$ | async) ?? false"
      [size]="'x-small'"
      [underline]="true"
      (click)="exportBankCards()"
    >
      Export Bank Cards
    </p-button-pure>
  </div>

  <ng-container *ngrxLet="bankCards$ as bankCards; error as bankCardsError; suspenseTpl: spinner">
    <div *ngIf="bankCardsError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadBankCards()"
      >
        Reload Bank Cards
      </p-button-pure>
    </div>

    <ng-container *ngIf="!bankCardsError && bankCards">
      <div class="overlay-spinner-host">
        <ng-container *ngIf="loading$ | async" [ngTemplateOutlet]="overlaySpinner" />

        <p-table caption="Bank Cards list table">
          <p-table-head>
            <p-table-head-row>
              <p-table-head-cell style="width: 10%">Person</p-table-head-cell>
              <p-table-head-cell style="width: 10%">Card Number</p-table-head-cell>
              <p-table-head-cell style="width: 10%">Daily Limit</p-table-head-cell>
              <p-table-head-cell style="width: 8.333%">POS</p-table-head-cell>
              <p-table-head-cell style="width: 8.333%">ATM</p-table-head-cell>
              <p-table-head-cell style="width: 8.333%">Banking Permissions</p-table-head-cell>
              <p-table-head-cell style="width: 8.333%">Create Payment Warrants</p-table-head-cell>
              <p-table-head-cell style="width: 8.333%">
                Authorize Payment Warrants
              </p-table-head-cell>
              <p-table-head-cell style="width: 8.333%">Mobile APP</p-table-head-cell>
              <p-table-head-cell style="width: 10%">Expiration Date</p-table-head-cell>
              <p-table-head-cell style="width: 10%">Active</p-table-head-cell>
            </p-table-head-row>
          </p-table-head>

          <p-table-body>
            <p-table-row
              *ngFor="
                let bankCard of bankCards.content | fillArray: bankCards.pageable.pageSize;
                trackBy: trackRow
              "
            >
              <p-table-cell>
                <ng-container *ngIf="bankCard; else emptyCell">
                  {{ bankCard.user.firstName }} {{ bankCard.user.lastName }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="bankCard; else emptyCell">
                  <a [routerLink]="['/bank-cards', bankCard.id]">
                    {{ bankCard.cardNumber }}
                  </a>
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="bankCard; else emptyCell">
                  {{ bankCard.dailyLimit | currency: 'EUR' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="bankCard; else emptyCell">
                  {{ bankCard.pos ? 'Yes' : 'No' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="bankCard; else emptyCell">
                  {{ bankCard.atm ? 'Yes' : 'No' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="bankCard; else emptyCell">
                  {{ bankCard.bankingPermissions ? 'Yes' : 'No' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="bankCard; else emptyCell">
                  {{ bankCard.createPaymentWarrants ? 'Yes' : 'No' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="bankCard; else emptyCell">
                  {{ bankCard.authorizePaymentWarrants ? 'Yes' : 'No' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="bankCard; else emptyCell">
                  {{ bankCard.mobileApp ? 'Yes' : 'No' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="bankCard; else emptyCell">
                  {{ bankCard.expirationDate | date: 'dd.MM.yyyy.' }}
                </ng-container>
              </p-table-cell>
              <p-table-cell>
                <ng-container *ngIf="bankCard; else emptyCell">
                  <div
                    class="status"
                    [ngClass]="{
                      'status--active': bankCard.active,
                      'status--inactive': !bankCard.active
                    }"
                  ></div>
                </ng-container>
              </p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>
      </div>

      <p-pagination
        [totalItemsCount]="bankCards.totalElements"
        [itemsPerPage]="bankCards.size"
        [activePage]="bankCards.pageable.pageNumber + 1"
        (update)="updatePageNo($event.detail.page)"
      />
    </ng-container>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>

<ng-template #overlaySpinner>
  <div class="overlay-spinner"><p-spinner /></div>
</ng-template>

<ng-template #emptyCell>
  <span class="empty-cell"></span>
</ng-template>
