import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  NgZone,
  inject,
  type OnChanges,
  type OnDestroy,
} from '@angular/core';
import type { NgChanges } from '../../shared/models/ng-changes.model';

@Directive({
  selector: '[opaGroupMembers]',
  exportAs: 'opaGroupMembers',
  standalone: true,
})
export class GroupMembersDirective implements OnChanges, OnDestroy {
  private readonly ngZone = inject(NgZone);
  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  /** WARN: PDS variable */
  @Input({ required: true }) gapWidth = 0;
  /** WARN: PDS variable */
  @Input({ required: true }) memberWidth = 0;

  private hostWidth = 0;
  private observer: ResizeObserver | undefined;
  maxIcons = 0;
  private previousMaxIcons = this.maxIcons;

  constructor() {
    this.ngZone.runOutsideAngular(() => {
      this.observer = new ResizeObserver((entries) => {
        const observee = entries[0];
        const sourceEl = observee.target as HTMLElement;
        this.hostWidth = sourceEl.offsetWidth;
        this.calculateMaxIcons();
      });
      this.observer.observe(this.elementRef.nativeElement);
    });
  }

  ngOnChanges(changes: NgChanges<GroupMembersDirective>): void {
    if (changes.gapWidth || changes.memberWidth) this.calculateMaxIcons();
  }

  ngOnDestroy(): void {
    this.ngZone.runOutsideAngular(() => {
      this.observer?.disconnect();
    });
  }

  private calculateMaxIcons(): void {
    if (!this.hostWidth || !this.gapWidth || !this.memberWidth) {
      this.maxIcons = 0;
    } else {
      this.maxIcons = Math.floor(
        (this.hostWidth - this.gapWidth) / (this.memberWidth + this.gapWidth),
      );
    }

    if (this.maxIcons < 0) this.maxIcons = 0;
    if (this.previousMaxIcons === this.maxIcons) return;

    this.previousMaxIcons = this.maxIcons;
    this.ngZone.run(() => this.changeDetectorRef.markForCheck());
  }
}
