import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  type OnChanges,
  type OnDestroy,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import type { Position } from '../../../shared/models/api/position.model';
import type { RemoteUserLocation } from '../../../shared/models/api/remote-user-location.model';
import type { EditUser, User } from '../../../shared/models/api/user.model';
import type { ControlsOf } from '../../../shared/models/controls-of.model';
import type { NgChanges } from '../../../shared/models/ng-changes.model';
import { PositionService } from '../../../shared/services/api/position.service';
import { RemoteUserLocationService } from '../../../shared/services/api/remote-user-location.service';
import { UserService } from '../../../shared/services/api/user.service';
import { OpaValidators } from '../../../shared/validators/opa.validators';
import type { EditUserData, EditUserFormValue } from './user-edit.models';

@Component({
  selector: 'opa-user-edit',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, ReactiveFormsModule, LetDirective],
  templateUrl: './user-edit.component.html',
  styleUrl: './user-edit.component.scss',
})
export class UserEditComponent implements OnChanges, OnDestroy {
  private readonly formBuilder = inject(FormBuilder).nonNullable;
  private readonly positionService = inject(PositionService);
  private readonly remoteUserLocationService = inject(RemoteUserLocationService);
  private readonly userService = inject(UserService);
  private readonly toastManager = inject(ToastManager);

  @Input({ required: true }) open = false;
  @Input({ required: true }) user: User | undefined = undefined;
  @Output() dismiss = new EventEmitter<User | undefined>();

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly resettingPassword = new BehaviorSubject(false);
  private readonly submitting = new BehaviorSubject(false);
  data$: Observable<EditUserData> | undefined = undefined;

  ngOnChanges(changes: NgChanges<UserEditComponent>): void {
    if (changes.open && this.open && !this.data$) this.reloadData();
    if ((changes.open && this.open) || changes.user) this.resetForm(this.user);
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(user?: User): void {
    this.dismiss.emit(user);
  }

  reloadData(): void {
    this.data$ = this.getData$();
  }

  resetPassword(): void {
    const user = this.user;
    if (!user) return;

    this.resettingPassword.next(true);
    this.subSink.sink = this.userService
      .resetUserPassword(user.id)
      .pipe(
        tap({
          next: () => {
            this.toastManager.addMessage({
              text: `Password reset link sent to ${user.firstName} ${user.lastName}`,
              state: 'success',
            });
            this.resettingPassword.next(false);
          },
          error: () => this.resettingPassword.next(false),
        }),
      )
      .subscribe();
  }

  trackPosition(index: number, position: Position): number {
    return position.id;
  }

  trackLocation(index: number, location: RemoteUserLocation): number {
    return location.id;
  }

  submit(): void {
    const user = this.user;
    if (!user) return;

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const editUser = this.formValueToUser(formValue);
    this.subSink.sink = this.userService
      .editUser(user.id, editUser)
      .pipe(
        tap({
          next: (updatedUser) => {
            this.toastManager.addMessage({
              text: `User ${updatedUser.firstName} ${updatedUser.lastName} updated`,
              state: 'success',
            });
            this.submitting.next(false);
            this.close(updatedUser);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<EditUserFormValue>> {
    const form = this.formBuilder.group<ControlsOf<EditUserFormValue>>({
      active: this.formBuilder.control(null, OpaValidators.required()),
      doubleMonthlyBudget: this.formBuilder.control(null, OpaValidators.required()),
      position: this.formBuilder.control(null, OpaValidators.required()),
      remoteEmployee: this.formBuilder.control(null, OpaValidators.required()),
      remoteLocationId: this.formBuilder.control(null),
    });
    this.subSink.sink = form.controls.remoteEmployee.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap((remoteEmployee) => {
          if (remoteEmployee) {
            this.form.controls.remoteLocationId.setValidators(OpaValidators.required());
          } else {
            this.form.controls.remoteLocationId.clearValidators();
          }
          this.form.controls.remoteLocationId.updateValueAndValidity();
        }),
      )
      .subscribe();
    return form;
  }

  private formValueToUser(form: EditUserFormValue): EditUser {
    const user: EditUser = {
      active: form.active!,
      doubleMonthlyBudget: form.doubleMonthlyBudget!,
      position: Number(form.position!),
      remoteEmployee: form.remoteEmployee!,
      remoteLocationId: form.remoteEmployee ? Number(form.remoteLocationId!) : undefined,
    };
    return user;
  }

  private userToFormValue(user: User): EditUserFormValue {
    const formValue: EditUserFormValue = {
      active: user.active,
      doubleMonthlyBudget: user.doubleMonthlyBudget,
      position: String(user.position.id),
      remoteEmployee: user.remoteEmployee,
      remoteLocationId: user.remoteLocation?.id ?? null,
    };
    return formValue;
  }

  private resetForm(user?: User): void {
    if (!user) {
      this.form.reset();
      return;
    }

    const formValue = this.userToFormValue(user);
    this.form.reset(formValue);
  }

  private getData$(): Observable<EditUserData> {
    const resettingPassword$ = this.resettingPassword.pipe(distinctUntilChanged());
    const positions$ = this.positionService.getPositions();
    const locations$ = this.remoteUserLocationService.getRemoteUserLocations();
    const submitting$ = this.submitting.pipe(distinctUntilChanged());
    return combineLatest([resettingPassword$, positions$, locations$, submitting$]).pipe(
      map(([resettingPassword, positions, locations, submitting]) => ({
        resettingPassword,
        positions,
        locations,
        submitting,
      })),
    );
  }
}
