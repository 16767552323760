import { CommonModule } from '@angular/common';
import { Component, ViewChild, inject, type OnDestroy } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { tap } from 'rxjs';
import { SubSink } from 'subsink';
import type { CreateOrEditInventoryItem } from '../../shared/models/api/inventory-item.model';
import { InventoryItemsService } from '../../shared/services/api/inventory-items.service';
import { AuthService } from '../../shared/services/auth.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { InventoryItemFormComponent } from '../inventory-item-form/inventory-item-form.component';

@Component({
  selector: 'opa-inventory-item-create',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    InventoryItemFormComponent,
    LetDirective,
    TranslocoPipe,
  ],
  templateUrl: './inventory-item-create.component.html',
  styleUrl: './inventory-item-create.component.scss',
})
export class InventoryItemCreateComponent implements OnDestroy {
  @ViewChild(InventoryItemFormComponent) form?: InventoryItemFormComponent;

  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);
  private readonly inventoryItemsService = inject(InventoryItemsService);

  readonly isInventoryAdmin = inject(AuthService).isInventoryAdmin();
  readonly mediaMaxXs$ = inject(MediaQueryService).max('xs');
  private readonly subSink = new SubSink();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  onSubmit(item: CreateOrEditInventoryItem): void {
    this.subSink.sink = this.inventoryItemsService
      .create(item)
      .pipe(
        tap({
          next: (createItem) => {
            this.toastManager.addMessage({
              text: `Inventory item ${createItem.manufacturer.description} ${createItem.model} created`,
              state: 'success',
            });
            this.router.navigate(['/inventory-items']);
          },
          error: () => this.form?.finalizeSubmit(),
        }),
      )
      .subscribe();
  }
}
