<opa-header />

<opa-navbar />

<main class="main">
  <router-outlet />
</main>

<opa-error-banner />

<p-toast />
