import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  shareReplay,
  startWith,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import type { Group } from '../../shared/models/api/group.model';
import type { Page } from '../../shared/models/api/page.model';
import type { User } from '../../shared/models/api/user.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { FillArrayPipe } from '../../shared/pipes/fill-array.pipe';
import { GroupService } from '../../shared/services/api/group.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { groupTypeTranslation } from './group-list.helpers';
import type { GroupListFormValue } from './group-list.models';

@Component({
  selector: 'opa-group-list',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    ReactiveFormsModule,
    LetDirective,
    FillArrayPipe,
    TranslocoPipe,
  ],
  templateUrl: './group-list.component.html',
  styleUrl: './group-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupListComponent {
  private readonly mediaQueryService = inject(MediaQueryService);
  private readonly groupService = inject(GroupService);
  private readonly formBuilder = inject(FormBuilder);
  readonly groupTypeTranslation = groupTypeTranslation;

  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  private readonly pageNo = new BehaviorSubject(1);
  readonly form = this.buildForm();
  private readonly loading = new BehaviorSubject(false);
  readonly loading$ = this.loading.pipe(distinctUntilChanged());
  groups$ = this.getGroups$();

  reloadGroups(): void {
    this.groups$ = this.getGroups$();
  }

  trackRow(index: number): number {
    return index;
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  updatePageNo(page: number): void {
    this.pageNo.next(page);
  }

  private buildForm(): FormGroup<ControlsOf<GroupListFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<GroupListFormValue>>({
      groupName: this.formBuilder.control(null),
    });
    return form;
  }

  private getGroups$(): Observable<Page<Group>> {
    const pageNo$ = this.pageNo.pipe(distinctUntilChanged());

    const form$ = merge(
      this.form.controls.groupName.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)),
    ).pipe(
      startWith(undefined),
      map(() => this.form.getRawValue()),
      tap(() => this.updatePageNo(1)),
    );

    return combineLatest([pageNo$, form$]).pipe(
      debounceTime(0),
      tap(() => this.loading.next(true)),
      switchMap(([pageNo, form]) => {
        const groupName = form.groupName ?? undefined;
        return this.groupService.getGroups(pageNo, groupName);
      }),
      tap({
        next: () => this.loading.next(false),
        error: () => this.loading.next(false),
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
