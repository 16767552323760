import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  ReplaySubject,
  distinctUntilChanged,
  shareReplay,
  tap,
  type Observable,
} from 'rxjs';
import type { Vendor } from '../../shared/models/api/vendor.model';
import { VendorService } from '../../shared/services/api/vendor.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { VendorCreateComponent } from './vendor-create/vendor-create.component';
import { VendorDeleteComponent } from './vendor-delete/vendor-delete.component';
import { VendorEditComponent } from './vendor-edit/vendor-edit.component';

@Component({
  selector: 'opa-vendor-list',
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    CommonModule,
    RouterLink,
    LetDirective,
    VendorCreateComponent,
    VendorEditComponent,
    VendorDeleteComponent,
  ],
  templateUrl: './vendor-list.component.html',
  styleUrl: './vendor-list.component.scss',
})
export class VendorListComponent {
  readonly mediaQueryService = inject(MediaQueryService);
  private readonly vendorService = inject(VendorService);

  private readonly loading = new BehaviorSubject(false);
  private readonly vendor = new ReplaySubject<Vendor | null>(1);
  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  readonly loading$ = this.loading.pipe(distinctUntilChanged());
  readonly vendor$ = this.vendor.asObservable();

  data$ = this.getData$();
  showCreateModal = false;
  showDeleteModal = false;
  showEditModal = false;

  trackVendor(index: number, location: Vendor): number {
    return location.id;
  }

  reloadData(): void {
    this.data$ = this.getData$();
  }

  private getData$(): Observable<Vendor[]> {
    this.loading.next(true);

    return this.vendorService.getVendors().pipe(
      tap({
        next: () => this.loading.next(false),
        error: () => this.loading.next(false),
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  openDeleteModal(vendor: Vendor): void {
    this.vendor.next(vendor);
    this.showDeleteModal = true;
  }

  closeDeleteModal(vendor?: Vendor): void {
    this.showDeleteModal = false;
    if (vendor) this.reloadData();
    this.vendor.next(null);
  }

  openEditModal(vendor: Vendor): void {
    this.vendor.next(vendor);
    this.showEditModal = true;
  }

  closeEditModal(success?: boolean): void {
    this.showEditModal = false;
    if (success) this.reloadData();
    this.vendor.next(null);
  }
}
