import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'opaStringSplit',
  standalone: true,
})
export class StringSplitPipe implements PipeTransform {
  transform(value: string | null | undefined, separator: string): string[] | null | undefined {
    return value?.split(separator);
  }
}
