import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type { RemoteUserLocation } from '../../models/api/remote-user-location.model';

@Injectable({
  providedIn: 'root',
})
export class RemoteUserLocationService {
  private readonly httpClient = inject(HttpClient);
  private readonly api = `${environment.apiUrl}/v1/codebooks/remote-user-location`;

  getRemoteUserLocations(): Observable<RemoteUserLocation[]> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Get Remote User Locations',
    );

    return this.httpClient.get<RemoteUserLocation[]>(this.api, { context });
  }
}
