import { CommonModule } from '@angular/common';
import { Component, inject, type OnDestroy } from '@angular/core';
import { FormBuilder, type FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, tap } from 'rxjs';
import { SubSink } from 'subsink';
import type { BulkCreateInventoryItems } from '../../shared/models/api/inventory-item.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { InventoryItemsService } from '../../shared/services/api/inventory-items.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import type { BulkInventoryItemsFormValue } from './inventory-item-create-bulk.models';

@Component({
  selector: 'opa-inventory-item-create-bulk',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, RouterLink, ReactiveFormsModule],
  templateUrl: './inventory-item-create-bulk.component.html',
  styleUrl: './inventory-item-create-bulk.component.scss',
})
export class InventoryItemCreateBulkComponent implements OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly inventoryItemsService = inject(InventoryItemsService);
  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);

  readonly mediaMaxXs$ = inject(MediaQueryService).max('xs');
  private readonly subSink = new SubSink();
  private readonly downloading = new BehaviorSubject(false);
  readonly downloading$ = this.downloading.asObservable();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.asObservable();
  readonly form = this.buildForm();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  downloadTemplate(): void {
    this.downloading.next(true);
    this.subSink.sink = this.inventoryItemsService
      .downloadCreateBulkTemplate()
      .pipe(
        tap({
          next: () => {
            this.toastManager.addMessage({
              text: 'Bulk Create Inventory Items Template downloaded',
              state: 'success',
            });
            this.downloading.next(false);
          },
          error: () => this.downloading.next(false),
        }),
      )
      .subscribe();
  }

  fileChange(event: Event, controlName: keyof BulkInventoryItemsFormValue): void {
    if (!this.form) return;
    const target = event.target as HTMLInputElement;
    if (!target.files?.length) return;

    this.form.patchValue({ [controlName]: target.files[0] });
  }

  submit(): void {
    if (!this.form) return;

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const items = this.formValueToItems(formValue);
    this.subSink.sink = this.inventoryItemsService
      .createBulk(items)
      .pipe(
        tap({
          next: () => {
            this.toastManager.addMessage({
              text: 'Inventory Items created',
              state: 'success',
            });
            this.router.navigate(['/inventory-items']);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<BulkInventoryItemsFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<BulkInventoryItemsFormValue>>({
      import: this.formBuilder.nonNullable.control(null, Validators.required),
      warranty: this.formBuilder.nonNullable.control(null),
      receipt: this.formBuilder.nonNullable.control(null),
    });
    return form;
  }

  private formValueToItems(formValue: BulkInventoryItemsFormValue): BulkCreateInventoryItems {
    const items: BulkCreateInventoryItems = {
      import: formValue.import!,
      warranty: formValue.warranty,
      receipt: formValue.receipt,
    };
    return items;
  }
}
