<p-button-pure
  *ngIf="inventoryItem && !inventoryItem?.assignee"
  [icon]="'none'"
  [underline]="true"
  (click)="open = true"
>
  Assign
</p-button-pure>

<p-modal [heading]="'Assign inventory item'" [open]="open" (dismiss)="close()">
  <form class="form" [formGroup]="form" (ngSubmit)="submit()">
    <div class="form-controls">
      <p-select-wrapper
        *ngrxLet="form.controls.assigneeId as control"
        [filter]="true"
        [label]="'Assign item to member: '"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <select [attr.required]="true" [formControl]="control">
          <option *ngFor="let user of users$ | async; trackBy: trackUser" [value]="user.id">
            {{ user.firstName }} {{ user.lastName }}
          </option>
        </select>
      </p-select-wrapper>
    </div>

    <p-button-group class="modal-actions">
      <p-button
        [icon]="'none'"
        [loading]="(submitting$ | async) ?? false"
        [type]="'submit'"
        [variant]="'primary'"
      >
        Assign
      </p-button>
      <p-button
        [iconSource]="'assets/icons/close.svg'"
        [type]="'button'"
        [variant]="'secondary'"
        (click)="close()"
      >
        Close
      </p-button>
    </p-button-group>
  </form>
</p-modal>
