<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Inventory Manufacturers</p-heading>

  <p-button
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
    (click)="showCreateModal = true"
  >
    Add New Manufacturer
  </p-button>
</div>

<p-divider aria-hidden="true" />

<section class="list">
  <p-heading *ngrxLet="data$ | async as manufacturers" [size]="'small'" [tag]="'h4'">
    {{ manufacturers?.length }} Manufacturers
  </p-heading>

  <ng-container *ngrxLet="data$ as data; error as dataError; suspenseTpl: spinner">
    <div *ngIf="dataError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadData()"
      >
        Reload Manufacturers
      </p-button-pure>
    </div>

    <ng-container *ngIf="!dataError && data">
      <div class="overlay-spinner-host">
        <ng-container *ngIf="loading$ | async" [ngTemplateOutlet]="overlaySpinner" />

        <p-table caption="Inventory Manufacturers list table">
          <p-table-head>
            <p-table-head-row>
              <p-table-head-cell style="width: 1%">Edit</p-table-head-cell>
              <p-table-head-cell style="width: 98%">Name</p-table-head-cell>
              <p-table-head-cell style="width: 1%">Delete</p-table-head-cell>
            </p-table-head-row>
          </p-table-head>

          <p-table-body>
            <p-table-row *ngFor="let manufacturer of data; trackBy: trackInventoryManufacturer">
              <p-table-cell>
                <p-button-pure
                  [iconSource]="'assets/icons/edit.svg'"
                  [hideLabel]="true"
                  (click)="openEditModal(manufacturer)"
                >
                  Edit {{ manufacturer.description }}
                </p-button-pure>
              </p-table-cell>

              <p-table-cell>
                <p-link-pure [icon]="'none'" [underline]="true">
                  <a
                    [routerLink]="['/inventory-items']"
                    [queryParams]="{ manufacturerId: manufacturer.id }"
                  >
                    {{ manufacturer.description }}
                  </a>
                </p-link-pure>
              </p-table-cell>
              <p-table-cell>
                <p-button-pure
                  [iconSource]="'assets/icons/delete.svg'"
                  [hideLabel]="true"
                  (click)="openDeleteModal(manufacturer)"
                >
                  Delete {{ manufacturer.description }}
                </p-button-pure>
              </p-table-cell>
            </p-table-row>
          </p-table-body>
        </p-table>
      </div>
    </ng-container>
  </ng-container>
</section>

<opa-inventory-manufacturer-create
  [open]="showCreateModal"
  (dismiss)="showCreateModal = false; $event && reloadData()"
/>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>

<ng-template #overlaySpinner>
  <div class="overlay-spinner"><p-spinner /></div>
</ng-template>

<opa-inventory-manufacturer-delete
  [open]="showDeleteModal"
  [inventoryManufacturer]="(inventoryManufacturer$ | async) ?? undefined"
  (dismiss)="closeDeleteModal($event)"
/>

<opa-inventory-manufacturer-edit
  [open]="showEditModal"
  [inventoryManufacturer]="(inventoryManufacturer$ | async) ?? undefined"
  (dismiss)="closeEditModal($event)"
/>
