import {
  InventoryFilters,
  type InventoryFilter,
} from '../../shared/models/api/inventory-item.model';
import { InventoryColumns, type InventoryColumn } from './inventory-item-list.models';

export const inventoryColumnTranslation: Readonly<Record<InventoryColumn, string>> = {
  [InventoryColumns.id]: 'INVENTORY_ITEMS.COLUMNS.ID',
  [InventoryColumns.manufacturerId]: 'INVENTORY_ITEMS.COLUMNS.MANUFACTURER',
  [InventoryColumns.model]: 'INVENTORY_ITEMS.COLUMNS.MODEL',
  [InventoryColumns.specification]: 'INVENTORY_ITEMS.COLUMNS.SPECIFICATION',
  [InventoryColumns.typeId]: 'INVENTORY_ITEMS.COLUMNS.TYPE',
  [InventoryColumns.serialNumber]: 'INVENTORY_ITEMS.COLUMNS.SERIAL_NUMBER',
  [InventoryColumns.imei]: 'INVENTORY_ITEMS.COLUMNS.IMEI',
  [InventoryColumns.ownerId]: 'INVENTORY_ITEMS.COLUMNS.OWNER',
  [InventoryColumns.locationId]: 'INVENTORY_ITEMS.COLUMNS.LOCATION',
  [InventoryColumns.warrantyExpiration]: 'INVENTORY_ITEMS.COLUMNS.WARRANTY_EXPIRATION',
  [InventoryColumns.contractNumber]: 'INVENTORY_ITEMS.COLUMNS.CONTRACT_NUMBER',
  [InventoryColumns.price]: 'INVENTORY_ITEMS.COLUMNS.PRICE',
  [InventoryColumns.assigneeId]: 'INVENTORY_ITEMS.COLUMNS.ASSIGNEE',
  [InventoryColumns.statusId]: 'INVENTORY_ITEMS.COLUMNS.STATUS',
  [InventoryColumns.note]: 'INVENTORY_ITEMS.COLUMNS.NOTE',
  [InventoryColumns.serviceNo]: 'INVENTORY_ITEMS.COLUMNS.SERVICE_NO',
  [InventoryColumns.action]: 'INVENTORY_ITEMS.COLUMNS.ACTION',
};

export const inventoryFilterTranslation: Readonly<Record<InventoryFilter, string>> = {
  [InventoryFilters.manufacturerId]: 'INVENTORY_ITEMS.FILTERS.MANUFACTURER',
  [InventoryFilters.model]: 'INVENTORY_ITEMS.FILTERS.MODEL',
  [InventoryFilters.specification]: 'INVENTORY_ITEMS.FILTERS.SPECIFICATION',
  [InventoryFilters.typeId]: 'INVENTORY_ITEMS.FILTERS.TYPE',
  [InventoryFilters.serialNumber]: 'INVENTORY_ITEMS.FILTERS.SERIAL_NUMBER',
  [InventoryFilters.imei]: 'INVENTORY_ITEMS.FILTERS.IMEI',
  [InventoryFilters.ownerId]: 'INVENTORY_ITEMS.FILTERS.OWNER',
  [InventoryFilters.locationId]: 'INVENTORY_ITEMS.FILTERS.LOCATION',
  [InventoryFilters.warrantyExpirationFrom]: 'INVENTORY_ITEMS.FILTERS.WARRANTY_EXPIRATION_FROM',
  [InventoryFilters.warrantyExpirationTo]: 'INVENTORY_ITEMS.FILTERS.WARRANTY_EXPIRATION_TO',
  [InventoryFilters.contractNumber]: 'INVENTORY_ITEMS.FILTERS.CONTRACT_NUMBER',
  [InventoryFilters.price]: 'INVENTORY_ITEMS.FILTERS.PRICE',
  [InventoryFilters.priceComparisonType]: 'INVENTORY_ITEMS.FILTERS.PRICE_COMPARISON_TYPE',
  [InventoryFilters.assigneeId]: 'INVENTORY_ITEMS.FILTERS.ASSIGNEE',
  [InventoryFilters.statusId]: 'INVENTORY_ITEMS.FILTERS.STATUS',
  [InventoryFilters.note]: 'INVENTORY_ITEMS.FILTERS.NOTE',
};
