import type { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { EMPTY } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const url = req.url;
  if (url.startsWith(environment.auth.issuer)) return next(req);

  if (!url.startsWith(environment.apiUrl)) return next(req);

  const authService = inject(AuthService);
  if (!authService.isLoggedIn()) return EMPTY;

  const token = authService.getToken();
  const headers = req.headers.set('Authorization', `Bearer ${token}`);
  return next(req.clone({ headers }));
};
