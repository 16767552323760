import type { User } from './user.model';

export interface Group {
  id: number;
  name: string;
  type: GroupType;
  users: User[];
  availableBudget: number;
}

export interface CreateGroup {
  name: string;
  type: GroupType;
  members: number[];
}

export interface EditGroup {
  name: string;
  type: GroupType;
}

export interface AddGroupMembers {
  members: number[];
}

export enum GroupType {
  LOCATION = 'LOCATION',
  PRODUCT_TEAM = 'PRODUCT_TEAM',
  ROLE = 'ROLE',
}
