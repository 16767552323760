import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type {
  CreateInventoryStatus,
  EditInventoryStatus,
  InventoryStatus,
} from '../../models/api/inventory-status.model';

@Injectable({
  providedIn: 'root',
})
export class InventoryStatusService {
  private readonly httpClient = inject(HttpClient);
  private readonly api = `${environment.apiUrl}/v1/codebooks/inventory-status`;

  getInventoryStatuses(): Observable<InventoryStatus[]> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Inventory Statuses');

    return this.httpClient.get<InventoryStatus[]>(this.api, { context });
  }

  createInventoryStatus(status: CreateInventoryStatus): Observable<InventoryStatus> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Create Inventory Status');

    return this.httpClient.post<InventoryStatus>(this.api, status, { context });
  }

  editInventoryStatus(id: number, status: EditInventoryStatus): Observable<InventoryStatus> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Edit Inventory Status');

    return this.httpClient.put<InventoryStatus>(`${this.api}/${id}`, status, { context });
  }

  deleteInventoryStatus(id: number): Observable<void> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Delete Inventory Status. Please make sure there are no inventory items associated with this status',
    );

    return this.httpClient.delete<void>(`${this.api}/${id}`, { context });
  }
}
