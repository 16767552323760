import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject, type OnDestroy } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  EMPTY,
  distinctUntilChanged,
  merge,
  of,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import { objectsEqual } from '../../shared/helpers/objects-equal.helper';
import type { InventoryItem } from '../../shared/models/api/inventory-item.model';
import { InventoryItemsService } from '../../shared/services/api/inventory-items.service';
import { AuthService } from '../../shared/services/auth.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { AssignInventoryItemComponent } from '../inventory-item-list/assign-inventory-item/assign-inventory-item.component';
import { UnassignInventoryItemComponent } from '../inventory-item-list/unassign-inventory-item/unassign-inventory-item.component';

@Component({
  selector: 'opa-inventory-item-details',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    LetDirective,
    AssignInventoryItemComponent,
    UnassignInventoryItemComponent,
  ],
  templateUrl: './inventory-item-details.component.html',
  styleUrl: './inventory-item-details.component.scss',
})
export class InventoryItemDetailsComponent implements OnDestroy {
  private readonly inventoryItemsService = inject(InventoryItemsService);
  private readonly route = inject(ActivatedRoute);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  readonly isInventoryAdmin = inject(AuthService).isInventoryAdmin();
  readonly mediaMaxXs$ = inject(MediaQueryService).max('xs');
  private readonly subSink = new SubSink();

  inventoryItem$ = this.getInventoryItem();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  reloadInventoryItem(markForCheck = false): void {
    this.inventoryItem$ = this.getInventoryItem();
    if (markForCheck) this.changeDetectorRef.markForCheck();
  }

  downloadWarranty(itemId: number): void {
    this.subSink.sink = this.inventoryItemsService
      .downloadInventoryItemWarranty(itemId)
      .subscribe();
  }

  downloadReceipt(itemId: number): void {
    this.subSink.sink = this.inventoryItemsService.downloadInventoryItemReceipt(itemId).subscribe();
  }

  private getInventoryItem(): Observable<InventoryItem> {
    const routeParams$ = merge(
      of(this.route.snapshot.params),
      this.route.params.pipe(
        startWith(this.route.snapshot.params),
        distinctUntilChanged((a, b) => objectsEqual(a, b)),
        skip(1),
        tap(() => this.reloadInventoryItem(true)),
        switchMap(() => EMPTY),
      ),
    );

    return routeParams$.pipe(
      switchMap((routeParams) => {
        const id = Number(routeParams['id']);
        return this.inventoryItemsService.getInventoryItem(id);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
