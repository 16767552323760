import { CommonModule } from '@angular/common';
import { Component, inject, type OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import type { CreateBankCard } from '../../shared/models/api/bank-card.model';
import type { User } from '../../shared/models/api/user.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { BankCardService } from '../../shared/services/api/bank-card.service';
import { UserService } from '../../shared/services/api/user.service';
import { OpaValidators } from '../../shared/validators/opa.validators';
import type { CreateBankCardData, CreateBankCardFormValue } from './bank-card-create.models';

@Component({
  selector: 'opa-bank-card-create',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, ReactiveFormsModule, LetDirective],
  templateUrl: './bank-card-create.component.html',
  styleUrl: './bank-card-create.component.scss',
})
export class BankCardCreateComponent implements OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly userService = inject(UserService);
  private readonly bankCardService = inject(BankCardService);
  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  data$ = this.getData$();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  reloadData(): void {
    this.data$ = this.getData$();
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const bankCard = this.formValueToBankCard(formValue);
    this.subSink.sink = this.bankCardService
      .createBankCard(bankCard)
      .pipe(
        tap({
          next: (createdBankCard) => {
            this.toastManager.addMessage({
              text: `Bank Card ${createdBankCard.cardNumber} created`,
              state: 'success',
            });
            this.router.navigate(['/bank-cards']);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<CreateBankCardFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<CreateBankCardFormValue>>({
      userId: this.formBuilder.control(null, OpaValidators.required()),
      cardNumber: this.formBuilder.control(null, [
        OpaValidators.required(),
        OpaValidators.minLength(4),
        OpaValidators.maxLength(4),
      ]),
      dailyLimit: this.formBuilder.control(null, [
        OpaValidators.required(),
        OpaValidators.min(0),
        OpaValidators.maxDecimals(2),
      ]),
      pos: this.formBuilder.control(false, OpaValidators.required()),
      atm: this.formBuilder.control(false, OpaValidators.required()),
      bankingPermissions: this.formBuilder.control(false, OpaValidators.required()),
      createPaymentWarrants: this.formBuilder.control(false, OpaValidators.required()),
      authorizePaymentWarrants: this.formBuilder.control(false, OpaValidators.required()),
      mobileApp: this.formBuilder.control(false, OpaValidators.required()),
      expirationDate: this.formBuilder.control(null, OpaValidators.required()),
      active: this.formBuilder.control(false, OpaValidators.required()),
    });
    return form;
  }

  private formValueToBankCard(form: CreateBankCardFormValue): CreateBankCard {
    const bankCard: CreateBankCard = {
      userId: Number(form.userId!),
      cardNumber: form.cardNumber!,
      dailyLimit: Number(form.dailyLimit!),
      pos: form.pos!,
      atm: form.atm!,
      bankingPermissions: form.bankingPermissions!,
      createPaymentWarrants: form.createPaymentWarrants!,
      authorizePaymentWarrants: form.authorizePaymentWarrants!,
      mobileApp: form.mobileApp!,
      expirationDate: form.expirationDate!,
      active: form.active!,
    };
    return bankCard;
  }

  private getData$(): Observable<CreateBankCardData> {
    const users$ = this.userService.getActiveUsers();
    const submitting$ = this.submitting.pipe(distinctUntilChanged());
    return combineLatest([users$, submitting$]).pipe(
      map(([users, submitting]) => ({ users, submitting })),
    );
  }
}
