import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import {
  PorscheDesignSystemModule,
  ToastManager,
  type TableHeadCellSort,
} from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  of,
  shareReplay,
  skip,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import { objectsEqual } from '../../shared/helpers/objects-equal.helper';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import type { SortQuery } from '../../shared/models/sort-query.model';
import { FillArrayPipe } from '../../shared/pipes/fill-array.pipe';
import { ReportService } from '../../shared/services/api/report.service';
import { UserService } from '../../shared/services/api/user.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import type { UserListData, UserListFormValue } from './user-list.models';

@Component({
  selector: 'opa-user-list',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    ReactiveFormsModule,
    LetDirective,
    FillArrayPipe,
  ],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss',
})
export class UserListComponent {
  private readonly mediaQueryService = inject(MediaQueryService);
  private readonly userService = inject(UserService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly toastManager = inject(ToastManager);
  private readonly reportService = inject(ReportService);

  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  private readonly pageNo = new BehaviorSubject(1);
  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly exporting = new BehaviorSubject<boolean>(false);
  readonly exporting$ = this.exporting.asObservable();
  private readonly statusSort = new BehaviorSubject<Required<TableHeadCellSort>>({
    id: 'active',
    active: false,
    direction: 'asc',
  });
  readonly statusSort$ = this.statusSort.asObservable();
  private readonly loading = new BehaviorSubject(false);
  readonly loading$ = this.loading.pipe(distinctUntilChanged());

  data$ = this.getData$();

  reloadData(): void {
    this.data$ = this.getData$();
  }

  updateSort(event: TableHeadCellSort): void {
    if (event.id === this.statusSort.value.id) {
      const sort = { ...this.statusSort.value };
      if (!sort.active) {
        sort.active = true;
        sort.direction = 'asc';
      } else if (sort.direction === 'asc') {
        sort.direction = 'desc';
      } else if (sort.direction === 'desc') {
        sort.active = false;
        sort.direction = 'asc';
      }
      this.statusSort.next(sort);
    }
  }

  trackRow(index: number): number {
    return index;
  }

  updatePageNo(page: number): void {
    this.pageNo.next(page);
  }

  exportUsers(): void {
    const form = this.form.getRawValue();
    const name = form.userName ?? undefined;

    this.exporting.next(true);
    this.subSink.sink = this.reportService
      .getUsers(name)
      .pipe(
        tap({
          next: () => {
            this.toastManager.addMessage({
              text: 'Members exported',
              state: 'success',
            });
            this.exporting.next(false);
          },
          error: () => this.exporting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<UserListFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<UserListFormValue>>({
      userName: this.formBuilder.control(null),
    });
    return form;
  }

  private getData$(): Observable<UserListData> {
    const usersStatusCount$ = this.userService.getUsersStatusCount();

    const pageNo$ = this.pageNo.pipe(distinctUntilChanged());

    const form$ = merge(
      of(this.form.getRawValue()),
      merge(
        this.form.controls.userName.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)),
      ).pipe(
        map(() => this.form.getRawValue()),
        tap(() => this.updatePageNo(1)),
      ),
    );

    const statusSort$ = merge(
      of(this.statusSort.value),
      this.statusSort.pipe(
        distinctUntilChanged((a, b) => objectsEqual(a, b)),
        skip(1),
        tap(() => this.updatePageNo(1)),
      ),
    );

    const users$ = combineLatest([pageNo$, form$, statusSort$]).pipe(
      debounceTime(0),
      tap(() => this.loading.next(true)),
      switchMap(([pageNo, form, statusSort]) => {
        const userName = form.userName ?? undefined;
        const sort: SortQuery[] = statusSort.active
          ? [{ property: statusSort.id, direction: statusSort.direction }]
          : [];
        return this.userService.getUsers(pageNo, userName, sort);
      }),
      tap({
        next: () => this.loading.next(false),
        error: () => this.loading.next(false),
      }),
    );

    return combineLatest([usersStatusCount$, users$]).pipe(
      map(([usersStatusCount, users]) => ({ usersStatusCount, users })),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
