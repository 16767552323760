import { coerceNumberProperty, type NumberInput } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Directive,
  inject,
  Input,
  TemplateRef,
  ViewContainerRef,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { delay, of, tap } from 'rxjs';
import { SubSink } from 'subsink';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[deferLoading]',
  standalone: true,
})
export class DeferLoadingDirective implements OnInit, OnDestroy {
  private readonly templateRef = inject(TemplateRef);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  @Input() deferLoading: NumberInput = 250;

  private readonly subSink = new SubSink();

  ngOnInit(): void {
    const time = coerceNumberProperty(this.deferLoading);
    this.subSink.sink = of(undefined)
      .pipe(
        delay(time),
        tap(() => this.viewContainerRef.createEmbeddedView(this.templateRef)),
        tap(() => this.changeDetectorRef.markForCheck()),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
