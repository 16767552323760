import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject, type OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { SubSink } from 'subsink';
import type {
  AssignInventoryItem,
  InventoryItem,
} from '../../../shared/models/api/inventory-item.model';
import type { User } from '../../../shared/models/api/user.model';
import type { ControlsOf } from '../../../shared/models/controls-of.model';
import { InventoryItemsService } from '../../../shared/services/api/inventory-items.service';
import { UserService } from '../../../shared/services/api/user.service';
import { OpaValidators } from '../../../shared/validators/opa.validators';
import type { AssignInventoryItemFormValue } from './assign-inventory-item.models';

@Component({
  selector: 'opa-assign-inventory-item',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, ReactiveFormsModule, LetDirective],
  templateUrl: './assign-inventory-item.component.html',
  styleUrl: './assign-inventory-item.component.scss',
})
export class AssignInventoryItemComponent implements OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly inventoryItemsService = inject(InventoryItemsService);
  private readonly toastManager = inject(ToastManager);
  readonly users$ = inject(UserService).getActiveUsers();

  @Input({ required: true }) inventoryItem?: InventoryItem;
  @Output() assignSuccessful = new EventEmitter<undefined>();

  open = false;
  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.pipe(distinctUntilChanged());

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(success?: boolean): void {
    this.open = false;
    this.form.reset();

    if (success) this.assignSuccessful.emit();
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const assignRequest = this.formValueToUnassignRequest(formValue);
    this.subSink.sink = this.inventoryItemsService
      .assign(assignRequest)
      .pipe(
        tap({
          next: (item: InventoryItem) => {
            this.toastManager.addMessage({
              text: `Successfully assigned item to ${item.assignee?.firstName} ${item.assignee?.lastName}`,
              state: 'success',
            });
            this.submitting.next(false);
            this.close(true);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<AssignInventoryItemFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<AssignInventoryItemFormValue>>({
      assigneeId: this.formBuilder.control(null, OpaValidators.required()),
    });

    return form;
  }

  private formValueToUnassignRequest(form: AssignInventoryItemFormValue): AssignInventoryItem {
    const request: AssignInventoryItem = {
      assigneeId: form.assigneeId!,
      inventoryItemId: this.inventoryItem!.id,
    };

    return request;
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }
}
