export function objectsEqual<T>(x: T, y: T): boolean {
  if (x == null || y == null) return x === y;

  const obj1 = x as Record<string, unknown>;
  const obj2 = y as Record<string, unknown>;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;

  const equal = keys1.every((key1) => {
    if (!Object.prototype.hasOwnProperty.call(obj2, key1)) return false;

    return obj1[key1] === obj2[key1];
  });

  return equal;
}
