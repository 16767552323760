import { Validators, type AbstractControl, type ValidatorFn } from '@angular/forms';
import type { ExtendedValidationErrors } from '../models/extended-validation-errors.model';

export class OpaValidators {
  static required(): ValidatorFn {
    return (control: AbstractControl): ExtendedValidationErrors | null => {
      const errors = Validators.required(control);
      return errors != null ? { ...errors, message: 'This field is required' } : null;
    };
  }

  static email(): ValidatorFn {
    return (control: AbstractControl): ExtendedValidationErrors | null => {
      const errors = Validators.email(control);
      return errors != null ? { ...errors, message: `Email format is invalid` } : null;
    };
  }

  static min(min: number): ValidatorFn {
    return (control: AbstractControl): ExtendedValidationErrors | null => {
      const errors = Validators.min(min)(control);
      return errors != null ? { ...errors, message: `Minimum allowed value is ${min}` } : null;
    };
  }

  static max(max: number): ValidatorFn {
    return (control: AbstractControl): ExtendedValidationErrors | null => {
      const errors = Validators.max(max)(control);
      return errors != null ? { ...errors, message: `Maximum allowed value is ${max}` } : null;
    };
  }

  static maxDecimals(max: number): ValidatorFn {
    return (control: AbstractControl): ExtendedValidationErrors | null => {
      const regex = max === 0 ? /^-?\d*$/ : new RegExp(`^-?\\d*(\\.\\d{1,${max}})?$`);
      const errors = Validators.pattern(regex)(control);
      return errors != null ? { ...errors, message: `Maximum ${max} decimals are allowed` } : null;
    };
  }

  static minLength(min: number): ValidatorFn {
    return (control: AbstractControl): ExtendedValidationErrors | null => {
      const errors = Validators.minLength(min)(control);
      return errors != null ? { ...errors, message: `Minimum allowed length is ${min}` } : null;
    };
  }

  static maxLength(max: number): ValidatorFn {
    return (control: AbstractControl): ExtendedValidationErrors | null => {
      const errors = Validators.maxLength(max)(control);
      return errors != null ? { ...errors, message: `Max allowed length is ${max}` } : null;
    };
  }

  static pattern(regex: string | RegExp, message: string): ValidatorFn {
    return (control: AbstractControl): ExtendedValidationErrors | null => {
      const errors = Validators.pattern(regex)(control);
      return errors != null ? { ...errors, message } : null;
    };
  }

  static maxDate(date: Date): ValidatorFn {
    return (control: AbstractControl): ExtendedValidationErrors | null => {
      const userDate = new Date(control.value);
      const errors = userDate > date ? { maxDate: true } : null;

      return errors != null
        ? { ...errors, message: `Max allowed date is ${date.toLocaleDateString()}` }
        : null;
    };
  }

  static isNormalized(): ValidatorFn {
    return (control: AbstractControl): ExtendedValidationErrors | null => {
      const regex = /^[A-Z0-9_]*$/;
      const errors = Validators.pattern(regex)(control);
      return errors != null
        ? { ...errors, message: `Only uppercase letters, numbers and '_' are allowed` }
        : null;
    };
  }
}
