import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  ReplaySubject,
  distinctUntilChanged,
  shareReplay,
  tap,
  type Observable,
} from 'rxjs';
import type { InventoryOwner } from '../../shared/models/api/inventory-owner.model';
import { InventoryOwnerService } from '../../shared/services/api/inventory-owner.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { InventoryOwnerCreateComponent } from './inventory-owner-create/inventory-owner-create.component';
import { InventoryOwnerDeleteComponent } from './inventory-owner-delete/inventory-owner-delete.component';
import { InventoryOwnerEditComponent } from './inventory-owner-edit/inventory-owner-edit.component';
@Component({
  selector: 'opa-inventory-item-owner-list',
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    CommonModule,
    RouterLink,
    LetDirective,
    InventoryOwnerCreateComponent,
    InventoryOwnerEditComponent,
    InventoryOwnerDeleteComponent,
  ],
  templateUrl: './inventory-item-owner-list.component.html',
  styleUrl: './inventory-item-owner-list.component.scss',
})
export class InventoryItemOwnerListComponent {
  readonly mediaQueryService = inject(MediaQueryService);
  private readonly inventoryOwnerService = inject(InventoryOwnerService);

  private readonly loading = new BehaviorSubject(false);
  private readonly inventoryOwner = new ReplaySubject<InventoryOwner | null>(1);
  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  readonly loading$ = this.loading.pipe(distinctUntilChanged());
  readonly inventoryOwner$ = this.inventoryOwner.asObservable();

  data$ = this.getData$();
  showCreateModal = false;
  showDeleteModal = false;
  showEditModal = false;

  trackInventoryOwner(index: number, owner: InventoryOwner): number {
    return owner.id;
  }

  reloadData(): void {
    this.data$ = this.getData$();
  }

  private getData$(): Observable<InventoryOwner[]> {
    this.loading.next(true);

    return this.inventoryOwnerService.getInventoryOwners().pipe(
      tap({
        next: () => this.loading.next(false),
        error: () => this.loading.next(false),
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  openDeleteModal(inventoryOwner: InventoryOwner): void {
    this.inventoryOwner.next(inventoryOwner);
    this.showDeleteModal = true;
  }

  closeDeleteModal(inventoryOwner?: InventoryOwner): void {
    this.showDeleteModal = false;
    if (inventoryOwner) this.reloadData();
    this.inventoryOwner.next(null);
  }

  openEditModal(inventoryOwner: InventoryOwner): void {
    this.inventoryOwner.next(inventoryOwner);
    this.showEditModal = true;
  }

  closeEditModal(success?: boolean): void {
    this.showEditModal = false;
    if (success) this.reloadData();
    this.inventoryOwner.next(null);
  }
}
