import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type {
  CreateInventoryLocation,
  EditInventoryLocation,
  InventoryLocation,
} from '../../models/api/inventory-location.model';

@Injectable({
  providedIn: 'root',
})
export class InventoryLocationService {
  private readonly httpClient = inject(HttpClient);
  private readonly api = `${environment.apiUrl}/v1/codebooks/inventory-location`;

  getInventoryLocations(): Observable<InventoryLocation[]> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Inventory Locations');

    return this.httpClient.get<InventoryLocation[]>(this.api, { context });
  }

  createInventoryLocation(location: CreateInventoryLocation): Observable<InventoryLocation> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Create Inventory Location',
    );

    return this.httpClient.post<InventoryLocation>(this.api, location, { context });
  }

  deleteInventoryLocation(id: number): Observable<void> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Delete Inventory Location. Please make sure there are no inventory items associated with this location.',
    );

    return this.httpClient.delete<void>(`${this.api}/${id}`, { context });
  }

  editInventoryLocation(
    id: number,
    location: EditInventoryLocation,
  ): Observable<InventoryLocation> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Edit Inventory Location');

    return this.httpClient.put<InventoryLocation>(`${this.api}/${id}`, location, { context });
  }
}
