<p-button-pure
  *ngIf="inventoryItem && inventoryItem?.assignee"
  [icon]="'none'"
  [underline]="true"
  (click)="open = true"
>
  Unassign
</p-button-pure>

<p-modal [heading]="'Unassign inventory item'" [open]="open" (dismiss)="close()">
  <form class="form" [formGroup]="form" (ngSubmit)="submit()">
    <div class="form-controls">
      <p-select-wrapper
        *ngrxLet="form.controls.locationId as control"
        [filter]="true"
        [label]="'Item returned to location: '"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <select [attr.required]="true" [formControl]="control">
          <option
            *ngFor="let location of locations$ | async; trackBy: trackInventoryLocation"
            [value]="location.id"
          >
            {{ location.description }}
          </option>
        </select>
      </p-select-wrapper>
    </div>

    <p-button-group class="modal-actions">
      <p-button
        [icon]="'none'"
        [loading]="(submitting$ | async) ?? false"
        [type]="'submit'"
        [variant]="'primary'"
      >
        Unassign
      </p-button>
      <p-button
        [iconSource]="'assets/icons/close.svg'"
        [type]="'button'"
        [variant]="'secondary'"
        (click)="close()"
      >
        Close
      </p-button>
    </p-button-group>
  </form>
</p-modal>
