import { CommonModule } from '@angular/common';
import { Component, inject, type OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  startWith,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import type { User } from '../../shared/models/api/user.model';
import type { CreateVpnPhoneNumber } from '../../shared/models/api/vpn-phone-number.model';
import type { VpnSubscriptionType } from '../../shared/models/api/vpn-subscription-type.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { UserService } from '../../shared/services/api/user.service';
import { VpnPhoneNumberService } from '../../shared/services/api/vpn-phone-number.service';
import { VpnSubscriptionTypeService } from '../../shared/services/api/vpn-subscription-type.service';
import { OpaValidators } from '../../shared/validators/opa.validators';
import type {
  CreateVpnPhoneNumberData,
  CreateVpnPhoneNumberFormValue,
} from './vpn-phone-number-create.models';

@Component({
  selector: 'opa-vpn-phone-number-create',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, ReactiveFormsModule, LetDirective],
  templateUrl: './vpn-phone-number-create.component.html',
  styleUrl: './vpn-phone-number-create.component.scss',
})
export class VpnPhoneNumberCreateComponent implements OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly userService = inject(UserService);
  private readonly vpnSubscriptionTypeService = inject(VpnSubscriptionTypeService);
  private readonly vpnPhoneNumberService = inject(VpnPhoneNumberService);
  private readonly toastManager = inject(ToastManager);
  private readonly router = inject(Router);

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  data$ = this.getData$();

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  reloadData(): void {
    this.data$ = this.getData$();
  }

  trackUser(index: number, user: User): number {
    return user.id;
  }

  trackVpnSubscriptionType(index: number, vpnSubscriptionType: VpnSubscriptionType): number {
    return vpnSubscriptionType.id;
  }

  submit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const vpnPhoneNumber = this.formValueToVpnPhoneNumber(formValue);
    this.subSink.sink = this.vpnPhoneNumberService
      .createVpnPhoneNumber(vpnPhoneNumber)
      .pipe(
        tap({
          next: (createdVpnPhoneNumber) => {
            this.toastManager.addMessage({
              text: `VPN Phone Number ${createdVpnPhoneNumber.vpnNumber} created`,
              state: 'success',
            });
            this.router.navigate(['/vpn-phone-numbers']);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<CreateVpnPhoneNumberFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<CreateVpnPhoneNumberFormValue>>({
      userId: this.formBuilder.control(null, OpaValidators.required()),
      vpnNumber: this.formBuilder.control(null, OpaValidators.required()),
      fullNumber: this.formBuilder.control(null, OpaValidators.required()),
      subscriptionTypeId: this.formBuilder.control(null, OpaValidators.required()),
      additionalOptionActivation: this.formBuilder.control(false, OpaValidators.required()),
      additionalOptionDescription: this.formBuilder.control(null),
      contractObligation: this.formBuilder.control(false, OpaValidators.required()),
      contractStartDate: this.formBuilder.control(null),
      contractEndDate: this.formBuilder.control(null),
      active: this.formBuilder.control(false, OpaValidators.required()),
    });

    this.subSink.sink = form.controls.additionalOptionActivation.valueChanges
      .pipe(startWith(form.controls.additionalOptionActivation.value), distinctUntilChanged())
      .subscribe((value) => {
        const control = form.controls.additionalOptionDescription;
        if (value) control.setValidators(OpaValidators.required());
        else control.clearValidators();
        control.reset(control.value);
      });

    this.subSink.sink = form.controls.contractObligation.valueChanges
      .pipe(startWith(form.controls.contractObligation.value), distinctUntilChanged())
      .subscribe((value) => {
        const startControl = form.controls.contractStartDate;
        const endControl = form.controls.contractEndDate;
        if (value) {
          startControl.setValidators(OpaValidators.required());
          endControl.setValidators(OpaValidators.required());
        } else {
          startControl.clearValidators();
          endControl.clearValidators();
        }
        startControl.reset(startControl.value);
        endControl.reset(endControl.value);
      });
    return form;
  }

  private formValueToVpnPhoneNumber(form: CreateVpnPhoneNumberFormValue): CreateVpnPhoneNumber {
    const vpnPhoneNumber: CreateVpnPhoneNumber = {
      userId: Number(form.userId!),
      vpnNumber: form.vpnNumber!,
      fullNumber: form.fullNumber!,
      subscriptionTypeId: Number(form.subscriptionTypeId!),
      additionalOptionActivation: form.additionalOptionActivation!,
      additionalOptionDescription: form.additionalOptionDescription!,
      contractObligation: form.contractObligation!,
      contractStartDate: form.contractStartDate!,
      contractEndDate: form.contractEndDate!,
      active: form.active!,
    };
    return vpnPhoneNumber;
  }

  private getData$(): Observable<CreateVpnPhoneNumberData> {
    const users$ = this.userService.getActiveUsers();
    const vpnSubscriptionTypes$ = this.vpnSubscriptionTypeService
      .getVpnSubscriptionTypes(null)
      .pipe(map((types) => types.content));
    const submitting$ = this.submitting.pipe(distinctUntilChanged());
    return combineLatest([users$, vpnSubscriptionTypes$, submitting$]).pipe(
      map(([users, vpnSubscriptionTypes, submitting]) => ({
        users,
        vpnSubscriptionTypes,
        submitting,
      })),
    );
  }
}
