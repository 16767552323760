import { Pipe, type PipeTransform } from '@angular/core';
import type { EduExpenseCategory } from '../../shared/models/api/edu-expense-category.model';
import type { BudgetType } from '../../shared/models/api/edu-expense.model';

@Pipe({
  name: 'opaEduExpenseCategoryFilter',
  standalone: true,
})
export class EduExpenseCategoryFilterPipe implements PipeTransform {
  transform(categories: EduExpenseCategory[], budgetType: BudgetType | null): EduExpenseCategory[] {
    return categories?.filter((category) => category.budgetType === budgetType);
  }
}
