import { coerceBooleanProperty, type BooleanInput } from '@angular/cdk/coercion';
import {
  Directive,
  ElementRef,
  inject,
  Input,
  type OnChanges,
  type OnDestroy,
} from '@angular/core';
import tippy, { type Instance, type Placement, type Props } from 'tippy.js';
import type { NgChanges } from '../models/ng-changes.model';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tippy]',
  standalone: true,
})
export class TippyDirective implements OnChanges, OnDestroy {
  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  @Input() tippy: string | null | undefined;
  @Input() tippyDisabled: BooleanInput;
  @Input() tippyPlacement: Placement | null | undefined;

  private readonly defaultPlacement: Placement = 'bottom';
  private tooltip: Instance<Props> | undefined;

  ngOnChanges(changes: NgChanges<TippyDirective>): void {
    if (!this.tooltip)
      this.tooltip = tippy(this.elementRef.nativeElement, {
        placement: this.defaultPlacement,
        animation: false,
        offset: [0, 16], // adjust based on arrow size (in css)
      });

    if (changes.tippy) this.tooltip.setContent(this.tippy ?? '');

    if (changes.tippy || changes.tippyDisabled) {
      const disabled = !this.tippy || coerceBooleanProperty(this.tippyDisabled);
      if (disabled) this.tooltip.disable();
      else this.tooltip.enable();
    }

    if (changes.tippyPlacement)
      this.tooltip.setProps({ placement: this.tippyPlacement ?? this.defaultPlacement });
  }

  ngOnDestroy(): void {
    this.tooltip?.destroy();
  }
}
