import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  type OnChanges,
  type OnDestroy,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { SubSink } from 'subsink';
import { GroupType, type EditGroup, type Group } from '../../../shared/models/api/group.model';
import type { ControlsOf } from '../../../shared/models/controls-of.model';
import type { NgChanges } from '../../../shared/models/ng-changes.model';
import { GroupService } from '../../../shared/services/api/group.service';
import { OpaValidators } from '../../../shared/validators/opa.validators';
import { groupTypeTranslation } from '../../group-list/group-list.helpers';
import type { EditGroupFormValue } from './group-edit.models';

@Component({
  selector: 'opa-group-edit',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    ReactiveFormsModule,
    LetDirective,
    TranslocoPipe,
  ],
  templateUrl: './group-edit.component.html',
  styleUrl: './group-edit.component.scss',
})
export class GroupEditComponent implements OnChanges, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly groupService = inject(GroupService);
  private readonly toastManager = inject(ToastManager);
  readonly groupTypes = Object.values(GroupType);
  readonly groupTypeTranslation = groupTypeTranslation;

  @Input({ required: true }) open = false;
  @Input({ required: true }) group: Group | undefined = undefined;
  @Output() dismiss = new EventEmitter<Group | undefined>();

  private readonly subSink = new SubSink();
  readonly form = this.buildForm();
  private readonly submitting = new BehaviorSubject(false);
  readonly submitting$ = this.submitting.pipe(distinctUntilChanged());

  ngOnChanges(changes: NgChanges<GroupEditComponent>): void {
    if ((changes.open && this.open) || changes.group) this.resetForm(this.group);
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  close(group?: Group): void {
    this.dismiss.emit(group);
  }

  submit(): void {
    const group = this.group;
    if (!group) return;

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitting.next(true);
    const formValue = this.form.getRawValue();
    const editGroup = this.formValueToGroup(formValue);
    this.subSink.sink = this.groupService
      .editGroup(group.id, editGroup)
      .pipe(
        tap({
          next: (updatedGroup) => {
            this.toastManager.addMessage({
              text: `Group ${updatedGroup.name} updated`,
              state: 'success',
            });
            this.submitting.next(false);
            this.close(updatedGroup);
          },
          error: () => this.submitting.next(false),
        }),
      )
      .subscribe();
  }

  private buildForm(): FormGroup<ControlsOf<EditGroupFormValue>> {
    const form = this.formBuilder.nonNullable.group<ControlsOf<EditGroupFormValue>>({
      name: this.formBuilder.control(null, OpaValidators.required()),
      type: this.formBuilder.control(null, OpaValidators.required()),
    });
    return form;
  }

  private formValueToGroup(form: EditGroupFormValue): EditGroup {
    const group: EditGroup = {
      name: form.name!,
      type: form.type!,
    };
    return group;
  }

  private resetForm(group?: Group): void {
    if (!group) {
      this.form.reset();
      return;
    }

    this.form.reset({
      name: group.name,
      type: group.type,
    });
  }
}
