import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type { CreateVendor, EditVendor, Vendor } from '../../models/api/vendor.model';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  private readonly httpClient = inject(HttpClient);
  private readonly vendorApi = `${environment.apiUrl}/v1/vendors`;

  getVendors(): Observable<Vendor[]> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Vendors');
    return this.httpClient.get<Vendor[]>(this.vendorApi, { context });
  }

  createVendor(vendor: CreateVendor): Observable<Vendor> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Create Vendor');
    return this.httpClient.post<Vendor>(this.vendorApi, vendor, { context });
  }

  editVendor(id: number, vendor: EditVendor): Observable<Vendor> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Edit Vendor');
    return this.httpClient.put<Vendor>(`${this.vendorApi}/${id}`, vendor, { context });
  }

  deleteVendor(id: number): Observable<void> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Delete Vendor');
    return this.httpClient.delete<void>(`${this.vendorApi}/${id}`, { context });
  }
}
