<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Edu & Equ Transactions</p-heading>

  <p-link
    *ngIf="isEduEquAdmin"
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/edu-transactions/create'">Create New Transaction</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="section">
  <div class="breadcrumbs">
    <p-link-pure [hideLabel]="true" [iconSource]="'assets/icons/arrow-left.svg'">
      <a [routerLink]="isEduEquAdmin ? '/edu-transactions' : '/edu-budget'">
        {{ isEduEquAdmin ? 'All Transactions' : 'My Transactions' }}
      </a>
    </p-link-pure>

    <p-heading *ngrxLet="eduExpense$ | async as eduExpense" [size]="'large'" [tag]="'h4'">
      <span *ngIf="!eduExpense; else expenseTitle">{{ '&nbsp;' }}</span>

      <ng-template #expenseTitle>
        <span>{{ eduExpense!.user.firstName }} {{ eduExpense!.user.lastName }}</span>
        <span> ({{ eduExpense!.dateUsed | date: 'dd.MM.yyyy' }})</span>
      </ng-template>
    </p-heading>
  </div>
</section>

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/info.svg'" />
      <span>Edu & Equ Transaction Details</span>
    </div>
  </p-heading>

  <ng-container
    *ngrxLet="eduExpense$ as eduExpense; error as eduExpenseError; suspenseTpl: spinner"
  >
    <div *ngIf="eduExpenseError">
      <p-button-pure
        [hideLabel]="true"
        [iconSource]="'assets/icons/refresh.svg'"
        [size]="'large'"
        (click)="reloadEduExpense()"
      >
        Reload Transaction Details
      </p-button-pure>
    </div>

    <div *ngIf="!eduExpenseError && eduExpense" class="expense-details">
      <div>
        <p-text [weight]="'semi-bold'">Person</p-text>
        <p-text>{{ eduExpense.user.firstName }} {{ eduExpense.user.lastName }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Type:</p-text>
        <p-text>{{ budgetTypeTranslation[eduExpense.budget] | transloco }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Category:</p-text>
        <p-text>{{ eduExpense.category || '-' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Purpose:</p-text>
        <p-text>{{ eduExpense.purpose || '-' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Date:</p-text>
        <p-text>{{ eduExpense.dateUsed | date: 'dd.MM.yyyy' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Amount:</p-text>
        <p-text>
          {{ eduExpense.amount | currency: 'EUR' | leadingPlus }}
        </p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Total Budget Before:</p-text>
        <p-text>{{ eduExpense.totalBudgetBefore | currency: 'EUR' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Total Budget After:</p-text>
        <p-text>{{ eduExpense.totalBudgetAfter | currency: 'EUR' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Total Budget Over Cap:</p-text>
        <p-text>{{ eduExpense.totalBudgetOverCap | currency: 'EUR' }}</p-text>
      </div>

      <div>
        <p-text [weight]="'semi-bold'">Productive Reference Number / Link:</p-text>
        <p-text>{{ eduExpense.productiveLink }}</p-text>
      </div>

      <div *ngIf="eduExpense.description">
        <p-text [weight]="'semi-bold'">Description:</p-text>
        <p-text>{{ eduExpense.description }}</p-text>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
