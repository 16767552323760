import { Component, Input } from '@angular/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { TippyDirective } from '../../directives/tippy.directive';
import { UserIconColorPipe } from './user-icon-color.pipe';
import { UserIconInitialsPipe } from './user-icon-initials.pipe';

@Component({
  selector: 'opa-user-icon',
  standalone: true,
  imports: [PorscheDesignSystemModule, UserIconColorPipe, UserIconInitialsPipe, TippyDirective],
  templateUrl: './user-icon.component.html',
  styleUrl: './user-icon.component.scss',
})
export class UserIconComponent {
  @Input({ required: true }) name = '';
  @Input() isCounter = false;
}
