import { inject } from '@angular/core';
import type { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const superAdminGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  return authService.isSuperAdmin();
};

export const funGamesAdminGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  return authService.isFunGamesAdmin();
};

export const eduEquAdminGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  return authService.isEduEquAdmin();
};

export const inventoryAdminGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  return authService.isInventoryAdmin();
};

export const peopleAdminGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  return authService.isPeopleAdmin();
};

export const phoneNumbersAdminGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  return authService.isPhoneNumbersAdmin();
};

export const bankCardsAdminGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  return authService.isBankCardsAdmin();
};

export const remoteEmployeeGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  return authService.isRemoteEmployee();
};
