<div class="title">
  <p-heading [size]="'x-large'" [tag]="'h3'">Inventory</p-heading>

  <p-link
    *ngIf="isInventoryAdmin"
    [hideLabel]="(mediaMaxXs$ | async) ?? false"
    [iconSource]="'assets/icons/add.svg'"
    [variant]="'secondary'"
  >
    <a [routerLink]="'/inventory-items/create/bulk'">Bulk Create</a>
  </p-link>
</div>

<p-divider aria-hidden="true" />

<section class="section">
  <p-heading class="section-heading" [size]="'medium'" [tag]="'h4'">
    <div class="content">
      <p-icon [source]="'assets/icons/add-circle.svg'" />
      <span>Create New Item</span>
    </div>
  </p-heading>

  <opa-inventory-item-form (submitForm)="onSubmit($event)" />
</section>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
