<!-- TODO: get icons for arrow-up, arrow-down and sort -->
<p-button-pure
  [active]="!!direction"
  [hideLabel]="true"
  [iconSource]="
    direction === 'asc'
      ? 'assets/icons/arrow-up.svg'
      : direction === 'desc'
        ? 'assets/icons/arrow-down.svg'
        : 'assets/icons/sort.svg'
  "
  [size]="'x-small'"
  (click)="changeSort()"
>
  Sort
</p-button-pure>
