import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  ReplaySubject,
  distinctUntilChanged,
  shareReplay,
  tap,
  type Observable,
} from 'rxjs';
import type { InventoryLocation } from '../../shared/models/api/inventory-location.model';
import { InventoryLocationService } from '../../shared/services/api/inventory-location.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { InventoryLocationCreateComponent } from './inventory-location-create/inventory-location-create.component';
import { InventoryLocationDeleteComponent } from './inventory-location-delete/inventory-location-delete.component';
import { InventoryLocationEditComponent } from './inventory-location-edit/inventory-location-edit.component';

@Component({
  selector: 'opa-inventory-item-location-list',
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    CommonModule,
    RouterLink,
    LetDirective,
    InventoryLocationCreateComponent,
    InventoryLocationEditComponent,
    InventoryLocationDeleteComponent,
  ],
  templateUrl: './inventory-item-location-list.component.html',
  styleUrl: './inventory-item-location-list.component.scss',
})
export class InventoryItemLocationListComponent {
  readonly mediaQueryService = inject(MediaQueryService);
  private readonly inventoryLocationService = inject(InventoryLocationService);

  private readonly loading = new BehaviorSubject(false);
  private readonly inventoryLocation = new ReplaySubject<InventoryLocation | null>(1);
  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  readonly loading$ = this.loading.pipe(distinctUntilChanged());
  readonly inventoryLocation$ = this.inventoryLocation.asObservable();

  data$ = this.getData$();
  showCreateModal = false;
  showDeleteModal = false;
  showEditModal = false;

  trackInventoryLocation(index: number, location: InventoryLocation): number {
    return location.id;
  }

  reloadData(): void {
    this.data$ = this.getData$();
  }

  private getData$(): Observable<InventoryLocation[]> {
    this.loading.next(true);

    return this.inventoryLocationService.getInventoryLocations().pipe(
      tap({
        next: () => this.loading.next(false),
        error: () => this.loading.next(false),
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  openDeleteModal(inventoryLocation: InventoryLocation): void {
    this.inventoryLocation.next(inventoryLocation);
    this.showDeleteModal = true;
  }

  closeDeleteModal(inventoryLocation?: InventoryLocation): void {
    this.showDeleteModal = false;
    if (inventoryLocation) this.reloadData();
    this.inventoryLocation.next(null);
  }

  openEditModal(inventoryLocation: InventoryLocation): void {
    this.inventoryLocation.next(inventoryLocation);
    this.showEditModal = true;
  }

  closeEditModal(success?: boolean): void {
    this.showEditModal = false;
    if (success) this.reloadData();
    this.inventoryLocation.next(null);
  }
}
