<p-modal [heading]="'Edit Edu Transaction Category'" [open]="open" (dismiss)="close()">
  <form class="form" [formGroup]="form" (ngSubmit)="submit()">
    <div class="form-controls">
      <p-select-wrapper
        *ngrxLet="form.controls.budgetType as control"
        [filter]="true"
        [label]="'Budget Type'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <select [attr.required]="true" [formControl]="control">
          <option *ngFor="let type of budgetTypes" [value]="type">
            {{ budgetTypeTranslation[type] | transloco }}
          </option>
        </select>
      </p-select-wrapper>

      <p-text-field-wrapper
        *ngrxLet="form.controls.description as control"
        [label]="'Category Description'"
        [message]="control.errors?.['message'] ?? ''"
        [state]="control.touched && control.errors ? 'error' : 'none'"
      >
        <input type="text" [attr.required]="true" [formControl]="control" />
      </p-text-field-wrapper>
    </div>

    <p-button-group class="modal-actions">
      <p-button
        [icon]="'none'"
        [loading]="(submitting$ | async) ?? false"
        [type]="'submit'"
        [variant]="'primary'"
      >
        Edit
      </p-button>
      <p-button
        [iconSource]="'assets/icons/close.svg'"
        [type]="'button'"
        [variant]="'secondary'"
        (click)="close()"
      >
        Close
      </p-button>
    </p-button-group>
  </form>
</p-modal>
