import { CommonModule } from '@angular/common';
import { Component, inject, type OnDestroy } from '@angular/core';
import { ReactiveFormsModule, type FormGroup } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, ToastManager } from '@porsche-design-system/components-angular';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  merge,
  shareReplay,
  startWith,
  tap,
  type Observable,
} from 'rxjs';
import { SubSink } from 'subsink';
import { PagedTableComponent } from '../../shared/components/paged-table/paged-table.component';
import { TableFilterComponent } from '../../shared/components/table-filter/table-filter.component';
import { TableSortComponent } from '../../shared/components/table-sort/table-sort.component';
import type { TableSort } from '../../shared/components/table-sort/table-sort.models';
import type { EduExpenseCategory } from '../../shared/models/api/edu-expense-category.model';
import type { EduExpensePurpose } from '../../shared/models/api/edu-expense-purpose.model';
import {
  BudgetType,
  EduExpenseFilters,
  type EduExpense,
  type EduExpenseFilter,
  type EduExpenseListFilter,
} from '../../shared/models/api/edu-expense.model';
import type { ControlsOf } from '../../shared/models/controls-of.model';
import { FillArrayPipe } from '../../shared/pipes/fill-array.pipe';
import { LeadingPlusPipe } from '../../shared/pipes/leading-plus.pipe';
import { EduExpenseCategoryService } from '../../shared/services/api/edu-expense-category.service';
import { EduExpensePurposeService } from '../../shared/services/api/edu-expense-purpose.service';
import { EduExpenseService } from '../../shared/services/api/edu-expense.service';
import { ReportService } from '../../shared/services/api/report.service';
import { AuthService } from '../../shared/services/auth.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { budgetTypeTranslation } from '../edu-expense-create/edu-expense-create.helpers';
import { eduExpenseFilterTranslation } from './edu-expense-list.helpers';
import type { EduExpenseListData, EduExpenseListFormValue } from './edu-expense-list.models';

@Component({
  selector: 'opa-edu-expense-list',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    FillArrayPipe,
    LetDirective,
    TranslocoPipe,
    ReactiveFormsModule,
    TableFilterComponent,
    TableSortComponent,
    LeadingPlusPipe,
  ],
  templateUrl: './edu-expense-list.component.html',
  styleUrl: './edu-expense-list.component.scss',
})
export class EduExpenseListComponent
  extends PagedTableComponent<EduExpense, EduExpenseListFilter, EduExpenseListFormValue>
  implements OnDestroy
{
  private readonly eduExpenseService = inject(EduExpenseService);
  private readonly reportService = inject(ReportService);
  private readonly toastManager = inject(ToastManager);

  readonly categoryService = inject(EduExpenseCategoryService);
  readonly purposeService = inject(EduExpensePurposeService);
  readonly mediaMaxXs$ = inject(MediaQueryService).max('xs');
  readonly isEduEquAdmin = inject(AuthService).isEduEquAdmin();

  readonly budgetTypes = Object.values(BudgetType);
  readonly budgetTypeTranslation = budgetTypeTranslation;
  private readonly subSink = new SubSink();
  private readonly exportInProgress = new BehaviorSubject<boolean>(false);
  readonly filters = EduExpenseFilters;
  readonly filterTranslations = eduExpenseFilterTranslation;

  data$ = this.getData$();
  readonly activeFilters$ = this.getActiveFilters$();
  readonly exportInProgress$ = this.exportInProgress.asObservable();

  override query$ = this.eduExpenseService.getEduExpenses.bind(this.eduExpenseService);

  override getDefaultSort(): TableSort {
    return { sortId: 'dateUsed', direction: 'desc' };
  }

  override getFilter$(): Observable<EduExpenseListFilter> {
    return merge(
      this.form.controls.budgetType.valueChanges.pipe(distinctUntilChanged()),
      this.form.controls.dateFrom.valueChanges.pipe(distinctUntilChanged()),
      this.form.controls.dateTo.valueChanges.pipe(distinctUntilChanged()),
      this.form.controls.expenseCategoryId.valueChanges.pipe(distinctUntilChanged()),
      this.form.controls.expensePurposeId.valueChanges.pipe(distinctUntilChanged()),
      this.form.controls.name.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)),
    ).pipe(
      startWith(undefined),
      map(() => {
        const form = this.form.getRawValue();
        const filter: EduExpenseListFilter = {
          budgetType: form.budgetType === this.allValue ? undefined : form.budgetType ?? undefined,
          expenseCategoryId:
            form.expenseCategoryId === this.allValue
              ? undefined
              : form.expenseCategoryId ?? undefined,
          expensePurposeId:
            form.expensePurposeId === this.allValue
              ? undefined
              : form.expensePurposeId ?? undefined,
          dateFrom: form.dateFrom ?? undefined,
          dateTo: form.dateTo ?? undefined,
          name: form.name ?? undefined,
        };

        return filter;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  override buildForm(): FormGroup<ControlsOf<EduExpenseListFormValue>> {
    const params = this.activatedRoute.snapshot.queryParams;

    const form = this.formBuilder.nonNullable.group<ControlsOf<EduExpenseListFormValue>>({
      budgetType: this.formBuilder.control(params[EduExpenseFilters.budgetType] ?? this.allValue),
      expenseCategoryId: this.formBuilder.control(
        params[EduExpenseFilters.expenseCategoryId] ?? this.allValue,
      ),
      expensePurposeId: this.formBuilder.control(
        params[EduExpenseFilters.expensePurposeId] ?? this.allValue,
      ),
      dateFrom: this.formBuilder.control(params[EduExpenseFilters.dateFrom] ?? null),
      dateTo: this.formBuilder.control(params[EduExpenseFilters.dateTo] ?? null),
      name: this.formBuilder.control(params[EduExpenseFilters.name] ?? null),
    });
    return form;
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  exportEduExpenseList(): void {
    if (this.exportInProgress.value) {
      this.toastManager.addMessage({
        text: 'Previous export is still in progress',
        state: 'info',
      });
    }

    const form = this.form.getRawValue();
    const budgetType =
      (form.budgetType === this.allValue ? undefined : form.budgetType) ?? undefined;
    const expenseCategoryId =
      (form.expenseCategoryId === this.allValue ? undefined : form.expenseCategoryId) ?? undefined;
    const expensePurposeId =
      (form.expensePurposeId === this.allValue ? undefined : form.expensePurposeId) ?? undefined;

    this.exportInProgress.next(true);
    this.toastManager.addMessage({
      text: 'Exporting Expenses in progress',
      state: 'info',
    });

    this.subSink.sink = this.reportService
      .getEduExpenses(
        form.name ?? undefined,
        budgetType ?? undefined,
        expenseCategoryId,
        expensePurposeId,
        form.dateFrom ?? undefined,
        form.dateTo ?? undefined,
      )
      .pipe(
        tap(() =>
          this.toastManager.addMessage({
            text: 'Exporting Expenses finished',
            state: 'success',
          }),
        ),
        finalize(() => this.exportInProgress.next(false)),
      )
      .subscribe();
  }

  reloadData(): void {
    this.data$ = this.getData$();
  }

  trackRow(index: number): number {
    return index;
  }

  trackCategory(index: number, category: EduExpenseCategory): number {
    return category.id;
  }

  trackPurpose(index: number, purpose: EduExpensePurpose): number {
    return purpose.id;
  }

  private getData$(): Observable<EduExpenseListData> {
    return combineLatest([
      this.categoryService.getEduExpenseCategories(),
      this.purposeService.getEduExpensePurposes(),
      this.setQueryParams$,
    ]).pipe(
      map(([categories, purposes]) => {
        return { categories, purposes };
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private getActiveFilters$(): Observable<{ key: EduExpenseFilter; value: unknown }[]> {
    const activeFilters$ = combineLatest([this.filter$, this.data$]).pipe(
      map(([filters, data]) => {
        const filtersWithValues = Object.entries(filters).filter(([, value]) => value != null);

        return filtersWithValues.map(([key, value]) => {
          if (key === this.filters.expenseCategoryId) {
            return {
              key: key as EduExpenseFilter,
              value:
                data.categories.find((category) => category.id === Number(value))?.description ??
                'Uknown',
            };
          }
          if (key === this.filters.expensePurposeId) {
            return {
              key: key as EduExpenseFilter,
              value:
                data.purposes.find((purpose) => purpose.id === Number(value))?.description ??
                'Uknown',
            };
          }

          return { key: key as EduExpenseFilter, value };
        });
      }),
    );

    return activeFilters$;
  }
}
