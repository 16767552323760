import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import type { TableSort } from '../../components/table-sort/table-sort.models';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type {
  AssignInventoryItem,
  BulkCreateInventoryItems,
  CreateOrEditInventoryItem,
  InventoryItem,
  InventoryItemFilter,
  UnassignInventoryItem,
} from '../../models/api/inventory-item.model';
import type { Page } from '../../models/api/page.model';
import { DownloadService } from '../download.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryItemsService {
  private readonly httpClient = inject(HttpClient);
  private readonly downloadService = inject(DownloadService);
  private readonly api = `${environment.apiUrl}/v1/inventory-items`;

  getInventoryItems(
    pageNo: number | null,
    filter?: InventoryItemFilter,
    sort?: TableSort,
  ): Observable<Page<InventoryItem>> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Inventory Items');

    let params = new HttpParams();

    if (pageNo === null) params = params.set('unpaged', true);
    else params = params.set('page', pageNo).set('size', 10);

    if (filter) {
      Object.entries(filter)
        .filter(([, value]) => value !== undefined)
        .forEach(([key, value]) => (params = params.set(key, value)));
    }
    if (sort) params = params.set('sort', `${sort.sortId},${sort.direction}`);

    return this.httpClient.get<Page<InventoryItem>>(this.api, { context, params });
  }

  getMyItems(): Observable<InventoryItem[]> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get My Inventory Items');

    return this.httpClient.get<InventoryItem[]>(`${this.api}/my-inventory-items`, { context });
  }

  getInventoryItem(id: number): Observable<InventoryItem> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Inventory Item');

    return this.httpClient.get<InventoryItem>(`${this.api}/${id}`, { context });
  }

  downloadInventoryItemWarranty(id: number): Observable<void> {
    return this.downloadService.downloadFile(
      `${this.api}/${id}/warranty`,
      'Failed to Get Warranty',
    );
  }

  downloadInventoryItemReceipt(id: number): Observable<void> {
    return this.downloadService.downloadFile(`${this.api}/${id}/receipt`, 'Failed to Get Receipt');
  }

  unassign(unassignItem: UnassignInventoryItem): Observable<InventoryItem> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Unassign Inventory Item');

    return this.httpClient.put<InventoryItem>(`${this.api}/unassign`, unassignItem, { context });
  }

  assign(assignItem: AssignInventoryItem): Observable<InventoryItem> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Assign Inventory Item');

    return this.httpClient.put<InventoryItem>(`${this.api}/assign`, assignItem, { context });
  }

  downloadCreateBulkTemplate(): Observable<void> {
    return this.downloadService.downloadFile(
      `${this.api}/bulk-import-template`,
      'Failed to Get Bulk Create Template',
    );
  }

  createBulk(items: BulkCreateInventoryItems): Observable<void> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Bulk Create Inventory Items',
    );
    const formData = new FormData();

    formData.append('import', items.import);
    if (items.warranty) formData.append('warranty', items.warranty);
    if (items.receipt) formData.append('receipt', items.receipt);

    return this.httpClient.post<void>(`${this.api}/bulk-import`, formData, { context });
  }

  create(inventoryItem: CreateOrEditInventoryItem): Observable<InventoryItem> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Create Inventory Item');
    const formData = new FormData();

    if (inventoryItem.warranty) formData.append('warranty', inventoryItem.warranty);
    if (inventoryItem.receipt) formData.append('receipt', inventoryItem.receipt);

    formData.append(
      'command',
      new Blob([JSON.stringify(inventoryItem.command)], {
        type: 'application/json',
      }),
    );

    return this.httpClient.post<InventoryItem>(this.api, formData, { context });
  }

  edit(id: number, inventoryItem: CreateOrEditInventoryItem): Observable<InventoryItem> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Update Inventory Item');

    const formData = new FormData();
    if (inventoryItem.warranty) formData.append('warranty', inventoryItem.warranty);
    if (inventoryItem.receipt) formData.append('receipt', inventoryItem.receipt);

    if (inventoryItem.command) {
      formData.append(
        'command',
        new Blob([JSON.stringify(inventoryItem.command)], {
          type: 'application/json',
        }),
      );
    }

    return this.httpClient.put<InventoryItem>(`${this.api}/${id}`, formData, { context });
  }
}
