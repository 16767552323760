import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type { BankCard, CreateBankCard, EditBankCard } from '../../models/api/bank-card.model';
import type { Page } from '../../models/api/page.model';

@Injectable({
  providedIn: 'root',
})
export class BankCardService {
  private readonly httpClient = inject(HttpClient);
  private readonly bankCardApi = `${environment.apiUrl}/v1/bank-cards`;

  getBankCards(
    pageNo: number | null,
    cardNumber?: string,
    dailyLimit?: number,
    active?: boolean,
  ): Observable<Page<BankCard>> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Bank Cards');

    let params = new HttpParams();

    if (pageNo === null) params = params.set('unpaged', true);
    else params = params.set('page', pageNo).set('size', 10);

    if (cardNumber != null) params = params.set('cardNumber', cardNumber);
    if (dailyLimit != null) params = params.set('dailyLimit', dailyLimit);
    if (active != null) params = params.set('active', active);

    return this.httpClient.get<Page<BankCard>>(this.bankCardApi, { context, params });
  }

  getBankCard(bankCardId: number): Observable<BankCard> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Get Bank Card');
    return this.httpClient.get<BankCard>(`${this.bankCardApi}/${bankCardId}`, {
      context,
    });
  }

  createBankCard(bankCard: CreateBankCard): Observable<BankCard> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Create Bank Card');
    return this.httpClient.post<BankCard>(this.bankCardApi, bankCard, { context });
  }

  editBankCard(bankCardId: number, bankCard: EditBankCard): Observable<BankCard> {
    const context = new HttpContext().set(ACTION_ERROR_TITLE, 'Failed to Edit Bank Card');
    return this.httpClient.put<BankCard>(`${this.bankCardApi}/${bankCardId}`, bankCard, {
      context,
    });
  }
}
