import { Component, inject } from '@angular/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { navbarOpenerClass } from '../navbar/navbar.constants';
import { NavbarService } from '../navbar/navbar.service';

@Component({
  selector: 'opa-header',
  standalone: true,
  imports: [PorscheDesignSystemModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  private readonly navbarService = inject(NavbarService);
  readonly navbarOpenerClass = navbarOpenerClass;

  toggleNavbar(): void {
    this.navbarService.toggle();
  }
}
