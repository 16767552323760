import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ACTION_ERROR_TITLE } from '../../interceptors/http-error.interceptor';
import type {
  CreateEduExpenseCategory,
  EditEduExpenseCategory,
  EduExpenseCategory,
} from '../../models/api/edu-expense-category.model';

@Injectable({
  providedIn: 'root',
})
export class EduExpenseCategoryService {
  private readonly httpClient = inject(HttpClient);
  private readonly categoryApi = `${environment.apiUrl}/v1/codebooks/edu-equ-expense-categories`;

  getEduExpenseCategories(): Observable<EduExpenseCategory[]> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Get Edu & Equ Transaction Categories',
    );

    return this.httpClient.get<EduExpenseCategory[]>(this.categoryApi, { context });
  }

  getEduExpenseCategory(categoryId: number): Observable<EduExpenseCategory> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Get Edu & Equ Transaction Category',
    );

    return this.httpClient.get<EduExpenseCategory>(`${this.categoryApi}/${categoryId}`, {
      context,
    });
  }

  createEduExpenseCategory(category: CreateEduExpenseCategory): Observable<EduExpenseCategory> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Create Edu & Equ Transaction Category',
    );

    return this.httpClient.post<EduExpenseCategory>(this.categoryApi, category, {
      context,
    });
  }

  editEduExpenseCategory(
    categoryId: number,
    category: EditEduExpenseCategory,
  ): Observable<EduExpenseCategory> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Edit Edu & Equ Transaction Category',
    );

    return this.httpClient.put<EduExpenseCategory>(`${this.categoryApi}/${categoryId}`, category, {
      context,
    });
  }

  deleteEduExpenseCategory(categoryId: number): Observable<void> {
    const context = new HttpContext().set(
      ACTION_ERROR_TITLE,
      'Failed to Delete Edu & Equ Transaction Category',
    );

    return this.httpClient.delete<void>(`${this.categoryApi}/${categoryId}`, {
      context,
    });
  }
}
